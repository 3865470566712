<div class="row" *ngIf="materialProfileForm" [formGroup]="materialProfileForm">
    <div class="col-md-12 col-lg-9">

        <div class="card">
            <div class="card-body">
                <div class="w-100">
                    <h5 class="se-title no-uppercase">
                        {{'profileMachineGroup.item.externalDimensions'|translate}}
                        <i class="error-circle bi bi-exclamation-octagon ml-1"
                         *ngIf="materialProfileForm.get('displayName').hasError('required')"
                         [ngbTooltip]="'profileMachineGroup.displaNameToolTip' | translate"></i>
                    </h5>
                </div>

                <div class="card-items">
                    <div formArrayName="materialProfileMeasure">
                        <div class="card-item d-flex align-items-center flex-sm-row flex-column"
                            *ngFor="let materialProfileMeasure of materialProfileMeasures; let j = index"
                            [formGroupName]="j">

                            <div class="card-item-input d-flex align-items-center col-lg-3">
                                <div class="card-item-label col-lg-3">
                                    {{'profileMachineGroup.item.from'|translate}}
                                </div>
                                <div class="card-item-value col-lg-9">
                                    <input type="number" class="form-control"
                                        placeholder="{{'profileMachineGroup.item.from'|translate}}" min="0"
                                        formControlName="fromValue" max="9999">
                                </div>
                            </div>

                            <div class="card-item-input d-flex align-items-center col-lg-3">
                                <div class="card-item-label col-lg-3">
                                    {{'profileMachineGroup.item.to'|translate}}
                                </div>
                                <div class="card-item-value w-100 col-lg-9">
                                    <input type="number" class="form-control"
                                        placeholder="{{'profileMachineGroup.item.to'|translate}}" min="0"
                                        formControlName="toValue" max="9999">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-11">
                                        <div class="card-item-label col-lg-4">
                                            {{'profileMachineGroup.item.machineGroups'|translate}}
                                        </div>
                                        <ng-select [items]="machineProfiles" bindLabel="name"
                                            bindValue="idMachineProfile" class="col-lg-8"
                                            formControlName="idMachineGroup" placeholder="Maschinengruppen"></ng-select>
                                    </div>
                                    <div class="col-lg-1 d-flex align-items-center justify-content-end">
                                        <i class="bi bi-trash3" (click)="removeMaterialProfileMeasure(j)"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button class="btn font-italic add-new" (click)="addMaterialProfileMeasure()">
                        <i class="bi bi-plus-square"></i> {{'profileMachineGroup.item.addNewMeasure'|translate}}
                    </button>
                </div>

                <br>
                <div class="w-100">
                    <h5 class="se-title uppercase">
                        {{'profileMachineGroup.materialProperties'|translate}}
                    </h5>
                </div>

                <div class="card-items">
                    <div>
                        <div class="card-item d-flex align-items-center flex-sm-row flex-column">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-12">
                                        <div class="card-item-label col-lg-4">
                                            {{'profileMachineGroup.displayName'|translate}}
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="text" class="form-control"
                                                placeholder="{{'profileMachineGroup.displayName'|translate}}"
                                                formControlName="displayName" maxlength="50">
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-11">
                                        <div class="card-item-label col-lg-4">
                                            {{'profileMachineGroup.hardness'|translate}}
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="text" class="form-control"
                                                placeholder="{{'profileMachineGroup.hardness'|translate}}"
                                                formControlName="hardness" maxlength="50">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center col-lg-1"></div>
                                </div>
                            </div>
                        </div>
                
                        <div class="card-item d-flex align-items-center flex-sm-row flex-column">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-12">
                                        <div class="card-item-label col-lg-3">
                                            {{'profileMachineGroup.colorId'|translate}}
                                        </div>
                                        <div class="col-lg-1">
                                            <div *ngIf="ballColor" class="color-circle" [ngbTooltip]="'global.color' | translate" [ngStyle]="{'background-color': ballColor}"></div>
                                        </div>
                                        <ng-select [items]="colors"
                                            bindLabel="{{'profileMachineGroup.itemsLanguage'|translate}}"
                                            bindValue="idColor" class="col-lg-8"
                                            placeholder="{{'profileMachineGroup.colorId'|translate}}"
                                            formControlName="colorId"></ng-select>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-11">
                                        <div class="card-item-label  col-lg-4">
                                            {{'profileMachineGroup.conformity'|translate}}
                                        </div>
                                        <ng-select [items]="conformities"
                                            bindLabel="{{'profileMachineGroup.itemsLanguage'|translate}}"
                                            bindValue="idConformity" class="col-lg-8" [multiple]="true"
                                            placeholder="{{'profileMachineGroup.conformity'|translate}}"
                                            formControlName="conformitiesId"></ng-select>
                                    </div>
                                    <div class="d-flex align-items-center col-lg-1"></div>
                                </div>
                            </div>
                        </div>
                
                        <div class="card-item d-flex align-items-center flex-sm-row flex-column">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-12">
                                        <div class="card-item-label col-lg-4">
                                            {{ 'profileMachineGroup.minTemp' | translate }}
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="number" class="form-control" placeholder="0ºC"
                                                formControlName="minTemp" max="9999">
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-11">
                                        <div class="card-item-label col-lg-4">
                                            {{ 'profileMachineGroup.maxTemp' | translate }}
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="number" class="form-control" placeholder="0ºC"
                                                formControlName="maxTemp" max="9999">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center col-lg-1"></div>
                                </div>
                            </div>
                        </div>
                
                        <div class="card-item d-flex align-items-center flex-sm-row flex-column">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="card-item-input d-flex align-items-center col-lg-11">
                                        <div class="card-item-label col-lg-2">
                                            {{ 'profileMachineGroup.description' | translate }}
                                        </div>
                                        <div class="col-lg-10">
                                            <input type="text" class="form-control desc-input" placeholder="Description"
                                                formControlName="description" maxlength="250">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center col-lg-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                




            </div>
        </div>
    </div>

    <div class="col-lg-3 mt-md-0 mt-2">
        <div class="card w-100 h-100">
            <div class="card-body">
                <h5 class="se-title">
                    {{'profileMachineGroup.item.materialProfileDetails'|translate}}

                </h5>
                <div class="card-item border-none p-0">
                    <div class="card-item-input w-100">
                        <div class="card-item-label">
                            {{'profileMachineGroup.item.name'|translate}}
                        </div>
                        <input type="text" class="form-control" formControlName="name" readonly>
                    </div>
                    <div class="card-item-input w-100 mt-2">
                        <div class="card-item-label">
                            {{'profileMachineGroup.item.search'|translate}}
                        </div>
                        <input type="text" class="form-control" formControlName="search" readonly>
                    </div>
                    <div class="card-item-input w-100 mt-2">
                        <div class="card-item-label">
                            {{'profileMachineGroup.item.processingTimeFactor'|translate}}
                        </div>
                        <input type="number" class="form-control" formControlName="factor" min="0">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>