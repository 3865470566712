import { ActivatedRoute } from '@angular/router';
import { ParametersConfigurationService } from 'src/app/shared/services/parameters-configuration.service';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from "@ngx-translate/core";
import { MachineProfile } from '../../../shared/models/machine-profile.model';
import { MaterialProfile } from '../../../shared/models/material-profile.model';
import { MaterialProfileService } from '../../../shared/services/material-profile.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile-machine-group',
  templateUrl: './profile-machine-group.component.html',
  styleUrls: ['./profile-machine-group.component.scss'],
})

export class ProfileMachineGroupComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  machineProfiles: MachineProfile[];

  idMaterialProfileFilter = null;
  allMaterialProfiles: MaterialProfile[];
  showingMaterialProfiles: MaterialProfile[] = [];
  activatedMaterialProfiles: MaterialProfile[] = [];
  defaultRangePositions: string;

  materialProfilesLength = [];

  constructor(private materialProfileService: MaterialProfileService, private toastService: ToastService, private translationsService: TranslateService, private parametersConfigurationService: ParametersConfigurationService, private route: ActivatedRoute) { }
 
  ngOnInit(): void {
    if (this.route.snapshot.params.idMaterialProfile) {
      this.idMaterialProfileFilter = this.route.snapshot.params.idMaterialProfile;
    }
    this.parametersConfigurationService.getDefaultMaterialProfileComponents().subscribe(data => {
      this.defaultRangePositions = data;
    });

    //Fullfills the machine profiles array
    this.materialProfileService.getMachineProfiles().subscribe(data => this.machineProfiles = data);

    this.materialProfileService.getMaterialProfiles().subscribe(data => {
      if(this.idMaterialProfileFilter) {
        this.allMaterialProfiles = data.filter(x => x.idMaterialProfile === this.idMaterialProfileFilter);
        this.showingMaterialProfiles = this.allMaterialProfiles;
        this.materialProfilesLength = [].constructor(this.showingMaterialProfiles.length);
      }else {
        this.allMaterialProfiles = data;
        this.activatedMaterialProfiles = this.allMaterialProfiles.filter(x => x.isActive);
        if (this.showActiveStatus) {
          this.showingMaterialProfiles = this.allMaterialProfiles;
        } else {
          this.showingMaterialProfiles = this.activatedMaterialProfiles;
        }
        this.materialProfilesLength = [].constructor(this.showingMaterialProfiles.length);
      }
    });
    
  }

  /**
   * Determines whether switch changed on
   * @param pos 
   * @param event 
   * @param opened 
   */
  onSwitchChanged(pos, event: any, opened: boolean) {
    if (this.showingMaterialProfiles[pos].isActive !== event) {
      this.showingMaterialProfiles[pos] = { ...this.showingMaterialProfiles[pos], isActive: event };
      // find in all material profiles and update isActive property
      this.allMaterialProfiles.find(x => x.idMaterialProfile === this.showingMaterialProfiles[pos].idMaterialProfile).isActive = event;
      if (!opened) {
        this.saveMaterialProfile(this.showingMaterialProfiles[pos]);
      }
    }
  }

  /**
   * On switch show active changed show or hide not active profiles
   * @param event 
   */
  onSwitchShowActiveChanged(event) {
    if (this.showActiveStatus !== event) {
      this.showingMaterialProfiles = [];
      // save in local storage
      localStorage.setItem('show-active-machine-group', event);
      // update showing material profiles
      if (event) {
        this.showingMaterialProfiles = this.allMaterialProfiles;
      }
      else {
        this.activatedMaterialProfiles = this.allMaterialProfiles.filter(x => x.isActive);
        this.showingMaterialProfiles = this.activatedMaterialProfiles;
      }
      this.materialProfilesLength = [].constructor(this.showingMaterialProfiles.length);
    }
  }

  /**
   * Gets show active status
   */
  get showActiveStatus() {
    if(this.idMaterialProfileFilter) {return true}
    return localStorage.getItem('show-active-machine-group') === 'true';
  }

  /**
   * Saves material profile
   * @param materialProfile 
   */
  saveMaterialProfile(materialProfile: MaterialProfile) {
    this.materialProfileService.saveMaterialProfile(materialProfile).subscribe(data => {
      // show toast update success
      this.toastService.show(this.translationsService.instant('codeTranslations.material-profile-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
    }, error => this.toastService.show(this.translationsService.instant('codeTranslations.material-profile-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 }));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
