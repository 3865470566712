<div class="navigaton-buttons-container" fxLayoutAlign="space-between center">
        <div fxHide.lt-md class="me">
                <div class="text-center" *ngIf="customerCare">
                        <span title="{{customerCare.name}}" *ngIf="customerCare.name">
                                {{(customerCare.name.length>20)? (customerCare.name | slice:0:20)+'..':(customerCare.name)}}
                        </span> 
                        <span title="{{customerCare.surname}}" *ngIf="customerCare.surname">
                                {{(customerCare.surname.length>20)? (customerCare.surname | slice:0:20)+'..':(customerCare.surname)}}
                        </span>
                        <div *ngIf="!me">{{customerCare.idErp}}</div>
                </div>
                <div class="text-center" *ngIf="me">
                        <span title="{{me.name}}" *ngIf="me.name">{{(me.name.length>20)? (me.name | slice:0:20)+'..':(me.name)}}</span> 
                        <span title="{{me.surname}}" *ngIf="me.surname"> {{(me.surname.length>20)? (me.surname | slice:0:20)+'..':(me.surname)}}</span>
                        <div>{{me.idErp}}</div>
                </div>
        </div>
        <app-shopping-cart-button *ngIf="showShoppingCart"></app-shopping-cart-button>
        <img class="nav-item" src="/assets/img/icons/login.svg" alt="login-icon" draggable="false" (click)="logout()">
        <app-sidebar class="nav-item"></app-sidebar>
</div>
