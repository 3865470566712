import { map } from 'rxjs/operators';
import { ParametersConfiguration } from './../models/parameters-configuration.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { User } from './../models/user.model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersConfigurationService {
  private apiUrl = environment.apiUrl;
  private user: User;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  /**
   * Gets parameters
   * @returns Observable
   */
  getParameters(): Observable<ParametersConfiguration[]> {
    return this.http.get<ParametersConfiguration[]>(`${this.apiUrl}/parameters/admin`);
  }

  /**
   * Gets date offset parameter
   * @returns date offset parameter
   */
  getDateOffsetParameter() {
    return this.http.get<number>(`${this.apiUrl}/parameters/date-offset`);
  }

  /**
   * Gets min framework contract orange parameter
   * @returns date offset parameter
   */
  getMinFrameworkContractOrange() {
    return this.http.get<number>(`${this.apiUrl}/parameters/framework-contract-orange`);
  }
  /**
   * Gets default material profile dimensions
   * @returns  
   */
  getDefaultMaterialProfileComponents() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(`${this.apiUrl}/parameters/default-material-measure-profile-range-dimensions`, { headers, responseType: 'text' });
  }


  /**
   * Updates parameters
   * @param parametersList parameters list
   * @returns Observable
   */
  updateParameters(parametersList: ParametersConfiguration[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/parameters/admin`, parametersList);
  }

}
