import { ScaledPrice } from 'src/app/shared/models/scaled-price.model';
import { ArticlesListMode } from './../models/articles-list-mode.enum';
import { CapacityPriceStatus } from './../models/capacity-price-status.enum';
export class ArticlesListUtils {

    /**
     * Gets status color
     * @param priceStatus LOW / NA / HIGH
     * @returns color
     */
    public static getStatusColor(priceStatus: string) {
        if (priceStatus === CapacityPriceStatus.LOW) {
            return '#4AB371';
        } else if (priceStatus === CapacityPriceStatus.NA) {
            return '#FE6A6B';
        } else if (priceStatus === CapacityPriceStatus.HIGH) {
            return '#FEA366';
        } else if (priceStatus === CapacityPriceStatus.PAST) {
            return '#CCCCCC';
        } else if (priceStatus === CapacityPriceStatus.HOLIDAY) {
            return '#CCCCCC';
        } else if (priceStatus === CapacityPriceStatus.NO_MATERIAL) {
            return '#6fbadf';
        } else if (priceStatus === CapacityPriceStatus.MIN_POSITION) {
            return '#6fbadf';
        } else if (priceStatus === CapacityPriceStatus.UNVALIDATED) {
            return '#DDDDDD';
        } else if (priceStatus === CapacityPriceStatus.BELOW_MIN_AMOUNT_SCALED_PRICE) {
            return '#6fbadf';
        } else if (priceStatus === CapacityPriceStatus.ABOVE_MAX_AMOUNT) {
            return '#6fbadf';
        } else if (priceStatus === CapacityPriceStatus.MAX_ORANGE) {
            return '#FE6A6B';
        } else if (priceStatus === CapacityPriceStatus.NO_PROCESS_STARTING_DAY) {
            return '#FE6A6B';
        } else if (priceStatus === CapacityPriceStatus.TRADE_GOODS) {
            return '#4AB371';
        }
    }

    /**
     * Checks if status is available
     * @param priceStatus  status
     * @param [permitUnvalidated] boolean
     * @returns boolean
     */
    public static checkStatusIsAvailable(priceStatus, permitUnvalidated = false) {
        if (priceStatus === CapacityPriceStatus.LOW || priceStatus === CapacityPriceStatus.HIGH ||
            priceStatus === CapacityPriceStatus.BELOW_MIN_AMOUNT_SCALED_PRICE || priceStatus === CapacityPriceStatus.TRADE_GOODS) {
            return true;
        } else if (permitUnvalidated && priceStatus === CapacityPriceStatus.UNVALIDATED) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Gets scaled price margin
     */
    public static getScaledPriceByAmount(scaledPrices: ScaledPrice[], amount: number) {
        const data = scaledPrices.filter(a => amount >= a.fromUnits && amount <= a.toUnits);
        return data[0] || null;
    }

    /**
     * Determines whether cart mode is
     * @param mode ArticlesListMode
     * @returns boolean
     */
    public static isCartMode(mode: ArticlesListMode) {
        return mode === ArticlesListMode.CART || mode === ArticlesListMode.CART_UNVALIDATED;
    }

    /**
     * Determines whether articles mode is
     * @param mode ArticlesListMode
     * @returns boolean
     */
    public static isArticlesMode(mode: ArticlesListMode) {
        return mode === ArticlesListMode.ARTICLES;
    }
}
