<div class="modal-header">
	<h4 class="modal-title">{{ title }}</h4>

</div>
<div class="modal-body">
	{{ message }}
</div>
<div class="modal-footer">
	<app-button class="hse-button" type="button" (click)="decline()">{{ btnCancelText }}</app-button>
	<app-button class="hse-button" type="button" (click)="accept()">{{ btnOkText }}</app-button>
</div>