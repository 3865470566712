import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { HttpClient } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './../app-routing.module';
import { AddNewButtonComponent } from './components/add-new-button/add-new-button.component';
import { ArticlesFilterComponent } from './components/articles-filter/articles-filter.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { RemoveConfirmationModalComponent } from './components/delete-confirmation/remove-confirmation-modal.component';
import { ConfirmationModalComponent } from './components/delete-confirmation-simple/confirmation-modal.component';
import { DiagramComponent } from './components/diagram/diagram.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationButtonsComponent } from './components/header/navigation-buttons/navigation-buttons.component';
import {
  ShoppingCartButtonComponent,
} from './components/header/navigation-buttons/shopping-cart-button/shopping-cart-button.component';
import { SidebarComponent } from './components/header/sidebar/sidebar.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PersonalContactComponent } from './components/personal-contact/personal-contact.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ScaledPricesTableComponent } from './components/scaled-prices-table/scaled-prices-table.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { ToastComponent } from './components/toast/toast.component';
import { BottomNavigationComponent } from './components/bottom-navigation/bottom-navigation.component';
import { AnimatedCheckmarkComponent } from './components/animated-checkmark/animated-checkmark.component';
import { FileUploadPopupComponent } from './components/file-upload-popup/file-upload-popup.component';
import { CloseConfirmationDialogComponent } from './components/close-confirmation-dialog/close-confirmation-dialog.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { ColorDropdownComponent } from './components/color-dropdown/color-dropdown';

@NgModule({
  declarations: [
    SearchBarComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    NavigationButtonsComponent,
    PersonalContactComponent,
    SidebarComponent,
    DatepickerComponent,
    CheckboxComponent,
    SectionHeaderComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    ColorDropdownComponent,
    ShoppingCartButtonComponent,
    ToastComponent,
    ScaledPricesTableComponent,
    RadioButtonComponent,
    DiagramComponent,
    ArticlesFilterComponent,
    BreadcrumbComponent,
    ProgressBarComponent,
    EditProfileComponent,
    SwitchButtonComponent,
    ImageUploaderComponent,
    RemoveConfirmationModalComponent,
    ConfirmationModalComponent,
    AddNewButtonComponent,
    BottomNavigationComponent,
    AnimatedCheckmarkComponent,
    FileUploadPopupComponent,
    CloseConfirmationDialogComponent,
    ModalPopupComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] },
    }),
    AppRoutingModule,
    NgxChartsModule,
    AngularSvgIconModule.forRoot(),
    ImageCropperModule,
    NgxDropzoneModule,
    NgSelectModule,
    DragDropModule
  ],
  exports: [
    SearchBarComponent,
    HeaderComponent,
    FooterComponent,
    PersonalContactComponent,
    PageNotFoundComponent,
    DatepickerComponent,
    CheckboxComponent,
    SectionHeaderComponent,
    LoadingSpinnerComponent,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    NgSelectModule,
    DragDropModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ButtonComponent,
    ToastComponent,
    ScaledPricesTableComponent,
    RadioButtonComponent,
    NgxChartsModule,
    DiagramComponent,
    AngularSvgIconModule,
    ArticlesFilterComponent,
    BreadcrumbComponent,
    ProgressBarComponent,
    EditProfileComponent,
    SwitchButtonComponent,
    ImageUploaderComponent,
    RemoveConfirmationModalComponent,
    AddNewButtonComponent,
    BottomNavigationComponent,
    AnimatedCheckmarkComponent,
    FileUploadPopupComponent,
    ColorDropdownComponent,
  ]
})
export class SharedModule { }
export function TranslationLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}
