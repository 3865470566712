<app-section-header section="manage-textblocks"></app-section-header>
<div class="textblocks">
    <div class="container-fluid">
        <div class="textblocks-table">
            <div class="controls">
                <app-button class="add-new-button ml-auto mb-3" type="button" (click)="addNewActivate()" i18n *ngIf="!addNew">Bestellkommentar hinzufügen</app-button>
            </div>
            <section class="se-table-flex">
                <div class="se-table-header se-table-row">
                    <div class="se-table-col pos-col" i18n>Pos</div>
                    <div class="se-table-col lg" i18n>Beschreibung (DE)</div>
                    <div class="se-table-col lg" i18n>Beschreibung (EN)</div>
                    <div class="se-table-col sm" i18n>Aktion</div>
                </div>
                <app-textblock-row *ngIf="addNew" [(createMode)]="addNew" (refresh)="loadTextblocks()"></app-textblock-row>
                <app-textblock-row [(textblock)]="textblocks[i]" *ngFor="let item of textblocks; let i = index" (refresh)="loadTextblocks()"></app-textblock-row>
                <div *ngIf="loading" class="loading se-table-col sm d-flex">
                    <app-loading-spinner></app-loading-spinner>
                </div>
                <div class="no-textblocks se-table-col lg d-flex" *ngIf="textblocks?.length == 0 && !loading && !addNew" i18n>Keine Artikel zu zeigen</div>
            </section>
        </div>
    </div>
</div>
