<header>
  <div class="navigation-bar">
    <div class=" container-fluid">
      <div fxLayout.md="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="start center">
        <div class="logo" fxFlex="15" fxFlex.lt-sm="10">
          <a [routerLink]="['/dashboard']">
            <img fxHide.lt-md class="logo__img" src="/assets/img/logos/logo.png" alt="haenssler-logo">
            <img fxShow.lt-md fxHide class="logo__img" src="/assets/img/logos/logo-min.png" alt="haenssler-logo">
          </a>
        </div>
        <div class="controls" fxLayoutAlign="end center" fxShow.lt-sm fxHide [style.flex]="'1 1 90%'" *ngIf="currentUser">
          <app-navigation-buttons></app-navigation-buttons>
        </div>
        <div class="navigation-items w-100" fxLayoutAlign="space-between center" fxFlex="70" fxFlex.lt-md="65"
          fxFlex.lt-sm="100" *ngIf="currentUser">
          <div class="search m-auto" fxFlex.lt-sm="100">
            <app-search-bar></app-search-bar>
          </div>
        </div>
        <div class="controls" fxLayoutAlign="end center" fxFlex="15" fxFlex.lt-md="20" fxHide.lt-sm *ngIf="currentUser">
          <app-navigation-buttons></app-navigation-buttons>
        </div>
      </div>
    </div>
  </div>
</header>
