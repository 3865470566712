import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/models/user.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUser: User;
  privacyPolicyUrl;
  constructor(
    private authenticationService: AuthenticationService,
    private translationsService: TranslateService,
    private router: Router
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.privacyPolicyUrl = this.translationsService.instant('codeTranslations.privacy-policy-footer-url');
  }

  ngOnInit(): void {
  }

  get showPersonalContact() {
    return this.currentUser && this.router.url !== '/manage-account';
  }
}
