import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {
  @Input() disabled = true;
  @Input() value = true;
  @Input() leftText: string;
  @Input() rightText: string;
  @Input() width = '60px';
  @Output() valueChanged = new EventEmitter<boolean>();
  @Input() updateValue = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.valueChanged.emit(this.value);
    this.updateValue.subscribe((value: boolean) => this.value = value);
  }

  /**
   * Gets active color
   */
  get activeColor(): string {
    return this.disabled ? '#afafaf' : '#000';
  }
}
