import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  /**
   * Shows toast message
   * @param textOrTpl text or tpl to show
   * @param [options] styles and duration
   */
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if(options.autohide === undefined) {
      options.autohide = true;
    }
    if(options.avoidDuplicates) {
      this.toasts = this.toasts.filter(t => t.textOrTpl !== textOrTpl);
    }
    this.toasts.push({ textOrTpl, ...options });
    // console.log(this.toasts);
  }

  /**
   * Removes toast message
   * @param toast toast object
   */
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}

