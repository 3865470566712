import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ArticleType } from 'src/app/shared/models/article-type.enum';
import { environment } from './../../../environments/environment';
import { CreateOrderResponse } from './../models/create-order-response.model';
import { OrderHistory } from './../models/order-history.model';
import { OrderRequest } from './../models/order-request.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private apiUrl = environment.apiUrl;
  private userId: string;
  API_ERROR_STATUS = 'API_ERROR';
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Requests an order creation
   * @param orderRequest order request
   * @returns order response
   */
  createOrder(orderRequest: OrderRequest): Observable<any> {
    return this.http.post(`${this.apiUrl}/order/${this.userId}/add-order`, orderRequest).pipe(
      catchError(error => {
        const response = new CreateOrderResponse();
        response.status = this.API_ERROR_STATUS;
        response.message = error.message ? error.message : error;
        console.error('There was an error while creating the your order, please contact Haenssler.');
        return of(response);
      }));
  }

  /**
   * Gets orders
   * @param startPosition start
   * @param endPosition end
   * @returns Observable
   */
  getOrders(startPosition: number, endPosition: number, searchText = null) {
    const search = searchText == null ? '' : searchText + '/';
    return this.http.get<OrderHistory[]>(`${this.apiUrl}/order/${this.userId}/${startPosition}/${endPosition}/${search}`).pipe(map(
      orders => orders.map(order => {
        order.orderArticles.map(art => { art.articleType = ArticleType.ORDER_ARTICLE; return art; }); return order;
      })
    ), catchError(error => {
      // console.error('There was an error while loading your orders, please contact Haenssler.');
      return of([]);
    }));
  }

  /**
   * Searchs orders by idOrderERP
   * @param idOrderERP idOrderERP
   * @returns Observable
   */
  searchOrdersById(idOrderERP) {
    return this.http.get<OrderHistory[]>(`${this.apiUrl}/order/${this.userId}/order-search/${idOrderERP}`).pipe(map(
      orders => orders.map(order => {
        order.orderArticles.map(art => { art.articleType = ArticleType.ORDER_ARTICLE; return art; }); return order;
      })
    ), catchError(error => {
      // console.error('There was an error while loading your orders, please contact Haenssler.');
      return of([]);
    }));
  }
}
