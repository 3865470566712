<div class="cart">
    <app-cart-progress></app-cart-progress>
    <div class="unvalid-articles" [ngClass]="{'d-none': !showUnvalidatedArticles}">
        <div class="container-fluid">
            <h3 class="cart__title se-title" i18n>{{'cart.pendingForValidation'|translate}}</h3>
        </div>
        <div class="cart__list cart__list-unvalidated">
            <app-articles-list [articlesListMode]="unvalidArticlesListMode" (articlesListChanged)="unvalidArticlesChanges($event)"></app-articles-list>
        </div>
    </div>
    <div class="container-fluid">
        <h3 class="cart__title se-title" i18n>{{'cart.shoppingCart'|translate}}</h3>
    </div>
    <div class="cart__list">
        <app-articles-list [articlesListMode]="articlesListMode" (articlesListChanged)="articlesChanges($event)"></app-articles-list>
    </div>
    <div class="container-fluid">
        <h5 class="se-title" i18n>{{'cart.addOrderComment'|translate}}</h5>
        <div class="d-flex pb-2">
            <div ngbDropdown class="textblock-dropdown mr-2" placement="bottom-left">
                <button class="btn se-button" ngbDropdownToggle [disabled]="!this.allowTextblockSelection">
                    <span *ngIf="textblock">
                        {{(getTextboxText(textblock)| slice:0:25) + (getTextboxText(textblock).length > 25 ? '...' : '')}} </span>
                    <span *ngIf="!textblock" i18n>{{'cart.selectOrderComment'|translate}}</span>
                </button>
                <div ngbDropdownMenu>
                    <button ngbDropdownItem *ngFor="let item of textblocks; let i = index" (click)="selectTextblock(item)">
                        <ng-container> {{(getTextboxText(item)| slice:0:130) + (getTextboxText(item).length > 130 ? '...' : '')}}</ng-container>
                    </button>
                </div>
            </div>
            <textarea name="textblock" class="se-textarea w-100" [formControl]="textblockValue" *ngIf="textblockValue.value !== '' && this.allowTextblockSelection"></textarea>
            <button type="button" class="btn bg-transparent textblock-clear" *ngIf="textblockValue.value !== '' && this.allowTextblockSelection" (click)="clearTextblockText()"><i class="bi bi-x"></i></button>
        </div>
    </div>
    <app-cart-navigation [disableForward]="!allowGotoOrderPage" step=1></app-cart-navigation>
</div>
