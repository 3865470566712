import { UserRoleType } from './../shared/models/user-role-type.enum';
import { ShoppingCartService } from './../shared/services/shopping-cart.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AccountManagerGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            if (this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER)) {
                // if user has AccountManager role
                return true;
            }
            this.router.navigate(['/dashboard']);
            return false;
        }

        // otherwise redirect to dashboard
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

}
