<div class="container-fluid">
    <div class="login">
        <div class="login__background"></div>
        <form class="login__form se-panel" [formGroup]="loginForm">
            <div class="login__header">
                <h1 class="login__title" i18n> Sales Engine </h1>
            </div>
            <div class="login__body">
                <div class="login__input-container">
                    <div class='login__icon login__icon_email'></div>
                    <input type="text" name="username" formControlName="username"
                        class="login__input login__input_email se-input" placeholder="{{'login.email'|translate}}"
                        [ngClass]="{ 'is-invalid': submitted && fields.username.errors }" i18n-placeholder>
                </div>
                <div *ngIf="submitted && fields.username.errors" class="invalid-feedback">
                    <div *ngIf="fields.username.errors.required" i18n>{{'login.emailRequired'|translate}}</div>
                </div>
                <div class="login__input-container">
                    <div class='login__icon login__icon_password'></div>
                    <input type="password" name="password" formControlName="password"
                        class="login__input login__input_password se-input" placeholder="{{'login.password'|translate}}"
                        [ngClass]="{ 'is-invalid': submitted && fields.password.errors }" i18n-placeholder>
                </div>
                <div *ngIf="submitted && fields.password.errors" class="invalid-feedback">
                    <div *ngIf="fields.password.errors.required" i18n>{{'login.passwordRequired'|translate}}</div>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
                    {{error == BAD_CREDENTIALS_ERROR ? translationsService.instant(BAD_CREDENTIALS_ERROR_KEY): error}}
                </div>
                <app-button label="{{'login.login'|translate}}" padding="10px" (onClick)="onSubmit()" class="login__button">
                </app-button>
                <a class="login__forgot-password" [routerLink]="['/forgot-password']" i18n> {{'login.forgotEmailPassword'|translate}} </a>
            </div>
        </form>
    </div>
</div>
