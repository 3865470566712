import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Colors } from 'src/app/shared/models/colors.model';
import { Conformities } from 'src/app/shared/models/conformities';
import { MaterialProfile } from 'src/app/shared/models/material-profile.model';
import { MeasureAbasType } from 'src/app/shared/models/measure-abas-type.enum';
import { MaterialAppService } from 'src/app/shared/services/material-app.service';
import { MaterialProfileService } from 'src/app/shared/services/material-profile.service';
import { OrderSealService } from 'src/app/shared/services/order-seal.service';


@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss']
})
export class MaterialTableComponent implements OnInit {

  @Input() outerDimensionAD: number;
  private unsubscribe: Subject<void> = new Subject();


  headers = [
    { property: "name", colsize: "col-2" },
    { property: "description", colsize: "col-2" },
    { property: "colorId", colsize: "col-1" },
    { property: "hardness", colsize: "col-1" },
    { property: "conformitiesId", colsize: "col-2" },
    { property: "minTemp", colsize: "col-1" },
    { property: "maxTemp", colsize: "col-1" },
    { property: "", colsize: "col-2" }
  ];

  selectedProfile: any;

  // Linked to the search inputs
  searchInput: string = '';   // General one
  searchName: string = '';
  searchDisplayName: string = '';
  searchDescription: string = '';
  searchHardness: string = '';
  searchTemp: number;
  selectedColor: number[] = [];
  selectedConformities: string[] = [];

  materialProfiles: MaterialProfile[];  // General Data array

  colors: Colors[]

  displayMaterials: MaterialProfile[] = [];
  sortingDirections: { [key: string]: 'asc' | 'desc' } = {};

  translatedItemsLanguage: string;
  showAdvancedSearch = false;

  usedMaterialsIds = [];
  usedColors = [];
  conformities: Conformities[];
  usedConformities = [];

  tooltipContent: string;
  inputSvgValue: number;

  constructor(
    private materialProfileService: MaterialProfileService,
    private translationService: TranslateService,
    public orderSealService: OrderSealService,
    private materialAppService: MaterialAppService,
    private modalService: NgbModal) { }


  ngOnInit(): void {
    this.loadData()

    this.headers.forEach(header => this.sortingDirections[header.property] = 'asc');

    this.loadColorDropdownTranslation()

    this.selectedProfile = this.orderSealService.selectedProfileValue.profileComponents[0].profileComponentMaterials;
  }

  //Fake objects that will be used in case the external api from Werkstoffe is failing, so the materialtable still wroks
  mockColors: Colors[] = [{ idColor: 1, name: '', nameEnglish: '', hexColor: '' }];
  mockConformities: Conformities[] = [{ idConformity: 1, shortName: '', name: '', nameEnglish: '' }];

  //Loads all the arrays of information used on the table
  loadData() {
    this.materialAppService.getToken().subscribe((tokenValue: any) => {
      this.materialAppService.getColors(tokenValue.token).pipe(
        catchError(error => {
          console.error('Error fetching colors:', error);
          return of(this.mockColors);
        })
      ).subscribe(data => {
        this.colors = data;
      });

      this.materialAppService.getConformities(tokenValue.token).pipe(
        catchError(error => {
          console.error('Error fetching conformities:', error);
          return of(this.mockConformities);
        })
      ).subscribe(data => {
        this.conformities = data;
      });

      this.materialProfileService.getMaterialProfilesActive().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.materialProfiles = data;

        this.sortMaterialProfiles("idMaterialProfile"); // Sorts by default when data is loaded
        this.filterEnabledMaterials()
      });
    });
  }



  // Hides all the materials that are not active/compatible on the Material Profile
  filterEnabledMaterials() {
    // Makes an array of enabled materials ID's
    this.usedMaterialsIds = []
    for (const item of this.selectedProfile) {
      this.usedMaterialsIds.push(item.materialProfile.idMaterialProfile);
    }

    // Clone the materialProfiles array to prevent modifying the original array
    this.displayMaterials = [...this.materialProfiles];

    this.displayMaterials = this.displayMaterials.filter(material => {
      return this.usedMaterialsIds.includes(material.idMaterialProfile);
    });

    this.materialProfiles = this.displayMaterials.filter(material => {
      return this.usedMaterialsIds.includes(material.idMaterialProfile);
    });

    this.populateUsedColors()
    this.populateUsedConformities()
  }


  // Puts on the colors select, the colors that are used by the active materials
  populateUsedColors() {
    // Use a Set to keep track of unique color IDs.
    const uniqueColorIds = new Set<number>();

    // Map the displayMaterials to extract unique color IDs.
    this.displayMaterials.forEach(material => {
      uniqueColorIds.add(material.colorId);
    });

    // Map the unique color IDs to create the usedColors array.
    this.usedColors = Array.from(uniqueColorIds).map(colorId => {
      return {
        id: colorId,
        name: this.getColorNameById(colorId, this.translationService.currentLang)
      };
    });
  }


  //Returns the Hexadecimal property giving its color ID
  getColorHexById(colorId: number): string {
    const color = this.colors.find((c) => c.idColor === colorId);
    return color ? color.hexColor : null;
  }


  getColorNameById(colorId: number, lang: string) {
    if (lang == "en") {
      const color = this.colors.find((c) => c.idColor === colorId);
      return color ? color.nameEnglish : 'N/A';
    } else {
      const color = this.colors.find((c) => c.idColor === colorId);
      return color ? color.name : 'N/A';
    }
  }

  // Puts on the conformities select, the conformities that are used by the active materials
  populateUsedConformities() {
    const usedConformityIds = new Set<number>();

    for (const material of this.displayMaterials) {
      if (material.conformitiesId && material.conformitiesId.length > 0) {
        for (const conformityId of material.conformitiesId) {
          usedConformityIds.add(conformityId);
        }
      }
    }

    // Filter the conformities array to include only those used in displayMaterials
    this.usedConformities = this.conformities.filter(conformity => usedConformityIds.has(conformity.idConformity));
  }


  //Returns the list of conformities shortname, giving its ID's
  getConformityNamesByIds(conformitiesIds: number[]): string[] {
    const conformityNames = [];
    for (const id of conformitiesIds) {
      const conformity = this.conformities.find((c) => c.idConformity === id);
      if (conformity) {
        if (this.translatedItemsLanguage == "nameEnglish") {
          conformityNames.push(conformity.nameEnglish);
        } else {
          conformityNames.push(conformity.name);
        }
      }
    }
    return conformityNames;
  }


  sortMaterialProfiles(header: string) {
    // Toggle the sorting direction for the current header
    this.sortingDirections[header] = this.sortingDirections[header] === 'asc' ? 'desc' : 'asc';

    // Get the sorting direction for the current header
    const direction = this.sortingDirections[header] === 'asc' ? 1 : -1;

    // Logic based on the sorting direction
    this.displayMaterials.sort((a, b) => {
      // Compares nulls one way or another depending if it's a string or a number
      if (header === 'name' || header === 'displayName' || header === 'description' || header === 'hardness' || header === 'conformitiesId'.toString()) {
        return direction * this.compareNullableStrings(a[header], b[header], direction);
      } else if (header === 'colorId' || header === 'minTemp' || header === 'maxTemp') {
        return this.compareNullableNumbers(a[header], b[header], direction);
      }

      return 0; // No sorting applied for other headers
    });
  }


  // Applies logic to null strings for sorting
  compareNullableStrings(valA: string | null | undefined, valB: string | null | undefined, direction) {
    if (valA === null && valB === null) { return 0; }

    if (valA === null) { return (direction === 'asc') ? 1 : -1; }
    if (valB === null) { return (direction === 'asc') ? -1 : 1; }

    // Convert null values to empty strings
    const strA = valA == null ? "" : valA.toString();
    const strB = valB == null ? "" : valB.toString();

    return strA.localeCompare(strB);
  }

  // Applies logic to null numbers for sorting
  compareNullableNumbers(valA: number | number[] | null | undefined, valB: number | number[] | null | undefined, direction) {
    const nullValue = (direction === 'asc') ? 1 : -1;

    if ((valA === null || valA === undefined) && (valB === null || valB === undefined)) {
      return 0;
    }

    if (valA === null || valA === undefined) {
      return nullValue;
    }

    if (valB === null || valB === undefined) {
      return -nullValue;
    }

    const compareFunction = (a, b) => (a - b);

    if (Array.isArray(valA) && Array.isArray(valB)) {
      // Compare the arrays element by element
      const minLength = Math.min(valA.length, valB.length);
      for (let i = 0; i < minLength; i++) {
        const result = compareFunction(valA[i], valB[i]);
        if (result !== 0) {
          return result * direction;
        }
      }

      // If the common elements are equal, compare the lengths
      return compareFunction(valA.length, valB.length) * direction;
    } else if (Array.isArray(valA) && !Array.isArray(valB)) {
      return compareFunction(valA.reduce((sum, num) => sum + num, 0), valB) * direction;
    } else if (!Array.isArray(valA) && Array.isArray(valB)) {
      return compareFunction(valA, valB.reduce((sum, num) => sum + num, 0)) * direction;
    } else {
      return compareFunction(valA, valB) * direction;
    }
  }




  getTranslation(header: any) {
    return this.translationService.instant(`profileMachineGroup.${header}`);
  }


  // FILTERS 🔍

  resetFilter() {
    // Removes the texts on the inputs
    this.searchName = '';
    this.searchDisplayName = '';
    this.searchDescription = '';
    this.searchHardness = '';
    this.searchTemp = null;
    this.selectedColor = [];
    this.selectedConformities = [];

    this.displayMaterials = this.materialProfiles;  // Copies from the original array so theres no filter or sorting

    this.filterEnabledMaterials()
  }

  generalFilter() {
    const searchTerm = this.searchDisplayName?.toLowerCase().trim();

    this.displayMaterials = this.materialProfiles.filter((material) =>
      Object.values(material).some((value) =>
        value !== null && value?.toString().toLowerCase().includes(searchTerm)
      )
    );

  }

  filterMaterialColor() {
    if (!this.selectedColor || this.selectedColor.length === 0) {
      // If no colors are selected, show all material profiles.
      this.displayMaterials = [...this.materialProfiles];
    } else {
      // Filter material profiles based on selected colors.
      this.displayMaterials = this.materialProfiles.filter(material => {
        return this.selectedColor.includes(material.colorId);
      });
    }
  }

  filterByConformities() {
    if (!this.selectedConformities || this.selectedConformities.length === 0) {
      // If no conformities are selected, show all material profiles.
      this.displayMaterials = this.materialProfiles;
    } else {
      // Filter material profiles based on selected conformities.
      this.displayMaterials = this.materialProfiles.filter(material => {
        return this.selectedConformities.every(selectedId => material.conformitiesId.includes(parseInt(selectedId)));
      });
    }
  }

  filterByName() {
    if (!this.searchName) {
      this.displayMaterials = this.materialProfiles;
    } else {
      this.displayMaterials = this.materialProfiles.filter(material => {
        return (material.name && material.name.toLowerCase().includes(this.searchName.toLowerCase()));
      });
    }
  }

  filterByDisplayName() {
    if (!this.searchDisplayName) {
      this.displayMaterials = this.materialProfiles;
    } else {
      this.displayMaterials = this.materialProfiles.filter(material => {
        return (material.displayName && material.displayName.toLowerCase().includes(this.searchDisplayName.toLowerCase()));
      });
    }
  }

  filterByDescription() {
    if (!this.searchDescription) {
      this.displayMaterials = this.materialProfiles;
    } else {
      this.displayMaterials = this.materialProfiles.filter(material => {
        return (material.description && material.description.toLowerCase().includes(this.searchDescription.toLowerCase()));
      });
    }
  }

  filterByHardness() {
    if (!this.searchHardness) {
      this.displayMaterials = this.materialProfiles;
    } else {
      this.displayMaterials = this.materialProfiles.filter(material => {
        return (material.hardness && material.hardness.toLowerCase().includes(this.searchHardness.toLowerCase()));
      });
    }
  }

  filterByTempRange() {
    if (!this.searchTemp) {
      this.displayMaterials = this.materialProfiles;
    } else {
      this.displayMaterials = this.materialProfiles.filter(material => {
        if (this.searchTemp) {
          return this.searchTemp >= material.minTemp && this.searchTemp <= material.maxTemp;
        }
      });
    }
  }
  // END OF FILTERS 🔍



  loadColorDropdownTranslation() {
    this.translationService.use(this.translationService.currentLang);
    this.translationService.get('profileMachineGroup.itemsLanguage').subscribe((translation: string) => {
      this.translatedItemsLanguage = translation;
    });
  }
  
 
  disableOnIncorrectMeasures(material: MaterialProfile): boolean {
    // Searches on the array for the Aussendurchmesser fromValue commented Franz 15nov23
    // const definitionMinMeasure = this.orderSealService.selectedProfileValue.profileMeasureInputLines.find(lineObject => lineObject.idMeasureAbas == MeasureAbasType.AD)?.fromValue ?? null;
    // const definitionMaxMeasure = this.orderSealService.selectedProfileValue.profileMeasureInputLines.find(lineObject => lineObject.idMeasureAbas == MeasureAbasType.AD)?.toValue ?? null;

    const materialMinMeasure = material.materialProfileMeasure[0].fromValue;
    const materialMaxMeasure = material.materialProfileMeasure[material.materialProfileMeasure.length - 1].toValue;

    this.tooltipContent = `${this.translationService.instant('materialTable.dimensionBetween')} ${materialMinMeasure} - ${materialMaxMeasure} ${this.translationService.instant('materialTable.dimensionBetween2')}`;
    
    let isValid = this.outerDimensionAD >= materialMinMeasure && this.outerDimensionAD <= materialMaxMeasure
 
    return isValid;
  }


  applyMaterial(i: number) {
    this.materialProfileService.applyMaterial.emit(i)
    this.modalService.dismissAll();
  }

}
