import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  label: string;
  url: string;
  breadcrumbs: any[] = [];
  showBreadcrumb = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }


  /**
   * Builds breadcrumb object
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '') {
    const label = route.routeConfig ? route.routeConfig.data.breadcrumb : 'Dashboard';
    const path = route.routeConfig ? `/${route.routeConfig.path}` : '';

    const nextUrl = `${url}${path}`;
    const breadcrumb = {
      label,
      url: nextUrl
    };
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl);
    }
    return breadcrumb;
  }

  subscribeOnCurrentRoute() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), distinctUntilChanged(), map(() => {
        return this.buildBreadCrumb(this.activatedRoute.root);
      }
      ))
      .subscribe(event => {
        const lastRepeatedIndex = _.findLastIndex(this.breadcrumbs, ['url', event.url]);
        if (event.url === '/dashboard') {
          this.showBreadcrumb = false;
          this.breadcrumbs = [];
        } else if (lastRepeatedIndex !== -1) {
          this.breadcrumbs.splice(lastRepeatedIndex, this.breadcrumbs.length);
        }
        if (event.url !== '/dashboard') {
          this.showBreadcrumb = true;
        }
        this.breadcrumbs.push(event);
        if (_.findLastIndex(this.breadcrumbs, ['url', '/dashboard']) === -1) {
          this.breadcrumbs.unshift({ label: 'Dashboard', url: '/dashboard' });
        }
      });
  }
}
