import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialProfileService } from 'src/app/shared/services/material-profile.service';

@Component({
  selector: 'app-material-table-popup',
  templateUrl: './material-table-popup.component.html',
  styleUrls: ['./material-table-popup.component.scss']
})
export class MaterialTablePopupComponent implements OnInit {

  outerDimensionAD:number;
  constructor(private materialProfileService: MaterialProfileService, private modalService: NgbModal) { }


  ngOnInit(): void {
    this.outerDimensionAD = this.materialProfileService.outerDimensionAD4PopUp;
    console.log("Entering ngOninit with outerDimensionAD4PopUp=",this.outerDimensionAD);
  }

  closeModal() {
    this.modalService.dismissAll(); // Close all open modals
  }

}
