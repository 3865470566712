import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Textblock } from './../../../shared/models/textblock.model';
import { TextblockService } from './../../../shared/services/textblock.service';

@Component({
  selector: 'app-textblock-row',
  templateUrl: './textblock-row.component.html',
  styleUrls: ['./textblock-row.component.scss']
})
export class TextblockRowComponent implements OnInit {
  @Input() textblock: Textblock;
  @Output() textblockChange = new EventEmitter<any>();
  editMode = false;
  textblockForm: UntypedFormGroup;
  @Input() createMode = false;
  @Output() createModeChange = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private textblockService: TextblockService) { }

  ngOnInit(): void {
    this.createGroup();
    if (this.createMode) {
      this.editMode = true;
    }
  }

  /**
   * Create a textblock form
   */
  createGroup() {
    this.textblockForm = this.fb.group({
      idTextblock: [this.textblock?.idTextblock || '', Validators.required],
      textEn: [this.textblock?.textEn || '', Validators.required],
      textDe: [this.textblock?.textDe || '', Validators.required]
    });
  }

  /**
   * Quit create mode
   */
  cancelCreateMode() {
    this.createMode = false;
    this.createModeChange.emit(this.createMode);
  }

  /**
   * Save a textblock
   */
  save() {
    // if in edit mode just save the changes
    if (this.editMode && !this.createMode) {
      this.edit();
    } else {
      // in create mode create a new textblock and refresh the table
      this.textblockService.addTextblock(this.textblockForm.value).subscribe((data: Textblock) => {
        this.textblock = data;
        this.editMode = false; this.textblockChange.emit(this.textblock); this.createGroup(); this.cancelCreateMode(); this.refresh.emit();
      });
    }
  }

  /**
   * Edit a textblock
   */
  edit() {
    this.textblockService.editTextblock(this.textblockForm.value).subscribe((data: Textblock) => {
      this.editMode = false;
      this.textblockChange.emit(data);
    });
  }

  /**
   * Delete a textblock
   */
  delete() {
    this.textblockService.deleteTextblock(this.textblockForm.value.idTextblock).subscribe(data => {
      this.textblock = null;
      this.textblockChange.emit(this.textblock);
      this.refresh.emit();
    });
  }

}
