import { AdminGuard } from './admin-guard';
import { UserRoleType } from './../shared/models/user-role-type.enum';
import { ShoppingCartService } from './../shared/services/shopping-cart.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private shoppingCartService: ShoppingCartService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // if user is account manager redirect to manage account page when id loginAs is not assigned
            if (this.authenticationService.isAuthorized(UserRoleType.ADMIN)) {
                if (this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER)
                    && route.routeConfig.canActivate.includes(AdminGuard)) {
                        return true;
                }
            }
            if (this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER) && currentUser.loginAs === undefined) {
                this.router.navigate(['/manage-account']);
                return false;
            }
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

}
