<div class="container-fluid">
    <div class="articles-table">
        <div *ngIf="isArticlesMode">
            <app-articles-filter (formGroupChange)="onSearch($event)" [searchFormControl]="searchFormControl"
                placeholder="{{'articlesList.articleNumber' | translate}}"></app-articles-filter>
        </div>
        <section class="se-table-flex">
            <div class="se-table-header se-table-row">
                <div class="se-table-col sm position-col d-none d-md-block" i18n>Pos</div>
                <div class="se-table-col lg article-id-col" i18n>{{'articlesList.article' | translate}} </div>
                <div class="se-table-col d-none d-sm-flex md" i18n>{{'articlesList.quantity' | translate}}</div>
                <div class="se-table-col d-none d-sm-flex md" i18n>{{'articlesList.shippingDate' | translate}}</div>
                <div class="se-table-col d-none d-sm-flex md" i18n>{{'articlesList.scaledPrices' | translate}}</div>
                <div class="se-table-col d-none d-sm-flex md" i18n>{{'articlesList.totalPrice' | translate}}</div>
                <div class="se-table-col"
                    [ngClass]="{'sm': isCartMode,'remove-button-col': isCartMode, 'max-width-100': isCartMode, 'md': isArticlesMode}">
                </div>
            </div>
            <div *ngFor="let article of articles; index as i" [article]="article" @row [@.disabled]="isArticlesMode"
                [cartArticle]="isCartMode ? articlesInCart[i] : null" [articlesListMode]="articlesListMode" [index]="i"
                app-articles-list-row (delete)="articleRemoved(i)"
                (capacityPriceChanges)="capacityPriceChanged($event, i)" (articlesChanged)="articlesChanged()">
            </div>
            <div *ngIf="loading" class="se-table-row">
                <div class="se-table-col lg" i18n>
                    <app-loading-spinner></app-loading-spinner>
                </div>
            </div>
            <div *ngIf="!loading && articles.length == 0" class="se-table-row">
                <div class="se-table-col lg text-center" i18n>{{'articlesList.noItemsToShow' | translate}} </div>
            </div>
        </section>
        <app-button class="load-more m-auto" (click)="loadMore()" [disabled]="!loadMoreAvailable"
            [ngClass]="{'inactive': loadMoreAvailable === false}" label="{{'articlesList.loadMore' | translate}}" i18n *ngIf="!this.isCartMode">
        </app-button>
    </div>
</div>
