<ng-container>
	<div class="card-items" [ngClass]="{'hide-border': profileOutputOther}">
		<ng-container>
			<div cdkDropList (cdkDropListDropped)="drop($event)">
				<ng-container [formGroup]="profileOutputLinesFormGroup" *ngIf="profileOutputLinesFormGroup">
					<div class="card-item d-flex align-items-center justify-content-start flex-sm-row flex-column"
						[ngClass]="{'hide-border': j === (profileMeasureOutputLines.length - 1) && !profileOutputOther, 'other-item': profileOutputOther, 'justify-content-start': profileOutputOther, 'justify-content-between': !profileOutputOther}"
						cdkDragLockAxis="y" cdkDrag *ngFor="let itemValue of profileMeasureOutputLines; let j = index" [formGroupName]="j">
						<div class="custom-placeholder" *cdkDragPlaceholder></div>
						<ng-container *ngIf="{editMode: itemValue.idProfileMeasureOutputLine === null} as edit">
							<div class="card-item-input card-item-input-name d-flex align-items-center">
								<div *ngIf="!lineIndex; else elseblock" class="col-lg-1 col-1 drag-and-drop" cdkDragHandle>
									<i class="bi bi-grip-vertical"></i>
								</div>
								<ng-template #elseblock>
									<div *ngIf="itemValue.idProfileMeasureOutputLine !== null" class="col-lg-1 col-1 drag-and-drop d-flex">
										{{'{'+(j+1)+'}'}}
									</div>
								</ng-template>

								<div class="card-item-label card-item-label-title d-flex align-items-center ml-2">
									<input type="text" class="form-control" [readonly]="!edit.editMode" placeholder="{{'sealingType.profile.name'|translate}}"
										formControlName="name" *ngIf="edit.editMode"
										(change)="itemValue.idProfileMeasureOutputLine === null ? updateProfileValuesWithDelay() : null">

									<span *ngIf="!edit.editMode">
										{{itemValue.name}}
									</span>
									<i class="bi bi-check2 ml-1" *ngIf="edit.editMode" (click)="updateProfileValues();edit.editMode = false"></i>
									<i class="bi bi-x" (click)="edit.editMode = !edit.editMode;" *ngIf="edit.editMode"></i>
									<i class="bi bi-pencil ml-2" (click)="itemValue.readonly ? null : edit.editMode = !edit.editMode"
										[ngClass]="{'disabled':itemValue.readonly}" *ngIf="!itemValue.readonly && !edit.editMode && profileOutputOther"></i>
								</div>
							</div>
							<div class="card-item-input d-flex align-items-center ">
								<div class="card-item-value ml-2 d-flex align-items-center">
									<button class="btn btn-edit-formula d-flex align-items-center" type="button" [disabled]="!itemValue.idSvgOutput ? 'disabled' : null"
										(click)="openFormulaModal(j)">
										<i class="bi bi-pencil mr-1"></i>
										{{'sealingType.profile.editVisualFormula'|translate}}
									</button>
									<i class="error-circle bi bi-exclamation-octagon ml-1" *ngIf="!itemValue.displayValid"></i>
								</div>
							</div>
							<div class="card-item-input d-flex align-items-center card-item-input-intern-formula">
								<ng-container *ngIf="!profileOutputOther">
									<div class="card-item-value ml-2 d-flex align-items-center">
										<button class="btn btn-edit-formula d-flex align-items-center" type="button" (click)="openFormulaModal(j, false)">
											<i class="bi bi-pencil mr-1"></i>
											{{'sealingType.profile.editWorkpieceFormula'|translate}}
										</button>
										<i class="error-circle bi bi-exclamation-octagon ml-1" *ngIf="!itemValue.internValid"></i>
									</div>
								</ng-container>
							</div>
							<div class="flex-wrap flex-lg-nowrap d-flex align-items-center line-control-buttons" [ngClass]="{'ml-auto': !profileOutputOther}">
								<i class="bi bi-tag" (click)="openLinkImageModal(j)"
									[ngClass]="{'active': itemValue.idSvgOutput, 'disabled': openLinkImageDisabled, 'not-assigned': (!itemValue.idSvgOutput && itemValue.readonly)}"></i>
								<i class="bi bi-trash3" (click)="!profileOutputOther ? null : deleteProfileMeasureOutputLine(j)"
									[ngClass]="{'disabled':itemValue.readonly}" *ngIf="!itemValue.readonly && profileOutputOther"></i>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<app-add-new-button padding="10px 0 0 0" text="{{'sealingType.profile.addNewMeasure'|translate}}" (onAddClick)="addItem()" *ngIf="profileOutputOther">
		</app-add-new-button>
	</div>
</ng-container>