<ng-template #popTitle i18n> {{'shared.fileUpload.uploadFilesArticle'|translate}} </ng-template>
<ng-template #popContent>
    <div class="uploaded-files" *ngIf="uploadedFiles && uploadedFiles.length>0">
        <div class="uploaded-file d-flex justify-content-between align-items-center" *ngFor="let item of uploadedFiles">
            {{(item.length>35) ? (item | slice:0:35)+'...':(item)}}
            <img class="d-block" (click)="removeFile(item)" src="/assets/img/icons/remove.svg" alt="remove-file">
        </div>
        <div class="info-max-files" *ngIf="uploadedFiles && uploadedFiles.length>=5"> {{'shared.fileUpload.noMoreData'|translate}} </div>
    </div>
    <img class="preview-image" *ngIf="imageMode && image && uploadedFiles && uploadedFiles.length>0"
        [src]="image+'?'+timestamp">
    <app-image-uploader *ngIf="imageMode && (uploadedFiles == null || uploadedFiles.length===0)"
        (fileChange)="addImage($event)" [svgExtension]="true">
    </app-image-uploader>
    <div class="custom-file" *ngIf="!imageMode">
        <input type="file" class="custom-file-input" id="customFileLang" lang="de"
            (change)="addFile($event.target.files)" [accept]="acceptFileType"
            [disabled]="uploadedFiles && uploadedFiles.length>=5">
        <label class="custom-file-label" for="customFileLang">
            <div *ngIf="fileToUpload">
                {{(fileToUpload.name.length>20) ? (fileToUpload.name  | slice:0:20)+'...':(fileToUpload.name )}}
            </div>
            <div *ngIf="!fileToUpload" i18n> {{'shared.fileUpload.selectData'|translate}}</div>
        </label>
    </div>
    <app-button class="se-button d-block mt-2" (click)="uploadFile()"
        [disabled]="fileToUpload == null || (uploadedFiles && uploadedFiles.length>=5)" label="{{'shared.fileUpload.uploadData'|translate}}" i18n>
    </app-button>
    <div class="progress mt-2">
        <div class="progress-bar progress-bar-striped"
            [ngClass]="{'bg-danger':uploadFailed, 'bg-success':!uploadFailed && uploadSucceed, 'reseting': uploadSucceed}"
            role="progressbar" [style]="'width:' +uploadProgress+'%'" aria-valuenow="10" aria-valuemin="0"
            aria-valuemax="100">
        </div>
    </div>
</ng-template>
<img src="assets/img/icons/upload.svg" alt="upload-file" [autoClose]="'outside'" class="upload-file-icon"
    [ngbPopover]="popContent" [popoverTitle]="popTitle">