<app-section-header section="framework-contracts"></app-section-header>
<div class="contracts">
    <div class="container-fluid">
        <app-articles-filter (formGroupChange)="onSearch($event)" [searchFormControl]="searchFormControl"
            placeholder="{{'frameworkContracts.filter'|translate}}"></app-articles-filter>
        <div class="contracts-content" *ngIf="contracts.length > 0">
            <div class="contract-toggle" *ngFor="let contract of contracts">
                <app-framework-contract-row [contract]="contract" (contractOpened)="contractOpened($event)" *ngIf="contracts.length == 1">
                </app-framework-contract-row>
                <app-framework-contract-row [contract]="contract" *ngIf="contracts.length > 1"></app-framework-contract-row>
                <div *ngIf="showLoadFullContract && contracts[0].contractArticles.length <= 1 && !showLoadFullContractClicked"
                    class="se-table-row load-full-contract-row" [ngClass]="{'opened': singleArticleContractOpened}">
                    <div class="se-table-col lg text-center">
                        <div class="load-full-contract-content d-flex align-items-center">
                            <img src="/assets/img/icons/dots.svg" alt="dots">
                            <button class="load-full-contract" i18n (click)="getFullContract()">{{'frameworkContracts.loadCompleteContracts'|translate}}
                                <img src="/assets/img/icons/reload.svg" alt="load-full"> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="contracts.length == 0 && !loading" class="se-table-row">
            <div class="se-table-col lg text-center" i18n>{{'frameworkContracts.noItemsToShow'|translate}}</div>
        </div>
        <div *ngIf="loading" class="loading">
            <app-loading-spinner></app-loading-spinner>
        </div>
    </div>
</div>
