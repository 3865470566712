<div class="container-fluid">
    <app-breadcrumb></app-breadcrumb>
</div>
<div class="logs-container">
    <div class="container-fluid">
        <h2 class="se-title underlined" i18n>{{'admin.logsViewer.viewLogs'|translate}}</h2>
        <div class="d-flex">
            <div ngbDropdown class="d-inline-block">
                <button class="se-button" id="dropdownBasic1"
                    ngbDropdownToggle>{{selectedFile || 'admin.logsViewer.chooseLog'|translate}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let log of logs | async" (click)="selectFile(log)">{{log}}</button>
                </div>
            </div>
            <app-button width="200px" height="45px" label="{{'admin.logsViewer.downloadLogImage'|translate}}"
                [disabled]="selectedFile == null || loadingFile" (click)="downloadFile()" [loading]="loadingFile">
            </app-button>
            <app-button width="200px" height="45px" label="{{'admin.logsViewer.last500Logs'|translate }}" (click)="loadLog()"
                [disabled]="selectedFile == null"></app-button>
        </div>
        <div class="log-viewer" *ngIf="logData">
            <div class="logline" *ngFor="let logLine of logData | async"> {{logLine}}</div>
        </div>
    </div>
</div>
