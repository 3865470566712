import { FrameworkContractService } from './framework-contracts.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { ArticleType } from '../models/article-type.enum';
import { environment } from './../../../environments/environment';
import { Article } from './../models/article.model';
import { CartArticle } from './../models/cart-article.model';
import { AuthenticationService } from './authentication.service';
import { OffersService } from './offers.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  private apiUrl = environment.apiUrl;
  private numberOfArts = 0;
  private articlesNumber: Subject<number> = new BehaviorSubject<number>(this.numberOfArts);
  private userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private frameworkContractService: FrameworkContractService,
    private articlesService: ArticlesService,
    private offersService: OffersService) {
    // getting current user
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Gets number of articles in cart
   * @returns BehaviorSubject
   */
  getNumberOfArticlesInCart() {
    // getting the articles number
    this.loadNumberOfArticles();
    return this.articlesNumber;
  }

  /**
   * Load cart object
   * @returns cart
   */
  loadCart() {
    return this.http.get(`${this.apiUrl}/cart/${this.userId}`).pipe(catchError(error => {
      console.error('There was an error in loading the number of articles in cart, please contact Haenssler.', error);
      return of();
    }));
  }

  /**
   * Loads number of articles in cart from backend
   */
  loadNumberOfArticles() {
    if (this.userId) {
      this.http.get(`${this.apiUrl}/cart/${this.userId}/number-articles`).pipe(
        catchError(error => {
          console.error('There was an error in loading the number of articles in cart, please contact Haenssler.', error);
          return of();
        })
      ).subscribe((data: number) => {
        this.numberOfArts = data;
        this.articlesNumber.next(this.numberOfArts);
      });
    }
  }

  /**
   * Adds article to cart
   * @param cartArticle cart article
   * @returns observable
   */
  addArticleToCart(cartArticle: CartArticle) {
    return this.http.post(`${this.apiUrl}/cart/${this.userId}/add-article`, cartArticle).pipe(
      map(data => {
        this.increaseNumberOfArticles();
        return data;
      }),
      catchError(error => {
        console.error('There was an error in adding the article to the cart, please contact Haenssler.', error);
        return of();
      })
    );
  }

  /**
   * Update cart order comment
   * @param orderComment order comment
   * @returns updated
   */
  changeCartOrderComment(orderComment: string) {
    return this.http.put(`${this.apiUrl}/cart/${this.userId}/order-comment`, { orderComment });
  }

  /**
   * Update article in cart
   * @param cartArticle cart article
   * @returns observable
   */
  updateArticleInCart(cartArticle: CartArticle) {
    return this.http.put(`${this.apiUrl}/cart/${this.userId}/update-article/${cartArticle.idCartArticle}`, cartArticle).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        console.error('There was an error in updating the cart article, please contact Haenssler.', error);
        return of();
      })
    );
  }

  /**
   * Gets articles in cart
   * @returns observable
   */
  getArticlesInCart(valid = true) {
    return this.http.get(`${this.apiUrl}/cart/${this.userId}/list-articles/`).pipe(map((articles: CartArticle[]) =>
      articles.filter((article: CartArticle) => article.valid === valid
      )), catchError(error => {
        console.error('There was an error in the receiving the cart articles, please contact Haenssler.', error);
        return of({});
      }));
  }

  /**
   * Gets articles in cart with details
   * @returns articles in cart array with details array
   */
  public getArticlesInCartWithDetails(valid = true): Observable<any> {
    if (this.userId) {
      return this.getArticlesInCart(valid)
        .pipe(
          mergeMap((articlesInCart: CartArticle[]) =>
            // `from` emits each article separately
            from(articlesInCart).pipe(

              // load each article details object using idArticleERP
              mergeMap(
                (articleInCart: CartArticle) => {
                  if (articleInCart.articleType === ArticleType.ARTICLE) {
                    return this.getArticleDetails(articleInCart.idArticleERP);
                  } else if (articleInCart.articleType === ArticleType.OFFER_ARTICLE) {
                    return this.getOfferArticleDetails(articleInCart.idOfferPosition);
                  } else if (articleInCart.articleType === ArticleType.FRAMEWORK_CONTRACT) {
                    return this.getFrameworkContractArticleDetails(articleInCart.idFrameworkContractERP, articleInCart.idArticleERP);
                  }
                },
              ),
              // collect all article details into an array
              toArray(),
              // add the newly fetched data to original result combining both arrays
              map((articleDetails: Article[]) => {
                articleDetails.sort((a, b) => a.idArticleERP.localeCompare(b.idArticleERP));
                articlesInCart.sort((a, b) => a.idArticleERP.localeCompare(b.idArticleERP));
                return { articlesInCart, articleDetails };
              }),
            )
          ), catchError(error => {
            console.error(error);
            return of(null);
          })
        );
    }
  }

  /**
   * Gets article details
   * @param idCartArticle ida cart article
   * @returns Observable<Article>
   */
  getArticleDetails(idArticleERP): Observable<Article> {
    if (this.userId) {
      return this.articlesService.getArticleDetails(idArticleERP) as Observable<Article>;
    }
  }

  /**
   * Gets offer article details
   * @param idOfferPosition idOfferPositionERP
   * @returns Observable<Article>
   */
  getOfferArticleDetails(idOfferPosition): Observable<Article> {
    if (this.userId) {
      return this.offersService.getOfferArticle(idOfferPosition) as Observable<Article>;
    }
  }

  /**
   * Gets framework contract article details
   * @param idFrameworkContractERP idFrameworkContractERP
   * @param idArticleERP idArticleERP
   * @returns framework contract article details
   */
  getFrameworkContractArticleDetails(idFrameworkContractERP, idArticleERP): Observable<Article> {
    if (this.userId) {
      return this.frameworkContractService.getFrameworkContract(idFrameworkContractERP, idArticleERP) as Observable<Article>;
    }
  }

  /**
   * Sets number of articles to zero
   */
  setNumberOfArticlesToZero() {
    this.numberOfArts = 0;
    this.articlesNumber.next(this.numberOfArts);
  }

  /**
   * Checks if the cart contains any article
   */
  get cartHasArticles() {
    return this.numberOfArts > 0;
  }

  /**
   * Deletes from cart
   * @param idCartArticle cart article id
   * @returns observable
   */
  deleteFromCart(idCartArticle: number) {
    return this.http.delete(`${this.apiUrl}/cart/${this.userId}/delete-article/${idCartArticle}`).pipe(
      map(data => {
        this.decreaseNumberOfArticles();
        return data;
      }),
      catchError(error => {
        console.error('There was an error in removing the article from cart, please contact Haenssler.', error);
        return of();
      })
    );
  }

  /**
   * Increases number of articles in the cart
   */
  increaseNumberOfArticles() {
    this.numberOfArts++;
    this.articlesNumber.next(this.numberOfArts);
  }

  /**
   * Decreases number of articles in the cart
   */
  decreaseNumberOfArticles() {
    this.numberOfArts--;
    this.articlesNumber.next(this.numberOfArts);
  }

}
