import { ConfiguratorOfferDTO } from './../models/configurator-offer.model';
import { ScaledPricesList } from './../models/scaled-price-list.model';
import { ScaledPrice } from 'src/app/shared/models/scaled-price.model';
import { Profile } from 'src/app/shared/models/profile.model';
import { SealingType } from './../models/sealing-type.model';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OrderSealService implements OnDestroy {
  private apiUrl = environment.apiUrl;
  private userId: string;
  private selectedSealingType: SealingType = null;
  private selectedProfile: Profile = null;
  private step = 0;
  private unsubscribe: Subject<void> = new Subject();
  // sections and steps used for section header and bottom navigation
  private _sections = [
    {
      title: 'codeTranslations.order-seal-sealing-types-section',
      route: '/profile/order-seal/0',
      key: 'order-seal-sealing-types',
      disable: false
    },
    {
      title: 'codeTranslations.order-seal-scraper-section',
      route: '/profile/order-seal/1',
      key: 'order-seal-scraper',
      disable: this.selectedSealingType == null
    },
    {
      title: 'codeTranslations.order-seal-measures-and-material-section',
      route: '/profile/order-seal/2',
      key: 'order-seal-measures-and-material',
      disable: this.selectedProfile == null || this.selectedSealingType == null
    }

  ];
  private _steps = [
    {
      back: {
        title: 'codeTranslations.navigation-bottom-back',
        route: '/dashboard',
        disable: false
      },
      forward: this.sections[1]
    },
    {
      back: this.sections[0],
      forward: this.sections[2]
    },
    {
      back: this.sections[1]
    }
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private translateService : TranslateService,
    private router: Router,
    private location: Location,
    private http: HttpClient) {
    this.authenticationService.currentUserId.pipe(takeUntil(this.unsubscribe)).subscribe(userId => {
      this.userId = userId;
    });
    // get current step on routing
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      this.sections.forEach((step, index) => {
        if (this.router.url.includes(step.route)) {
          this.step = index;
        }
      });
      if (event instanceof NavigationEnd) {
        // return to the first page if sealing type is not selected
        if (this.step !== 0 && this.selectedSealingType === null) {
          this.router.navigate([this.sections[0].route]);
          this.step = 0;
        }
        if (this._sections.findIndex(section => section.route === event.url) === -1) {
          this.selectedSealingType = null;
          this.selectedProfile = null;
          this.step = 0;
        }
      }
    });
  }

  /**
   * Go to next page
   */
  goToNextPage() {
    this.router.navigate([this.steps[this.step].forward.route]);
    this.step = this.step + 1;
  }

  /**
   * Gets sealing types from the api
   * @returns  
   */
  getSealingTypes(): Observable<SealingType[]> {
    return this.http.get(`${this.apiUrl}/sealing-types`) as Observable<SealingType[]>
  }

  /**
   * Gets scaled prices by query
   * @returns  
   */
  getScaledPrices(scaledPricesQuery): Observable<ConfiguratorOfferDTO> {
    return this.http.post(`${this.apiUrl}/configurator/get-scaled-prices/${this.userId}`, scaledPricesQuery) as Observable<ConfiguratorOfferDTO>
  }

  /**
   * Selects sealing type
   * @param sealingType 
   */
  selectSealingType(sealingType) {
    this.selectedSealingType = sealingType;
    this.steps[this.step].forward.disable = false;
    let prueba = this.translateService.instant("codeTranslations.navigation-bottom-back");
    console.log(this.translateService.instant("codeTranslations.navigation-bottom-back"));
  }

  /**
   * Selects profile
   * @param sealingType 
   */
  selectProfile(profile) {
    this.selectedProfile = profile;
    this.steps[this.step].forward.disable = false;
  }

  /**
   * Gets selected sealing type value
   */
  get selectedSealingTypeValue(): SealingType {
    return this.selectedSealingType;
  }

  /**
   * Gets selected profile value
   */
  get selectedProfileValue(): Profile {
    return this.selectedProfile;
  }

  /**
   * Gets current step index
   */
  get currentStep(): number {
    return this.step;
  }

  /**
   * Gets sections
   */
  get sections() {
    return this._sections;
  }

  /**
   * Gets steps
   */
  get steps() {
    return this._steps;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Gets the calculated read only value by idSvgInput
   * @returns  
   */
  calculateReadOnly(idProfile: number, idSvgInput: string, idMaterialProfile: string, measuresQuery: any) {
    return this.http.post(`${this.apiUrl}/configurator/calculate-read-only/${idProfile}/${idSvgInput}/${idMaterialProfile}`, measuresQuery);
  }
  
  /**
   * Validates input in java
   * @param idProfile 
   * @param idSvgInput 
   * @param scaledPriceQuery 
   * @returns  
   */
  validateInput(idProfile: number, idSvgInput: string, scaledPriceQuery: any) {
    return this.http.post(`${this.apiUrl}/configurator/validate-input/${idProfile}/${idSvgInput}`, scaledPriceQuery);
  }
}
