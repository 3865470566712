import { catchError } from 'rxjs/operators';
import { Customer } from './../models/customer.model';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private apiUrl = environment.apiUrl;
  userIdErp: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.authenticationService.currentUser.subscribe(user => {
      if (user) { this.userIdErp = user.idErp; }
    });
  }

  /**
   * Gets customers
   * @returns customers
   */
  getCustomers(userIdErp = null) {
    if (userIdErp === null) { userIdErp = this.userIdErp; }
    return this.http.get<Customer[]>(`${this.apiUrl}/account-manager/customers/${userIdErp}`).pipe(catchError(error => {
      console.error(`There was an error while getting customers, please contact Haenssler.`);
      return of(null);
    }));
  }

  /**
   * Gets account managers list
   * @returns account-managers
   */
  getAccountManagersList() {
    return this.http.get<Customer[]>(`${this.apiUrl}/account-manager/account-managers`).pipe(catchError(error => {
      console.error(`There was an error while getting account-managers, please contact Haenssler.`);
      return of(null);
    }));
  }

}
