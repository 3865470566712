import { MaterialProfileMeasure } from './material-profile-measure.model';
export class ProfileComponentMaterialLine {
    idProfileComponentMaterialLine: number;
    fromValue: number;
    toValue: number;
    setupUnit: number;
    processingTime: number;
    idProfileComponentMaterialFK: number;
    materialProfileMeasure: MaterialProfileMeasure;
    valid: boolean;
    fromTemplate: boolean;
}
