import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-animated-checkmark',
  templateUrl: './animated-checkmark.component.html',
  styleUrls: ['./animated-checkmark.component.scss']
})
export class AnimatedCheckmarkComponent implements OnInit {
  @Input() checked = false;
  constructor() { }

  ngOnInit(): void {
  }

}
