import { HttpEventType, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { FileUploadService } from './../../services/file-upload.service';
import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
  styleUrls: ['./file-upload-popup.component.scss']
})
export class FileUploadPopupComponent implements OnInit {
  @Input() imageMode: boolean = false;
  @Input() acceptFileType: string = 'image/tiff,.tif,.stp,.pdf';
  @Input() id: string;
  @Input() resource: string;
  public fileToUpload: File = null;
  public uploadProgress = 0;
  public uploadFailed = false;
  public uploadSucceed = false;
  public timeout: any = null;
  public uploadedFiles: string[];
  public image: string;
  public timestamp = Math.round(+new Date()/1000);
  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.listFiles();
  }

  /**
   * Assigns file to fileToUpload object
   */
  addFile(file) {
    this.uploadProgress = 0;
    this.uploadFailed = false;
    this.uploadSucceed = false;
    this.fileToUpload = file.item(0);
  }

  /**
   * Assigns image file to fileToUpload object
   */
  addImage(file) {
    this.uploadProgress = 0;
    this.uploadFailed = false;
    this.uploadSucceed = false;
    this.fileToUpload = file;
  }

  /**
   * Uploads file on server
   */
  uploadFile() {
    if (this.fileToUpload == null || (this.uploadedFiles && this.uploadedFiles.length >= 5)) { return; }
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    this.fileUploadService.upload(formData, this.resource, this.id).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.uploadProgress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.uploadFailed = true;
        return of(`File upload failed.`);
      })).subscribe((event: any) => {
        if (event instanceof HttpResponse && event.status === 200) {
          this.listFiles();
          this.fileToUpload = null;
          this.timeout = window.setTimeout(() => {
            this.uploadSucceed = true;
          }, 1000);
          this.timeout = window.setTimeout(() => {
            this.uploadProgress = 0;
          }, 2000);
        }
      });
  }

  /**
   * Lists files related to the article in cart
   */
  listFiles() {
    this.fileUploadService.listFiles(this.resource, this.id).subscribe(data => {
      this.uploadedFiles = data;
      this.image = null;
      if (this.imageMode && data.length > 0) {
        this.image = this.fileUploadService.getImage(this.resource, this.id);
        this.timestamp = Math.round(+new Date()/1000);
      }
    });
  }

  /**
   * Removes file by filename and shows the updated list of files
   * @param filename filename
   */
  removeFile(filename: string) {
    this.fileUploadService.removeFile(this.resource, filename).subscribe(data => this.listFiles());
  }

}
