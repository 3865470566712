<div class="sidebar-container" #insideElement>
    <img class="nav-item mt-1" src="/assets/img/icons/menu.svg" alt="menu-icon" draggable="false" (click)="toggle()">
    <nav class="sidebar" [@slideInOut]="menuState">
        <button class="d-block btn ml-auto sidebar__close" (click)="toggle()"><span
                aria-hidden="true">&times;</span></button>
        <ul class="sidebar__menu">
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex" [routerLink]="['/dashboard']">
                    {{'sidebar.home'|translate}} <img class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex" [routerLink]="['/articles']">
                    {{'sidebar.article'|translate}} <img class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex" [routerLink]="['/orders']">
                    {{'sidebar.orders'|translate}} <img class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex" [routerLink]="['/offers']">
                    {{'sidebar.offers'|translate}} <img class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex"
                    [routerLink]="['/framework-contracts']"> {{'sidebar.frameworkContracts'|translate}} <img
                        class="d-block ml-auto chevron-right" src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex" [routerLink]="['/cart']">
                    {{'sidebar.shoppingCart'|translate}} <img class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item" *ngIf="isUserAccountManager">
                <button type="button" class="transparet btn sidebar__item-button d-flex"
                    [routerLink]="['/manage-account']"> {{'sidebar.myCustomers'|translate}} <img
                        class="d-block ml-auto chevron-right" src="/assets/img/icons/chevron-right.svg">
                </button>
            </li>
            <li class="sidebar__item">
                <button type="button" class="transparet btn sidebar__item-button d-flex"
                    (click)="sections[0] = !sections[0]" [attr.aria-expanded]="!sections[0]"
                    aria-controls="collapseMenu"> {{'sidebar.settings'|translate}} <img
                        class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-{{sections[0] ? 'right' : 'down' }}.svg">
                </button>
                <div class="sidebar__submenu" [ngbCollapse]="sections[0]">
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/admin/parameters']">
                        <a><img src="/assets/img/icons/configuration.svg" alt="configuration-icon">
                            {{'sidebar.salesEngineConfiguration'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/admin/logs']">
                        <a><img src="/assets/img/icons/logs.svg" alt="logs-icon">
                            {{'sidebar.salesEngineLogs'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" [routerLink]="['/update-password/me']">
                        <a><img src="/assets/img/icons/asterisk.svg" alt="chat-icon">
                            {{'sidebar.changePassword'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/admin/manage-users']">
                        <a><img src="/assets/img/icons/manage-users-black.svg" alt="user-icon">
                            {{'sidebar.userAdministration'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/admin/textblocks']">
                        <a><img src="/assets/img/icons/manage-textblocks-black.svg" alt="user-icon">
                            {{'sidebar.manageOrderComments'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/admin/create-user']">
                        <a><img src="/assets/img/icons/user-add.svg" alt="user-icon">
                            {{'sidebar.createUser'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAccountManager" [routerLink]="['/update-profile-image']">
                        <a><i class="bi bi-file-person"></i> {{'sidebar.editProfilePicture'|translate}}</a>
                    </div>
                </div>
            </li>
            <li class="sidebar__item" *ngIf="isUserAccountManager || isUserAdmin">
                <button type="button" class="transparet btn sidebar__item-button d-flex"
                    (click)="sections[1] = !sections[1]" [attr.aria-expanded]="!sections[1]"
                    aria-controls="collapseMenu"> {{'sidebar.sealConfigurator'|translate}} <img
                        class="d-block ml-auto chevron-right"
                        src="/assets/img/icons/chevron-{{sections[1] ? 'right' : 'down' }}.svg">
                </button>
                <div class="sidebar__submenu" [ngbCollapse]="sections[1]">
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/profile/machine-groups']">
                        <a><img src="/assets/img/icons/configuration.svg" alt="configuration-icon">
                            {{'sidebar.configurationMachineGroup'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/profile/category-and-profile']">
                        <a><img src="/assets/img/icons/configuration.svg" alt="configuration-icon">
                            {{'sidebar.configurationCategoryAndProfiles'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAccountManager || isUserAdmin" [routerLink]="['/profile/order-seal']">
                        <a><img src="/assets/img/icons/configuration.svg" alt="configuration-icon">
                            {{'sidebar.orderSeal'|translate}}</a>
                    </div>
                    <div class="sidebar__subitem" *ngIf="isUserAdmin" [routerLink]="['/profile/template-outer-dimension']">
                        <a><img src="/assets/img/icons/configuration.svg" alt="configuration-icon">
                            {{'sidebar.outerDimensionTemplate'|translate}}</a>
                    </div>

                </div>
            </li>
        </ul>
        <ng-container *ngFor="let language of languageList">
            <div class="language d-flex" (click)="changeLanguage(language.code)">
                <img class="language__flag" src="/assets/img/icons/{{language.code}}-flag.png" alt="language-icon"
                    draggable="false">
                <a class="language__link"> {{language.label}}</a>
                <img class="d-block ml-auto chevron-right" src="/assets/img/icons/chevron-right.svg">
            </div>
        </ng-container>
    </nav>
</div>