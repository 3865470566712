<div class="section-header">
    <div class="container-fluid">
        <div class="section-image">
            <img class="section-image__img" src="/assets/img/section-header-images/{{section}}-section-bg.png"
                alt="{{section}}-section-image">
            <div class="section-image__title">
                <img class="mb-1" src="/assets/img/icons/{{section}}.svg" alt="{{section}}-icon" *ngIf="!bootstrapIcon">
                <i class="b-icon mb-1 {{bootstrapIcon}}" *ngIf="bootstrapIcon"></i>
                <h3>{{sectionName}}</h3>
            </div>
        </div>
    </div>
</div>
