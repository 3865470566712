<div class="container-fluid">
    <div class="articles-table">
        <div>
            
            <div class="search-create-container">
                <form>
                    <div class="form-group form-inline">
                        <div class="ml-auto d-flex search-box">
                            <input class="form-control ml-2 se-input search-article-input remove-border" type="text"
                                [(ngModel)]="searchText" name="searchText" [placeholder]="'global.search' | translate">
                            <img src="/assets/img/icons/search.svg" alt="search-icon">
                        </div>
                    </div>
                </form>
                <app-button (click)="addNewTemplate()">{{'templates.createTemplate'|translate}}</app-button>
            </div>

            <!-- List of all templates-->
            <section class="templates-list">

                <div class="pt-5 pb-5 m-auto" *ngIf="this.templates?.length === 0">
                    <span>
                        {{'templates.noTemplatesFound'|translate}}
                    </span>
                </div>

                <!-- Container of a template item element 1 -->
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div [cdkDragDisabled]="!isAnyTemplateInEditMode"  cdkDrag cdkDragLockAxis="y" id="material-template-{{i}}"
                        *ngFor="let template of templates | filterTemplates: searchText; let i = index" class="profile-component-material-container whitePanel">
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <div>
                            <ngb-accordion #acc="ngbAccordion" class="profile-component">
                                <ngb-panel>
                                    <ng-template ngbPanelHeader let-opened="opened">
                                        <div class="d-flex">

                                            <div class="d-flex align-items-center inner-header-content">
                                                <i cdkDragHandle class="bi bi-grip-vertical change-cursor" style="z-index: 1000;"></i>
                                    
                                                <!-- Usa [disabled] para habilitar o deshabilitar el botón de toggle -->
                                                <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button auto-width" [disabled]="isEditing[i]" (click)="$event.stopPropagation()"></button>
                                    
                                                <i *ngIf="!isEditing[i]" class="bi bi-chevron-right mt-1 ml-auto mr-1" [ngClass]="{'opened': opened}" (click)="togglePanel(i)"></i>
                                                <span *ngIf="!isEditing[i]; else editName" class="title">{{ template.name }}</span>
                                                
                                                <ng-template #editName>
                                                    <input maxlength=250 [(ngModel)]="editedTemplateNames[i]" type="text" name="template-name" class="form-control se-input custom-input" placeholder="{{templates[i].name}}" />
                                                </ng-template>
                                            </div>
                                            
                                        </div>
                                    </ng-template>
                                  
                                    <ng-template ngbPanelContent>
                                        <!-- table headers -->
                                        <div class="row table-headers"
                                            *ngIf="template.profileComponentMaterialTemplateLines.length > 0">
                                            <div class="col-lg-5">
                                                <div class="card-item-label">
                                                    {{'sealingType.profile.profileMeasure'|translate}} (mm)
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="card-item-label">
                                                    {{'sealingType.profile.setupUnit'|translate}} (RE)
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="card-item-label">
                                                    {{'sealingType.profile.processingTime'|translate}}
                                                    {{'sealingType.profile.processingTimeUnits'|translate}}
                                                </div>
                                            </div>
                                        </div>

                                        <app-outer-dimension-templates-list-line *ngFor="let line of templates[i].profileComponentMaterialTemplateLines; let j = index"
                                        [validateForm]="validateForms"     
                                        (profileComponentTemplatelLineChange)="profileComponentTemplateLineChange(i, $event)"
                                        [template]="templates[i]"
                                        [templateLine]="templates[i].profileComponentMaterialTemplateLines[j]"
                                        [templateLines]="templates[i].profileComponentMaterialTemplateLines"
                                        (onDelete)="deleteProfileComponentMaterialTemplateLine(i, j)">
                                        
                                        </app-outer-dimension-templates-list-line>
                                    
                                        <app-add-new-button padding="10px 0" align="left" text="{{'sealingType.profile.addNewRow'|translate}}" (onAddClick)="addProfileComponentMaterialLine(i)"></app-add-new-button>  
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                        
                        <div class="template-tools">

                            <div>
                                <app-button (click)="toggleEditMode(i)" [padding]="'2px 8px'"  *ngIf="!isEditing[i]" class="material-action__option">
                                    <!-- Edit button big screen -->
                                    <span class="d-none d-lg-block">
                                        {{'templates.editTemplate'|translate}}
                                    </span>
                                    <!-- Edit button mobile -->
                                    <span  class="d-block d-lg-none"><i class="bi bi-pencil"></i></span>
                                </app-button>
                                 <!-- Edit mode controls -->
                                <div class="edit-controls" *ngIf="isEditing[i]">
                                     <!-- Save changes button -->
                                    <app-button  
                                    [padding]="'0px 10px;'"  
                                    [disabled]="(editedTemplateNames[i] == templates[i].name)" 
                                    [ngClass]="{'disabled': (editedTemplateNames[i] != templates[i].name)}" 
                                    (click)="saveTemplateName(i)">

                                    <span class="icon-with-padding"><i [ngbTooltip]="'Accept'" class="bi bi-check2"></i></span>
                                      
                                    </app-button>
                                     <!-- Quit edit mode button -->
                                    <app-button (click)="toggleEditMode(i)" [padding]="'0px 10px;'" >
                                       <span class="icon-with-padding"><i [ngbTooltip]="'Cancel'" class="bi bi-x"></i></span>
                                    </app-button>
                                </div>
                            </div>
                            <i *ngIf="templatesListMode == OuterDimensionTemplatesListMode.POPUP" [ngbTooltip]="'Apply template'" class="bi bi-file-arrow-down hoverPointer" (click)="applyTemplate(i)"></i>
                            <i  [ngbTooltip]="'Delete template'" class="bi bi-trash3 hoverPointer" (click)="deleteProfileComponentMaterialTemplate(i)" ></i>
                        </div>  
                    </div>
                </div>
            </section>
        </div>
    </div>