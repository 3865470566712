import { SortByPipe } from './shared/pipes/sort-by.pipe';
import { NumberDirective } from './shared/directives/numbers-only.directive';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardImagesComponent } from './components/dashboard/dashboard-images/dashboard-images.component';
import { LoginComponent } from './components/login/login.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ArticlesListComponent } from './components/articles/articles-list/articles-list.component';
import { ArticlesListRowComponent } from './components/articles/articles-list/articles-list-row/articles-list-row.component';
import { CartComponent } from './components/cart/cart.component';
import { CartNavigationComponent } from './components/cart/cart-navigation/cart-navigation.component';
import { CartProgressComponent } from './components/cart/cart-progress/cart-progress.component';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { CreateOrderComponent } from './components/cart/create-order/create-order.component';
import { CreateOrderFormComponent } from './components/cart/create-order/create-order-form/create-order-form.component';
import { ParametersConfigurationComponent } from './components/admin/parameters-configuration/parameters-configuration.component';
import { LogsViewerComponent } from './components/admin/logs-viewer/logs-viewer.component';
import { PriceLabelComponent } from './components/articles/articles-list/articles-list-row/price-label/price-label.component';
import { OffersComponent } from './components/offers/offers.component';
import { OfferRowComponent } from './components/offers/offer-row/offer-row.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderRowComponent } from './components/orders/order-row/order-row.component';
import { OrderArticleRowComponent } from './components/orders/order-row/order-article-row/order-article-row.component';
import { CalculationProcessComponent } from './components/articles/articles-list/articles-list-row/calculation-process/calculation-process.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ManageUsersComponent } from './components/admin/manage-users/manage-users.component';
import { CreateUserComponent } from './components/admin/create-user/create-user.component';
import { FrameworkContractsComponent } from './components/framework-contracts/framework-contracts.component';
import { FrameworkContractRowComponent } from './components/framework-contracts/framework-contract-row/framework-contract-row.component';
import { ContractInfoComponent } from './components/articles/articles-list/articles-list-row/contract-info/contract-info.component';
import { TextblocksComponent } from './components/textblocks/textblocks.component';
import { TextblockRowComponent } from './components/textblocks/textblock-row/textblock-row.component';
import { ProfileMachineGroupComponent } from './components/configurator/profile-machine-group/profile-machine-group.component';
import { ProfileMachineGroupItemComponent } from './components/configurator/profile-machine-group/profile-machine-group-item/profile-machine-group-item.component';
import { ProfileComponent } from './components/configurator/sealing-type/sealing-type.component';
import { ProfileHeaderComponent } from './components/configurator/sealing-type/sealing-type-header/sealing-type-header.component';
import { ProfileBodyComponent } from './components/configurator/sealing-type/sealing-type-body/sealing-type-body.component';
import { ProfileLinkImageDialogComponent } from './components/configurator/sealing-type/sealing-type-body/profile-inputs/profile-link-image-dialog/profile-link-image-dialog.component';
import { ProfileComponentMaterialComponent } from './components/configurator/sealing-type/sealing-type-body/profile-component/profile-component-material/profile-component-material.component';
import { ProfileComponentMaterialLineComponent } from './components/configurator/sealing-type/sealing-type-body/profile-component/profile-component-material/profile-component-material-line/profile-component-material-line.component';
import { ProfileComponentComponent } from './components/configurator/sealing-type/sealing-type-body/profile-component/profile-component.component';
import { OrderSealSelectSealingTypeComponent } from './components/configurator/order-seal/order-seal-select-sealing-type/order-seal-select-sealing-type.component';
import { OrderSealComponent } from './components/configurator/order-seal/order-seal.component';
import { OrderSealSelectScraperComponent } from './components/configurator/order-seal/order-seal-select-scraper/order-seal-select-scraper.component';
import { OrderSealSelectMeasuresAndMaterialComponent } from './components/configurator/order-seal/order-seal-select-measures-and-material/order-seal-select-measures-and-material.component';
import { OrderSealWrapperComponent } from './components/configurator/order-seal/order-seal-wrapper/order-seal-wrapper.component';
import { ProfileInputValuesComponent } from './components/configurator/sealing-type/sealing-type-body/profile-inputs/profile-input-values/profile-input-values.component';
import { ProfileOutputValuesComponent } from './components/configurator/sealing-type/sealing-type-body/profile-outputs/profile-output-values/profile-output-values.component';
import { ProfileLinkImageOutputsDialogComponent } from './components/configurator/sealing-type/sealing-type-body/profile-outputs/profile-link-image-outputs-dialog/profile-link-image-outputs-dialog.component';
import { ProfileOutputsFormulaDialogComponent } from './components/configurator/sealing-type/sealing-type-body/profile-outputs/profile-outputs-formula-dialog/profile-outputs-formula-dialog.component';
import { ProfileLinkValuesDialogComponent } from './components/configurator/sealing-type/sealing-type-body/profile-inputs/profile-link-values-dialog/profile-link-values-dialog.component';
import { ConfiguratorTemplateComponent } from './components/configurator/sealing-type/sealing-type-body/profile-component/configurator-template/configurator-template.component';
import { OuterDimensionTemplatesComponent } from './components/configurator/outer-dimension-templates/outer-dimension-templates.component';
import { OuterDimensionTemplatesListComponent } from './components/configurator/outer-dimension-templates/outer-dimension-templates-list/outer-dimension-templates-list.component';
import { OuterDimensionTemplatesListLineComponent } from './components/configurator/outer-dimension-templates/outer-dimension-templates-list/outer-dimension-templates-list-line/outer-dimension-templates-list-line.component';
import { MaterialTableComponent } from './components/configurator/material-table/material-table.component';
import { MaterialTablePopupComponent } from './components/configurator/material-table-popup/material-table-popup.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterTemplatesPipe } from './pipes/filterTemplates.pipe';

@NgModule({
  declarations: [
    NumberDirective,
    SortByPipe,
    AppComponent,
    DashboardComponent,
    DashboardImagesComponent,
    LoginComponent,
    ArticlesComponent,
    ArticlesListComponent,
    ArticlesListRowComponent,
    CartComponent,
    CartNavigationComponent,
    CartProgressComponent,
    CreateOrderComponent,
    CreateOrderFormComponent,
    ParametersConfigurationComponent,
    LogsViewerComponent,
    PriceLabelComponent,
    OffersComponent,
    OfferRowComponent,
    OrdersComponent,
    OrderRowComponent,
    OrderArticleRowComponent,
    CalculationProcessComponent,
    ManageAccountComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    ManageUsersComponent,
    CreateUserComponent,
    FrameworkContractsComponent,
    FrameworkContractRowComponent,
    ContractInfoComponent,
    TextblocksComponent,
    TextblockRowComponent,
    ProfileMachineGroupComponent,
    ProfileMachineGroupItemComponent,
    ProfileComponent,
    ProfileHeaderComponent,
    ProfileBodyComponent,
    ProfileLinkImageDialogComponent,
    ProfileLinkValuesDialogComponent,
    ProfileComponentMaterialComponent,
    ProfileComponentMaterialLineComponent,
    ProfileComponentComponent,
    OrderSealSelectSealingTypeComponent,
    OrderSealComponent,
    OrderSealSelectScraperComponent,
    OrderSealSelectMeasuresAndMaterialComponent,
    OrderSealWrapperComponent,
    ProfileInputValuesComponent,
    ProfileOutputValuesComponent,
    ProfileLinkImageOutputsDialogComponent,
    ProfileOutputsFormulaDialogComponent,
    ConfiguratorTemplateComponent,
    OuterDimensionTemplatesComponent,
    OuterDimensionTemplatesListComponent,
    OuterDimensionTemplatesListLineComponent,
    MaterialTableComponent,
    MaterialTablePopupComponent,
    FilterPipe,
    FilterTemplatesPipe
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
