import { FileUtils } from './../../../shared/utils/file.utils';
import { Observable } from 'rxjs';
import { LogsService } from './../../../shared/services/logs.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logs-viewer',
  templateUrl: './logs-viewer.component.html',
  styleUrls: ['./logs-viewer.component.scss']
})
export class LogsViewerComponent implements OnInit {
  logs: Observable<string[]> = this.logsService.getLogsFiles();
  logData: Observable<string[]>;
  selectedFile: string;
  fileToDownload = null;
  loadingFile = false;
  constructor(private logsService: LogsService) { }

  ngOnInit(): void {
  }

  /**
   * Loads logs from selected file
   */
  loadLog() {
    this.logData = this.logsService.getLogs(this.selectedFile, 500);
  }

  /**
   * Selects file from list
   * @param file file name
   */
  selectFile(file: string) {
    this.fileToDownload = null;
    this.selectedFile = file;
  }

  /**
   * Downloads file
   */
  downloadFile() {
    this.loadingFile = true;
    this.logsService.getLogs(this.selectedFile).subscribe(text => {
      const data = new Blob(text, { type: 'application/octet-stream' });
      FileUtils.downloadFile(data, this.selectedFile);
      this.loadingFile = false;
    });
  }
}
