<div class="container-fluid">
    <div class="navigation" fxLayout="row" fxLayoutAlign="space-between">
        <div class="navigation__item" [routerLink]="disableBack ? null : [cartSteps[step].back.route]" fxLayout="row"
            fxLayoutAlign="center center" *ngIf="cartSteps[step].back">
            <img src="assets/img/icons/chevron-left{{disableBack ? '-grey' : ''}}.svg" alt="back-icon"
                class="navigation__icon">
            <div class="navigation__text" [ngClass]="{'disabled': disableBack}">{{cartSteps[step].back.title|translate}}</div>
        </div>
        <div class="navigation__item" fxLayout="row" fxLayoutAlign="center center" *ngIf="cartSteps[step].forward"
            [routerLink]="disableForward ? null : [cartSteps[step].forward.route]">
            <div class="navigation__text" [ngClass]="{'disabled': disableForward}"> {{cartSteps[step].forward.title|translate}}
            </div> <img src="assets/img/icons/chevron-right{{disableForward ? '-grey' : ''}}.svg" alt="forward-icon"
                class="navigation__icon">
        </div>
    </div>
</div>
