
export enum CapacityPriceStatus {
    LOW = 'LOW',
    HIGH = 'HIGH',
    NA = 'NA',
    HOLIDAY = 'HOLIDAY',
    UNVALIDATED = 'UNVALIDATED',
    NO_MATERIAL = 'NO_MATERIAL',
    PAST = 'PAST',
    BELOW_MIN_AMOUNT_SCALED_PRICE = 'BELOW_MIN_AMOUNT_SCALED_PRICE',
    ABOVE_MAX_AMOUNT = 'ABOVE_MAX_AMOUNT',
    MAX_ORANGE = 'MAX_ORANGE',
    MIN_POSITION = 'MIN_POSITION',
    NO_PROCESS_STARTING_DAY = 'NO_PROCESS_STARTING_DAY',
    TRADE_GOODS = 'TRADE_GOODS'
 }
