import { ReplaySubject, Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  previewOutput: any = null;
  @Input() svgExtension = false;
  @Output() fileChange: EventEmitter<any> = new EventEmitter();

  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
  public files = [];
  imageChangedEvent: any = '';

  /**
   * On select file perform an upload
   * @param event event
   */
  onSelect(event): void {
    if (event.rejectedFiles.length > 0) { return; }
    // check file type and process file
    if (this.svgExtension) {
      event.addedFiles[0]
      if (event.addedFiles[0].type === 'image/svg+xml') {
        const fileReader = new FileReader();
        fileReader.onload = async e => {
          const value = this.sanitizer.bypassSecurityTrustUrl(fileReader.result as string);
          // get the preview
          this.previewOutput = value;
        }
        fileReader.readAsDataURL(event.addedFiles[0]);
        // emit the file to the parent component
        this.fileChange.emit(event.addedFiles[0]);
      }
    } else {
      // convert from file into base64
      this.convertFile(event.addedFiles[0]).subscribe(base64 => {
        this.previewOutput = 'data:image/png;base64,' + base64;
        fetch(this.previewOutput)
          .then(res => res.blob())
          .then(
            blob => {
              const file = new File([blob], 'image.png');
              this.fileChange.emit(file);
            }
          )
      });
    }
  }

  /**
  * On click save submit changes
  */
  onSubmit(): void {

  }

  /**
   * Convert from File into base64
   * @param file File
   * @returns base64
   */
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  /**
   * On remove event
   * @param event remove event
   */
  onRemove(event): void {
    this.previewOutput = null;
  }


}
