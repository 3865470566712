import { Profile } from 'src/app/shared/models/profile.model';
import { ImagesService } from './../../../../shared/services/images.service';
import { OrderSealService } from '../../../../shared/services/order-seal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-seal-select-scraper',
  templateUrl: './order-seal-select-scraper.component.html',
  styleUrls: ['./order-seal-select-scraper.component.scss']
})
export class OrderSealSelectScraperComponent implements OnInit {
  public profiles: Profile[] = [];
  timer: any;
  constructor(private orderSealService: OrderSealService, private imagesService: ImagesService) { }

  /**
   * Checks sealing type item on select
   * @param item 
   */
  checkItem(item) {
    this.profiles.map(profile => { if (profile.idProfile !== item.idProfile) { profile.checked = false; } return profile; });
    item.checked = true;
    this.orderSealService.selectProfile(item);
    // wait time
    if (item.checked) {

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.orderSealService.goToNextPage();
      }, 1000);
    }
  }

  ngOnInit(): void {
    // get profiles
    if (this.orderSealService.selectedSealingTypeValue) {
      this.profiles = this.orderSealService.selectedSealingTypeValue.profiles.filter(profile => profile.valid);
      this.profiles.map(profile => {
        //also add image 
        profile.image = this.imagesService.getProfileImageUrl(profile.idProfile, 'profile');
        profile.checked = this.orderSealService.selectedProfileValue?.idProfile === profile?.idProfile || false;
        return profile;
      });
    }
  }

}
