import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ParametersConfiguration } from './../../../shared/models/parameters-configuration.model';
import { ParametersType } from './../../../shared/models/parameters-type.enum';
import { TranslateService } from "@ngx-translate/core";
import { ParametersConfigurationService } from './../../../shared/services/parameters-configuration.service';
import { ToastService } from './../../../shared/services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-parameters-configuration',
  templateUrl: './parameters-configuration.component.html',
  styleUrls: ['./parameters-configuration.component.scss']
})
export class ParametersConfigurationComponent implements OnInit, OnDestroy {
  parameters: ParametersConfiguration[];
  parametersInitial: ParametersConfiguration[];
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    private parametersConfigurationService: ParametersConfigurationService,
    private toastService: ToastService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.parametersConfigurationService.getParameters().pipe(takeUntil(this.unsubscribe)).subscribe((data: ParametersConfiguration[]) => {
      this.parameters = data;
      // deep copy array
      this.parametersInitial = data.map(x => Object.assign({}, x));
    });
  }

  /**
   * Updates parameters
   */
  updateParameters() {
    this.parametersConfigurationService.updateParameters(this.parameters).pipe(takeUntil(this.unsubscribe)).subscribe(ok => {
      this.showParametersUpdatesMessage();
      this.parametersInitial = this.parameters.map(x => Object.assign({}, x));
    });
  }
  /**
   * Gets type of paramether
   * @param type type string
   * @returns type
   */
  getType(type: string) {
    return ParametersType[type];
  }

  /**
   * Gets validation pattern for each type
   * @param type ParametersType
   * @returns pattern
   */
  getValidationPattern(type: string) {
    if (ParametersType[type] === ParametersType.INT_TYPE) {
      return '^[+-]?[0-9]*$';
    } else if (ParametersType[type] === ParametersType.DOUBLE_TYPE) {
      return '^[-+]?[0-9]+\.[0-9]+$';
    } else if (ParametersType[type] === ParametersType.STRING_TYPE) {
      return '^.*?$';
    }
  }


  get buttonDisabled() {
    return (JSON.stringify(this.parameters) === JSON.stringify(this.parametersInitial));
  }

  showParametersUpdatesMessage() {
    this.toastService.show(
      this.translateService.instant('codeTranslations.parameters-configuration-updated'),
      { classname: 'bg-success text-light', delay: 5000 }
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
