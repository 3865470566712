import { Textblock } from './../../shared/models/textblock.model';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TextblockService } from 'src/app/shared/services/textblock.service';
import { Article } from './../../shared/models/article.model';
import { ArticlesListMode } from './../../shared/models/articles-list-mode.enum';
import { CartArticle } from './../../shared/models/cart-article.model';
import { TranslateService } from "@ngx-translate/core";
import { ShoppingCartService } from './../../shared/services/shopping-cart.service';
import { ToastService } from './../../shared/services/toast.service';

export interface ArticlesLists { articles: Article[]; articlesInCart: CartArticle[]; }
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  allowGotoOrderPage = false;
  pricesChanged = false;
  firstTimeLoad = false;
  articlesListMode = ArticlesListMode.CART;
  unvalidArticlesListMode = ArticlesListMode.CART_UNVALIDATED;
  showUnvalidatedArticles = false;
  textblocks;
  public timeout: any = null;
  textblock;
  textblockValue = new UntypedFormControl('');
  allowTextblockSelection = false;
  constructor(
    public toastService: ToastService,
    public translationsService: TranslateService,
    public textblockService: TextblockService,
    public shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    // update number of articles in header on accessing cart page
    this.shoppingCartService.loadNumberOfArticles();
    this.loadTextblocks();
    this.textblockValue.valueChanges.subscribe(value => {

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.shoppingCartService.changeCartOrderComment(value).subscribe(data => this.showCartOrderMessageUpdatedMessage());
      }, 2000);
    });
    this.shoppingCartService.loadCart().subscribe((data: any) => {
      if (data) { this.textblockValue.patchValue(data.orderComment, { emitEvent: false }); }
    });
  }

  /**
   * Loads textblocks
   */
  loadTextblocks() {
    this.textblockService.getTextblocks().subscribe((data: Textblock[]) => { this.textblocks = data; });
  }

  clearTextblockText() {
    this.textblockValue.patchValue('', { emitEvent: true });
    this.textblock = null;
  }

  /**
   * Gets a current language code
   */
  get currentLanguageCode() {
    return this.translationsService.getDefaultLang();
  }

  get textboxSelectedText() {
    return (this.currentLanguageCode === 'en' && this.textblock.textEn?.length > 0) ? this.textblock.textEn : this.textblock.textDe || '-';
  }
  getTextboxText(textblock: Textblock) {
    return (this.currentLanguageCode === 'en' && textblock.textEn?.length > 0) ? textblock.textEn : textblock.textDe || '-';
  }
  /**
   * Selects a textblock and updates the cart
   * @param textblock selected textblock
   */
  selectTextblock(textblock: Textblock) {
    this.textblock = textblock;
    if (textblock) {
      this.textblockValue.patchValue(
        (this.currentLanguageCode === 'en' && textblock.textEn?.length > 0) ? textblock.textEn : textblock.textDe || '-'
      );
    } else { this.textblockValue.patchValue(''); }

    // const textblockText = (this.currentLanguageCode === 'en' && this.textblock.textEn?.length > 0)
    // ? this.textblock.textEn : this.textblock.textDe || '-';
    // this.shoppingCartService.changeCartOrderComment()
  }

  /**
   * On unvalid articles changes checks if we have any unvalid article
   * @param articles unvalid articles
   */
  unvalidArticlesChanges(articles: ArticlesLists) {
    // if greater then one -> show
    if (articles.articlesInCart.length === 0) {
      this.showUnvalidatedArticles = false;
    } else {
      if (!this.showUnvalidatedArticles) {
        this.toastService.show(this.translationsService.instant('codeTranslations.cart-unvalid-articles'),
          { classname: 'bg-warning text-light', delay: 5000 });
      }
      this.showUnvalidatedArticles = true;
    }
  }

  /**
   * Shows order created message
   */
  showCartOrderMessageUpdatedMessage() {
    this.toastService.show(this.translationsService.instant('codeTranslations.order-message-updated'),
      { classname: 'bg-success text-light', delay: 5000 });
  }


  /**
   * On articles changes checks if we can access order page
   * when articles list is empty articles page link gets disabled
   * @param articles ArticlesLists[]
   */
  articlesChanges(articles: ArticlesLists) {
    this.allowTextblockSelection = articles.articles.length > 0;
    this.allowGotoOrderPage = articles.articles.length > 0;
    articles.articlesInCart.forEach(article => {
      if (article.priceConfirmed === false) {
        this.pricesChanged = true;
        this.allowGotoOrderPage = false;
      }
      if (article.price <= 0 || article.valid === false || !article.available) {
        this.allowGotoOrderPage = false;
      }
    });
    if (this.pricesChanged === true && !this.firstTimeLoad) {
      this.toastService.show(this.translationsService.instant('codeTranslations.cart-prices-changed'),
        { classname: 'bg-warning text-light', delay: 5000 });
      this.firstTimeLoad = true;
    }
  }

}
