import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OuterDimensionTemplatesListMode } from 'src/app/shared/models/outer-dimension-template-mode.enum';
import { ProfileComponentMaterialLine } from 'src/app/shared/models/profile-component-material-line.model';

@Component({
  selector: 'app-outer-dimension-templates',
  templateUrl: './outer-dimension-templates.component.html',
  styleUrls: ['./outer-dimension-templates.component.scss']
})
export class OuterDimensionTemplatesComponent implements OnInit {


  OuterDimensionTemplatesListMode = OuterDimensionTemplatesListMode;

  constructor() { }

  ngOnInit(): void {
  }


}
