import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DatepickerColors } from './../../models/datepicker-colors.model';
import { CustomAdapter } from './custom-adapter';
import { CustomDateParserFormatter } from './custom-date-parser-formatter';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Output() selectedDate = new EventEmitter<Date>();
  @Output() selectedMonth = new EventEmitter<{}>();
  @Input() setDate = new EventEmitter<Date>();
  @Input() minDate: number;
  @Input() placeholder: string;
  @Input() defaultDate: string;
  @Input() dateReset: boolean;
  @Input() customColors: DatepickerColors[];
  invalid = false;
  model: any;
  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, private config: NgbDatepickerConfig) {
    if (this.minDate === undefined || this.minDate === null) {
      // disable dates before today
      let current = new Date();
	  current.setDate(current.getDate() + 1);
      config.minDate = {
        year: current.getFullYear(), month:
          current.getMonth() + 1, day: current.getDate()
      };
      config.outsideDays = 'hidden';
      config.navigation = 'arrows';
    } else {
      // TODO: implement custom min date
    }
  }

  /**
   * on changes should reset date if dateReset is true
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.dateReset) {
      this.model = null;
    }
  }

  /**
   * Months change
   * @param event event
   */
  monthChange(event) {
    if (!this.customColors) {
      return;
    }
    if (event.current && !_.find(this.customColors, { month: event.current.month })) {
      this.selectedMonth.emit(event.current);
    }
    if (event.next && !_.find(this.customColors, { month: event.next.next })) {
      this.selectedMonth.emit(event.next);
    }
  }

  /**
   * Gets day color
   * @param day number
   * @param month number
   * @param year number
   * @returns color string
   */
  getDayColor(day: number, month: number, year: number) {
    const customColors = this.customColors.filter(color => color.day === day && color.month === month && color.year === year);
    if (customColors.length > 0) {
      return customColors[0].color;
    }
    return '#fff';
  }
  /**
   * Gets actual date
   */
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  /**
   * Emits date
   */
  emitDate() {
    this.validateDate();
    if (!this.invalid) {
      this.selectedDate.emit(this.model);
    }
  }

  /**
   * Validates date
   */
  validateDate() {
    if (this.model == null) {
      this.invalid = false;
      return;
    }
    const pickedDateParts = this.model.split('-');
    const pickedDate = new Date(+pickedDateParts[2], pickedDateParts[1] - 1, +pickedDateParts[0]);
    const todaysDate = new Date(this.ngbCalendar.getToday().year, this.ngbCalendar.getToday().month - 1, this.ngbCalendar.getToday().day);
    if (isNaN(pickedDate.getTime())) {
      this.invalid = true;
    } else {
      this.invalid = (pickedDate < todaysDate);
    }
  }

  ngOnInit(): void {
    this.setDate.subscribe(date => {
      const newDate = new Date(date);
      this.model = this.dateAdapter
        .toModel({ day: newDate.getUTCDate(), month: newDate.getUTCMonth() + 1, year: newDate.getUTCFullYear() });
    });
    // show default date if is set
    if (this.defaultDate) {
      const date = new Date(this.defaultDate);
      this.model = this.dateAdapter.toModel({ day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() });
      this.selectedDate.emit(this.model);
    }

  }
  isDisabled(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }
}
