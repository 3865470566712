<ngx-dropzone [disableClick]="previewOutput !== null" (change)="onSelect($event)" (onRemove)="onRemove($event)"
    [maxFileSize]="5242880" [multiple]="false" [accept]="svgExtension ? 'image/svg+xml' : 'image/*'">
    <ngx-dropzone-label>
        <div class="text-center">
            <div class="upload-icon">
                <i class="bi bi-upload"></i>
            </div>
            <div class="upload-text" i18n>
                <u>
                    {{'shared.imageUpload.selectPicture'|translate}}
                </u>
                <br>{{'shared.imageUpload.selectInThisField'|translate}}
            </div>
        </div>
    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngIf="previewOutput" [removable]="true" (removed)="onRemove($event)">
        <ngx-dropzone-label>
            <img [src]="previewOutput" />
        </ngx-dropzone-label>
    </ngx-dropzone-preview>
</ngx-dropzone>