import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Article } from 'src/app/shared/models/article.model';
import { CapacityPrice } from 'src/app/shared/models/capacity-price.model';
import { CapacityPricesRequest } from 'src/app/shared/models/capacity-prices-request.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ArticleType } from '../models/article-type.enum';
import { environment } from './../../../environments/environment';
import { ArticleCalculationProcess } from './../models/article-calculation-proces-map.model';
import { ScaledPricesList } from './../models/scaled-price-list.model';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  private apiUrl = environment.apiUrl;
  private closeOtherScaledPricesEventEmitter = new EventEmitter<string>();
  userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Gets articles list
   * @returns articles
   */
  getArticles(startPosition: number, endPosition: number, searchText = null) {
    const search = searchText == null ? '' : searchText + '/';
    return this.http.get<Article[]>(`${this.apiUrl}/articles/${this.userId}/${startPosition}/${endPosition}/${search}`)
      .pipe(map(article => article.map(art => { art.articleType = ArticleType.ARTICLE; return art; })), catchError(error => {
        // console.error(`There was an error while loading the articles list, please contact Haenssler.`);
        return of([]);
      }));
  }

  /**
   * Gets article capacity prices
   * @param capacityPricesRequest capacityPricesRequest
   * @returns  CapacityPrice[]
   */
  getArticleCapacityPrices(capacityPricesRequest: CapacityPricesRequest) {
    return this.http.post<CapacityPrice>(`${this.apiUrl}/capacity/prices/${this.userId}`, capacityPricesRequest).pipe(map((article: CapacityPrice) => {
      article.capacityPricesList.map(cap => {
        const capacityPriceCalcAnalyticsMap: ArticleCalculationProcess[] = [];
        
        if (!cap.capacityPriceCalcAnalyticsMap) { 
            return cap; 
          }

          Object.keys(cap.capacityPriceCalcAnalyticsMap).forEach(idArticleErp => {
            capacityPriceCalcAnalyticsMap.push({ idArticleErp, values: cap.capacityPriceCalcAnalyticsMap[idArticleErp] });
          });
          
          cap.capacityPriceCalcAnalyticsMap = capacityPriceCalcAnalyticsMap;

          return cap;
        });

        return article;

      }), catchError(error => {
        console.error(`There was an error while loading the capacity prices for article ${capacityPricesRequest.idArticleERP}, please contact Haenssler.`);
        return of(null);
      }));
    }


    /**
     * Gets article scaled prices
     * @param idArticleERP id article
     * @returns  ScaledPrice[]
    */
   getArticleScaledPrices(idArticleERP: string, idOfferPosition = null) {
     console.log("getArticleScaledPrices for: " , idArticleERP)
    let url = `${this.apiUrl}/articles/${this.userId}/scaled-prices/${idArticleERP}`;
    if (idOfferPosition !== null) {
      url = url + '/' + idOfferPosition;
    }
    return this.http.get<ScaledPricesList>(url).pipe(
      catchError(error => {
        console.error(`There was an error while loading the scaled prices for article ${idArticleERP}, please contact Haenssler.`);
        console.error("ERROR: ", error)
        return of(null);
      }));
  }

  /**
   * Closes other scaled prices
   * @param idArticleERP actual scaled price to keep opened. keep empty to close all scaled prices
   */
  closeOtherScaledPrices(idArticleERP = 'null') {
    this.closeOtherScaledPricesEventEmitter.emit(idArticleERP);
  }

  /**
   * Gets close other scaled prices event
   */
  get closeOtherScaledPricesEvent() {
    return this.closeOtherScaledPricesEventEmitter;
  }

  /**
   * Gets article details
   * @param idArticleERP id article
   * @returns  ScaledPrice[]
   */
  getArticleDetails(idArticleERP: string) {
    return this.http.get<Article>(`${this.apiUrl}/articles/${this.userId}/article/${idArticleERP}`)
      .pipe(map(offer => { offer.articleType = ArticleType.ARTICLE; return offer; }), catchError(error => {
        console.error(`There was an error while loading the article details for article ${idArticleERP}, please contact Haenssler.`);
        return of(null);
      }));
  }
}
