
    <div class="order-seal-container container pt-3 pb-3">
        <div class="row">
            <div class="pt-5 m-auto" *ngIf="sealingTypes?.length === 0">
                <span>
                    {{'orderSeal.sealingType.noSealTypesFound' | translate}}
                </span>
            </div>
            <div class="col-md-4 mb-3" *ngFor="let item of sealingTypes">
                <div class="card" (click)="checkItem(item)" [ngClass]="{'checked' : item.checked}">
                    <div class="card-body">
                        <h5 class="card-title">{{item.name}}</h5>
                        <app-animated-checkmark [checked]="item.checked"></app-animated-checkmark>
                        <img class="sealing-type-image" [src]="item.image" [alt]="item.name"  onerror="this.onerror=null;this.src='assets/img/placeholder.png';">
                    </div>
                </div>
            </div>
        </div>
    </div>
