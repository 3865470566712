import { ToastService } from './../../services/toast.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastComponent implements OnInit {

  show = true;
  constructor(public toastService: ToastService) { }

  /**
   * Determines whether the mesage contains template or not
   * @param toast toast object
   * @returns boolean
   */
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  ngOnInit(): void {
  }



}

