import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ProfileComponentMaterialTemplate } from '../models/profile-component-material-template.model';
import { ProfileComponentMaterialTemplateLine } from '../models/profile-component-material-template-line.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileComponentTemplateService {


  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(private http: HttpClient) { }


  /**
   * Gets all templates component
   * @param profileComponentMaterialTemplate
   * @returns template
   */
  public getAllProfileComponentTemplates(): Observable<ProfileComponentMaterialTemplate[]> {
    return this.http.get(this.apiUrl + '/profile-component-template/all') as Observable<ProfileComponentMaterialTemplate[]>;
  }
  
  /**
   * Creates template component
   * @param profileComponentMaterialTemplate
   * @returns template
   */
  public createProfileComponentTemplate(profileComponentMaterialTemplate: ProfileComponentMaterialTemplate): Observable<ProfileComponentMaterialTemplate> {
    return this.http.post(this.apiUrl + '/profile-component-template/', profileComponentMaterialTemplate) as Observable<ProfileComponentMaterialTemplate>;
  }

  /**
  * Updates template component
  * @param profileComponentMaterialTemplate
  * @returns template
  */
  public updateProfileComponentTemplate(profileComponentMaterialTemplate: ProfileComponentMaterialTemplate): Observable<ProfileComponentMaterialTemplate> {
    return this.http.put(this.apiUrl + '/profile-component-template/', profileComponentMaterialTemplate) as Observable<ProfileComponentMaterialTemplate>;
  }

  /**
   * Gets template component
   * @param idTemplate
   * @returns template
   */
  public getProfileComponentTemplate(idTemplate: number): Observable<ProfileComponentMaterialTemplate> {
    return this.http.get(this.apiUrl + '/profile-component-template/' + idTemplate) as Observable<ProfileComponentMaterialTemplate>;
  }

  /**
   * Gets template component
   * @param idTemplate
   * @returns template
   */
  public deleteProfileComponentTemplate(idTemplate: number): Observable<ProfileComponentMaterialTemplate> {
    return this.http.delete(this.apiUrl + '/profile-component-template/' + idTemplate) as Observable<ProfileComponentMaterialTemplate>;
  }

  // Updates templates order HSEP-1580
  public updateTemplateOrders(jsonDATA){
    return this.http.put(this.apiUrl + '/profile-component-template/update-positions/', jsonDATA);
  }


  // 👆👆👆 Template Parent Methods 👆👆👆

  // 👇👇👇 Template Line Methods 👇👇👇

  /**
   * Creates template component line
   * @param profileComponentMaterialLine
   * @returns template line
   */
  public createProfileComponentTemplateLine(profileComponentMaterialLine: ProfileComponentMaterialTemplateLine): Observable<ProfileComponentMaterialTemplateLine> {
    return this.http.post(this.apiUrl + '/profile-component-template/line/', profileComponentMaterialLine) as Observable<ProfileComponentMaterialTemplateLine>;
  }

  /**
  * Updates template component line
  * @param profileComponentMaterialLine
  * @returns template line
  */
  public updateProfileComponentTemplateLine(profileComponentMaterialLine: ProfileComponentMaterialTemplateLine): Observable<ProfileComponentMaterialTemplateLine> {
    return this.http.put(this.apiUrl + '/profile-component-template/line/', profileComponentMaterialLine) as Observable<ProfileComponentMaterialTemplateLine>;
  }

  /**
   * Gets template component line
   * @param idTemplateLine 
   * @returns template line
   */
  public getProfileComponentTemplateLine(idTemplateLine: number): Observable<ProfileComponentMaterialTemplateLine> {
    return this.http.get(this.apiUrl + '/profile-component-template/line/' + idTemplateLine) as Observable<ProfileComponentMaterialTemplateLine>;
  }

  /**
   * Gets template component line
   * @param idTemplateLine 
   * @returns template line
   */
  public deleteProfileComponentTemplateLine(idTemplateLine: number): Observable<ProfileComponentMaterialTemplateLine> {
    return this.http.delete(this.apiUrl + '/profile-component-template/line/' + idTemplateLine) as Observable<ProfileComponentMaterialTemplateLine>;
  }

  


}
