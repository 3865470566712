<div class="search-content" #insideElement>
  <div class="search-bar" fxLayout="row">
    <input id="typeahead-basic" type="text" class="form-control" placeholder="{{'shared.searchBar.articleOrOfferNumber'|translate}}"
      [(ngModel)]="model" i18n-placeholder (ngModelChange)="articlesSearch($event)" (keyup.enter)="articlesSearch($event.target.value)"  (click)="openSearchWindow()">
    <button type="submit" class="btn default">
      <img src="/assets/img/icons/search.svg" alt="search-icon">
    </button>
  </div>
  <div class="results first-step" *ngIf="openSearch && searchStep == 1">
    <div class="articles-results">
      <h3 *ngIf="(articlesResult | async) != null; else loadingArticles" i18n>{{ (articlesResult | async)?.length }} {{'shared.searchBar.articleFound'|translate}}</h3>
      <div class="result-section" *ngIf="(articlesResult | async)?.length > 0;">
        <ng-template ngFor let-item [ngForOf]="articlesResult | async" let-i="index">
          <div class="result d-flex justify-content-between align-items-center" (click)="openArticle(item, $event)"
            *ngIf="i<=5 || showAllArticles">
            <span><b>{{item.idArticleERP}}</b><span class="d-sm-none d-lg-inline">
                {{(item.descriptionERP | slice:0:50) + (item.descriptionERP.length > 50 ? '...' : '')}}</span></span> <a
              class="search-in-orders" (click)="searchOffersAndOrdersByArticleId(item)">{{'shared.searchBar.findArticles'|translate}}​ <img class="search-icon" src="/assets/img/icons/search.svg" alt="search">
            </a></div>
        </ng-template>
      </div>
      <div *ngIf="(articlesResult | async)?.length > 5 && !showAllArticles">
        <p (click)="showAllArticles=true" class="show-more" i18n>{{'shared.searchBar.showMore'|translate}}</p>
      </div>
      <ng-template #loadingArticles>
        <div class="articles-loading d-flex justify-content-between align-items-center">
          <h3>{{'shared.searchBar.findInArticles'|translate}}</h3>
          <app-loading-spinner [size]="14"></app-loading-spinner>
        </div>
      </ng-template>
    </div>
    <div class="offers-results">
      <h3 *ngIf="(offersResult | async) != null; else loadingOffers">{{ (offersResult | async)?.length }} {{'shared.searchBar.matchesOfferNumber'|translate}} </h3>
      <ng-template #loadingOffers>
        <div class="offers-loading d-flex justify-content-between align-items-center">
          <h3>{{'shared.searchBar.searchByOfferNumber'|translate}}</h3>
          <app-loading-spinner [size]="14"></app-loading-spinner>
        </div>
      </ng-template>
      <div class="result d-flex justify-content-between align-items-center" *ngFor="let item of offersResult | async"
        (click)="openOffer(item)">
        <span><b>{{item.idOfferERP}}</b><span class="d-sm-none d-lg-inline"> {{'shared.searchBar.from'|translate}}
            {{DateUtils.getFormattedDate(item.offerDate,true)}}</span> </span>
      </div>
    </div>
    <div class="orders-results">
      <h3 *ngIf="(ordersResult | async) != null; else loadingOrders">{{ (ordersResult | async)?.length }} {{'shared.searchBar.matchesOrderNumber'|translate}} </h3>
      <ng-template #loadingOrders>
        <div class="orders-loading d-flex justify-content-between align-items-center">
          <h3>{{'shared.searchBar.searchByOrderNumber'|translate}}</h3>
          <app-loading-spinner [size]="14"></app-loading-spinner>
        </div>
      </ng-template>
      <div class="result d-flex justify-content-between align-items-center" *ngFor="let item of ordersResult | async"
        (click)="openOrder(item)">
        <span><b>{{item.idOrderERP}}</b><span class="d-sm-none d-lg-inline"> {{'shared.searchBar.from'|translate}}
            {{DateUtils.getFormattedDate(item.shippingDate,true)}}</span></span>
      </div>
    </div>
  </div>
  <div class="results second-step" *ngIf="openSearch && searchStep == 2 && searchedArticle !== null">
    <div class="d-flex justify-content-between search-controls">
      <button class="control-button" (click)="searchStep = 1; model = lastSearch"><img class="control-icon"
          src="/assets/img/icons/back-arrow.svg" alt="return"></button>
      <button class="control-button close-button" (click)="openSearch = false; searchStep = 1;">
        <img class="control-icon" src="/assets/img/icons/close.svg" alt="close"></button>
    </div>
    <div class="show-article">
      <h3> {{'shared.searchBar.articleAppearsIn'|translate}} .. </h3>
      <div class="result d-flex justify-content-between align-items-center"
        (click)="openArticle(searchedArticle, $event)">
        <span><b>{{searchedArticle.idArticleERP}}</b><span class="d-sm-none d-lg-inline">
            {{searchedArticle.descriptionERP}}</span></span>
      </div>
    </div>
    <div class="search-in-orders-results" *ngIf="(ordersByArticleIdResult | async)?.length as ordersLength" >
      <h3 *ngIf="ordersLength && ordersLength > 0">{{'shared.searchBar.orders'|translate}} <span class="number-span">
          {{ordersLength}} </span>
      </h3>
      <div class="result-section">
        <ng-template ngFor let-item [ngForOf]="ordersByArticleIdResult | async" let-i="index">
          <div class="result d-flex justify-content-between align-items-center" (click)="openOrder(item, false)"
            *ngIf="i<=5 || showAllOrdersByArticleId">
            <span><b>{{item.idOrderERP}}</b> <span class="d-sm-none d-lg-inline"> {{'shared.searchBar.from'|translate}}
                {{ DateUtils.getFormattedDate(item.shippingDate,true)}}</span></span>
          </div>
        </ng-template>
      </div>
      <div *ngIf="ordersLength > 5 && !showAllOrdersByArticleId">
        <p (click)="showAllOrdersByArticleId=true" class="show-more" i18n>{{'shared.searchBar.showMore'|translate}}</p>
      </div>
      <div *ngIf="(ordersByArticleIdResult | async) == null">
        <div class="articles-loading d-flex justify-content-between align-items-center">
          <h3>{{'shared.searchBar.searchInOrders'|translate}}</h3>
          <app-loading-spinner [size]="14"></app-loading-spinner>
        </div>
      </div>
    </div>
    <div class="search-in-offers-results" *ngIf="(offersByArticleIdResult | async)?.length as offersLength">
      <h3 *ngIf="offersLength && offersLength > 0">{{'shared.searchBar.offers'|translate}} <span class="number-span">
          {{offersLength}} </span>
      </h3>
      <div class="result-section">
        <ng-template ngFor let-item [ngForOf]="offersByArticleIdResult | async" let-i="index">
          <div class="result d-flex justify-content-between align-items-center" (click)="openOffer(item, false)"
            *ngIf="i<=5 || showAllOffersByArticleId">
            <span><b>{{item.idOfferERP}}</b> <span class="d-sm-none d-lg-inline"> {{'shared.searchBar.from'|translate}}
                {{DateUtils.getFormattedDate(item.offerDate,true)}}</span></span>
          </div>
        </ng-template>
      </div>
      <div *ngIf="offersLength > 5 && !showAllOffersByArticleId">
        <p (click)="showAllOffersByArticleId=true" class="show-more" i18n>{{'shared.searchBar.showMore'|translate}}</p>
      </div>
    </div>
    <div *ngIf="(offersByArticleIdResult | async) == null">
      <div class="articles-loading d-flex justify-content-between align-items-center">
        <h3>{{'shared.searchBar.searchInOffers'|translate}}</h3>
        <app-loading-spinner [size]="14"></app-loading-spinner>
      </div>
    </div>
  </div>
</div>
