import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { environment } from './../../../environments/environment';
import { UserRoleType } from './../models/user-role-type.enum';
import { Customer } from '../models/customer.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentUserIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentUser: Observable<User> = new Observable<User>();
  public currentUserId: Observable<string> = new Observable<string>();
  constructor(private router: Router, private http: HttpClient) {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserSubject = new BehaviorSubject<User>(currentUser);
    this.currentUser = this.currentUserSubject.asObservable();
    if (currentUser !== null) {
      if (this.isAuthorized(UserRoleType.ACCOUNT_MANAGER) && currentUser.loginAs !== null && currentUser.loginAs !== undefined) {
        this.currentUserIdSubject = new BehaviorSubject<string>(currentUser.loginAs);
      } else {
        this.currentUserIdSubject = new BehaviorSubject<string>(currentUser.id);
      }
    }
    this.currentUserId = this.currentUserIdSubject.asObservable();
  }

  /**
   * Gets current user value from localStorage
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Gets current user value from localStorage
   */
  public get currentUserIdValue(): string {
    return this.currentUserIdSubject.value;
  }

  /**
   * Logs in user into system
   * @param username username
   * @param password password
   * @returns login
   */
  login(username: string, password: string): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/auth/signin`, { username, password })
      .pipe(map(user => {
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.currentUserIdSubject.next(user.id);
          return user;
        }
      }));
  }

  /**
   * Logins as cusomer
   * @param id string
   */
  loginAsCusomer(id: string) {
    if (this.isAuthorized(UserRoleType.ACCOUNT_MANAGER)) {
      const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
      currentUser.loginAs = id;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      this.currentUserIdSubject.next(currentUser.loginAs);
      this.router.navigate(['/dashboard']);
    }
  }

  /**
   * Logouts substituted customer
   */
  logoutAsCustomer() {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.loginAs = undefined;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
    this.currentUserIdSubject.next(currentUser.loginAs);
  }

  /**
   * Logins as account manager
   * @param id string
   */
  loginAsAccountManager(id: string) {
    if (this.isAuthorized(UserRoleType.ACCOUNT_MANAGER)) {
      const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
      currentUser.loginAsManager = id;
      delete currentUser.loginAs;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
    }
  }

  /**
   * Removes jwt token from storage
   */
  removeToken() {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.currentUserIdSubject.next(null);
    this.router.navigate(['/login']);
  }

  /**
   * Sends logout request and removes user from local storage to log user out
   */
  logout() {
    this.http.get<any>(`${environment.apiUrl}/auth/logout`).subscribe(result => this.removeToken());
  }

  /**
   * checks if user is authorized or not depending on the role
   * @param allowedRole role
   * @returns true if authorized
   */
  isAuthorized(allowedRole: UserRoleType): boolean {
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRole == null) {
      return true;
    }

    // decode token to read the payload details
    const decodeToken = jwt_decode(this.currentUserValue.token);
    // check if it was decoded successfully, if not the token is not valid, deny access
    if (!decodeToken) {
      console.log('Invalid token');
      return false;
    }
    // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
    return decodeToken.roles.includes(allowedRole.valueOf());
  }

  /**
   * Gets or creates user
   * @param customer Customer
   * @returns Observable
   */
  getOrCreateUser(customer: Customer) {
    return this.http.post<any>(`${environment.apiUrl}/account-manager/get-create/`, customer);
  }

  /**
   * Gets account manager user
   * @param idErp id account manager erp
   * @returns Observable
   */
  getAccountManager(idErp: string) {
    return this.http.get<any>(`${environment.apiUrl}/account-manager/${idErp}`);
  }

  /**
   * Gets logged user
   * @returns user
   */
  getLoggedUser() {
    if (this.currentUserIdValue && this.currentUserIdValue !== undefined) {
      return this.http.get<User>(`${environment.apiUrl}/auth/me/${this.currentUserIdValue}`);
    }
    return of();
  }
}
