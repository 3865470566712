<div class="sections">
  <div class="container-fluid noselect" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
    <div class="sections__column" fxFlex="33.3">
      <div class="sections__container sections__container-first" [routerLink]="['/articles']">
        <div class="sections__image-wrapper">
          <img class="sections__image" src="/assets/img/dashboard-images/my-articles.jpg" alt="my-articles"
            draggable="false">
        </div>
        <div class="sections__title sections__title-left">
          <img class="mb-1" src="/assets/img/icons/articles.svg" alt="articles-icon">
          <h3 i18n>{{'dashboard.images.myArticles'|translate}}</h3>
        </div>
      </div>
    </div>
    <div class="sections__column" fxFlex="33.3">
      <div class="sections__container" [routerLink]="['/orders']">
        <div class="sections__image-wrapper">
          <img class="sections__image" src="/assets/img/dashboard-images/orders.jpg" alt="orders" draggable="false">
        </div>
        <div class="sections__title">
          <img class="mb-1" src="/assets/img/icons/orders.svg" alt="orders-icon">
          <h3 i18n>{{'dashboard.images.orders'|translate}}</h3>
        </div>
      </div>
      <div class="sections__container" [routerLink]="['/offers']">
        <div class="sections__image-wrapper">
          <img class="sections__image" src="/assets/img/dashboard-images/deals.jpg" alt="offers" draggable="false">
        </div>
        <div class="sections__title">
          <img class="mb-1" src="/assets/img/icons/offers.svg" alt="offers-icon">
          <h3 i18n>{{'dashboard.images.offers'|translate}}</h3>
        </div>
      </div>
    </div>
    <div class="sections__column" fxFlex="33.3">
      <div class="sections__container sections__container-last" [routerLink]="['/framework-contracts']">
        <div class="sections__image-wrapper">
          <img class="sections__image" src="/assets/img/dashboard-images/framework_contracts.jpg" alt="cart"
            draggable="false">
        </div>
        <div class="sections__title">
          <img src="/assets/img/icons/framework-contracts.svg" alt="doc-icon" class="doc-icon">
          <h3 i18n>{{'dashboard.images.frameworkContracts'|translate}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
