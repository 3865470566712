import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Article } from 'src/app/shared/models/article.model';
import { User } from 'src/app/shared/models/user.model';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ArticlesListMode } from './../../../shared/models/articles-list-mode.enum';
import { CapacityPriceList } from './../../../shared/models/capacity-price-list.model';
import { CartArticle } from './../../../shared/models/cart-article.model';
import { SearchResult } from './../../../shared/models/search-result-enum.model';
import { ShoppingCartService } from './../../../shared/services/shopping-cart.service';
import { ArticlesListUtils } from './../../../shared/utils/articles-list.utils';
@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  providers: [DecimalPipe, DatePipe],
  animations: [
    trigger('row', [
      transition(':leave', [
        animate('400ms ease-out',
          style({ opacity: 0, transform: 'translateX(35px)' })
        )])
    ])
  ]
})
export class ArticlesListComponent implements OnInit, OnDestroy {
  @Output() articlesListChanged = new EventEmitter();
  @Input() articlesListMode = ArticlesListMode.ARTICLES;
  public showSearch: boolean;
  public ArticlesListMode = ArticlesListMode;
  public searchFormControl = new UntypedFormControl('');
  private unsubscribe: Subject<void> = new Subject();
  // all articles list without filters
  public articles: Article[] = [];
  public articlesFull: Article[] = [];
  // contains the information about the article id, the amount and the desired delivery date
  public articlesInCart: CartArticle[] = [];
  // logged user
  public user: User;
  // start loading articles from 0
  private startPos = 0;
  private fullListStartPos = 0;
  // any time we click load more 20 new articles will be loaded
  private numberOfArticlesToLoad = 20;
  // load articles until position
  private limit = this.numberOfArticlesToLoad;
  private searchText = null;
  // loading circle
  public loading = true;
  constructor(
    private articlesService: ArticlesService,
    private shoppingCartService: ShoppingCartService,
    private authenticationService: AuthenticationService,
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute,
    private router: Router
  ) { this.user = this.authenticationService.currentUserValue; }

  ngOnInit(): void {
    // if route contains showSearch param load articles from local storage
    if (this.route.snapshot.paramMap.get('showSearch')) {
      this.showSearch = true;
      this.searchFormControl.setValue(this.route.snapshot.paramMap.get('articleId'));
    }
    this.router.events.subscribe(data => {
      if (this.route.snapshot.paramMap.get('showSearch')) {
        this.showSearch = true;
        this.loadArticles();
        this.searchFormControl.setValue(this.route.snapshot.paramMap.get('articleId'));
      }
    });
    // for cart mode get articles in cart and also articles details
    if (this.isCartMode) {
      this.loadCartArticles();
    } else if (this.user) {
      // in articles page mode load articles
      this.loadArticles();
    }
  }

  /**
   * Searches articles by text when user types into the search input
   * @param data string
   */
  onSearch(data: string) {
    data = data.trim();
    this.startPos = 0;
    this.loading = true;
    this.articles = [];
    if (data === '') {
      // if the search field is empty use the last original list values and show the list we had before search
      this.searchText = null;
      this.articles = this.articlesFull;
      this.startPos = this.fullListStartPos;
      if (this.articlesFull.length === 0) {
        this.loadArticles();
      } else {
        this.loading = false;
      }
      return;
    }
    this.searchText = data;
    this.loadArticles();
    this.showSearch = false;
  }

  /**
   * Loads articles
   */
  loadArticles() {
    if (this.showSearch) {
      // get articles from local storage
      this.articles = [JSON.parse(localStorage.getItem(SearchResult.SearchResultArticles))];
      this.loading = false;
    } else {
      this.articlesService.getArticles(this.startPos, this.limit, this.searchText).pipe(
        takeUntil(this.unsubscribe)).subscribe((articles: Article[]) => {
          if (articles) {
            this.loading = false;
            if (this.articles !== null) {
              this.articles = [...this.articles, ...articles];
            } else {
              this.articles = articles;
            }
            if (this.searchText == null) {
              this.fullListStartPos = this.startPos;
              this.articlesFull = this.articles;
            }
          } else {
            this.loading = false;
          }
        });
    }
    // triggers search on search input changes
    this.detectSearchInputChanges();
  }

  /**
   * Loads cart articles
   */
  loadCartArticles() {
    // should load valid articles if articlesListMode equals ArticlesListMode.CART and unvalid articles for CART_UNVALID
    const loadValidArticles = this.articlesListMode === ArticlesListMode.CART;
    this.shoppingCartService.getArticlesInCartWithDetails(loadValidArticles)
      .pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        if (data) {
          this.loading = false;
          const list = [];
          // create temp list
          for (let j = 0; j < data.articlesInCart.length; j++) {
            list.push({ articlesInCart: data.articlesInCart[j], articleDetails: data.articleDetails[j] });
          }
          // sort cart articles by id so they are showed with the correct order
          list.sort((a, b) => a.articlesInCart.idCartArticle - b.articlesInCart.idCartArticle);
          for (const listItem of list) {
            // article in cart info (amount, quantity ...)
            this.articlesInCart.push(listItem.articlesInCart);
            // article details for each cart article (id, description ...)
            this.articles.push(listItem.articleDetails);
          }
          this.articlesListChanged.emit({ articles: this.articles, articlesInCart: this.articlesInCart });
        } else {
          this.loading = false;
        }
      });
  }


  /**
   * Loads more articles on click on load more
   * @returns void
   */
  loadMore() {
    if (!this.loadMoreAvailable) { return; }
    this.loading = true;
    if (this.articles !== null) {
      this.startPos = this.articles.length;
      this.limit = this.numberOfArticlesToLoad;
    }
    this.loadArticles();
  }

  /**
   * Gets triggered when article is removed and removes it from array
   */
  articleRemoved(index) {
    this.articles.splice(index, 1);
    this.articlesInCart.splice(index, 1);
    this.articlesListChanged.emit({ articles: this.articles, articlesInCart: this.articlesInCart });
  }

  /**
   * Capacitys price changed
   * @param capacityPrice capacityPrice
   * @param index capacityPrice index
   */
  capacityPriceChanged(capacityPrice: CapacityPriceList, index) {
    if (this.isCartMode) {
      this.articlesInCart[index].available = ArticlesListUtils.checkStatusIsAvailable(capacityPrice.priceStatus);
      this.articlesListChanged.emit({ articles: this.articles, articlesInCart: this.articlesInCart });
    }
  }

  /**
   * Articles changed
   */
  articlesChanged() {
    if (this.isCartMode) {
      this.articlesListChanged.emit({ articles: this.articles, articlesInCart: this.articlesInCart });
    }
  }

  /**
   * Detects search input changes
   */
  detectSearchInputChanges() {
    // this.articles$ = this.searchByIdFormControl.valueChanges.pipe(
    //   startWith(''),
    //   map(text => this.search(text, this.decimalPipe))
    // );
  }

  /**
   * Searchs articles from search input
   * @param text text
   * @param pipe pipe
   * @returns found articles
   */
  search(text: string, pipe: PipeTransform): any[] {
    return this.articles.filter(article => {
      const term = text.toLowerCase();
      return article.idArticleERP.toLowerCase().includes(term);
    });
  }

  /**
   * Gets load more available
   */
  get loadMoreAvailable() {
    return (this.articles !== null && this.startPos + this.limit === this.articles.length);
  }
  /**
   * Gets whether is cart mode
   */
  get isCartMode() {
    return ArticlesListUtils.isCartMode(this.articlesListMode);
  }

  /**
   * Gets whether is articles mode
   */
  get isArticlesMode() {
    return ArticlesListUtils.isArticlesMode(this.articlesListMode);
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
