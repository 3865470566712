<div class="separator-line"></div>
<div class="row" [formGroup]="profileComponentMaterialLineForm">
    <div class="card-item-input d-flex align-items-center col-md-5 col-lg-5">
        <div class="card-item-label">
            {{'sealingType.profile.from'|translate}}
        </div>
        <div class="card-item-value ml-2">
            <input type="number" class="form-control" placeholder="{{'sealingType.profile.from'|translate}}" min="0" formControlName="fromValue">
        </div>
        <div class="card-item-label ml-2">
            {{'sealingType.profile.to'|translate}}
        </div>
        <div class="card-item-value ml-2">
            <input type="number" class="form-control" placeholder="{{'sealingType.profile.to'|translate}}" min="0" formControlName="toValue">
        </div>
    </div>
    <div class="card-item-input col-md-3 col-lg-3">
        <div class="card-item-value">
            <input type="number" class="form-control" placeholder="{{'sealingType.profile.setupUnit'|translate}}" min="0" formControlName="setupUnit">
        </div>
    </div>
    <div class="card-item-input d-flex col-md-4 col-lg-4 align-items-center justify-content-between">
        <div class="card-item-value">
            <input type="number" class="form-control" placeholder="{{'sealingType.profile.processingTime'|translate}}" formControlName="processingTime" min="0">
        </div>
       
        <i class="bi bi-trash3 mt-1 ml-2" (click)="deleteProfileComponentMaterialLine()"></i>
    </div>
</div>