import { map } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from '../../../../shared/services/toast.service';
import { MachineProfile } from '../../../../shared/models/machine-profile.model';
import { MaterialProfile } from '../../../../shared/models/material-profile.model';
import { UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { Colors } from 'src/app/shared/models/colors.model';
import { Conformities } from 'src/app/shared/models/conformities';
import { MaterialAppService } from 'src/app/shared/services/material-app.service';


@Component({
  selector: 'app-profile-machine-group-item',
  templateUrl: './profile-machine-group-item.component.html',
  styleUrls: ['./profile-machine-group-item.component.scss']
})


export class ProfileMachineGroupItemComponent implements OnInit {

  private unsubscribe: Subject<void> = new Subject();
  @Output() submitMaterialChanges: EventEmitter<any> = new EventEmitter();
  private _materialProfile: MaterialProfile;
  private lastValidValue = {};
  @Input() defaultRangePositions: string;

  private timeout: any;
  private timeoutWarning: any;

  @Input() machineProfiles: MachineProfile[];
  @Input() set materialProfile(obj: MaterialProfile) {
    this._materialProfile = obj;
    if (this.materialProfileForm) { this.materialProfileForm.patchValue(obj); }
  }

  public get materialProfile(): MaterialProfile {
    return this._materialProfile;
  }

  @Input() activate: EventEmitter<boolean>;
  selectedMachineGroup: number[] = [].constructor(5);
  materialProfileForm: UntypedFormGroup;

  displayName: any;
  hardness: any;
  minTemp: any;
  maxTemp: any;
  colors: Colors[];
  conformities: Conformities[];
  description: string;

  ballColor: String;

  constructor(private fb: UntypedFormBuilder, private toastService: ToastService, private translationsService: TranslateService, private materialAppService: MaterialAppService) { }

  ngOnInit(): void {

    this.materialProfile?.materialProfileMeasure.sort((a, b) => a.position - b.position);

    this.materialProfileForm = this.fb.group({
      idMaterialProfile: [this.materialProfile?.idMaterialProfile, Validators.required],
      name: [this.materialProfile.name, Validators.required],
      isActive: [this.materialProfile.isActive, Validators.required],
      factor: [this.materialProfile.factor, Validators.required],
      search: [this.materialProfile.search, Validators.required],
      displayName: [this.materialProfile.displayName, Validators.required],
      hardness: [this.materialProfile.hardness],
      colorId: [this.materialProfile.colorId],
      conformitiesId: [this.materialProfile.conformitiesId],
      minTemp: [this.materialProfile.minTemp],
      maxTemp: [this.materialProfile.maxTemp],
      description: [this.materialProfile.description],
      materialProfileMeasure: this.fb.array(
        this.materialProfile?.materialProfileMeasure.map((m) => {
          return this.fb.group({
            idMaterialProfileMeasure: [m.idMaterialProfileMeasure, Validators.required],
            fromValue: [m.fromValue],
            toValue: [m.toValue],
            idMachineGroup: [m.idMachineGroup, Validators.required],
            position: [m.position, Validators.required],
          })
        })
      ),
    });

    const control = this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray;

    for (let i = 0; i < control.controls.length; i++) {
      control.at(i).get('toValue').addValidators(this.greaterThan('fromValue'));
      control.at(i).get('fromValue').setValidators([this.fromValueNoGapsValidation(), this.greaterThan('toValue', true)]);
    }

    this.lastValidValue = this.materialProfileForm.value;

    // materialProfileForm on change trigger validation of all fields in the form for materialProfileMeasure array
    control.valueChanges.subscribe((data) => {
      control.markAllAsTouched();
      control.controls.forEach((c, index) => {
        c.get('toValue').updateValueAndValidity({ emitEvent: false });
        c.get('fromValue').updateValueAndValidity({ emitEvent: false });
      });
      control.updateValueAndValidity({ emitEvent: false });
      this.materialProfileForm.updateValueAndValidity({ emitEvent: false });
    });

    this.materialProfileForm.valueChanges.subscribe((data) => { this.updateMaterial(); });
    this.onMaterialProfileMeasureEditCheckIfContainsLines();



    this.materialAppService.getToken().subscribe((data: any) => {
      let token = data.token;

      this.materialAppService.getConformities(token.toString()).subscribe(
        (conformities: Conformities[]) => {
          this.conformities = conformities;
        },
        (error) => {
          console.error('Error fetching conformities:', error);
        }
      );

      this.materialAppService.getColors(token.toString()).subscribe(
        (colors: Colors[]) => {
          this.colors = colors;
          this.updateBallColor();
          this.ballColor = this.colors.find((color) => color.idColor === this.materialProfile.colorId)?.hexColor || '';
        },
        (error) => {
          console.error('Error fetching colors:', error);
        }
      );
    });


  }

  // Updates the color of the ball dinamically when changing the value of the select
  updateBallColor() {
    this.materialProfileForm.get('colorId').valueChanges.subscribe(selectedColorId => {
      if (selectedColorId !== null) {
        const selectedColor = this.colors.find(color => color.idColor === selectedColorId);
  
        // Check if the selected color is found before assigning the hexColor
        this.ballColor = selectedColor ? selectedColor.hexColor : null;
      } else {
        this.ballColor = null; // Handle the case when colorId is null
      }
    });
  }
  

  /**
   * Updates material
   */
  updateMaterial() {
    // check if valid and update
    if (this.timeout) { clearTimeout(this.timeout); }
    if (this.materialProfileForm.valid && JSON.stringify(this.materialProfileForm.value) !== JSON.stringify(this.lastValidValue)) {
      // wait until update 1s to prevent multiple updates
      this.timeout = setTimeout(() => {
        this.lastValidValue = this.materialProfileForm.value;

        this.submitMaterialChanges.emit(this.materialProfileForm.value);
      }, 1000);
    }
    this.updateBallColor()
  }

  /**
   * Validates form
   * @returns  
   */
  validateForm() {
    return this.materialProfileForm.valid;
  }

  /**
   * Adds material measure profile
   */
  addMaterialProfileMeasure() {
    const control = this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray;
    const defaultRangePositions = this.defaultRangePositions.split(',');
    const rangePositionIndex = control.length;
    let fromValue = 0;
    let toValue = 0;
    const rangePosition = defaultRangePositions[rangePositionIndex]?.split('-') || null;
    // if previous toValue is not the same as the value in defaultRangePositions, then use the previous toValue as the fromValue plus 1
    if (rangePositionIndex > 0 && Number(control.at(rangePositionIndex - 1).get('toValue').value) != (Number(defaultRangePositions[rangePositionIndex - 1]?.split('-')[1]) || 0)) {
      fromValue = Number(control.at(rangePositionIndex - 1).get('toValue').value) + 1;
    } else {
      fromValue = rangePosition ? Number(rangePosition[0]) : this.getFromValueOrZero();
      toValue = rangePosition ? Number(rangePosition[1]) : 0;
    }
    const lastIndex = control.length;
    control.push(this.fb.group({
      idMaterialProfileMeasure: [null],
      fromValue: [fromValue, Validators.required],
      toValue: [toValue, Validators.required],
      idMachineGroup: [null, Validators.required],
      position: [lastIndex, Validators.required],
    }));
    // add validator is greater than previous value
    control.at(lastIndex).get('toValue').addValidators(this.greaterThan('fromValue'));
    control.at(lastIndex).get('fromValue').setValidators([this.fromValueNoGapsValidation(), this.greaterThan('toValue', true)]);

  }

  /**
   * Gets materialProfileMeasure value or zero
   * @returns  
   */
  getFromValueOrZero() {
    const measureValues = (this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray).value;
    return measureValues.length > 0 && Number(measureValues[measureValues.length - 1].toValue) != 0 ? Number(measureValues[measureValues.length - 1].toValue) + 1 : 0
  }

  /**
   * Removes material measure profile
   * @param index 
   */
  removeMaterialProfileMeasure(index: number) {
    const control = this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray;
    control.removeAt(index);
    // reorder all materialProfileMeasure
    const newMeasureValues = control.value;
    newMeasureValues.forEach((m, i) => {
      m.position = i;
    });
    control.patchValue(newMeasureValues);
  }

  /**
   * Gets material measure profiles
   */
  get materialProfileMeasures(): UntypedFormGroup[] {
    return (this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  /**
   * Greater than form control validator
   * @param field 
   * @param [prevArrayValue] 
   * @returns than 
   */
  greaterThan(field: string, prevArrayValue = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // get current index
      const index = control.parent.parent.value.findIndex(x => x.fromValue == control.parent.value.fromValue && x.toValue == control.parent.value.toValue);
      if (prevArrayValue && index < 1) {
        return null;
      }
      const group = (prevArrayValue == true) ? control.parent.parent.value[index - 1] : control.parent.value;
      const valueToCompare = group[field];
      const isLessThan = Number(valueToCompare) >= Number(control.value);
      return isLessThan ? { 'lessThan': { value: control.value } } : null;
    }
  }

  /**
   * Froms value no gaps validation
   * if fromValue is bigger than previous toValue + 1, fromValue is invalid
   * @returns value no gaps validation 
   */
  fromValueNoGapsValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // get current index
      const index = control.parent.parent.value.findIndex(x => x.fromValue == control.parent.value.fromValue && x.toValue == control.parent.value.toValue);
      if (index < 1) {
        return null;
      }
      const group = control.parent.parent.value[index - 1];
      const valueToCompare = group.toValue;
      const containsGap = Number(control.value) !== Number(valueToCompare) + 1;
      return containsGap ? { 'containsGap': { value: control.value } } : null;
    }
  }

  /**
   * Show warning if material profile measure contains lines
   */
  onMaterialProfileMeasureEditCheckIfContainsLines() {
    merge(...(this.materialProfileForm.get('materialProfileMeasure') as UntypedFormArray).controls.map((control: AbstractControl, index: number) =>
      control.valueChanges.pipe(map(value => ({ rowIndex: index, value }))))).subscribe(changes => {
        // find in array by id
        const materialProfileMeasure = this.materialProfile?.materialProfileMeasure.find(x => x.idMaterialProfileMeasure == changes.value.idMaterialProfileMeasure);
        // if contains lines, then show warning

        const materialProfileMeasureWithDependentConfigs = this.materialProfile?.materialProfileMeasure.find(x => x.profileComponentMaterialLines.length > 0)
        if (materialProfileMeasureWithDependentConfigs) {
          if (this.timeoutWarning) { clearTimeout(this.timeoutWarning); }
          this.timeoutWarning = setTimeout(() => {
            // check if valid
            if (this.materialProfileForm.valid) {
              this.toastService.show(this.translationsService.instant('codeTranslations.material-profile-line-dependent-config-warning'), { classname: 'bg-warning text-light', delay: 3000, autohide: false, avoidDuplicates: true });
            }
          }, 2000);
        }
      });
  }
}