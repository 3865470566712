import { trigger, transition, style, animate, keyframes } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrameworkContract } from './../../../shared/models/framework-contract.model';
import { PdfService } from './../../../shared/services/pdf.service';
import { DateUtils } from './../../../shared/utils/date.utils';

@Component({
  selector: 'app-framework-contract-row',
  templateUrl: './framework-contract-row.component.html',
  styleUrls: ['./framework-contract-row.component.scss'],
  animations: [
    trigger('row', [
      transition(':leave', [
        animate('400ms ease-out',
          style({ opacity: 0, transform: 'translateX(35px)' })
        )])
    ])
  ]
})

export class FrameworkContractRowComponent implements OnInit {
  @Input() contract: FrameworkContract;
  @Output() contractOpened = new EventEmitter();

  DateUtils = DateUtils;
  contractCollapse = true;
  overflow = false;
  selectedFile: string;
  fileToDownload = null;
  constructor(private pdfService: PdfService) { }

  ngOnInit(): void {
  }

  /**
   * Downloads pdf
   */
  downloadPdf(idContractERP: string) {
    // this.pdfService.getContractPdf(idContractERP).subscribe((data: any) => {
    //   if (data.size && data.size > 0) {
    //     const blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
    //     FileUtils.downloadFile(blob, this.contract.idFrameworkContractERP);
    //   }
    // });
  }
  /**
   * Collapses contract
   * @param event event
   */
  collapseContract(event) {
    const className = event.target.className;
    // avoid opening or closing on clicking pdf download button
    if (className !== 'download-pdf' && className !== 'download-pdf-img') {
      this.contractCollapse = !this.contractCollapse;
      this.contractOpened.emit(!this.contractCollapse);
      // make overflow visible when the contract is opened
      if (!this.contractCollapse) {
        /* wait until animation is finished */
        setTimeout(() => {
          this.overflow = true;
        }, 500);
      } else {
        this.overflow = false;
      }
    }
  }

}
