import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  private apiUrl = environment.apiUrl;
  private userId: string;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Gets personal contact image url
   * @param idUser users id
   * @returns image url
   */
  getPersonalContactImageUrl(idUser: string) {
    return `${environment.apiUrl}/images/personal-contact/${idUser}`;
  }

  /**
   * upload file to api
   */
  public uploadPersonalContactImage(formData): any {
    return this.http.post<any>(`${this.apiUrl}/images/personal-contact/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  /**
   * Gets sealing type image url for select sealing type page
   * @param idSealingType 
   * @returns  
   */
   public getSealingTypeImageUrl(idSealingType) {
    return `${environment.apiUrl}/images/sealing-type/${idSealingType}`;
  }

  /**
   * Gets profile profile image url for profile configurator page
   * @param idCategoryItem 
   * @returns  
   */
  public getProfileImageUrl(idCategoryItem: number, imageType: string) {
    return `${environment.apiUrl}/images/profile/${imageType}/${idCategoryItem}`;
  }

  /**
   * Uploads profile profile image
   * @param formData 
   * @returns profile profile image 
   */
  public uploadProfileImage(formData, idProfile, imageType: string): any {
    return this.http.post<any>(`${this.apiUrl}/images/profile/upload/${imageType}/${idProfile}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
