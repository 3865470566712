import { SealingType } from './../../../../shared/models/sealing-type.model';
import { OrderSealService } from '../../../../shared/services/order-seal.service';
import { SealingTypesService } from '../../../../shared/services/sealing-types.service';
import { ImagesService } from '../../../../shared/services/images.service';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-order-seal-select-sealing-type',
  templateUrl: './order-seal-select-sealing-type.component.html',
  styleUrls: ['./order-seal-select-sealing-type.component.scss']
})
export class OrderSealSelectSealingTypeComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  public sealingTypes: SealingType[] = null;
  timer: any;
  constructor(private orderSealService: OrderSealService, private sealingTypesService: SealingTypesService, private imagesService: ImagesService) { }

  ngOnInit(): void {
    // get sealing types
    this.sealingTypesService.getSealingTypes().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data.length === 0) { this.sealingTypes = [] }

      // Filter me the sealingtypes which have a valid profiles.
      this.sealingTypes = data.filter(sealingType => sealingType.profiles.filter(profile => profile.valid).length > 0);

      // check if sealing type is already selected on page load
      this.sealingTypes.map(sealingType => {
        //also add image 
        sealingType.image = this.imagesService.getSealingTypeImageUrl(sealingType.idSealingType);
        sealingType.checked = this.orderSealService.selectedSealingTypeValue?.idSealingType === sealingType?.idSealingType || false;
        return sealingType;
      });
    }, error => { this.sealingTypes = [] });
  }

  /**
   * Checks sealing type item on select
   * @param item 
   */
  checkItem(item) {
    // let goToNextPage = item.checked ? false : true;
    this.sealingTypes.map(sealingType => { if (sealingType.idSealingType !== item.idSealingType) { sealingType.checked = false; } return sealingType; });
    item.checked = true;
    this.orderSealService.selectSealingType(item);
    // wait time
    if (item.checked) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.orderSealService.goToNextPage();
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
