import { CreateOrderResponse } from './../../../shared/models/create-order-response.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  createdOrder: CreateOrderResponse;
  constructor() { }

  ngOnInit(): void {
  }

}
