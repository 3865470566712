<div class="offer-toggle__header d-flex justify-content-between" (click)="collapseOffer($event)">
    <div class="offer-toggle__name"><img src="/assets/img/icons/chevron-{{offerCollapse?'right':'down'}}.svg"
            alt="toggle"> ANGEBOT NR {{offer.idOfferERP}} <button class="download-pdf" (click)="downloadPdf(offer.idOfferERP)"> <img
                src="/assets/img/icons/file.svg" alt="pdf-download-icon" class="download-pdf-img"></button>
    </div>
    <div class="offer-toggle__date">{{DateUtils.getFormattedDate(offer.offerDate, true)}}</div>
</div>
<div class="offer-toggle__content" [class.opened]="!offerCollapse"
    [style.overflow]="overflow == true && offerCollapse == false ? 'visible' : 'hidden'">
    <section class="se-table-flex">
        <div class="se-table-header se-table-row">
            <div class="se-table-col sm position-col d-none d-md-block" i18n>Pos</div>
            <div class="se-table-col lg" i18n>{{'frameworkContractsList.article' | translate}} </div>
            <div class="se-table-col d-none d-sm-flex md" i18n>{{'frameworkContractsList.amount' | translate}}</div>
            <div class="se-table-col d-none d-sm-flex md" i18n>{{'frameworkContractsList.shippingDate' | translate}}</div>
            <div class="se-table-col d-none d-sm-flex md" i18n>{{'frameworkContractsList.scaledPrices' | translate}}</div>
            <div class="se-table-col d-none d-sm-flex md" i18n>{{'frameworkContractsList.totalPrice' | translate}}</div>
            <div class="se-table-col md">
            </div>
        </div>
        <div *ngFor="let article of offer.offerArticles; index as i" [article]="article" [index]="i"
            app-articles-list-row class="offer-row">
        </div>
        <div *ngIf="offer.offerArticles != null && offer.offerArticles.length == 0" class="se-table-row">
            <div class="se-table-col lg text-center" i18n>Keine Artikel zu zeigen </div>
        </div>
    </section>
</div>
