<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'confirmationModal.title' | translate}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss({remove: false})">
        <i class="bi bi-x"></i>
    </button>
</div>
<div class="modal-body">
    <div>
        {{'confirmationModal.sure' | translate}}<br><span class='text-danger'>{{'confirmationModal.warning' | translate}}</span>
    </div>
    <div class="mt-3" *ngIf="showQuestion">{{'confirmationModal.continue' | translate}}
        <div class="d-flex justify-content-between align-items-center mt-2">
            <b>{{x}}</b>
            <b>+</b>
            <b>{{y}}</b>
            <b>=</b>
            <input class="form-control wsa-input w-75" type="number" min="0" [(ngModel)]="userResult">
        </div>
    </div>
</div>
<div class="modal-footer">
    <app-button (click)="modal.dismiss()"> {{'confirmationModal.cancel' | translate}} </app-button>
    <app-button [disabled]="showQuestion && userResult != result"
        (click)="(!showQuestion || userResult == result) ? modal.close({remove: true}) : null"> {{'confirmationModal.confirm' | translate}}</app-button>
</div>