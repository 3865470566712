<div class="d-flex contract-info-container">
    <div class="d-block w-100" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle">
        <app-progress-bar [progress]="amountProgress" [minOrange]="minOrangeProgress" i18n>{{amountProgress}}% <span>Artikel</span></app-progress-bar>
        <app-progress-bar [progress]="timeProgress" [minOrange]="minOrangeProgress" i18n>{{timeProgress}}% <span>Laufzeit</span></app-progress-bar>
    </div>
    <button class="se-button help-button d-none d-lg-block" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle"> ?
    </button>
</div>
<ng-template #popTitle i18n> Vertragsinformationen </ng-template>
<ng-template #popContent i18n>
    <div> {{article.orderedQuantity}} von {{article.totalQuantity}} Artikeln bestellt.</div>
    <div>Laufzeit von {{startDate}} bis {{validUntilDate}}.</div>
</ng-template>
