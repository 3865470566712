<button type="button" [disabled]="disabled || false" class="se-button d-block" (click)="onClickButton($event)"
    [style.width]="width || '100%'" [style.minHeight]="height ||'auto'" [style.padding]="padding || '5px 10px'"
    [ngClass]="{'hover-grey':backgroundImage, 'active':active }">
    <div class="content" *ngIf="!loading">
        <span *ngIf="!backgroundImage">{{label}}
            <ng-content></ng-content>
        </span>
        <div class="d-block background" *ngIf="backgroundImage" [style.background]="'url(' + backgroundImage + ')'">
        </div>
    </div>
    <div class="content d-flex align-items-center" *ngIf="loading"><span class="loading-text">{{loadingText}} </span>
        <app-loading-spinner size=20></app-loading-spinner>
    </div>
</button>
