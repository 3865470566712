import { ProfileComponentMaterial } from 'src/app/shared/models/profile-component-material.model';
import { ProfileComponentMaterialLine } from './../models/profile-component-material-line.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ProfileComponent } from '../models/profile-component.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileComponentService {


  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(private http: HttpClient) { }


  /**
   * Creates profile component
   * @param profileComponent
   * @returns profile component
   */
  public createProfileComponent(profileComponent: ProfileComponent, idProfile: number): Observable<ProfileComponent> {
    return this.http.post(this.apiUrl + '/profile-component/' + idProfile, profileComponent) as Observable<ProfileComponent>;
  }

  /**
   * Updates profile component
   * @param profileComponent 
   * @returns 
   */
  public updateProfileComponent(profileComponent: ProfileComponent): Observable<ProfileComponent> {
    return this.http.put(this.apiUrl + '/profile-component', profileComponent) as Observable<ProfileComponent>;
  }

  /**
   * Deletes profile component
   * @param idProfileComponent 
   * @returns profile component 
   */
  public deleteProfileComponent(idProfileComponent: number): Observable<ProfileComponent> {
    return this.http.delete(this.apiUrl + '/profile-component/' + idProfileComponent) as Observable<ProfileComponent>;
  }

  /**
   * Creates profile component material
   * @param idMaterialProfile 
   * @param selectedArticleGroup 
   * @param idProfileComponent 
   * @returns  
   */
  public createProfileComponentMaterial(idMaterialProfile: number, idProfileComponent: number) {
    return this.http.post(this.apiUrl + '/profile-component/' + idProfileComponent + '/material/' + idMaterialProfile, null) as Observable<ProfileComponent>;
  }

  /**
   * Deletes profile component material
   * @param idProfileComponentMaterial 
   * @returns  
   */
  public deleteProfileComponentMaterial(idProfileComponentMaterial: number) {
    return this.http.delete(this.apiUrl + '/profile-component/material/' + idProfileComponentMaterial) as Observable<ProfileComponent>;
  }

  /**
   * Gets profile components by idProfile
   * @param idProfile
   * @returns profile components
   */
  public getProfileComponents(idProfile: number): Observable<ProfileComponent[]> {
    return this.http.get(this.apiUrl + '/profile-component/' + idProfile) as Observable<ProfileComponent[]>;
  }

  /**
   * Gets profile component material lines
   * @param idProfileComponentMaterial 
   * @param idMaterialProfileMeasure 
   * @returns profile component material lines 
   */
  public getProfileComponentMaterialLines(idProfileComponentMaterial: number, idMaterialProfileMeasure: number): Observable<ProfileComponentMaterialLine[]> {
    return this.http.get(this.apiUrl + '/profile-component/material-lines/' + idProfileComponentMaterial + '/' + idMaterialProfileMeasure) as Observable<ProfileComponentMaterialLine[]>;
  }

  /**
   * Gets article groups
   * @returns  
   */
  public getArticleGroups() {
    return this.http.get(this.apiUrl + '/article-group') as Observable<any[]>;
  }

  /**
   * Creates profile component material line
   * @param materialProfile
   * @returns material profile
   */
  public createProfileComponentMaterialLine(profileComponentMaterialLine: ProfileComponentMaterialLine, idMaterialProfileMeasure: number, idProfileComponentMaterial: number): Observable<ProfileComponentMaterialLine> {
    return this.http.post(this.apiUrl + '/profile-component/material-line/' + idMaterialProfileMeasure + '/' + idProfileComponentMaterial, profileComponentMaterialLine) as Observable<ProfileComponentMaterialLine>;
  }

  /**
  * Updates profile component material line
  * @param materialProfile
  * @returns material profile
  */
  public updateProfileComponentMaterialLine(profileComponentMaterialLine: ProfileComponentMaterialLine): Observable<ProfileComponentMaterialLine> {
    return this.http.put(this.apiUrl + '/profile-component/material-line', profileComponentMaterialLine) as Observable<ProfileComponentMaterialLine>;
  }

  /**
   * Deletes profile component material line
   * @param idProfileComponentMaterialLine
   */
  public deleteProfileComponentMaterialLine(idProfileComponentMaterialLine: number): Observable<ProfileComponentMaterialLine> {
    return this.http.delete(this.apiUrl + '/profile-component/material-line/' + idProfileComponentMaterialLine) as Observable<ProfileComponentMaterialLine>;
  }

}
