<div class="container-fluid">
    <div class="cart-progress">
        <app-breadcrumb></app-breadcrumb>
        <div class="cart-progress__container" fxLayout="row" fxLayoutAlign="center center">
            <div class="cart-progress__item" fxLayout="column" fxLayoutAlign="center center">
                <div class="cart-progress__circle" [ngClass]="{'active': step == 0}">
                    <img class="cart-progress__icon" src="/assets/img/icons/offers{{step != 0 ? '-black' : ''}}.svg">
                </div>
                <h3 class="cart-progress__title" i18n>{{'cart.checkShoppingCart'|translate}}</h3>
                <!-- <p class="cart-progress__description"> Lorem ipsum dolor sit amet consectetur. </p>-->
            </div>
            <div class="cart-progress__item" fxLayout="column" fxLayoutAlign="center center">
                <div class="cart-progress__circle" [ngClass]="{'active': step == 1}">
                    <img class="cart-progress__icon" src="/assets/img/icons/geo{{step == 1 ? '-white' : ''}}.svg">
                </div>
                <h3 class="cart-progress__title" i18n>{{'cart.selectAddress'|translate}}</h3>
                <!-- <p class="cart-progress__description"> Lorem ipsum dolor sit amet consectetur. </p>-->
            </div>
        </div>
    </div>
</div>
