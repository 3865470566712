<ng-template #processingBlock>
    <span class="d-block alert ng-star-inserted text-center">
        <app-loading-spinner *ngIf="processing" [size]="15" class="d-block mt-1" @fade>
        </app-loading-spinner>
    </span>
</ng-template>
<div *ngIf="!processing; else processingBlock" class="d-flex align-items-center justify-content-around">
    <div @fade>
        <div *ngIf="articleDatePrice; else emptyPriceBlock">
            <span *ngIf="articleDatePrice.priceStatus !== CapacityPriceStatus.NA; else notAvailableBlock"
                class="d-block alert alert-{{articleDatePrice.priceStatus == CapacityPriceStatus.LOW ? 'success' : 'warning' }} ng-star-inserted text-center"
                style.background="{{ArticlesListUtils.getStatusColor(articleDatePrice.priceStatus)+'30'}}"
                style.borderColor="{{ArticlesListUtils.getStatusColor(articleDatePrice.priceStatus)}}"
                style.color="{{ArticlesListUtils.getStatusColor(articleDatePrice.priceStatus)}}">
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.UNVALIDATED">
                    {{translationsService.instant('codeTranslations.price-unvalidated')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.HOLIDAY">
                    {{ translationsService.instant('codeTranslations.price-holiday')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.PAST">
                    {{ translationsService.instant('codeTranslations.price-past')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.NO_MATERIAL">
                    {{ translationsService.instant('codeTranslations.price-no-material')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.BELOW_MIN_AMOUNT_SCALED_PRICE">
                    {{ translationsService.instant('codeTranslations.scaled-price-below-min-amount')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.ABOVE_MAX_AMOUNT">
                    {{ translationsService.instant('codeTranslations.scaled-price-above-max-amount')|translate}}</p>
                <p *ngIf="articleDatePrice.priceStatus == CapacityPriceStatus.MIN_POSITION">
                    {{ translationsService.instant('codeTranslations.price-min-position')|translate}}</p>
                <p *ngIf="[CapacityPriceStatus.HOLIDAY.toString(),
                    CapacityPriceStatus.UNVALIDATED.toString(),
                    CapacityPriceStatus.PAST.toString(),
                    CapacityPriceStatus.NO_MATERIAL.toString(),
                    CapacityPriceStatus.BELOW_MIN_AMOUNT_SCALED_PRICE.toString(),
                    CapacityPriceStatus.ABOVE_MAX_AMOUNT.toString(),
                    CapacityPriceStatus.MIN_POSITION.toString()]
                    .indexOf(articleDatePrice.priceStatus) === -1">
                    {{ articleDatePrice.price | currency: articleDatePrice.currency}}</p>
            </span>
            <ng-template #notAvailableBlock><span class="d-block alert alert-danger ng-star-inserted text-center" i18n
                    style.background="{{ArticlesListUtils.getStatusColor(CapacityPriceStatus.NA)+'30'}}"
                    style.borderColor="{{ArticlesListUtils.getStatusColor(CapacityPriceStatus.NA)}}"
                    style.color="{{ArticlesListUtils.getStatusColor(CapacityPriceStatus.NA)}}">
                    {{ translationsService.instant('codeTranslations.price-not-available')|translate}} </span>
            </ng-template>
        </div>
        <ng-template #emptyPriceBlock><span class="d-block alert ng-star-inserted text-center">
                <span *ngIf="articlesListMode == ArticlesListMode.ARTICLES" @fade> {{ '0' | currency: CurrencyType.EUR}}
                </span>
            </span>
        </ng-template>
    </div>
    <div class="price-changed d-flex" *ngIf="showPriceChange==true" @fade>
        <div class="checkbox" (click)="acceptPrice()">
            <svg-icon src="/assets/img/icons/check.svg" [svgStyle]="{ 'fill':'green', 'width.px':30, 'height.px':30 }"
                *ngIf="priceAccepted">
            </svg-icon>
        </div>
        <span i18n class="d-none d-md-block">Preisänderung bestätigen</span>
    </div>
</div>
