import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { Component, Inject, LOCALE_ID, isDevMode, ViewChild, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserRoleType } from 'src/app/shared/models/user-role-type.enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)',
        boxShadow: '-12px 0px 33px 0px rgba(0, 0, 0, 0.2)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)',
        boxShadow: 'none'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SidebarComponent implements OnInit {
  @ViewChild('insideElement', { static: false }) insideElement;
  //curentLanguage = localStorage.getItem('Language');
  languageList = [{ code: 'en', label: 'English' }, { code: 'de', label: 'Deutsch' }];
  sections = [true, true, true];
  menuState = 'out';
  loaded = false;
  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private router: Router,
    private authenticationService: AuthenticationService,
    protected translateService: TranslateService) { }
  toggle() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  ngOnInit() {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('mouseout', this.onDocumentClick);
    document.addEventListener('click', this.onDocumentClick);
    this.loaded = true;
    const browserlang = this.translateService.getBrowserLang();

    // If I find the localStorage language on our languageList.
    if (localStorage.getItem('language') && this.languageList.filter((obj: any) => localStorage.getItem('language') === obj.code).length > 0) {
      this.translateService.setDefaultLang(localStorage.getItem('language'));
      // Otherwise we check if we find the Browser Language on our languageList.
    } else if (this.languageList.filter((obj: any) => browserlang === obj.code).length > 0) {
      this.translateService.setDefaultLang(browserlang);
      // Otherwise page default language will be English.
    } else {
      this.translateService.setDefaultLang('en');
    }
  }


  get isUserAdmin() {
    return this.authenticationService.isAuthorized(UserRoleType.ADMIN);
  }

  get isUserAccountManager() {
    return this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER);
  }
  /**
   * Changes app language
   * @param lang lang code
   */
  changeLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
    localStorage.setItem('language', lang);

    const prueba = this.translateService.instant("sidebar.home");
  }
  /**
   * Close sidebar on click outside of it
   * @param event mouseEvent
   * @returns void
   */
  protected onDocumentClick(event: MouseEvent) {
    // close sidebar on outside click
    if (this.insideElement.nativeElement.contains(event.target)) {
      return;
    }
    this.menuState = 'out';
  }
}
