import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemoveConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation/remove-confirmation-modal.component';
import { ProfileMeasureOutputLine } from '../../../../../../shared/models/profile-measure-output-line.model';
import { Profile } from '../../../../../../shared/models/profile.model';
import { ProfileValidationService } from '../../../../../../shared/services/profile-validation.service';
import { SealingTypesService } from '../../../../../../shared/services/sealing-types.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ProfileLinkImageOutputsDialogComponent } from '../profile-link-image-outputs-dialog/profile-link-image-outputs-dialog.component';
import { CloseConfirmationDialogComponent } from './../../../../../../shared/components/close-confirmation-dialog/close-confirmation-dialog.component';
import { ProfileOutputsFormulaDialogComponent } from './../profile-outputs-formula-dialog/profile-outputs-formula-dialog.component';

@Component({
	selector: 'app-profile-output-values',
	templateUrl: './profile-output-values.component.html',
	styleUrls: ['./profile-output-values.component.scss']
})
export class ProfileOutputValuesComponent implements OnInit {
	private unsubscribe: Subject<void> = new Subject();
	@Input() profile: Profile;
	@Input() imageProfileLeft;
	@Input() openLinkImageDisabled = true; // pass this.showUploadForm[0] as parameter to show/hide upload form
	@Input() profileForm: UntypedFormGroup;
	@Output() recreateForm = new EventEmitter();
	@Input() profileOutputOther = false;
	@Input() componentIndex;
	@Input() showInputsOrder = new EventEmitter();

	timer: any;
	editNameMode = false;
	lineIndex = false;
	@Input() profileChange = new EventEmitter<Profile>();

	constructor(private toastService: ToastService, private fb: UntypedFormBuilder, private modalService: NgbModal, private sealingTypesService: SealingTypesService, private translationsService: TranslateService, private profileValidationService: ProfileValidationService) { }

	ngOnInit(): void {
		// order profileMeasureOutputLines
		this.profileMeasureOutputLines?.sort((a, b) => a.showOrder - b.showOrder);
	}
	/**
	 * On drag and drop category profile item update the values
	 * @param event 
	 */
	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.profileOutputLinesFormGroup.value, event.previousIndex, event.currentIndex);
		moveItemInArray(this.getProfileMeasureOutputLines(), event.previousIndex, event.currentIndex);
		// iterate over profileMeasureOutputLines inside form and set a new order
		this.getProfileMeasureOutputLines().forEach((m, index) => {
			m.showOrder = index;
			this.profileOutputLinesControl[index].patchValue(m);
		});
		//this.profile.profileMeasureOutputLines = this.profileForm.value.profileMeasureOutputLines;
		clearTimeout(this.timer); this.timer = setTimeout(() => { this.updateProfileValues(); }, 1000);
	}

	/**
	 * Updates item values
	 * @param [updateOriginal] 
	 */
	updateProfileValues(formulaUpdated = false) {
		if (this.profileForm.valid) {
			let profile = { ...this.profile };
			profile.profileMeasureOutputOtherLines = this.profileForm.get('profileMeasureOutputOtherLines').value;
			profile.profileComponents = this.profileForm.get('profileComponents').value;
			this.sealingTypesService.saveProfile(profile, formulaUpdated).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
				data.profileComponents.forEach(p => {
					p.profileMeasureOutputLines = p.profileMeasureOutputLines.sort((a, b) => a.showOrder - b.showOrder);
				});
				data.profileMeasureOutputOtherLines = data.profileMeasureOutputOtherLines.sort((a, b) => a.showOrder - b.showOrder);
				this.profile = data;
				this.profileChange.emit(data);
				this.profileValidationService.updateValidations();
				// toast success
				// show toast message success
				this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

			}, error => {
				// show toast message error
				this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
			}
			);
		}
	}

	/**
	 * Updates item values with delay
	 * @param [updateOriginal] 
	 */
	updateProfileValuesWithDelay() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.updateProfileValues();
		}, 3000);
	}


	/**
	 * Opens link image modal
	 * @param itemValueIndex 
	 * @returns  
	 */
	openLinkImageModal(itemValueIndex: number) {
		if (this.openLinkImageDisabled) {
			return;
		}
		const modal = this.modalService.open(ProfileLinkImageOutputsDialogComponent, {
			size: 'lg', backdropClass: '.image-link-modal-backdrop',
			windowClass: '.image-link-modal-window'
		});
		modal.componentInstance.image = this.imageProfileLeft;
		modal.componentInstance.profile = this.profile;
		modal.componentInstance.profileMeasureOutputLines = this.profileMeasureOutputLines;
		modal.componentInstance.itemValueIndex = itemValueIndex;
		modal.componentInstance.otherItem = this.profileOutputOther;
		modal.componentInstance.itemUpdated.subscribe(item => {
			this.profileMeasureOutputLines[itemValueIndex] = item;
			this.profileOutputLinesControl[itemValueIndex].patchValue(item);
			this.updateProfileValues();
		});
	}


	/**
	 * Opens formula modal 
	 * @param itemValueIndex index of output
	 * @param displayType display or intern type
	 * @returns 
	 */
	openFormulaModal(itemValueIndex: number, displayType = true) {
		if (this.openLinkImageDisabled) {
			return;
		}
		const modal = this.modalService.open(ProfileOutputsFormulaDialogComponent, {
			size: 'lg', backdropClass: 'image-link-modal-backdrop',
			windowClass: 'image-link-modal-window',
			beforeDismiss: () => {
				if (!modal.componentInstance.formulaUnsaved) {
					return true;
				}
				this.confirmClose(modal);
				return false;
			}
		});
		this.showInputsOrder.emit(true);
		modal.componentInstance.profileMeasureOutputLine = JSON.parse(JSON.stringify(this.profileMeasureOutputLines[itemValueIndex]));
		modal.componentInstance.idProfile = this.profile.idProfile;
		modal.componentInstance.displayType = displayType;
		modal.componentInstance.materials = this.profileComponentMaterials;
		modal.componentInstance.profileOutputOther = this.profileOutputOther;
		modal.componentInstance.profileMeasureInputLines = this.profileMeasureInputLines;
		modal.componentInstance.profileMeasureOutputLineChange.pipe(takeUntil(this.unsubscribe)).subscribe(item => {
			this.profileMeasureOutputLines[itemValueIndex] = item;
			this.profileOutputLinesControl[itemValueIndex].patchValue(item);
			this.updateProfileValues();
			//modal.dismiss();
		});
		modal.dismissed.pipe(takeUntil(this.unsubscribe)).subscribe(data => { this.showInputsOrder.emit(false); })
	}
	/**
	 * Confirms close with an additional modal
	 * @param modal 
	 */
	confirmClose(modal: NgbModalRef) {
		// get element by class
		const modalElement = document.getElementsByClassName('image-link-modal-window')[0];
		const prevStyle = modalElement.getAttribute('style');
		modalElement.setAttribute('style', 'z-index: 999 !important;');
		const confModal = this.modalService.open(CloseConfirmationDialogComponent, {
			size: 'md', backdrop: 'static', keyboard: false,
			backdropClass: 'close-confirm-modal-backdrop',
			windowClass: 'close-confirm-modal-window',
		});
		confModal.componentInstance.title = this.translationsService.instant('codeTranslations.confirmation-title');
		confModal.componentInstance.message = this.translationsService.instant('codeTranslations.confirmation-message');
		confModal.componentInstance.btnCancelText = this.translationsService.instant('codeTranslations.confirmation-cancel');
		confModal.componentInstance.btnOkText = this.translationsService.instant('codeTranslations.confirmation-ok');
		confModal.closed.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
			if (result) {
				modal.close();
			} else {
				// restore previous style
				modalElement.setAttribute('style', prevStyle);
			}
		});
	}
	/**
	 * Deletes item value
	 */
	deleteProfileMeasureOutputLine(index) {
		const id = this.profileMeasureOutputLines[index].idProfileMeasureOutputLine;
		if (id) {
			this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
				if (result.remove === true) {
					this.sealingTypesService.deleteProfileMeasureOutputLine(id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
						// delete this.profileForm.get('profileMeasureOutputOtherLines')[index];
						this.profileOutputLinesFormGroup.removeAt(index);
						this.profileForm.updateValueAndValidity();
						this.getProfileMeasureOutputLines().splice(index, 1);
						this.profileChange.emit(this.profile);
						// show toast message success
						this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
					}, error => {
						// show toast message error
						this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
					});
				}
				return result;
			}).catch((res) => { });
		} else {
			this.profileOutputLinesFormGroup.removeAt(index);
			this.profileForm.updateValueAndValidity();
			this.profileMeasureOutputLines.splice(index, 1);
			this.profileChange.emit(this.profile);
		}
	}
	/**
	* Modifies the object
	* @param obj 
	* @param newObj 
	*/
	modify(obj, newObj) {
		Object.keys(obj).forEach(function (key) { delete obj[key]; });
		Object.keys(newObj).forEach(function (key) { obj[key] = newObj[key]; });
	}

	/**
	 * Adds output line
	 */
	addItem() {
		const control = this.profileOutputLinesControl;
		const item: ProfileMeasureOutputLine = {
			idProfileMeasureOutputLine: null,
			name: 'Neues Maß',
			idSvgOutput: '',
			displayTabs: [],
			internTabs: [],
			showOrder: 0,
			valid: false
		};
		this.getProfileMeasureOutputLines().push(item);

		this.profileOutputLinesFormGroup.push(this.fb.group(item));


		this.profileForm.updateValueAndValidity();
	}

	/**
	 * Gets profile measure output lines from profile
	 * @returns profile measure output lines 
	 */
	getProfileMeasureOutputLines(): ProfileMeasureOutputLine[] {
		return this.profileOutputOther ? this.profile.profileMeasureOutputOtherLines : this.profile.profileComponents.filter(component => component)[this.componentIndex].profileMeasureOutputLines;
	}

	/**
	 * Gets profile measure output lines (getter)
	 */
	get profileMeasureOutputLines() {
		return this.profileOutputOther ? this.profile.profileMeasureOutputOtherLines : this.profile.profileComponents.filter(component => component)[this.componentIndex]?.profileMeasureOutputLines || [];
	}

	/**
	 * Gets profile measure output lines (getter)
	 */
	get profileComponentMaterials() {
		return this.profile.profileComponents.filter(component => component)[this.componentIndex]?.profileComponentMaterials.map(material => material.materialProfile) || [];
	}

	/**
	 * Gets profile output lines controls (returns the correct one for component outputs and other outputs)
	 */
	get profileOutputLinesControl(): UntypedFormControl {
		return (this.profileOutputOther ? (this.profileForm.get('profileMeasureOutputOtherLines') as any)?.controls : ((this.profileForm.get('profileComponents') as any).controls as UntypedFormArray)[this.componentIndex]?.controls.profileMeasureOutputLines.controls) as UntypedFormControl;
	}

	/**
	 * Gets profile output lines form group (returns the correct one for component outputs and other outputs)
	 */
	get profileOutputLinesFormGroup(): UntypedFormArray {
		return (this.profileOutputOther ? this.profileForm.get('profileMeasureOutputOtherLines') : ((this.profileForm.get('profileComponents') as any)?.controls as UntypedFormArray)[this.componentIndex]?.controls.profileMeasureOutputLines) as UntypedFormArray;
	}

	/**
	 * Gets profile measure input lines
	 */
	get profileMeasureInputLines() {
		return this.profile.profileMeasureInputLines;
	}

	/**
	 * Gets formula formatted string for preview
	 * @param formula 
	 * @param elseFormula 
	 * @returns  
	 */
	getFormulaFormatted(formula: any[], elseFormula: string) {
		let formulaArray = [];
		formulaArray = formula?.map(item => {
			return 'if ' + item.condition + ' then ' + item.then;
		}) || []
		return formulaArray?.join('; ') + (formulaArray?.length > 0 ? ';' : '') + (elseFormula ? ' else ' + elseFormula : '');
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
