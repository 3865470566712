import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { PersonalContact } from './../../shared/models/personal-contact.model';
import { AuthenticationService } from './authentication.service';
import { TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PersonalContactService {
  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private translationsService: TranslateService,
    private http: HttpClient) {
      this.authenticationService.currentUserId.subscribe(userId => {
        this.userId = userId;
      });
  }

  /**
   * Gets personal contact
   * @returns get
   */
  getPersonalContact() {
    return this.http.get<PersonalContact>(`${this.apiUrl}/contact-person/` + this.userId).pipe(
      catchError(error => {
        console.error('There was an error in the receiving the personal contact, please contact Haenssler.');
        const personalContact = new PersonalContact();
        personalContact.errorMessage = 'codeTranslations.personal-contact-error';
        return of(personalContact);
      }));
  }

}
