import { RemoveConfirmationModalComponent } from '../../../../../../shared/components/delete-confirmation/remove-confirmation-modal.component';
import { ProfileLinkImageDialogComponent } from '../profile-link-image-dialog/profile-link-image-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { ProfileValidationService } from '../../../../../../shared/services/profile-validation.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { takeUntil } from 'rxjs/operators';
import { SealingTypesService } from '../../../../../../shared/services/sealing-types.service';
import { UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Profile } from 'src/app/shared/models/profile.model';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ProfileLinkValuesDialogComponent } from '../profile-link-values-dialog/profile-link-values-dialog.component';

@Component({
  selector: 'app-profile-input-values',
  templateUrl: './profile-input-values.component.html',
  styleUrls: ['./profile-input-values.component.scss']
})
export class ProfileInputValuesComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() profile: Profile;
  @Input() imageProfileLeft;
  @Input() openLinkImageDisabled = true; // pass this.showUploadForm[0] as parameter to show/hide upload form
  @Input() profileForm: UntypedFormGroup;
  @Output() recreateForm = new EventEmitter();
  @Input() showInputsOrder = new EventEmitter();


  timer: any;
  editNameMode = false;
  lineIndex = false;
  @Input() profileChange = new EventEmitter<Profile>();

  constructor(private toastService: ToastService, private fb: UntypedFormBuilder, private modalService: NgbModal, private sealingTypesService: SealingTypesService, private translationsService: TranslateService, private profileValidationService: ProfileValidationService) { }

  ngOnInit(): void {
    this.showInputsOrder.pipe(takeUntil(this.unsubscribe)).subscribe(show => {
      if (show) {
        this.lineIndex = true;
      } else {
        this.lineIndex = false;
      }
    });

    // order profileMeasureInputLines
    this.profile.profileMeasureInputLines?.sort((a, b) => a.showOrder - b.showOrder);
    // on change update value and validity
    const control = this.profileForm.get('profileMeasureInputLines') as UntypedFormArray;
    control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      control.markAllAsTouched();
      control.controls.forEach((c, index) => {
        c.get('toValue').updateValueAndValidity({ emitEvent: false });
        c.get('fromValue').updateValueAndValidity({ emitEvent: false });
      });
      control.updateValueAndValidity({ emitEvent: false });
      this.profileForm.updateValueAndValidity({ emitEvent: false });
    });
  }

  
  /**
   * On drag and drop category profile item update the values
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.profileForm.value.profileMeasureInputLines, event.previousIndex, event.currentIndex);
    moveItemInArray(this.profile.profileMeasureInputLines, event.previousIndex, event.currentIndex);
    // iterate over profileMeasureInputLines inside form and set a new order
    this.profileForm.value.profileMeasureInputLines.forEach((m, index) => {
      m.showOrder = index;
      (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).controls[index].patchValue(m);
    });
    this.profile.profileMeasureInputLines = this.profileForm.value.profileMeasureInputLines;
    clearTimeout(this.timer); this.timer = setTimeout(() => { this.updateProfileValues(true, false, true); }, 1000);
  }


  /**
   * Updates item values
   * @param [updateOriginal] 
   */
  updateProfileValues(updateOriginal = true, formulaUpdated = false, orderUpdated = false) {
    if (this.profileForm.valid) {
      let profile = { ...this.profile };
      profile.profileMeasureInputLines = this.profileForm.get('profileMeasureInputLines').value;
      this.sealingTypesService.saveProfile(profile, formulaUpdated, orderUpdated).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        if (updateOriginal) {
          data.profileMeasureInputLines.forEach((line, index) => {
            this.profile.profileMeasureInputLines[index]
            this.modify(this.profile.profileMeasureInputLines[index], line);
          });
          this.profile.profileComponents = data.profileComponents;
          this.profile.profileMeasureOutputOtherLines = data.profileMeasureOutputOtherLines;
          this.profileChange.emit(this.profile);
          this.recreateForm.emit();
          //this.createForm();
        }
        this.profileValidationService.updateValidations();
        // toast success
        // show toast message success
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

      }, error => {
        // show toast message error
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
      }
      );
    }
  }

  /**
   * Updates item values with delay
   * @param [updateOriginal] 
   */
  updateProfileValuesWithDelay(updateOriginal = true) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.updateProfileValues(updateOriginal);
    }, 3000);
  }

  /**
 * Opens link value modal
 * @param itemValueIndex 
 */
  openLinkValueModal(itemValueIndex: number) {

    this.lineIndex = true;

    const modal = this.modalService.open(ProfileLinkValuesDialogComponent, {
      size: 'md'

    });
    modal.dismissed.subscribe(data => {
      this.lineIndex = false;
    })
    modal.componentInstance.profileMeasureInputLines = (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).value;
    modal.componentInstance.itemValueIndex = itemValueIndex;
    modal.componentInstance.itemUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(item => {
      this.profile.profileMeasureInputLines[itemValueIndex] = item;
      (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).controls[itemValueIndex].patchValue(item);
      this.updateProfileValues(true, true);
      this.lineIndex = false;
      modal.close();


    });

  }


  /**
   * Opens link image modal
   * @param itemValueIndex 
   * @returns  
   */
  openLinkImageModal(itemValueIndex: number) {
    if (this.openLinkImageDisabled) {
      return;
    }
    const modal = this.modalService.open(ProfileLinkImageDialogComponent, {
      size: 'lg', backdropClass: '.image-link-modal-backdrop',
      windowClass: '.image-link-modal-window'
    });
    modal.componentInstance.image = this.imageProfileLeft;
    modal.componentInstance.profileMeasureInputLines = (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).value;
    modal.componentInstance.itemValueIndex = itemValueIndex;
    modal.componentInstance.itemUpdated.subscribe(item => {
      this.profile.profileMeasureInputLines[itemValueIndex] = item;
      (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).controls[itemValueIndex].patchValue(item);
      this.updateProfileValues(false);
    });
  }


  /**
   * Deletes item value
   */
  deleteProfileMeasureInputLine(index) {
    const id = this.profileForm.get('profileMeasureInputLines').value[index].idProfileMeasureInputLine;
    if (id) {
      this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
        if (result.remove === true) {
          this.sealingTypesService.deleteProfileMeasureInputLine(id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.profileForm.get('profileMeasureInputLines').value.splice(index, 1);
            this.profile.profileMeasureInputLines.splice(index, 1);
            this.profileChange.emit(this.profile);
            // show toast message success
            this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

          }, error => {
            // show toast message error
            this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
          }
          );
        }
        return result;
      }).catch((res) => { });
    } else {
      this.profileForm.get('profileMeasureInputLines').value.splice(index, 1);
      this.profile.profileMeasureInputLines.splice(index, 1);
      this.profileChange.emit(this.profile);
    }
  }


  /**
  * Modifies the object
  * @param obj 
  * @param newObj 
  */
  modify(obj, newObj) {
    Object.keys(obj).forEach(function (key) { delete obj[key]; });
    Object.keys(newObj).forEach(function (key) { obj[key] = newObj[key]; });
  }


  /**
   * Adds profileMeasureInputLine item
   */
  addItem() {
    const control = this.profileForm.get('profileMeasureInputLines') as UntypedFormArray;
    const item = {
      idProfileMeasureInputLine: null,
      idProfile: this.profile.idProfile,
      name: 'Neues Maß',
      fromValue: null,
      toValue: null,
      idSvgInput: null,
      showOrder: control.length,
      compareRatio: 1,
      compareTo: null,
      formula: null,
      readonly: false,
      idMeasureAbas: null,
    };
    this.profile.profileMeasureInputLines.push(item);
    (this.profileForm.get('profileMeasureInputLines') as UntypedFormArray).push(this.fb.group(item));
    control.at(control.length - 1).get('toValue').addValidators(Validators.required);
    control.at(control.length - 1).get('fromValue').addValidators(Validators.required);
    // control.at(control.length - 1).get('factor').addValidators([Validators.required, Validators.pattern(/^([-+*] ?\d+(\.\d+)? ?)*$/), this.validateFactor]);
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  
  canOpenLinkValueModal(itemValue) {
    if (!itemValue.formula) {
      return false;
    }

    const pattern = /^\{\d{1,2}\}$/;
    return !pattern.test(itemValue.formula);
  }

}
