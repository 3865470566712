import { TranslateService } from "@ngx-translate/core";
import { ToastService } from '../../../../shared/services/toast.service';
import { RemoveConfirmationModalComponent } from '../../../../shared/components/delete-confirmation/remove-confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SealingType } from '../../../../shared/models/sealing-type.model';
import { SealingTypesService } from '../../../../shared/services/sealing-types.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sealing-type-header',
  templateUrl: './sealing-type-header.component.html',
  styleUrls: ['./sealing-type-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit {
  @Input() sealingType: SealingType;
  @Output() sealingTypeChange = new EventEmitter<SealingType>();

  @Input() opened: boolean = false;
  editName = '';
  @Input() editMode = false;
  constructor(private sealingTypesService: SealingTypesService, private modalService: NgbModal, private translationsService: TranslateService, private toastService: ToastService) { }
  ngOnInit(): void {
    this.editName = this.sealingType.name;
  }

  /**
   * Updates the name∫
   * @returns  
   */
  updateName() {
    if (this.sealingType.idSealingType == null) {
      this.createSealingType();
      this.editMode = false;
      return;
    }
    let sealingType = { ...this.sealingType };
    sealingType.name = this.editName;
    this.sealingTypesService.saveSealingType(sealingType).subscribe(data => {
      this.sealingType = data; this.sealingTypeChange.emit(data);

      // show toast message success
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

    }, error => {
      // show toast message error
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
    }
    );
    this.editMode = false;
  }

  /**
   * Creates a new category in the database
   */
  createSealingType() {
    let sealingType = { ...this.sealingType };
    sealingType.name = this.editName;
    this.sealingTypesService.createSealingType(sealingType).subscribe(data => {
      this.sealingType = data; this.sealingTypeChange.emit(data);

      // show toast message success
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

    }, error => {
      // show toast message error
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
    }
    );
  }
  /**
   * Cancels edit and emits a change
   */
  cancelEdit() {
    this.editMode = false;
    if (this.sealingType.idSealingType == null) {
      this.sealingTypeChange.emit(null);
    }
  }

  /**
   * Deletes a sealingType
   */
  deleteSealingType() {
    // if no id just set to null
    if (this.sealingType.idSealingType == null) {
      this.sealingTypeChange.emit(null);
      return;
    }
    this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
      if (result.remove === true) {
        this.sealingTypesService.deleteSealingType(this.sealingType.idSealingType).subscribe(data => {
          this.sealingTypeChange.emit(null);

          // show toast message success
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

        }, error => {
          // show toast message error
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
        }
        );
      }
      return result;
    }).catch((res) => { });
  }
}
