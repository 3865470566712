<form class="order-form" fxLayout="row wrap" fxLayout.lt-sm="column" [formGroup]="orderAddressForm">
    <div class="order-form__column" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
        <h3 i18n class="order-form__title ttu se-title underlined bold">{{'cart.shippingAddress'|translate}}</h3>
        <fieldset class="order-form__delivery">
            <app-radio-button *ngFor="let address of deliveryAddresses; let i = index;"
                [value]="address.idUserAddressERP"
                [checked]="address.idUserAddressERP === fields.deliveryAddressId.value"
                formControlName="deliveryAddressId" ngDefaultControl name="deliveryAddressRadio">
                <div class="order-form__text"> {{address.addressName}} </div>
                <div class="order-form__text"> {{address.street}} </div>
                <div class="order-form__text"> {{address.zipCode}} {{address.city}} </div>
                <div class="order-form__text"> {{address.country}} </div>
            </app-radio-button>
            <div class="custom-address-option">
                <app-radio-button [value]="-1" [checked]="fields.deliveryAddressId.value == -1"
                    formControlName="deliveryAddressId" ngDefaultControl name="deliveryAddressRadio">
                    <div class="order-form__text" i18n> {{'cart.createNewAddress'|translate}} </div>
                </app-radio-button>
                <textarea *ngIf="fields.deliveryAddressId.value == -1" formControlName="customDeliveryAddress" class="custom-address order-form__textarea se-textarea"></textarea>
            </div>
        </fieldset>
    </div>
    <div class="order-form__column" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
        <h3 i18n class="order-form__title ttu se-title underlined bold">{{'cart.billingAddress'|translate}}</h3>
        <fieldset class="order-form__billing">
            <app-radio-button *ngFor="let address of invoiceAddresses; let i = index;" name="invoiceAddressRadio"
                [value]="address.idUserAddressERP"
                [checked]="address.idUserAddressERP === fields.invoiceAddressId.value"
                formControlName="invoiceAddressId" ngDefaultControl [id]="address.idUserAddressERP">
                <div class="order-form__text"> {{address.addressName}} </div>
                <div class="order-form__text"> {{address.street}} </div>
                <div class="order-form__text"> {{address.zipCode}} {{address.city}} </div>
                <div class="order-form__text"> {{address.country}} </div>
            </app-radio-button>
        </fieldset>
    </div>
    <div class="order-form__column" fxFlex="33" fxFlex.lt-md="100">
        <div *ngIf="isUserAccountManager && this.idAccountManager !== undefined" class="customer-care-id">
            <h3 i18n class="order-form__title ttu se-title underlined bold">{{'cart.accountManager'|translate}}</h3>
            <div class="order-form__final">
                <input name="order-number" class="order-form__input se-input w-100 d-block mb-4"
                    [value]="idAccountManager" type="text" formControlName="idAccountManager" ngDefaultControl>
            </div>
        </div>
        <h3 i18n class="order-form__title ttu se-title underlined bold">{{'cart.customerOrderNumber'|translate}}</h3>
        <div class="order-form__final">
            <input name="order-number" class="order-form__input se-input w-100 d-block" type="text"
                formControlName="idClientOrder" ngDefaultControl>
        </div>
        <h3 i18n class="order-form__title ttu se-title underlined bold mt-4">{{'cart.shippingNotice'|translate}}</h3>
        <div class="order-form__final">
            <textarea name="comments" class="order-form__textarea se-textarea" formControlName="shippingNote"
                ngDefaultControl></textarea>
            <app-button label="{{'cart.completeOrder'|translate}}" height="50px" [disabled]="ifButtonDisabled"
                [loading]="processingOrder" loadingText="{{'cart.pleaseWait'|translate}}" (click)="onFormSubmit()"></app-button>
        </div>
    </div>
</form>
