import { ProfileValidationService } from '../../../../../../../shared/services/profile-validation.service';
import { MaterialProfileMeasure } from '../../../../../../../shared/models/material-profile-measure.model';
import { MaterialProfile } from '../../../../../../../shared/models/material-profile.model';
import { ProfileComponentMaterial } from '../../../../../../../shared/models/profile-component-material.model';
import { ProfileComponentService } from '../../../../../../../shared/services/profile-component.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { ProfileComponentMaterialLine } from '../../../../../../../shared/models/profile-component-material-line.model';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-profile-component-material-line',
  templateUrl: './profile-component-material-line.component.html',
  styleUrls: ['./profile-component-material-line.component.scss']
})
export class ProfileComponentMaterialLineComponent implements OnInit, OnChanges {
  @Input() profileComponentMaterialLine: ProfileComponentMaterialLine;
  @Input() profileComponentMaterial: ProfileComponentMaterial;
  @Input() profileComponentMaterialLines: ProfileComponentMaterialLine[];
  @Input() materialProfileMeasure: MaterialProfileMeasure;
  @Input() validateForm = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter();
  @Output() profileComponentMaterialLineChange = new EventEmitter<ProfileComponentMaterialLine>();

  
  timer: any;
  profileComponentMaterialLineForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private toastService: ToastService, private translationsService: TranslateService, private profileComponentService: ProfileComponentService, private validationService: ProfileValidationService) { }

  ngOnInit(): void {


       
    // This patch Value is to refresh the validation of each line when they are added through a template apply.
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.profileComponentMaterialLineForm.patchValue(this.profileComponentMaterialLine, { emitEvent: false });
    }, 200);

 
      this.createForm();
      
      this.profileComponentMaterialLineForm.valueChanges.subscribe(data => {
        this.updateItemValuesWithDelay()
      });

      this.validateForm.subscribe(data => {
        if (data != this.profileComponentMaterialLine?.idProfileComponentMaterialLine) {
          this.profileComponentMaterialLineForm.updateValueAndValidity({ emitEvent: false });
          // this.profileComponentMaterialLineForm.markAsTouched();
          this.profileComponentMaterialLineForm.controls.fromValue.setValidators([Validators.required, this.fromValueNoGapsValidation(), this.fromBiggerThenMeasureFromValue()]);
          this.profileComponentMaterialLineForm.controls.toValue.setValidators([Validators.required, this.toGreaterThanFrom(), this.lastToSmallerThanMeasureToValue(), this.toSmallerThenMeasureToValue()]);
          this.profileComponentMaterialLineForm.controls.toValue.updateValueAndValidity({ emitEvent: false });
          this.profileComponentMaterialLineForm.controls.fromValue.updateValueAndValidity({ emitEvent: false });
        }
      });
      
    }


  hasOnlyIdAndMeasureAttributes(profileComponentMaterialLine) {
    return (
      profileComponentMaterialLine &&
      profileComponentMaterialLine.idProfileComponentMaterialFK !== null &&
      profileComponentMaterialLine.materialProfileMeasure !== null &&
      Object.keys(profileComponentMaterialLine).length === 2
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.profileComponentMaterialLineForm) {
      this.profileComponentMaterialLineForm.updateValueAndValidity();
    }
  }

  /**
   * Creates form
   */
  createForm() {
    this.profileComponentMaterialLineForm = this.fb.group({
      idProfileComponentMaterialLine: [this.profileComponentMaterialLine?.idProfileComponentMaterialLine || null],
      fromValue: [this.profileComponentMaterialLine?.fromValue || 0, [Validators.required, this.fromValueNoGapsValidation(), this.fromBiggerThenMeasureFromValue()]],
      toValue: [this.profileComponentMaterialLine?.toValue || null, [Validators.required, this.toGreaterThanFrom(), this.lastToSmallerThanMeasureToValue(), this.toSmallerThenMeasureToValue()]],
      setupUnit: [this.profileComponentMaterialLine?.setupUnit || null, Validators.required],
      processingTime: [this.profileComponentMaterialLine?.processingTime || null, Validators.required],
      idProfileComponentMaterialFK: [this.profileComponentMaterialLine?.idProfileComponentMaterialFK || null],
      materialProfileMeasure: [this.profileComponentMaterialLine?.materialProfileMeasure || null]
    });
  }

 

  /**
   * Deletes profile component material line
   */
  deleteProfileComponentMaterialLine() {
    if (!this.profileComponentMaterialLine?.idProfileComponentMaterialLine) {
      this.profileComponentMaterialLine = null;
      this.onDelete.emit();
    } else {

      this.profileComponentService.deleteProfileComponentMaterialLine(this.profileComponentMaterialLine.idProfileComponentMaterialLine).subscribe(data => {
        this.profileComponentMaterialLine = null;
        this.onDelete.emit();
      });
    }
  }

  /** 
   * Updates item values with delay
   * @param [updateOriginal] 
   */
  updateItemValuesWithDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.updateProfileComponentMaterialLine();
    }, 1000);
  }

  updateTemplateItemValuesWithDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.createNewMaterialLine();
    }, 1000);
  }

  hasNoIdAndNonNullAttribute(profileComponentMaterialLine) {
    return !profileComponentMaterialLine.idProfileComponentMaterialLine;
  }

  createNewMaterialLine() {
    if (!this.profileComponentMaterialLine?.idProfileComponentMaterialLine) {
      this.profileComponentService.createProfileComponentMaterialLine(
        this.profileComponentMaterialLineForm.value,
        this.profileComponentMaterialLine.materialProfileMeasure.idMaterialProfileMeasure,
        this.profileComponentMaterial.idProfileComponentMaterial).subscribe(data => {

          this.modify(this.profileComponentMaterialLine, data);
          this.profileComponentMaterialLine.valid = this.profileComponentMaterialLineForm.valid;
          this.profileComponentMaterialLineChange.emit(this.profileComponentMaterialLine);
          this.onUpdateOrCreateValidate();
        
          this.profileComponentMaterialLineForm.patchValue(this.profileComponentMaterialLine, { emitEvent: false });
        }, error => {

        });
    }
  }


  /**
   * Updates profile component material line
   */
  updateProfileComponentMaterialLine() {
    if (!this.profileComponentMaterialLine?.idProfileComponentMaterialLine) {
      this.profileComponentService.createProfileComponentMaterialLine(
        this.profileComponentMaterialLineForm.value,
        this.profileComponentMaterialLine.materialProfileMeasure.idMaterialProfileMeasure,
        this.profileComponentMaterial.idProfileComponentMaterial).subscribe(data => {
          this.modify(this.profileComponentMaterialLine, data);
          this.profileComponentMaterialLine.valid = this.profileComponentMaterialLineForm.valid;
          this.profileComponentMaterialLineChange.emit(this.profileComponentMaterialLine);
          this.onUpdateOrCreateValidate();
          // this.createForm();
          this.profileComponentMaterialLineForm.patchValue(this.profileComponentMaterialLine, { emitEvent: false });
          // show toast message success
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
        }, error => {
          // show toast message error
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
        });
    } else {
      this.profileComponentService.updateProfileComponentMaterialLine(this.profileComponentMaterialLineForm.value).subscribe(data => {
        this.modify(this.profileComponentMaterialLine, data);
        this.profileComponentMaterialLine.valid = this.profileComponentMaterialLineForm.valid;
        this.profileComponentMaterialLineChange.emit(this.profileComponentMaterialLine);
        this.onUpdateOrCreateValidate();

        // this.createForm();
        this.profileComponentMaterialLineForm.patchValue(this.profileComponentMaterialLine, { emitEvent: false });
        // show toast message success
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      }, error => {
        // show toast message error
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
      });
    }
  }
  modify(obj, newObj) {

    Object.keys(obj).forEach(function (key) {
      delete obj[key];
    });

    Object.keys(newObj).forEach(function (key) {
      obj[key] = newObj[key];
    });

  }

  onUpdateOrCreateValidate() {
    this.validationService.updateValidations();

  }

  /**
   * Froms value no gaps validation
   * if fromValue is bigger than previous toValue + 1, fromValue is invalid
   * @returns value no gaps validation 
   */
  fromValueNoGapsValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.profileComponentMaterialLineForm) { return null; }
      // get current index
      const index = this.profileComponentMaterialLines
        .findIndex(x => x.idProfileComponentMaterialLine == this.profileComponentMaterialLineForm.get('idProfileComponentMaterialLine').value);
      let valueToCompare = this.materialProfileMeasure.fromValue;
      let containsGap = false;
      if (index < 0) { return null; }
      if (index > 0) {
        const group = this.profileComponentMaterialLines[index - 1];
        valueToCompare = group.toValue;
        containsGap = Number(control.value) !== Number(valueToCompare) + 1;
      } else if (index == 0) {
        containsGap = Number(control.value) !== Number(valueToCompare);
      }
      return containsGap ? { 'containsGap': { value: control.value } } : null;
    }
  }

  /**
   * To value greater than from value
   * @returns greater than from 
   */
  toGreaterThanFrom(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.profileComponentMaterialLineForm) { return null; }
      // get current index
      const index = this.profileComponentMaterialLines
        .findIndex(x => x.idProfileComponentMaterialLine == this.profileComponentMaterialLineForm.get('idProfileComponentMaterialLine').value);

      if (index < 0) { return null; }
      const group = this.profileComponentMaterialLines[index];
      return group.fromValue > group.toValue ? { 'fromGreaterThanTo': { value: control.value } } : null;
    }
  }

  /**
   * Last to value smaller than measure to value
   * @returns to smaller than measure to value 
   */
  lastToSmallerThanMeasureToValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.profileComponentMaterialLineForm) { return null; }
      // get current index
      const index = this.profileComponentMaterialLines.findIndex(
        x => x.idProfileComponentMaterialLine == this.profileComponentMaterialLineForm.get('idProfileComponentMaterialLine'
        ).value);

      if (index == this.profileComponentMaterialLines.length - 1) {
        return this.materialProfileMeasure.toValue !== control.value ? { 'toGreaterThanMeasureToValue': { value: control.value } } : null;
      }

    }
  }

  /**
   * To value smaller then measure to value
   * @returns smaller then measure to value 
   */
  toSmallerThenMeasureToValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.profileComponentMaterialLineForm) { return null; }
      return this.materialProfileMeasure.toValue < control.value ? { 'toSmallerThenMeasureToValue': { value: control.value } } : null;
    }
  }

  /**
   * From value bigger then measure from value
   * @returns bigger then measure from value 
   */
  fromBiggerThenMeasureFromValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.profileComponentMaterialLineForm) { return null; }
      return this.materialProfileMeasure.fromValue > control.value ? { 'fromBiggerThenMeasureFromValue': { value: control.value } } : null;
    }
  }




  closePopupEvent() {
   // this.updateProfileComponentMaterialLine();
  }

  getForm() {
    return this.profileComponentMaterialLineForm.value;
  }


}
