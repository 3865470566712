import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ArticleType } from 'src/app/shared/models/article-type.enum';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FrameworkContractService {
  private apiUrl = environment.apiUrl;
  private userId: string;
  API_ERROR_STATUS = 'API_ERROR';
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => this.userId = userId);
  }

  /**
   * Gets frameworkContracts
   * @returns Observable
   */
  getFrameworkContracts() {
    return this.http.get(`${this.apiUrl}/framework-contracts/${this.userId}/`).pipe(map(
      (frameworkContracts: any[]) => frameworkContracts.map(frameworkContract => {
        frameworkContract.frameworkContractArticles.map(art => { art.articleType = ArticleType.FRAMEWORK_CONTRACT; return art; });
        return frameworkContract;
      })), catchError(error => {
        // console.error('There was an error while loading your frameworkContracts, please contact Haenssler.');
        return of([]);
      }));
  }

  /**
   * Gets framework contract
   */
  getFrameworkContract(idFrameworkContractERP, idArticleERP) {
    return this.http.get(`${this.apiUrl}/framework-contracts/${this.userId}/${idFrameworkContractERP}/${idArticleERP}`).pipe(map(
      (frameworkContractArticle: any) => {
        frameworkContractArticle.articleType = ArticleType.FRAMEWORK_CONTRACT;
        return frameworkContractArticle;
      }
    ), catchError(error => {
      // console.error('There was an error while loading your frameworkContracts, please contact Haenssler.');
      return of({});
    }));
  }
}
