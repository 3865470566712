<div class="title-container mb-4 d-flex align-items-center">
    <h5 class="se-title" *ngIf="!editNameMode">
        {{profile.name}}
    </h5>
    <input class="form-control" type="text" [(ngModel)]="editName" *ngIf="editNameMode" placeholder="Name"
        [ngClass]="{'invalid': (editName == '' || editName == null)}">
    <i class="bi bi-pencil ml-3" (click)="editNameMode = !editNameMode" *ngIf="!editNameMode"></i>
    <i class="bi bi-trash3 ml-2" *ngIf="!editNameMode" (click)="deleteProfile()"></i>

    <i class="bi bi-check2 ml-3" *ngIf="editNameMode" (click)="updateProfile()"></i>
    <i class="bi bi-x" (click)="cancelEdit()" *ngIf="editNameMode"></i>


</div>
<div class="d-flex flex-wrap flex-lg-nowrap profile-cards">
    <div class="col-12 col-lg-6" *ngIf="profile" [formGroup]="profileForm">
        <div class="profile-card">
            <div class="row border profile-card-container" [ngClass]="{'disabled':profile.idProfile == null}">
                <div class="col-lg-7">
                    <div class="card h-100">
                        <h5 class="se-title">
                            {{'sealingType.body.grooveDimension'|translate}}
                        </h5>
                        <img class="profile-image" [src]="imageProfileLeft" *ngIf="!showUploadForm[0]">
                        <app-image-uploader *ngIf="showUploadForm[0]" (fileChange)="fileChange($event, 'dimensions')"
                            [svgExtension]="true">
                        </app-image-uploader>
                        <button class="upload-photo mt-2 ml-auto mr-auto"
                            (click)="showUploadForm[0] = !showUploadForm[0]">
                            <span *ngIf="!showUploadForm[0]">{{'sealingType.body.changePicture'|translate}} <i class="bi bi-upload"></i></span>
                            <span *ngIf="showUploadForm[0] && !imageInvalid[0]">{{'sealingType.body.abort'|translate}} <i
                                    class="bi bi-x-lg"></i></span>
                        </button>
                    </div>
                </div>
                <div class="col-lg-5 border-left">
                    <div class="card h-100">
                        <h5 class="se-title">
                            {{'sealingType.body.sealingProfile'|translate}}
                        </h5>
                        <img class="profile-image" [src]="imageProfileRight" *ngIf="!showUploadForm[1]">
                        <app-image-uploader *ngIf="showUploadForm[1]" (fileChange)="fileChange($event, 'profile')"
                            [svgExtension]="true">
                        </app-image-uploader>
                        <button class="upload-photo mt-2 ml-auto mr-auto"
                            (click)="showUploadForm[1] = !showUploadForm[1] ">
                            <span *ngIf="!showUploadForm[1]">{{'sealingType.body.changePicture'|translate}} <i class="bi bi-upload"></i></span>
                            <span *ngIf="showUploadForm[1] && !imageInvalid[1]">{{'sealingType.body.abort'|translate}} <i
                                    class="bi bi-x-lg"></i></span>
                        </button>
                    </div>
                </div>
                <div class="col-lg-12 border-top">
                    <div class="card h-100">
                        <div class="card-body">
                            <app-profile-input-values [profile]="profile" [profileForm]="profileForm" [showInputsOrder]="showInputsOrder"
                                (recreateForm)="createForm()" [imageProfileLeft]="imageProfileLeft" [showInputsOrder]="showInputsOrder"
                                [openLinkImageDisabled]="showUploadForm[0]" [profileChange]="profileChange">
                            </app-profile-input-values>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 border-top">
                    <div class="card h-100">
                        <div class="card-body">

                            <div class="w-100">
                                <h5 class="se-title">
                                    {{'sealingType.body.outputValues'|translate}}
                                </h5>
                            </div>
                            <h4></h4>



                            <ngb-accordion #acc="ngbAccordion" class="profile-component-values-accordion"
                                [activeIds]="'panel-0'">
                                <ngb-panel *ngFor="let componentValue of profileOutputValuesWrappers; let j = index"
                                    [id]="'panel-' + j">
                                    <ng-template ngbPanelHeader let-opened="opened">
                                        <div class="d-flex align-items-center inner-header-content">
                                            <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button">
                                            </button>
                                            <i class="bi bi-chevron-right mt-1" [ngClass]="{'opened': opened}"></i>
                                            <span class="ml-2 ">{{componentValue.name}}</span>
                                            <!-- <span i18n class="error-message" *ngIf="materialProfileMeasureInvalid">Es gibt eine Fehler</span>
                                            -->
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <app-profile-output-values [profile]="profile" [profileForm]="profileForm"  [showInputsOrder]="showInputsOrder"
                                            [componentIndex]="j" [profileOutputOther]="componentValue.name == 'Other'"
                                            (recreateForm)="createForm()" [imageProfileLeft]="imageProfileLeft"
                                            [openLinkImageDisabled]="showUploadForm[0]" [profileChange]="profileChange">
                                        </app-profile-output-values>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <app-profile-component *ngFor="let item of profileComponents; let i = index"
            [(profileComponent)]="profileComponents[i]" [(profile)]="profile" (profileComponentChange)="createForm()"
            [materialProfiles]="materialProfiles" [idProfile]="profile.idProfile"></app-profile-component>
        <div class="row">
            <div class="border add-more-container w-100">
                <div class="col-lg-12">
                    <app-add-new-button class="create-component" padding="10px 15px" text="{{'sealingType.body.addNewComponents'|translate}}"
                        (onAddClick)="createNewComponent()"></app-add-new-button>
                </div>
            </div>
        </div>
    </div>
</div>