
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-navigation',
  templateUrl: './cart-navigation.component.html',
  styleUrls: ['./cart-navigation.component.scss']
})
export class CartNavigationComponent implements OnInit {


  @Input() step = 0;
  @Input() disableBack = false;
  @Input() disableForward = false;
  cartSteps = [
    {
      back: {
        title: 'codeTranslations.cartprogress-back',
        route: '/dashboard'
      },
      forward: {
        title: 'codeTranslations.cartprogress-cart-page',
        route: '/cart'
      }
    },
    {
      back: {
        title: 'codeTranslations.cartprogress-articles-page',
        route: '/articles'
      },
      forward: {
        title: 'codeTranslations.cartprogress-order-page',
        route: '/order'
      }
    },
    {
      back: {
        title: 'codeTranslations.cartprogress-cart-page',
        route: '/cart'
      }
    }];
  constructor(private translationsService: TranslateService) { }

  ngOnInit(): void {

  }

}
