import { TranslateService } from "@ngx-translate/core";
import { ToastService } from './../../shared/services/toast.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { SearchResult } from './../../shared/models/search-result-enum.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from './../../shared/services/order.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderHistory } from './../../shared/models/order-history.model';
import _ from 'lodash';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  orders: OrderHistory[] = [];
  public ordersFull: OrderHistory[] = [];
  loading = true;
  public searchFormControl = new UntypedFormControl('');
  // start loading articles from 0
  private startPos = 0;
  private fullListStartPos = 0;
  // any time we click load more 20 new articles will be loaded
  private numberOfArticlesToLoad = 20;
  // load articles until position
  private limit = this.numberOfArticlesToLoad;
  private loadedArticlesCount = 0;
  public showSearch = false;
  private searchText = null;
  private unsubscribe: Subject<void> = new Subject();
  public showLoadFullOrder = false;
  public showLoadFullOrderClicked = false;
  public singleArticleOrderOpened = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translationsService: TranslateService,
    private router: Router) { }

  ngOnInit(): void {
    // if route contains showSearch param load orders from local storage
    if (this.route.snapshot.paramMap.get('showSearch')) {
      this.showSearch = true;
      // set search form control without triggering the search function
      this.searchFormControl.setValue(this.route.snapshot.paramMap.get('orderId'), { emitEvent: false });
      // check if order is loaded completely
      this.showLoadFullOrder = !JSON.parse(this.route.snapshot.paramMap.get('complete'));
    }
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (this.route.snapshot.paramMap.get('showSearch')) {
        this.showLoadFullOrderClicked = false;
        this.showSearch = true;
        this.loadOrders();
        this.showLoadFullOrder = !JSON.parse(this.route.snapshot.paramMap.get('complete'));
        // set search form control on route change without triggering the search function
        this.searchFormControl.setValue(this.route.snapshot.paramMap.get('orderId'), { emitEvent: false });
      }
    });
    this.loadOrders();
  }

  /**
   * Searches orders by text when user types into the search input
   * @param data string
   */
  onSearch(data: string) {
    data = data.trim();
    this.startPos = 0;
    this.loading = true;
    this.orders = [];
    if (data === '') {
      // if the search field is empty use the last original list values and show the list we had before search
      this.searchText = null;
      this.showSearch = false;
      this.orders = this.ordersFull;
      this.startPos = this.fullListStartPos;
      if (this.ordersFull.length === 0) {
        this.loadOrders();
      } else {
        this.loading = false;
      }
      return;
    }
    this.searchText = data;
    this.loadOrders();
    this.searchOffersByIdOffer();
    this.showSearch = false;
  }

  /**
   * Loads more orders on click on load more
   * @returns void
   */
  loadMore() {
    if (!this.loadMoreAvailable) { return; }
    this.loading = true;
    if (this.orders !== null) {
      this.startPos += this.numberOfArticlesToLoad;
      this.limit = this.numberOfArticlesToLoad;
    }
    this.loadOrders();
  }

  /**
   * Loads orders
   */
  loadOrders() {
    if (this.showSearch) {
      // get orders from local storage
      this.orders = [JSON.parse(localStorage.getItem(SearchResult.SearchResultOrders))];
      this.loading = false;
    }
    else {
      this.orderService.getOrders(this.startPos, this.limit, this.searchText)
        .pipe(takeUntil(this.unsubscribe)).subscribe((orders: OrderHistory[]) => {
          if (orders) {
            // merge existing array with the received one
            this.orders = [...this.orders, ...orders];
            this.orders = _.uniqBy(this.orders, 'idOrderERP');
            this.loading = false;
            // number of articles received
            if (orders.length > 0) {
              this.loadedArticlesCount = orders.length;
            } else {
              this.loadedArticlesCount = 0;
            }

            if (this.searchText == null) {
              this.fullListStartPos = this.startPos;
              this.ordersFull = this.orders;
            }
          } else {
            this.loading = false;
          }
        });
    }
  }

  /**
   * Searchs orders by id order and merges with matchcode search results
   */
  searchOffersByIdOffer(loadFullListAction = false) {
    this.loading = true;
    this.orderService.searchOrdersById(this.searchText)
      .pipe(takeUntil(this.unsubscribe)).subscribe((orders: OrderHistory[]) => {
        // merge existing array with the received one
        if (orders && !loadFullListAction) {
          this.orders = [...this.orders, ...orders];
          this.orders = _.uniqBy(this.orders, 'idOrderERP');
        } else if (orders) {
          if (this.orders[0].orderArticles.length !== orders[0].orderArticles.length) {
            // on calling this function in order to load the full order articles list substitute orderArticles
            this.orders[0].orderArticles = orders[0].orderArticles;
          } else {
            // if no changes since reloading the order show warning message no-more-full-order-items
            this.showGetFullOrderWarning();
          }
        }
        this.loading = false;
      });
  }

  /**
   * Loads the full order with all the articles
   */
  getFullOrder() {
    this.searchText = this.route.snapshot.paramMap.get('orderId');
    this.searchOffersByIdOffer(true);
    this.showLoadFullOrderClicked = true;
  }

  /**
   * Shows a warning toaster message when the full offer contains only one article
   */
  showGetFullOrderWarning() {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.no-more-full-order-items'),
      { classname: 'bg-warning text-light', delay: 3000 }
    );
  }

  /**
   * Used to show the load full order button
   * @param opened Boolean
   */
  orderOpened(opened) {
    this.singleArticleOrderOpened = opened;
  }

  /**
   * Gets load more available
   */
  get loadMoreAvailable() {
    return (this.orders !== undefined
      && this.loadedArticlesCount > 0);
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
