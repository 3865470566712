<div class="container-fluid">
    <div class="reset-password" *ngIf="tokenValidated">
        <div class="reset-password__background"></div>
        <form class="reset-password__form se-panel" [formGroup]="resetPasswordForm">
            <div class="reset-password__header">
                <h1 class="reset-password__title" i18n> {{'password-reset.changePassword'|translate}} </h1>
            </div>
            <div class="reset-password__body">
                <div class="reset-password__input-container" *ngIf="showOldPasswordField">
                    <div class='reset-password__icon reset-password__icon_password'></div>
                    <input type="password" name="oldPassword" formControlName="oldPassword"
                        class="reset-password__input reset-password__input_password se-input"
                        placeholder="{{'password-reset.oldPassword'|translate}}" i18n-placeholder>
                </div>
                <div class="reset-password__input-container">
                    <div class='reset-password__icon reset-password__icon_password'></div>
                    <input type="password" name="password" formControlName="password"
                        class="reset-password__input reset-password__input_password se-input" placeholder="{{'password-reset.newPassword'|translate}}"
                        i18n-placeholder>
                </div>
                <div class="reset-password__input-container">
                    <div class='reset-password__icon reset-password__icon_password'></div>
                    <input type="password" name="confirmPassword" formControlName="confirmPassword"
                        class="reset-password__input reset-password__input_password se-input"
                        placeholder="{{'password-reset.confirmPassword'|translate}}" i18n-placeholder>
                </div>
                <app-button label="{{'password-reset.updatePassword'|translate}}" padding="10px" (onClick)="onSubmit()"
                    [disabled]="!resetPasswordForm.valid" class="reset-password__button">
                </app-button>
                <div class="reset-password__validation text-left">
                    <div i18n> <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{hasNumber ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': hasNumber ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.specialCharacter'|translate}}</div>
                    <div i18n>
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{hasUpperCase ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': hasUpperCase ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.capitalLetter'|translate}}</div>
                    <div i18n>
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{hasLowerCase ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': hasLowerCase ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.lowercase'|translate}}</div>
                    <div i18n>
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{hasMinLength ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': hasMinLength ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.length'|translate}}</div>
                    <div i18n>
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{notSame ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': notSame ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.samePassword'|translate}}</div>
                    <div i18n *ngIf="showOldPasswordField">
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{oldPasswordRequired ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': oldPasswordRequired ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span>{{'password-reset.conditions.oldPasswordRequired'|translate}}</div>
                </div>
            </div>
        </form>
    </div>
    <app-loading-spinner *ngIf="!tokenValidated"></app-loading-spinner>
</div>
