import { ScaledPrice } from './../../models/scaled-price.model';
import { Article } from 'src/app/shared/models/article.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/models/user.model';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ScaledPricesList } from './../../models/scaled-price-list.model';
import { ArticleType } from '../../models/article-type.enum';

@Component({
  selector: 'app-scaled-prices-table',
  templateUrl: './scaled-prices-table.component.html',
  styleUrls: ['./scaled-prices-table.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ScaledPricesTableComponent implements OnInit {
  @Input() article: Article;
  @Input() currentScaledPrice: ScaledPrice;
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  ArticleType = ArticleType;
  errorOnLoading = false;
  private user: User;
  tableCollapse = true;
  valid = true;
  constructor(private articleService: ArticlesService, private authenticationService: AuthenticationService) {
    this.user = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.articleService.closeOtherScaledPricesEvent.subscribe(data => {
      if (data !== this.article.idArticleERP) {
        this.dropdown.close();
        this.tableCollapse = true;
      }
    });
  }


  /**
   * Loads scaled prices from service
   */
  loadScaledPrices() {
    this.articleService.closeOtherScaledPrices(this.article.idArticleERP);
    if ((!this.article.scaledPrices ||
      this.article.scaledPrices.scaledPriceList.length === 0) || (this.article.idArticleERP !== 'no-id')
      && this.tableCollapse === true && this.article.articleType !== ArticleType.FRAMEWORK_CONTRACT) {
      this.article.scaledPrices = null;
      let idOfferPosition = null;
      if (this.article.articleType === ArticleType.OFFER_ARTICLE) {
        idOfferPosition = this.article.idPositionERP;
      }
      this.articleService.getArticleScaledPrices(this.article.idArticleERP, idOfferPosition)
        .subscribe((result: ScaledPricesList) => {
          if (result) {
              this.article.scaledPrices = result;
              this.deleteFirstLinePrice(this.article);
              this.updateLastPriceToValue(this.article);
          } else {
            this.errorOnLoading = true;
          }
        });
    } else {
      this.deleteFirstLinePrice(this.article);
      this.updateLastPriceToValue(this.article);
    }
    this.tableCollapse = !this.tableCollapse;
  }

  /**
   * Used to delete the first line of prices of the article if it's the same as the second one.
   * @param article Article.
   */
  deleteFirstLinePrice(article) {
    if(article.createdBySalesEngine && article.scaledPrices != null) {
      if(article.scaledPrices.scaledPriceList.length >= 2) {
        let firstPrice = article.scaledPrices.scaledPriceList[0].fromUnits;
        let secondPrice = article.scaledPrices.scaledPriceList[1].fromUnits;
        if(firstPrice == secondPrice) {
          article.scaledPrices.scaledPriceList.splice(0,1);
        }
      }
    }
  }

  /**
   * Used to update the value 999'999 into 9999 from the "TO" section of a scaled price.
   * @param article Article.
   */
  updateLastPriceToValue(article) {
    if(article.scaledPrices != null) {
      // Uncomment this lines of code if you want to check each price it its "TO" value equals to 999'999
      /*
      article.scaledPrices.scaledPriceList.forEach(price => {
        if(price.toUnits == 999999) {
          price.toUnits = 999;
        }
      });
      */
      article.scaledPrices.scaledPriceList[article.scaledPrices.scaledPriceList.length-1].toUnits = 9999;
    }

  }


}
