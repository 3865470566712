import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-articles-filter',
  templateUrl: './articles-filter.component.html',
  styleUrls: ['./articles-filter.component.scss']
})
export class ArticlesFilterComponent implements OnInit {
  @Output() private formGroupChange = new EventEmitter();
  @Output() private dateChange = new EventEmitter();
  @Input() searchFormControl = new UntypedFormControl('');
  @Input() placeholder: string;

  public timeout: any = null;
  minDate = new Date();
  constructor() { }

  ngOnInit(): void {
    this.searchFormControl.valueChanges.subscribe(data => {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.formGroupChange.emit(data);
      }, 2000);
    });
  }

}
