import { ShoppingCartService } from '../shared/services/shopping-cart.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class OrderPageGuard  {
    constructor(
        private router: Router,
        private shoppingCartService: ShoppingCartService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // disables access to the order page when the cart is empty
        if (this.shoppingCartService.cartHasArticles) {
            return true;
        }
        this.router.navigate(['/dashboard']);
        return false;
    }
}
