<app-section-header bootstrapIcon="bi bi-collection" section="sealing-type-and-profile"></app-section-header>
<div class="container-fluid mt-3 mb-3 d-flex justify-content-end">
    <app-button (click)="addNew()">{{'sealingType.createCategory'|translate}}</app-button>
</div>
<div class="container-fluid mt-3 mb-3">
    <ngb-accordion [closeOthers]="false" [animation]="true" class="profile-accordion"
        (panelChange)="panelChange($event)">
        <ng-container *ngFor="let sealingType of sealingTypes; let i = index;">
            <ngb-panel id="material-profile-{{i}}" *ngIf="sealingType != null">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="d-flex align-items-center justify-content-between inner-header-content">
                        <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button"></button>
                        <app-sealing-type-header [opened]="opened" [(sealingType)]="sealingTypes[i]"
                            (sealingTypeChange)="removeNullValues()" [editMode]="sealingType.idSealingType == null">
                        </app-sealing-type-header>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                            <ng-container
                                *ngFor="let profile of sealingType.profiles; let j = index; trackBy:trackByFn">
                                <li [ngbNavItem]="j" *ngIf="profile">
                                    <a ngbNavLink>
                                        <p class="profile-name-text text-truncate mb-0">{{sealingType.profiles[j].name || '...'}} 
                                            <i class="error-circle bi bi-exclamation-octagon ml-3" *ngIf="sealingType.profiles[j].valid === false"></i>
                                        </p>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <app-sealing-type-body [materialProfiles]="materialProfiles"
                                            [(profile)]="sealingType.profiles[j]"
                                            (profileChange)="removeNullValuesCategory(j)"
                                            [idSealingType]="sealingType.idSealingType">
                                        </app-sealing-type-body>
                                    </ng-template>
                                </li>
                            </ng-container>
                            <li class="nav-item" (click)="addNewItem(i, nav)" *ngIf="addNewAvailable(i)">
                                <a class="nav-link">
                                    <p class="text-truncate mb-0 ttu">{{'sealingType.profileName'|translate}} <i class="bi bi-plus-square ml-2"></i></p>
                                </a>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
    <div *ngIf="sealingTypes == null || sealingTypes.length == 0" class="se-table-row">
        <div class="se-table-col lg text-center border-bottom" i18n>{{'sealingType.noItemsToShow'|translate}}</div>
    </div>
</div>