import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-new-button',
  templateUrl: './add-new-button.component.html',
  styleUrls: ['./add-new-button.component.scss']
})
export class AddNewButtonComponent implements OnInit {
  @Input() text: string;
  @Input() disabled = false;
  @Input() padding: string;
  @Input() align: string = 'left';
  @Output() onAddClick = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  /**
   * On click send add click event
   */
  addItem() {
    if(this.disabled) {
      return;
    }
    this.onAddClick.emit();
  }
}
