import { ArticleType } from './article-type.enum';
export class CapacityPricesRequest {
    idArticleERP: string;
    amount: number;
    startDate: Date;
    endDate: Date;
    articleType: ArticleType;
    idOfferPosition?: string;
    idOfferERP?: string;
    positionOffer?: string;
    idFrameworkContractERP?: string;
}
