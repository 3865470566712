<app-section-header bootstrapIcon="bi bi-collection" section="profile-machine-group"></app-section-header>
<div class="container-fluid mt-3 mb-3 d-flex justify-content-end">
    <app-switch-button class="show-deactivated" [value]="showActiveStatus" [disabled]="false" leftText="{{'profileMachineGroup.onlyActive'|translate}}" *ngIf="idMaterialProfileFilter == null"
        (valueChanged)="onSwitchShowActiveChanged($event)" rightText="{{'profileMachineGroup.showAll'|translate}}" width="200px">
    </app-switch-button>
</div>
<div class="container-fluid mt-3 mb-3" *ngIf="showingMaterialProfiles">
    <ngb-accordion [closeOthers]="false" [animation]="true" *ngIf="materialProfilesLength" #a="ngbAccordion">
        <ngb-panel id="material-profile-{{i}}" *ngFor="let obj of materialProfilesLength; let i = index;">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button"></button>
                    <div class="material-info d-flex align-items-center">
                        <i class="bi bi-chevron-right mt-1" [ngClass]="{'opened': opened}"></i>
                        <h5 class="m-0 ml-2">{{showingMaterialProfiles[i].name}}</h5>
                    </div>
                    <span *ngIf="showingMaterialProfiles[i].hasBeenDeletedFromErpJson" class="material-removed font-italic font-weight-light ml-2 mt-1 mr-auto">{{'profileMachineGroup.item.jsonDataDeleted'|translate}}</span>
                    <app-switch-button class="mr-2" [value]="showingMaterialProfiles[i].isActive" [disabled]="false"
                        leftText="{{'profileMachineGroup.disabled'|translate}}" (valueChanged)="onSwitchChanged(i, $event, opened)" rightText="{{'profileMachineGroup.active'|translate}}"
                        width="200px">
                    </app-switch-button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-profile-machine-group-item *ngIf="showingMaterialProfiles[i]" [materialProfile]="showingMaterialProfiles[i]" [defaultRangePositions]="defaultRangePositions" [machineProfiles]="machineProfiles" (submitMaterialChanges)="saveMaterialProfile($event)">
                </app-profile-machine-group-item>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <div *ngIf="showingMaterialProfiles == null || showingMaterialProfiles.length == 0" class="se-table-row">
        <div class="se-table-col lg text-center border-bottom" i18n>{{'profileMachineGroup.item.noItemsToShow'|translate}}</div>
    </div>
</div>