<div class="profile-component-card" *ngIf="profileComponent">
    <div class="row profile-component-card-container border-left border-top border-right">
        <div class="col-lg-12">
            <div class="card h-100">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="se-title w-75">
                        <div class="d-flex align-items-center">
                            <input class="form-control" type="text" *ngIf="editMode" [(ngModel)]="profileComponent.name">
                            <span *ngIf="!editMode"> {{profileComponent.name }}</span>
                            <span class="ml-2 mr-2" *ngIf="!editMode && articleGroupsById[profileComponent.articleGroup]">- {{articleGroupsById[profileComponent.articleGroup].name}}</span>
                            <span *ngIf="!editMode">    <i class="error-circle bi bi-exclamation-octagon ml-3" *ngIf="profileComponent.valid === false"></i></span>
                            <ng-select [items]="articleGroups" bindLabel="name" bindValue="shortName" class="edit-article-group w-100 ml-2" [ngClass]="{'error': !profileComponent.articleGroup || !articleGroupsById[profileComponent.articleGroup]}" placeholder="{{'sealingType.profile.articleGroup'|translate}}" *ngIf="editMode" [(ngModel)]="profileComponent.articleGroup"></ng-select>
                            <i class="bi bi-check2 ml-3"  *ngIf="editMode" (click)="updateProfileComponent();" [ngClass]="{'disabled': !profileComponent.articleGroup}"></i>
                            <i class="bi bi-x" (click)="editMode = false;" *ngIf="editMode && !createMode"></i>
                        </div>
                    </h5>
                    <div class="control-buttons">
                        <i class="bi bi-pencil mt-1 mr-2" [ngClass]="{'disabled': editMode}"
                            (click)="editMode = true"></i>
                        <i class="bi bi-trash3 mt-1" (click)="deleteComponent()"></i>
                    </div>
                </div>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="invalidIds" class="material-dimensions-accordion">
                    <ngb-panel id="material-profile-{{item.idProfileComponentMaterial}}"
                        *ngFor="let item of profileComponent.profileComponentMaterials; let i = index">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center inner-header-content">
                                <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button">
                                </button>
                                <i class="bi bi-chevron-right mt-1" [ngClass]="{'opened': opened}"></i>
                                <span class="ml-2 mr-2">{{profileComponent.profileComponentMaterials[i].materialProfile.name}}</span>
                                <div class="accordion-buttons">
                                    <i class="bi bi-box-arrow-up-right" [routerLink]="'/profile/machine-groups/'+item.materialProfile.idMaterialProfile"></i>
                                    
                                    <i class="bi bi-trash3" (click)="deleteComponentMaterial(item.idProfileComponentMaterial)"></i>
                                    <i class="error-circle bi bi-exclamation-octagon ml-2" *ngIf="profileComponent.profileComponentMaterials[i].valid === false"></i>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-profile-component-material
                                *ngFor="let itemMaterial of profileComponent.profileComponentMaterials[i].materialProfile.materialProfileMeasure|sortBy:'asc':'position'"
                                [materialProfileMeasure]="itemMaterial" [profileComponent]="profileComponent" [(profileComponentMaterial)]="profileComponent.profileComponentMaterials[i]">
                            </app-profile-component-material>
                            <span
                                *ngIf="!item.materialProfile.materialProfileMeasure || item.materialProfile.materialProfileMeasure.length == 0" i18n>{{'sealingType.profile.noDimensionsAvailable'|translate}}</span>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mt-2 d-flex justify-content-between align-items-center select-new-material">
                    <ng-select [items]="materialProfilesExcludeUsed" bindLabel="name" bindValue="idMaterialProfile"
                        class="w-100 mr-2" placeholder="{{'sealingType.profile.material'|translate}}" *ngIf="addMaterialMode"
                        [(ngModel)]="selectedMaterial"></ng-select>
                    <i class="bi bi-check2 ml-3" *ngIf="addMaterialMode"
                        (click)="addProfileComponentMaterial();addMaterialMode = false"></i>
                    <i class="bi bi-x" (click)="addMaterialMode = false;" *ngIf="addMaterialMode"></i>
                </div>
                <app-add-new-button padding="15px 0px 0 0" text="{{'sealingType.profile.addNewMaterial'|translate}}"
                    (onAddClick)="addMaterialMode = true" *ngIf="!addMaterialMode"></app-add-new-button>
            </div>
        </div>
    </div>
</div>