import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';

import { Component, LOCALE_ID, Inject, OnInit, isDevMode, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	currentUser: User;
	private unsubscribe: Subject<void> = new Subject();
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		protected translateService: TranslateService
	) {
		this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.currentUser = x);
	}
	ngOnInit(): void {
		if (!localStorage.getItem('language') && !this.currentUser) {
			this.translateService.setDefaultLang('de');
			localStorage.setItem('language', 'de');
		}
	}

	/**
	 * on destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

}
