import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { ProfileComponentMaterialTemplateLine } from 'src/app/shared/models/profile-component-material-template-line.model';
import { ProfileComponentMaterialTemplate } from 'src/app/shared/models/profile-component-material-template.model';
import { ProfileComponentTemplateService } from 'src/app/shared/services/profile-component-template.service';


import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OuterDimensionTemplatesListMode } from 'src/app/shared/models/outer-dimension-template-mode.enum';
import { RemoveConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation/remove-confirmation-modal.component';
import { ProfileComponentMaterialLine } from 'src/app/shared/models/profile-component-material-line.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation-simple/confirmation-modal.component';

@Component({
  selector: 'app-outer-dimension-templates-list',
  templateUrl: './outer-dimension-templates-list.component.html',
  styleUrls: ['./outer-dimension-templates-list.component.scss'],
})

// Class used to have a list of templates to then apply them to ProfileComponentMaterial
export class OuterDimensionTemplatesListComponent implements OnInit {

  @Input() templatesListMode: OuterDimensionTemplatesListMode;
  @Output() onApplyTemplate = new EventEmitter<ProfileComponentMaterialLine[]>();

  searchText!: string;

  public templates: ProfileComponentMaterialTemplate[] = [];
  private testTemplate: ProfileComponentMaterialTemplate = new ProfileComponentMaterialTemplate();
  private templateLines: ProfileComponentMaterialTemplateLine[] = [];
  validateForms = new EventEmitter<number>();

  public editMode = false;
  public editedTemplateName = '';
  public editName = false;
  public isEditing: boolean[] = new Array(this.templates.length).fill(false);
  public isOpen: boolean[] = new Array(this.templates.length).fill(false);
  public editedTemplateNames: string[] = [];
  OuterDimensionTemplatesListMode = OuterDimensionTemplatesListMode;


  constructor(private profileComponentTemplateService: ProfileComponentTemplateService, private modalService: NgbModal, private toastService: ToastService, public translationsService: TranslateService) { }

  ngOnInit(): void {
    this.profileComponentTemplateService.getAllProfileComponentTemplates().subscribe(data => {
      this.templates = data;
      // Sorts the templates array taking the position property instead of the normal order
      this.templates.sort((a, b) => a.position - b.position);

      // Sorts the lines in order of ID (for some reason its not by default??)
      this.templates.forEach((template) => {
        template.profileComponentMaterialTemplateLines.sort((a, b) => a.idTemplateLine - b.idTemplateLine);
      });

      this.editedTemplateNames = this.templates.map(template => template.name);
    });
  }


  // Toggles the item depending if its being edited
  isAnyTemplateInEditMode(): boolean {
    return this.isEditing.some(value => value === true);
  }


  // Controls the draggable items
  drop(event: CdkDragDrop<string[]>) {

    // If any of the elements is in editMode, we don't allow change positoin.
    //if (this.isEditing[event.previousIndex] || this.isEditing[event.currentIndex]) {
    if (this.isAnyTemplateInEditMode()) {
      this.toastService.show(this.translationsService.instant('codeTranslations.template-position-edit-error'),
        { classname: 'bg-danger text-light', delay: 2000 });
      return;
    }

    moveItemInArray(this.templates, event.previousIndex, event.currentIndex);


    // Puts on the property .position the current position inside the array
    this.templates.forEach((template, index) => {
      template.position = index
    });

    let JSONinsert = this.templates.map(template => ({ idTemplate: template.idTemplate, position: template.position }))

    this.profileComponentTemplateService.updateTemplateOrders(JSONinsert).subscribe();

    this.profileComponentTemplateService.updateTemplateOrders(JSONinsert).subscribe(
      (response) => {
        this.editedTemplateNames = this.templates.map(template => template.name);
      }
    );
  }

  /**
  * Adds profile component material line
  */
  addProfileComponentMaterialLine(i: number) {
    let newLine = new ProfileComponentMaterialTemplateLine();
    newLine.idTemplateFK = this.templates[i].idTemplate;
    this.templates[i].profileComponentMaterialTemplateLines.push(newLine);
  }

  /***
   * Deletes a Mterial Template Line
   * @param templateIndex Index of the template
   * @param lineIndex     Index of the line to delete
   */
  deleteProfileComponentMaterialTemplateLine(templateIndex: number, lineIndex: number) {
    this.templates[templateIndex].profileComponentMaterialTemplateLines.splice(lineIndex, 1);
  }

  /**
   * Profiles component material line change
   * @param order 
   * @param line 
   */
  profileComponentTemplateLineChange(order, line: ProfileComponentMaterialTemplateLine) {
    this.templateLines[order] = line;
    this.validateForms.emit(line.idTemplateLine);
  }

  /**
  * Adds new template
  */
  addNewTemplate() {
    this.reorderPosition();

    let newTemplate = new ProfileComponentMaterialTemplate();
    newTemplate.profileComponentMaterialTemplateLines = [];

    const nextNumber = this.findNextAvailableNumber(this.templates);
    const newTemplateName = `New Template ${nextNumber}`;
    newTemplate.name = newTemplateName;


    this.profileComponentTemplateService.createProfileComponentTemplate(newTemplate).subscribe(

      data => {
        newTemplate.idTemplate = data.idTemplate;
        this.templates.push(newTemplate);

        this.toastService.show(
          this.translationsService.instant('codeTranslations.template-created'),
          { classname: 'bg-success text-light', delay: 2000 }
        );
      },
      error => {
        this.toastService.show(this.translationsService.instant('codeTranslations.template-error-create'),
          { classname: 'bg-danger text-light', delay: 2000 });
      });

  }

  // Reorders the elements depending on the property position, instead of the ordering depending on ID
  reorderPosition() {
    this.templates.forEach(template => {
      template.position++;
    });

    let JSONinsert = this.templates.map(template => ({ idTemplate: template.idTemplate, position: template.position }))
    this.profileComponentTemplateService.updateTemplateOrders(JSONinsert).subscribe();
  }

  // Finds the next number available on templates to use it as title
  findNextAvailableNumber(templates: any[]): number {
    const existingNumbers = this.templates
      .filter(template => /^New Template \d+$/.test(template.name))
      .map(template => parseInt(template.name.match(/\d+/)[0]));

    let nextNumber = 1;
    while (existingNumbers.includes(nextNumber)) {
      nextNumber++;
    }

    return nextNumber;
  }

  /**
  * Deletes a template.
  * @param index 
  */
  deleteProfileComponentMaterialTemplate(index: number) {

    this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
      console.log(result)
      if (result.remove === true) {

        const templateToDelete = this.templates[index];

        this.profileComponentTemplateService.deleteProfileComponentTemplate(templateToDelete.idTemplate)
          .subscribe(
            (response) => {
              this.templates.splice(index, 1);
              this.toastService.show(this.translationsService.instant('codeTranslations.template-deleted'),
                { classname: 'bg-success text-light', delay: 2000 });
            },
            error => {
              this.toastService.show(this.translationsService.instant('codeTranslations.template-error-deleted'),
                { classname: 'bg-danger text-light', delay: 2000 });
            });
      }
    });
  }

  applyTemplate(index: number) {
    if (this.templates[index].profileComponentMaterialTemplateLines.length >= 0) {
      this.modalService.open(ConfirmationModalComponent).result.then((result: any) => {
        if (result.remove === true) {
          let lines: ProfileComponentMaterialLine[] = this.convertToMaterialLines(this.templates[index].profileComponentMaterialTemplateLines)
          this.onApplyTemplate.emit(lines);
          this.modalService.dismissAll();
        }
      }).catch((res) => {});
    }
  }



  // Maps from templateLines to Lines
  convertToMaterialLines(templateLines): ProfileComponentMaterialLine[] {
    return templateLines.map(templateLine => ({
      idProfileComponentMaterialLine: null,
      fromValue: templateLine.fromValue,
      toValue: templateLine.toValue,
      setupUnit: templateLine.setupUnit,
      processingTime: templateLine.processingTime,
      idProfileComponentMaterialFK: null,
      valid: null
    }));
  }



  /**
   * Saves a new template name.
   * @param index 
   */
  saveTemplateName(index: number) {

    this.templates[index].name = this.editedTemplateNames[index];
    this.profileComponentTemplateService.updateProfileComponentTemplate(this.templates[index]).subscribe(
      (response) => {
        this.toastService.show(this.translationsService.instant('codeTranslations.template-updated'),
          { classname: 'bg-success text-light', delay: 2000 });
      },
      error => {
        this.toastService.show(this.translationsService.instant('codeTranslations.template-error-update'),
          { classname: 'bg-danger text-light', delay: 2000 });
      });
    this.editedTemplateNames[index] = this.templates[index].name;
    this.toggleEditMode(index);
  }

  toggleEditMode(i: number) {
    this.isEditing[i] = !this.isEditing[i];
    this.editedTemplateNames[i] = this.templates[i].name;
  }

  // Changes the state of the panel, if its open or not, applies on the line using the Index i
  togglePanel(i: number) {
    this.isOpen[i] = !this.isOpen[i];
  }

}
