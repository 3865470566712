<div class="modal-header">
	<h4 class="modal-title col-lg-10">{{'sealingType.profile.outsideDiameterFormula'| translate: {outputLineTitle: profileMeasureOutputLine.name} }}</h4>
	<button type="button" class="btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
</div>
<div class="modal-body" [formGroup]="tabForm">
	<ng-container formArrayName="tabs">
		<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" (activeIdChange)="onTabChange($event)">
			<li [formGroupName]="i" [ngbNavItem]="i" *ngFor="let tab of tabs; let i = index">
				<a ngbNavLink>Tab {{i+1}}<button class="close-tab" (click)="closeTab($event, i)" *ngIf="!profileOutputOther && tabs.length !== 1"><i
							class="bi bi-x-lg"></i></button></a>
				<ng-template ngbNavContent>
					<div class="col-lg-12 d-flex justify-content-end"  *ngIf="!profileOutputOther">
						<button [disabled]="!unselectAllAvailable" class="select-all-button" (click)="unselectAllMaterials()" >{{'sealingType.profile.unselectAll' | translate}}</button>
						<button [disabled]="!selectAllAvailable" class="select-all-button" (click)="selectAllMaterials()">{{'sealingType.profile.selectAll' | translate}}</button>
					</div>
					<div class="grid col-lg-12 pt-3" *ngIf="!profileOutputOther">
						<ng-container *ngFor="let material of materials let i = index;">
							<app-checkbox (change)="checkMaterial(material, $event)" [checked]="materialIsChecked(material.idMaterialProfile)"
								[readonly]="!materialIsEditable(material.idMaterialProfile)" [disabled]="!materialIsEditable(material.idMaterialProfile)">
								
								<div class="material-name pb-1">{{material.name}}</div>
							</app-checkbox>
						</ng-container>
					</div>
					<ng-container formArrayName="formula">
						<div class="formula-inputs d-flex" [formGroupName]="j"
							*ngFor="let item of getFormulaControls(i); let j = index">
							<div class="col-lg-11 d-flex formula-inputs-values">
								<div class="form-group">
									<label class="text-center" *ngIf="j === 0">
										If:
									</label>
									<input type="text" class="form-control"
										placeholder="{{'sealingType.profile.introduceIfCondition'|translate}}"
										aria-label="Introduce the if condition" formControlName="condition">
								</div>
								<div class="form-group">
									<label class="text-center" *ngIf="j === 0">
										Then:
									</label>
									<input type="text" class="form-control" aria-label="Introduce the formula"
										placeholder="{{'sealingType.profile.introduceTheFormula'|translate}}"
										formControlName="then">
								</div>
							</div>
							<div class="col-lg-1 remove-line">
								<button class="btn" (click)="removeFormulaLine(j)"><i class="bi bi-trash3"></i></button>
							</div>
						</div>
					</ng-container>
					<app-add-new-button class="create-component" padding="5px 15px 15px 15px"
						text="{{'sealingType.profile.addFormulaLine'|translate}}" (onAddClick)="addFormulaLine()">
					</app-add-new-button>
					<div class="factor-input mb-3">
						<div class="col-lg-12">
							<label class="text-center">
								Else:
							</label>
							<input type="text" class="form-control" aria-label="Introduce the formula"
								formControlName="elseFormula"
								placeholder="{{'sealingType.profile.introduceTheFormula'|translate}}">
						</div>
					</div>
					<div class="factor-input mt-3">
						<div class="form-group col-lg-12">
							<label class="text-center">{{'sealingType.profile.factor'|translate}}
							</label>
							<input type="text" class="form-control" formControlName="factor"
								aria-label="Introduce the factor"
								placeholder="{{'sealingType.profile.introduceTheFactor'|translate}}">
						</div>
					</div>


					<div class="example-formula col-lg-12">
						<span>
							<div class="d-flex">
								<p class="font-weight-bold mb-1">{{'sealingType.profile.formExample'|translate}}</p>
							</div>
							<div class="d-flex">
								<p class="font-weight mb-1">If: </p>
								<p class="font-weight-normal mb-0 ml-1">{{'{1} > 20'}}</p>
								<p class="font-weight mb-1 ml-3">Then: </p>
								<p class="font-weight-normal mb-0 ml-1">{{'{1} - 2.0'}}</p>
							</div>
							<div class="d-flex">
								<p class="font-weight mb-1">{{'sealingType.profile.factor'|translate}}: </p>
								<p class="font-weight-normal mb-0 ml-1">{{'* 1.1 + 1.6'}}</p>
							</div>
							<div class="font-weight-normal">
								<p class="font-weight-bold mb-1">{{'sealingType.profile.availableValues'|translate}}</p>
								<div class="d-flex">
									<div class="w-75">
										<div class="d-flex mt-2" [formGroup]="calculatorForm"
											*ngFor="let item of profileMeasureInputLines; let i = index">
											<ng-container formArrayName="calculatorValues">
												<div>
													{{'{'+(item.showOrder+1)+'}'}}
												</div>
												<div class="ml-2 input-name">
													{{item.name}}
												</div>
												<div class="ml-3" [formGroupName]="i">
													<input class="form-control" type="number" formControlName="value"
														(focusout)="testCalculateOutput()">
												</div>
											</ng-container>
										</div>
									</div>
									<div class="d-flex align-items-end justify-content-end flex-column ml-auto">
										<p class="result text-center w-100" *ngIf="calculationResult !== null">=
											{{calculationResult}}</p>
										<app-button class="hse-button calculate-button" [disabled]="!tabForm?.valid"
											(click)="testCalculateOutput()">
											{{'sealingType.profile.calculate'|translate}}</app-button>
									</div>
								</div>
							</div>

						</span>
					</div>
				</ng-template>
			</li>
			<li class="nav-item" *ngIf="!profileOutputOther">
				<button class="nav-link" (click)="addTab()"><i class="bi bi-plus-lg"></i></button>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</ng-container>
</div>
<div class="modal-footer">
		<ng-template #tipContent>
			{{copyFromTypeString}}
			{{'sealingType.profile.copyFromFormula'| translate: {type: copyFromTypeString} }}
		</ng-template>

		<button
			*ngIf="(!displayType && profileMeasureOutputLine?.displayTabs.length > 0) || (displayType && profileMeasureOutputLine?.internTabs?.length > 0)"
			class="btn" [ngbTooltip]="tipContent" (click)="copyFromDisplayFormula()">
			<i class="bi bi-clipboard"></i>
		</button>
		<app-button class="hse-button" [disabled]="!tabForm?.valid || !formulaUnsaved" (click)="connect()">{{'global.save'|translate}}
		</app-button>

</div>