import { trigger, transition, animate, style } from '@angular/animations';
import { OrderSealService } from './../../../../shared/services/order-seal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-seal-wrapper',
  templateUrl: './order-seal-wrapper.component.html',
  styleUrls: ['./order-seal-wrapper.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1})) 
      ]),
    
    ])
  ]
})
export class OrderSealWrapperComponent implements OnInit {

  constructor(private orderSealService: OrderSealService) { }

  ngOnInit(): void {
    
  }

  get step () {
    return this.orderSealService.currentStep;
  }

}
