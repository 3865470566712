import { OrderSealService } from '../../../shared/services/order-seal.service';

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-seal',
  templateUrl: './order-seal.component.html',
  styleUrls: ['./order-seal.component.scss']
})
export class OrderSealComponent implements OnInit {


  constructor(private orderSealService: OrderSealService) { }

  ngOnInit(): void { }

  /**
   * Gets steps for bottom navigation
   */
  get steps() {
    return this.orderSealService.steps;
  }

  /**
   * Gets current step index
   */
  get step() {
    return this.orderSealService.currentStep;
  }

  /**
   * Gets section for section header
   */
  get section() {
    return this.orderSealService.sections[this.orderSealService.currentStep];
  }
}
