import { ProfileComponentMaterialTemplateLine } from './profile-component-material-template-line.model';

export class ProfileComponentMaterialTemplate {
    splice(lineIndex: number, arg1: number) {
      throw new Error('Method not implemented.');
    }
    idTemplate: number;
    name : string;
    position : number;
    profileComponentMaterialTemplateLines: ProfileComponentMaterialTemplateLine[];
}
