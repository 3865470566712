import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { Article } from './../../models/article.model';
import { Offer } from './../../models/offer.model';
import { OrderHistory } from './../../models/order-history.model';
import { Order } from './../../models/order.model';
import { SearchResult } from './../../models/search-result-enum.model';
import { ArticlesService } from './../../services/articles.service';
import { OffersService } from './../../services/offers.service';
import { OrderService } from './../../services/order.service';
import { DateUtils } from './../../utils/date.utils';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @ViewChild('insideElement', { static: false }) insideElement;
  DateUtils = DateUtils;
  faSearch = faSearch;
  public model: any = '';
  public searchStep = 1;
  constructor(
    private articlesService: ArticlesService,
    private offersService: OffersService,
    private ordersService: OrderService,
    private router: Router
  ) { }
  articlesResult = of([]);
  offersResult = of([]);
  ordersResult = of([]);
  ordersByArticleIdResult = of([]);
  offersByArticleIdResult = of([]);
  showAllOffersByArticleId = false;
  showAllOrdersByArticleId = false;
  showAllArticles = false;
  openSearch = false;
  lastSearch = '';
  searchedArticle: Article = null;
  public timeout: any = null;
  /**
   * Opens search window
   */
  openSearchWindow() {
    this.resetShowMore();
    if (this.model !== '' && this.model !== null) {
      this.openSearch = true;
      // if (this.lastSearch !== this.model) {
      //   this.articlesSearch(this.model);
      // }
    }
  }

  resetShowMore() {
    this.showAllOffersByArticleId = false;
    this.showAllOrdersByArticleId = false;
    this.showAllArticles = false;
  }

  /**
   * Articles search
   * @param text text to search
   */
  articlesSearch(text) {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.resetShowMore();
      text = text.trim();
      if (text !== null && text !== '') {
        this.openSearch = true;
        this.articlesResult = this.articlesService.getArticles(-1, -1, text) as Observable<Article[]>;
        this.offersResult = this.offersService.searchOffersById(text) as Observable<Offer[]>;
        this.ordersResult = this.ordersService.searchOrdersById(text) as Observable<Order[]>;
      } else {
        this.openSearch = false;
        this.articlesResult = of([]);
        this.offersResult = of([]);
        this.ordersResult = of([]);
      }
    }, 500);
  }
  /**
   * Searchs offers and orders by article id
   * @param article Article
   */
  searchOffersAndOrdersByArticleId(article: Article) {
    this.lastSearch = this.model;
    this.searchedArticle = article;
    this.model = article.idArticleERP;
    this.openSearch = true;
    this.searchStep = 2;
    this.offersByArticleIdResult = this.offersService.getOffers(-1, -1, article.idArticleERP) as Observable<Offer[]>;
    this.ordersByArticleIdResult = this.ordersService.getOrders(-1, -1, article.idArticleERP) as Observable<Order[]>;
    this.resetShowMore();
  }

  /**
   * On click outside search area closes the search window
   */
  protected onDocumentClick(event) {
    if (this.insideElement.nativeElement.contains(event.target)
      || event.target.className === 'show-more'
      || event.target.className === 'search-in-orders'
      || event.target.className === 'return-button'
      || event.target.className === 'control-icon'
    ) {
      return;
    }
    this.openSearch = false;
    this.searchStep = 1;
  }

  /**
   * Opens articles page with the selected article in search
   * @param article Article
   * @param event $event
   */
  openArticle(article: Article, event) {
    const className = event.target.className;
    if (className !== 'search-in-orders') {
      this.model = '';
      this.saveToLocalStorage(SearchResult.SearchResultArticles, JSON.stringify(article));
      this.router.navigate(['/articles', { showSearch: true, articleId: article.idArticleERP }]);
      this.openSearch = false;
      this.showAllOffersByArticleId = false;
      this.showAllOrdersByArticleId = false;
      this.showAllArticles = false;
    }
  }

  /**
   * Opens offers page with the selected offer in search
   * @param offer Offer
   */
  openOffer(offer: Offer, complete = true) {
    this.model = '';
    this.saveToLocalStorage(SearchResult.SearchResultOffers, JSON.stringify(offer));
    this.router.navigate(['/offers', { showSearch: true, offerId: offer.idOfferERP, complete }]);
    this.openSearch = false;
    this.resetShowMore();
  }

  /**
   * Opens orders page with the selected order in search
   * @param order OrderHistory
   */
  openOrder(order: OrderHistory, complete = true) {
    this.model = '';
    this.saveToLocalStorage(SearchResult.SearchResultOrders, JSON.stringify(order));
    this.router.navigate(['/orders', { showSearch: true, orderId: order.idOrderERP, complete }]);
    this.openSearch = false;
    this.resetShowMore();
  }

  /**
   * Saves search result to the local storage
   * @param type SearchResult
   * @param object Article | Offer | OrderHistory
   */
  saveToLocalStorage(type: SearchResult, object: any) {
    localStorage.setItem(type, object);
  }

  ngOnInit(): void {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('click', this.onDocumentClick);
  }
}
