<div class="modal-header">
    <h4 class="modal-title">{{profileMeasureInputLines[itemValueIndex].name}}</h4>
    <button type="button" class="btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i
            class="bi bi-x-lg"></i></button>
</div>

<div class="modal-body">
    <div class="form-group">
      <label class="col-lg-12 text-center">
        {{ 'sealingType.profile.formula' | translate }}
      </label>
      
      <!-- Use *ngFor to iterate through formulaForms -->
      <ng-container *ngFor="let formulaControl of formulaForm.controls; let i = index">
        <div class="d-flex form-input">

            <input  type="text" class="col-lg-12 form-control" [formControl]="formulaControl" [attr.aria-label]="'Introduce the formula ' + (i + 1)"> 
            
            <i class="bi bi-trash3 trashcan" (click)="removeFormulaForm(i)"  [ngbTooltip]="'global.delete' | translate"></i>

        </div>
      </ng-container>
      
      <app-add-new-button padding="10px 0 0 0" text="{{ 'sealingType.addLine' | translate }}" (onAddClick)="addFormulaForm()"></app-add-new-button>
      
    </div>
  
    <p class="example-formula">
      <span>
        <div class="d-flex">
          <p class="font-weight-bold mb-1">{{ 'sealingType.profile.exampleFormula' | translate }}:</p>
          <p class="font-weight-normal mb-0 ml-1">{{ '{1}<={2}  or  {1}>{3}' }}</p>
        </div>
        <p class="font-weight-normal">
          {{ 'sealingType.profile.rowValuesFormula' | translate }}
        </p>
      </span>
    </p>
  </div>
  

<div class="modal-footer">
    <app-button class="hse-button" (click)="activeModal.dismiss()"
        [disabled]="!this.profileMeasureInputLines[this.itemValueIndex].formula">
        {{'sealingType.profile.erase'|translate}}</app-button>
    <app-button class="hse-button" (click)="connect()"
        [disabled]="!formulaForm.valid">{{'sealingType.profile.link'|translate}}</app-button>
</div>