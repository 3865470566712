import { CreateUserRequest } from './../models/create-user-request.model';
import { User } from './../models/user.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PasswordResetRequest } from './../models/password-reset-request.model';
import { ResetPassword } from './../models/reset-password.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;
  private userId: string;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }
  /**
   * Resets password
   * @param data ResetPassword
   * @returns Observable
   */
  resetPassword(data: ResetPassword) {
    return this.http.post(`${this.apiUrl}/password/reset-password`, data);
  }

  /**
   * Resets password of logged user
   * @param data ResetPassword
   * @returns Observable
   */
  resetPasswordLoggedUser(data: ResetPassword) {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${this.apiUrl}/password/reset-password-logged/${currentUser.id}`, data);
  }

  /**
   * Request password reset
   * @param data PasswordResetRequest
   * @returns Observable
   */
  resetPasswordRequest(data: PasswordResetRequest) {
    return this.http.post(`${this.apiUrl}/password/reset-password-request`, data);
  }

  /**
   * Validates token
   * @param token token
   * @returns Observable
   */
  validateToken(token: string) {
    return this.http.get(`${this.apiUrl}/password/validate-token/${token}`);
  }
  /**
   * Creates a new user or updates existing user
   * @param data CreateUserRequest
   * @returns Observable
   */
  createUser(data: CreateUserRequest) {
    return this.http.post(`${this.apiUrl}/users/add-user`, data);
  }
}
