<app-section-header section="offers"></app-section-header>
<div class="offers">
	<div class="container-fluid mt-3 mb-3 d-flex justify-content-between">
		<app-switch-button class="show-deactivated" [value]="showSalesEngineOnly" [disabled]="false"
			leftText="{{'offers.showAll'|translate}}" (valueChanged)="onSwitchShowSalesEngineOnlyChanged($event)"
			rightText="{{'offers.onlyConfigurator'|translate}}" width="200px">
		</app-switch-button>
		<app-articles-filter (formGroupChange)="onSearch($event)" [searchFormControl]="searchFormControl"
			placeholder="{{'offers.filter'|translate}}"></app-articles-filter>

	</div>
	<div class="container-fluid">
		<div class="offers-content" *ngIf="offers && offers.length > 0">
			<div class="offer-toggle" *ngFor="let offer of offers">
				<app-offer-row [offer]="offer" (offerOpened)="offerOpened($event)" *ngIf="offers.length == 1">
				</app-offer-row>
				<app-offer-row [offer]="offer" *ngIf="offers.length > 1"></app-offer-row>
				<div *ngIf="showLoadFullOffer && offers[0].offerArticles.length <= 1 && !showLoadFullOfferClicked"
					class="se-table-row load-full-offer-row" [ngClass]="{'opened': singleArticleOfferOpened}">
					<div class="se-table-col lg text-center">
						<div class="load-full-offer-content d-flex align-items-center">
							<img src="/assets/img/icons/dots.svg" alt="dots">
							<button class="load-full-offer" i18n (click)="getFullOffer()">{{'offers.loadCompleteOrder'|translate}} <img
									src="/assets/img/icons/reload.svg" alt="load-full"> </button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="offers.length == 0 && !loading" class="se-table-row no-results">
			<div class="se-table-col lg text-center" i18n>{{'offers.noItemsToShow'|translate}}</div>
		</div>
		<div *ngIf="loading" class="loading mt-1">
			<app-loading-spinner></app-loading-spinner>
		</div>
	</div>
	<app-button class="load-more m-auto" *ngIf="!showSearch" (click)="loadMore()"
		[disabled]="loadMoreAvailable === false || loading == true"
		[ngClass]="{'inactive': loadMoreAvailable === false || loading == true}" label="{{'offers.loadMore'|translate}}" i18n></app-button>
	<app-cart-navigation [step]="0"></app-cart-navigation>
</div>