import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent implements OnInit {
  @Input() step = 0;
  @Input() disableBack = false;
  @Input() disableForward = false;
  @Input() steps = [];
  constructor() { }

  ngOnInit(): void {
  }

}
