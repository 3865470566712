import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private translationsService: TranslateService,
    private toastService: ToastService
  ) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Gets offer pdf
   * @param idOfferERP idOfferERP
   * @returns Observable
   */
  getOfferPdf(idOfferERP: string) {
    return this.http.get(`${this.apiUrl}/pdf/${this.userId}/offers/${idOfferERP}/`, { responseType: 'blob' }).pipe(
      catchError(error => {
        console.error('There was an error in the receiving the pdf, please contact Haenssler.');
        this.showPdfNotAvailable();
        return of({});
      }));
  }

  /**
   * Gets order pdf
   * @param idOrderERP idOrderERP
   * @returns Observable
   */
  getOrderPdf(idOrderERP: string) {
    return this.http.get(`${this.apiUrl}/pdf/${this.userId}/orders/${idOrderERP}/`, { responseType: 'blob' }).pipe(
      catchError(error => {
        console.error('There was an error in the receiving the pdf, please contact Haenssler.');
        this.showPdfNotAvailable();
        return of({});
      }));
  }

  /**
   * Gets article pdf
   * @param idArticleErp idArticleErp
   * @returns Observable
   */
  getArticlePdf(idArticleErp: string) {
    return this.http.get(`${this.apiUrl}/pdf/${this.userId}/articles/${idArticleErp}/`, { responseType: 'blob' }).pipe(
      catchError(error => {
        console.error('There was an error in the receiving the pdf, please contact Haenssler.');
        this.showPdfNotAvailable();
        return of({});
      }));
  }

  /**
   * Shows pdf not available
   */
  showPdfNotAvailable() {
    this.toastService.show(this.translationsService.instant('codeTranslations.pdf-not-available'),
      { classname: 'bg-danger text-light', delay: 3000 });
  }
}
