<div class="se-table-row">
    <div class="se-table-col sm position-col d-none d-md-block">
        {{ article.articleType == ArticleType.ARTICLE ? index + 1 : article.position }}</div>
    <div class="se-table-col lg">
        <div class="article-id">
            <span class="bold" *ngIf="showId">{{article.idArticleERP}}</span>
            <span class="bold" *ngIf="showId && article.idArticleCustomer"> / </span>
            <span class="bold" *ngIf="article.idArticleCustomer"> {{article.idArticleCustomer}}</span>
            <span *ngIf="article.pdf && article.pdf !== ''" class="download-article-pdf">
                <img src="assets/img/icons/download.svg" alt="download" [ngbTooltip]="'Download PDF'" (click)="downloadPdf(article.idArticleERP)">
            </span>
            <span *ngIf="isCartMode" class="upload-article-file">

                <app-file-upload-popup [id]="cartArticle.idCartArticle" [resource]="'cart-article'" acceptFileType="image/tiff,.tif,.stp,.pdf"></app-file-upload-popup>
                
            </span>
        </div>
        <p class="article-description" *ngIf="article.descriptionERP !== ''"> {{article.descriptionERP}}</p>
        <p class="article-description" *ngIf="article.descriptionERP == '' && article.descriptionCustomer !== ''">
            {{article.descriptionCustomer}}</p>
    </div>
    <div class="se-table-col d-none d-md-block md se-row se-row-inputs" *ngIf="article.articleType === ArticleType.FRAMEWORK_CONTRACT && !isCartMode"> 
        <app-contract-info [article]="article"></app-contract-info> 
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row se-row-inputs">
        <ng-template #tipContent>{{tooltipMessage}} <br> {{'articlesList.takeQuantity' | translate}}<div
                class="suggestions d-flex justify-content-center" i18n>
                <button class="suggestions-button"
                    *ngIf="this.articleDatePrice.amountSuggestion !== 0;else stockButtons"
                    (click)="setRequestQueryAmount(article.idArticleERP, this.articleDatePrice.amountSuggestion); articleQuantityFromControl=this.articleDatePrice.amountSuggestion;">{{this.articleDatePrice.amountSuggestion}}</button>
                <ng-template #stockButtons>
                    <button class="suggestions-button" *ngIf="showAvailableMaterial && materialInStock"
                        (click)="setRequestQueryAmount(article.idArticleERP, materialInStock); articleQuantityFromControl=materialInStock;">{{materialInStock}}</button>
                    <button class="suggestions-button" *ngIf="articlesInStock"
                        (click)="setRequestQueryAmount(article.idArticleERP, articlesInStock); articleQuantityFromControl=articlesInStock;">{{articlesInStock}}</button>
                </ng-template>
            </div>
        </ng-template>
        <input type="text" name="quantity" class="form-control se-input quantity-input" placeholder="{{'articlesList.amount' | translate}}"
            [ngbTooltip]="tipContent" #myTip="ngbTooltip" triggers="manual" [(ngModel)]="articleQuantityFromControl"
            (ngModelChange)="setRequestQueryAmount(article.idArticleERP, $event)" appNumbersOnly maxlength="9"
            tooltip-placement="top">
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row se-row-inputs">
        <app-datepicker class="datepicker-input" (selectedDate)="setRequestQueryDate(article.idArticleERP, $event)"
            [defaultDate]="defaultDate" [dateReset]="dateReset" [setDate]="setNewDate">
        </app-datepicker>
    </div>
    <div class="se-table-col d-none d-sm-flex md align-items-center">
        <app-scaled-prices-table [article]="article" [currentScaledPrice]="currentScaledPrice"></app-scaled-prices-table>
    </div>
    <div class="se-table-col d-none d-sm-flex md align-items-center">
        <div class="price-info d-flex">
            <ng-template #priceTipContent>
                <span i18n *ngIf="articleDatePrice.price">{{'articlesList.minimOrderValue' | translate}} {{articleDatePrice.price}}
                    Euro</span>
            </ng-template>
            <app-price-label [ngbTooltip]="priceTipContent" triggers="manual" #myPriceTip="ngbTooltip"
                [articleDatePrice]="articleDatePrice" [processing]="processing" [articlesListMode]="articlesListMode"
                [priceChanged]="priceChanged" (priceAccept)="confirmPrice()"
                *ngIf="ArticlesListMode.CART_UNVALIDATED !== this.articlesListMode">
            </app-price-label>
            <app-calculation-process *ngIf="articleDatePrice && articleDatePrice.capacityPriceCalcAnalyticsMap"
                [capacityPriceCalcAnalyticsMap]="articleDatePrice.capacityPriceCalcAnalyticsMap">
            </app-calculation-process>
        </div>
    </div>
    <div class="se-table-col" [ngClass]="{'sm': isCartMode,'remove-button-col': isCartMode, 'md': isArticlesMode}">
        <div class="d-none d-sm-block se-row" *ngIf="isArticlesMode">
            <app-button class="d-none d-md-block" label="{{'articlesList.inShoppingCart'|translate}}" height="45px"
                (onClick)="addOrRemoveArticle()" [disabled]="buttonDisabled">
            </app-button>
            <app-button class="d-block d-md-none" backgroundImage="/assets/img/icons/cart.png"
                (onClick)="addOrRemoveArticle()" width="45px" height="45px" [disabled]="buttonDisabled">
            </app-button>
        </div>
        <div class="d-none d-sm-block se-row remove-button-row" *ngIf="isCartMode">
            <app-button class="d-block ml-auto remove-button" backgroundImage="/assets/img/icons/remove.svg"
                (onClick)="addOrRemoveArticle()" width="45px" height="45px" [disabled]="buttonDisabled">
            </app-button>
        </div>
        <div class="d-block d-sm-none">
            <button type="button" class="btn mobile-collapse-button d-block ml-auto"
                (click)="mobileCollapse = !mobileCollapse" [attr.aria-expanded]="!mobileCollapse"
                aria-controls="mobileCollapse">
                <img class="icon-open" src="assets/img/icons/chevron-right.svg" alt="open prices"
                    *ngIf="mobileCollapse">
                <img class="icon-close" src="assets/img/icons/chevron-down.svg" alt="close prices"
                    *ngIf="!mobileCollapse">
            </button>
        </div>
    </div>
    <div class="break"></div>
    <div [ngbCollapse]="mobileCollapse" class="se-table-col md mobile-calculate-price d-sm-none">
        <div class="mobile-col ml-auto">
            <input type="text" name="quantity" class="form-control se-input quantity-input w-100"
                placeholder="Stückzahl" [(ngModel)]="articleQuantityFromControl"
                (ngModelChange)="setRequestQueryAmount(article.idArticleERP, $event)" appNumbersOnly maxlength="9">
            <app-datepicker class="datepicker-input w-100" [defaultDate]="defaultDate" [dateReset]="dateReset"
                (selectedDate)="setRequestQueryDate(article.idArticleERP, $event)" [customColors]="datepickerColors"
                (selectedMonth)="monthSelectionChange($event)">
            </app-datepicker>
            <app-price-label class="mb-4" [articleDatePrice]="articleDatePrice" [processing]="processing"
                [articlesListMode]="articlesListMode" [priceChanged]="priceChanged" (priceAccept)="confirmPrice()"
                *ngIf="isCartMode && !cartArticle.valid">
            </app-price-label>
            <app-button class="d-block w-100" [label]="cartArticleInCart ? 'Löschen' : 'In den Warenkorb'"
                (onClick)="addOrRemoveArticle()" [disabled]="buttonDisabled">
            </app-button>
        </div>
    </div>
    <div class="diagram-section d-sm-table-row d-none" [class.opened]="!diagramCollapse">
        <!--<div class="diagramic-section d-sm-table-row d-none" [class.opened]="!diagramCollapse">-->
        <div class="content">
            <!--<app-diagramic *ngIf=" !diagramCollapse" @fade></app-diagram>-->
            <app-diagram [article]="article" (dateChanged)="onCapacityPriceChanged($event)" (currentScaledPrice)="currentScaledPrice=$event"
                (articleInStock)="articleInStockReceived($event);" [requestQueryChanges]="requestArticleChange" @fade
                *ngIf="requestQuery && requestQuery.date && requestQuery.amount">
            </app-diagram>
        </div>
        <div class="image-wrapper">
            <img class="open-diagram-button" (click)="diagramCollapse = !diagramCollapse"
                src="assets/img/icons/chevron-{{diagramCollapse ? 'down' : 'up'}}.svg" alt="open-diagram"
                *ngIf="articleDatePrice !== null && articleDatePrice.priceStatus !== CapacityPriceStatus.UNVALIDATED"
                @fade>
        </div>
    </div>
</div>
