<app-dashboard-images></app-dashboard-images>
<div class="intro">
  <div class="container">
    <h1 class="intro__title">{{'dashboard.shopTitle'|translate}}</h1>
    <p class="intro__description" innerHTML="{{'dashboard.shopDescription'|translate}}"></p>
  </div>
</div>
<div class="how-it-works">
  <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
    <div class="how-it-works__text" fxFlex="40" fxFlex.lt-md="100">
      <h2 class="how-it-works__title underlined"> {{'dashboard.howItWorks'|translate}}</h2>
      <p class="how-it-works__description"> {{'dashboard.howItWorksDescription'|translate}} </p>
      <p class="how-it-works__description"> {{'dashboard.howItWorksDescription2'|translate}}
      </p>
    </div>
    <div class="how-it-works__video" fxFlex="50" fxFlex.lt-md="100">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IhC9neGNdIY" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</div>
<div class="advantages">
  <div class="container">
    <h2 class="underlined"> {{'dashboard.advantages'|translate}} </h2>
    <div class="advantages-content" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between"
      fxLayoutAlign.lt-lg="space-around" fxLayoutAlign.lt-md="center center">
      <div class="advantages-card" fxFlex="32">
        <img class="advantages-card__image" src="/assets/img/dashboard-images/advantage-first.png"
          alt="stores-advantage">
        <div class="advantages-card__body">
          <h3 class="advantages-card__title">{{'dashboard.advantageTitle1'|translate}} </h3>
          <div class="advantages-card__subtitle">{{'dashboard.advantageSubtitle1'|translate}}</div>
          <div class="advantages-card__text">{{'dashboard.advantageDescription1'|translate}} </div>
        </div>
      </div>
      <div class="advantages-card" fxFlex="32">
        <img class="advantages-card__image" src="/assets/img/dashboard-images/advantage-second.png"
          alt="stores-advantage">
        <div class="advantages-card__body">
          <h3 class="advantages-card__title">{{'dashboard.advantageTitle2'|translate}} </h3>
          <div class="advantages-card__subtitle">{{'dashboard.advantageSubtitle2'|translate}}</div>
          <div class="advantages-card__text">{{'dashboard.advantageDescription2'|translate}}</div>
        </div>
      </div>
      <div class="advantages-card" fxFlex="32">
        <img class="advantages-card__image" src="/assets/img/dashboard-images/advantage-third.png"
          alt="stores-advantage">
        <div class="advantages-card__body">
          <h3 class="advantages-card__title">{{'dashboard.advantageTitle3'|translate}}</h3>
          <div class="advantages-card__subtitle">{{'dashboard.advantageSubtitle3'|translate}}</div>
          <div class="advantages-card__text">{{'dashboard.advantageDescription3'|translate}} </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="qualities">
  <div class="container" fxLayout="row wrap" fxLayoutAlign="space-evenly start">
    <div class="quality-item" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="200px">
      <div class="quality-item__image-wrapper">
        <img src="/assets/img/dashboard-images/production-quality.png" alt="construction-quality"
          class="quality-item__image">
      </div>
      <h4 class="quality-item__title" innerHTML="{{'dashboard.qualityTitle1'|translate}}"> </h4>
      <p class="quality-item__description" innerHTML="{{'dashboard.qualityDescription1'|translate}}"></p>
    </div>
    <div class="quality-item" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="200px">
      <div class="quality-item__image-wrapper">
        <img src="/assets/img/dashboard-images/quality-control.png" alt="construction-quality"
          class="quality-item__image">
      </div>
      <h4 class="quality-item__title" innerHTML="{{'dashboard.qualityTitle2'|translate}}"> </h4>
      <p class="quality-item__description" innerHTML="{{'dashboard.qualityDescription2'|translate}}"></p>
    </div>
    <div class="quality-item" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="200px">
      <div class="quality-item__image-wrapper">
        <img src="/assets/img/dashboard-images/traceability.png" alt="construction-quality" class="quality-item__image">
      </div>
      <h4 class="quality-item__title" innerHTML="{{'dashboard.qualityTitle3'|translate}}"> </h4>
      <p class="quality-item__description" innerHTML="{{'dashboard.qualityDescription3'|translate}}"></p>
    </div>
    <div class="quality-item" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="200px">
      <div class="quality-item__image-wrapper">
        <img src="/assets/img/dashboard-images/construction-quality.png" alt="construction-quality"
          class="quality-item__image">
      </div>
      <h4 class="quality-item__title" innerHTML="{{'dashboard.qualityTitle4'|translate}}"> </h4>
      <p class="quality-item__description" innerHTML="{{'dashboard.qualityDescription4'|translate}}"></p>
    </div>
    <div class="quality-item" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="200px">
      <div class="quality-item__image-wrapper">
        <img src="/assets/img/dashboard-images/partnerships.png" alt="construction-quality" class="quality-item__image">
      </div>
      <h4 class="quality-item__title" innerHTML="{{'dashboard.qualityTitle5'|translate}}"> </h4>
      <p class="quality-item__description" innerHTML="{{'dashboard.qualityDescription5'|translate}}"></p>
    </div>
  </div>
</div>