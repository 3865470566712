import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Uploads file to the storage
   * @param formData form data of the file
   * @param idCartArticle related article
   * @returns Observable
   */
  public upload(formData, resource: string, id: string) {
    return this.http.post<any>(this.apiUrl + '/file/upload/' + resource + '/' + this.userId + '/' + id, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  /**
   * Lists files of the article in cart
   * @param idCartArticle related article
   * @returns Observable
   */
  public listFiles(resource: string, id: string) {
    return this.http.get<any>(this.apiUrl + '/file/list/' + resource + '/' + this.userId + '/' + id);
  }

  /**
   * Gets image for file uploader
   * @param resource 
   * @param id 
   * @returns  
   */
  public getImage(resource: string, id: string) {
    return this.apiUrl + '/file/image/' + resource + '/' + this.userId + '/' + id;
  }

  /**
   * Removes file from the storage and db
   * @param filename filename of the file to remove
   * @returns Observable
   */
  public removeFile(resource: string, filename: string) {
    return this.http.get<any>(this.apiUrl + '/file/remove/' + resource + '/' + this.userId + '/' + filename);
  }
}
