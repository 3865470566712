<div class="d-flex align-items-center justify-content-between">
    <div class="material-info d-flex align-items-center">
        <i class="bi bi-chevron-right mt-1" [ngClass]="{'opened': opened}"></i>
        <h5 class="m-0 ml-3 mr-2" *ngIf="!editMode">{{sealingType.name}}
           
            <i class="error-circle bi bi-exclamation-octagon ml-3" *ngIf="sealingType.valid === false"></i>
        </h5>
        <app-file-upload-popup *ngIf="sealingType.idSealingType && !editMode" [id]="sealingType.idSealingType" [resource]="'sealing-type'" acceptFileType="image/svg+xml" [imageMode]="true"></app-file-upload-popup>
        <div class="d-flex name-edit ml-3 align-items-center" *ngIf="editMode">
            <input class="form-control" type="text" [(ngModel)]="editName" placeholder="Name">
            <i class="bi bi-check2 ml-3" (click)="updateName()"></i><i class="bi bi-x" (click)="cancelEdit();editName=sealingType.name"></i>
        </div>
        
    </div>
    <div class="control-buttons">
        <i class="bi bi-pencil mt-1 mr-2" (click)="editMode = !editMode"></i>
        <i class="bi bi-trash3 mt-1" (click)="deleteSealingType()"></i>
    </div>
</div>