<div class="profile-component-material-container border-bottom-red">
    <div>
        <ngb-accordion #acc="ngbAccordion" class="profile-component" [activeIds]="invalidIds">
            <ngb-panel id="measure-{{materialProfileMeasure.idMaterialProfileMeasure}}">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="d-flex">
                        <div class="d-flex align-items-center inner-header-content">
                            <button ngbPanelToggle class="btn btn-link p-0 toggle-invisible-button">
                            </button>
                            <span>{{'sealingType.profile.profileMeasure'|translate}}
                                {{materialProfileMeasure.fromValue}}mm-{{materialProfileMeasure.toValue}}mm</span>
                            <span i18n class="error-message"
                                *ngIf="materialProfileMeasureInvalid">{{'sealingType.profile.materialProfileMeasureInvalid'|translate}}</span>
                            <i class="bi bi-chevron-right mt-1 ml-auto" [ngClass]="{'opened': opened}"></i>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <!-- table headers -->
                    <div *ngIf="profileComponentMaterialLines.length > 0" class="row table-headers">
                        <div class="col-lg-5">
                            <div class="card-item-label">
                                {{'sealingType.profile.profileMeasure'|translate}} (mm)
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card-item-label">
                                {{'sealingType.profile.setupUnit'|translate}} (RE)
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-item-label">
                                {{'sealingType.profile.processingTime'|translate}}
                                {{'sealingType.profile.processingTimeUnits'|translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadingTemplate" class="loading-template">
                        <div class="se-table-col lg" i18n>
                            <app-loading-spinner></app-loading-spinner>
                        </div>
                    </div>
                    <!-- table rows -->
                    <app-profile-component-material-line
                        *ngFor="let item of profileComponentMaterialLines; let i = index" 
                        (profileComponentMaterialLineChange)="profileComponentMaterialLineChange(i, $event)"
                        [profileComponentMaterialLine]="profileComponentMaterialLines[i]"
                        [materialProfileMeasure]="materialProfileMeasure"
                        [profileComponentMaterialLines]="profileComponentMaterialLines"
                        [profileComponentMaterial]="profileComponentMaterial"
                        (onDelete)="deleteProfileComponentMaterialLine(i)">
                    </app-profile-component-material-line>

                    <app-add-new-button padding="10px 0" align="left"
                        text="{{'sealingType.profile.addNewRow'|translate}}"
                        (onAddClick)="addProfileComponentMaterialLine()"></app-add-new-button>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="configure-template-icon">
        <i class="bi bi-clipboard2-check" (click)="openTemplateModal()" [ngbTooltip]="'Templates'"></i>
    </div>
</div>