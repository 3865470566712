import { Profile } from '../../../../../../shared/models/profile.model';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileMeasureOutputLine } from '../../../../../../shared/models/profile-measure-output-line.model';

@Component({
  selector: 'app-profile-link-image--outputs-dialog',
  templateUrl: './profile-link-image-outputs-dialog.component.html',
  styleUrls: ['./profile-link-image-outputs-dialog.component.scss']
})
export class ProfileLinkImageOutputsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() image;
  @Input() profile: Profile;
  @Input() profileMeasureOutputLines: ProfileMeasureOutputLine[];
  @Input() itemValueIndex: number;
  @Input() otherItem: boolean;
  @Output() itemUpdated = new EventEmitter();
  @ViewChild('svg') svgEl;
  assignedIds = [];
  constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient, private renderer: Renderer2) { }

  ngOnInit(): void {
    // get svg from api
    this.httpClient.get(this.image, { responseType: 'text' }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      // set svg to div element
      this.renderer.setProperty(this.svgEl.nativeElement, 'innerHTML', data);
      this.handleSvgOutputs();
    });
    for (let profileComponent of this.profile.profileComponents) { this.mapOutputsToAssignedIds(profileComponent.profileMeasureOutputLines); }
    this.mapOutputsToAssignedIds(this.profile.profileMeasureOutputOtherLines);
  }

  /**
   * Maps outputs to assigned ids
   * @param profileMeasureOutputLines 
   */
  mapOutputsToAssignedIds(profileMeasureOutputLines: ProfileMeasureOutputLine[]) {
    profileMeasureOutputLines.forEach(profileMeasureOutputLine => {
      if (profileMeasureOutputLine.idSvgOutput) {
        this.assignedIds.push(profileMeasureOutputLine.idSvgOutput)
      }
    })
  }

  /**
   * Iterates over all svg inputs and creates labels for them, adds event listeners and sets correct styles
   */
  handleSvgOutputs() {
    // remove all previous event listeners from inputs
    this.svgEl.nativeElement.childNodes[2].replaceWith(this.svgEl.nativeElement.childNodes[2].cloneNode(true));
    // get all input elements in svg
    const inputs = this.svgEl.nativeElement.childNodes[2].querySelectorAll('input');
    // loop through all input elements
    for (let i = 0; i < inputs.length; i++) {
      // get profile item if its already assigned to input
      const profileMeasureOutputLine = this.profileMeasureOutputLines.find(x => x.idSvgOutput === inputs[i].parentElement.id);
      // set default properties to input and assign the dimension name if assigned
      inputs[i].value = "";
      inputs[i].readOnly = true;
      inputs[i].disabled = true;
      if (inputs[i].parentElement.querySelector('label')) {
        inputs[i].parentElement.removeChild(inputs[i].parentElement.querySelector('label'))
      }
      // if dimension is not assigned add event listener to input to assign it on click
      if (!profileMeasureOutputLine) {
        if (inputs[i].parentElement.getAttribute('readonly') && !this.assignedIds.includes(inputs[i].parentElement.id)) {
          inputs[i].disabled = false;
          inputs[i].style.borderColor = "#000";
          inputs[i].addEventListener('click', (e) => {
            // assign input to dimension
            this.profileMeasureOutputLines[this.itemValueIndex].idSvgOutput = e.target.parentElement.id;
            this.itemUpdated.emit(this.profileMeasureOutputLines[this.itemValueIndex]);
            this.activeModal.close();
          });
        } else {
          inputs[i].disabled = true;
          inputs[i].style.cursor = 'not-allowed';
          inputs[i].style.borderColor = "#999";
          inputs[i].style.backgroundColor = "#bfbfbf";
        }
      }
      else {
        const label = document.createElement('label');
        label.style.position = 'absolute'; label.style.bottom = '-15px'; label.style.fontSize = '10px'; label.style.width = 'max-content';
        inputs[i].parentElement.style.overflow = 'visible'; label.innerHTML = `${profileMeasureOutputLine.name}`;
        inputs[i].parentElement.prepend(label);
      }
      if (profileMeasureOutputLine && profileMeasureOutputLine.idProfileMeasureOutputLine === this.profileMeasureOutputLines[this.itemValueIndex].idProfileMeasureOutputLine) {
        // add cross icon
        const el = this.renderer.createElement('i');
        el.classList.add('bi', 'bi-x-lg');
        inputs[i].style.borderColor = "#00d47c";
        inputs[i].parentElement.appendChild(el);
        // add event listener on click on remove cross icon
        el.addEventListener('click', (e) => {
          this.profileMeasureOutputLines[this.itemValueIndex].idSvgOutput = null;
          this.itemUpdated.emit(this.profileMeasureOutputLines[this.itemValueIndex]);
          inputs[i].parentElement.removeChild(el);
          this.assignedIds.splice(this.assignedIds.indexOf(inputs[i].parentElement.id), 1);
          this.handleSvgOutputs();
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
