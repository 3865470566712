import { Pipe, PipeTransform } from '@angular/core';
import { ProfileComponentMaterialTemplate } from 'src/app/shared/models/profile-component-material-template.model';

@Pipe({
  name: 'filterTemplates'
})
export class FilterTemplatesPipe implements PipeTransform {
  transform(templates: ProfileComponentMaterialTemplate[], searchText: string): ProfileComponentMaterialTemplate[] {
    if (!templates) return [];
    if (!searchText) return templates;

    const searchTextLower = searchText.toLowerCase();
    return templates.filter(template => template.name.toLowerCase().includes(searchTextLower));
  }
}
