import { orderBy } from 'lodash';
import { Profile } from './../models/profile.model';
import { SealingTypesService } from './sealing-types.service';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileValidationService {
  sealingTypes = [];
  sealingTypesUpdated: EventEmitter<any> = new EventEmitter();
  sealingTypesRevalidated: EventEmitter<any> = new EventEmitter();
  constructor(private sealingTypesService: SealingTypesService) { }

  updateValidations() {
    this.loadSealingTypes();
  }
  setSealingTypes(sealingTypes) {
    this.sealingTypes = sealingTypes;
  }

  loadSealingTypes() {
    this.sealingTypesService.getSealingTypes().subscribe(data => {
      this.sealingTypes = data;
      this.sealingTypesUpdated.emit(this.sealingTypes)
    });
  }

  /**
   * Gets profile component valid value 
   * @param idProfileComponent 
   * @returns  
   */
  getProfileComponentValid(idProfileComponent) {
    for (let i = 0; i < this.sealingTypes.length; i++) {
      for (let j = 0; j < this.sealingTypes[i].profiles.length; j++) {
        for (let k = 0; k < this.sealingTypes[i].profiles[j].profileComponents.length; k++) {
          if (this.sealingTypes[i].profiles[j].profileComponents[k].idProfileComponent == idProfileComponent) {
            return this.sealingTypes[i].profiles[j].profileComponents[k].valid;
          }
        }
      }
    }
  }

  /**
   * Gets profile component material valid value
   * @param idProfileComponentMaterial 
   * @returns  
   */
  getProfileComponentMaterialValid(idProfileComponentMaterial) {
    for (let i = 0; i < this.sealingTypes.length; i++) {
      for (let j = 0; j < this.sealingTypes[i].profiles.length; j++) {
        for (let k = 0; k < this.sealingTypes[i].profiles[j].profileComponents.length; k++) {
          for (let l = 0; l < this.sealingTypes[i].profiles[j].profileComponents[k].profileComponentMaterials.length; l++) {
            if (this.sealingTypes[i].profiles[j].profileComponents[k].profileComponentMaterials[l].idProfileComponentMaterial == idProfileComponentMaterial) {
              return this.sealingTypes[i].profiles[j].profileComponents[k].profileComponentMaterials[l].valid;
            }
          }
        }
      }
    }
  }

  /**
   * iterates over sealingTypes and subarray profiles and update valid property
   * @param sealingTypes 
   */
  updateValidationsValues(sealingTypes) {
    sealingTypes.forEach(sealingType => {
      const sealingTypeIndex = this.sealingTypes.findIndex((obj => obj.idSealingType == sealingType.idSealingType));
      sealingType.valid = this.sealingTypes[sealingTypeIndex].valid;
      sealingType.profiles.forEach((item: Profile) => {
        const itemIndex = this.sealingTypes[sealingTypeIndex].profiles.findIndex((obj => obj.idProfile == item.idProfile));
        item.valid = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].valid;
        item.profileMeasureInputLines = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileMeasureInputLines.sort((a, b) => a.showOrder-b.showOrder);
        item.profileComponents.forEach(component => {
          if (component) {
            const componentIndex = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents.findIndex((obj => obj?.idProfileComponent == component?.idProfileComponent));
            component.valid = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents[componentIndex]?.valid;
            if (component.profileComponentMaterials.length !== this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents[componentIndex]) {
              this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents[componentIndex]?.profileComponentMaterials.forEach(material => {
                if (!component.profileComponentMaterials.find(obj => obj.idProfileComponentMaterial == material.idProfileComponentMaterial)) {
                  component.profileComponentMaterials.push(material);
                }
              });
            }
            component.profileComponentMaterials.forEach(material => {
              const materialIndex = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents[componentIndex].profileComponentMaterials.findIndex((obj => obj.idProfileComponentMaterial == material.idProfileComponentMaterial));
              material.valid = this.sealingTypes[sealingTypeIndex].profiles[itemIndex].profileComponents[componentIndex].profileComponentMaterials[materialIndex]?.valid;
              this.sealingTypesRevalidated.emit(this.sealingTypes);
            });
          }
        });
      })
    })
  }

}
