import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialProfileMeasure } from 'src/app/shared/models/material-profile-measure.model';
import { ProfileComponentMaterialLine } from 'src/app/shared/models/profile-component-material-line.model';
import { ProfileComponentMaterial } from 'src/app/shared/models/profile-component-material.model';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';
import { ProfileComponentService } from 'src/app/shared/services/profile-component.service';
import { ProfileValidationService } from 'src/app/shared/services/profile-validation.service';

import { ToastService } from 'src/app/shared/services/toast.service';
import { OuterDimensionTemplatesListMode } from 'src/app/shared/models/outer-dimension-template-mode.enum';

@Component({
  selector: 'app-configurator-template',
  templateUrl: './configurator-template.component.html',
  styleUrls: ['./configurator-template.component.scss'],
})

export class ConfiguratorTemplateComponent implements OnInit, OnChanges {
  @Input() materialProfileMeasure: MaterialProfileMeasure;
  @Input() profileComponent: ProfileComponent;
  @Input() profileComponentMaterial: ProfileComponentMaterial;
  @Output() profileComponentMaterialChange = new EventEmitter<ProfileComponentMaterial>();
  @Output() onApplyTemplate = new EventEmitter<ProfileComponentMaterialLine[]>();

  invalidIds = [];
  profileComponentMaterialLineIds = [];
  profileComponentMaterialLines: ProfileComponentMaterialLine[] = [];
  materialProfileMeasureInvalid = false;
  validateForms = new EventEmitter<number>();
  OuterDimensionTemplatesListMode = OuterDimensionTemplatesListMode;

  constructor(public activeModal: NgbActiveModal, private profileComponentService: ProfileComponentService, private profileValidationService: ProfileValidationService, private toastService: ToastService, private translationsService: TranslateService,  private modalService: NgbModal) { }

  ngOnInit(): void {
    this.profileComponentService.getProfileComponentMaterialLines(this.profileComponentMaterial.idProfileComponentMaterial, this.materialProfileMeasure.idMaterialProfileMeasure).subscribe(data => {
      this.profileComponentMaterialLines = data;

      if (!this.profileComponent.valid && this.profileComponentMaterialLines.length > 0) {
        this.invalidIds = ['measure-' + this.materialProfileMeasure.idMaterialProfileMeasure];
      }
      // check material profile measure for validity
      this.materialValidate();
    });
  }


  // Applies the values of the template onto the actual material component
  sendLinesToComponentMaterial(result: ProfileComponentMaterialLine[]) {
    this.onApplyTemplate.emit(result);
  }



  toastConfirmationDelete(){
    this.toastService.show(this.translationsService.instant('Are you sure you want to delete it?'), { classname: 'bg-warning text-light', delay: 3000 });
  }

  /**
   * Validates material lines 
   */
  materialValidate() {
    this.profileComponentService.getProfileComponentMaterialLines(this.profileComponentMaterial.idProfileComponentMaterial, this.materialProfileMeasure.idMaterialProfileMeasure).subscribe(data => {
      this.profileComponentMaterialLines.forEach(x => {
        // find in data
        const found = data.find(y => y.idProfileComponentMaterialLine == x.idProfileComponentMaterialLine);
        x.valid = found.valid;
      });
      // if all lines are valid, set material profile measure valid property to false
      this.materialProfileMeasureInvalid = this.profileComponentMaterialLines.filter(x => x.valid == false).length > 0;
    });
  }

  ngOnChanges(changes: SimpleChanges): void { }


}
