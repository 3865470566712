import { MachineProfile } from './../models/machine-profile.model';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaterialProfile } from '../models/material-profile.model';


@Injectable({
  providedIn: 'root'
})
export class MaterialProfileService {
  private apiUrl = environment.apiUrl;
  private _outerDimensionAD4PopUp:number;
  userId: string;
  constructor(private http: HttpClient) { }


  get outerDimensionAD4PopUp () {
    return this._outerDimensionAD4PopUp;
  }
  set outerDimensionAD4PopUp(value: number){
    this._outerDimensionAD4PopUp = value;
  }

  /**
   * Get material profiles
   * @returns material profiles
   */
  public getMaterialProfiles(): Observable<MaterialProfile[]> {
    return this.http.get(this.apiUrl + '/profile-admin/materials') as Observable<MaterialProfile[]>;
  }
  /**
   * Get material profiles
   * @returns material profiles
   */
  public getMaterialProfilesActive(): Observable<MaterialProfile[]> {
    return this.http.get(this.apiUrl + '/profile-admin/materials-active') as Observable<MaterialProfile[]>;
  }

  /**
   * Get machine profiles
   * @returns machine profiles
   */
  public getMachineProfiles(): Observable<any[]> {
    return this.http.get(this.apiUrl + '/profile-admin/machines') as Observable<MachineProfile[]>;
  }

  /**
   * Saves material profile
   * @param materialProfile 
   * @returns material profile 
   */
  public saveMaterialProfile(materialProfile: MaterialProfile): Observable<MaterialProfile> {
    return this.http.put(this.apiUrl + '/profile-admin/update-material', materialProfile) as Observable<MaterialProfile>;
  }


  public applyMaterial: EventEmitter<number> = new EventEmitter();
  executeFunction(i: number) {
    this.applyMaterial.emit(i);
  }


}
