import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true
		}
	]
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
	@Input() checked = false;
	@Input() readonly = false;
	@Input() disabled = false;
	onChange: any = () => { };
	onTouch: any = () => { };

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	constructor() { }

	ngOnInit(): void { }

	/**
	 * Set value of checked on outside changes
	 * @param checked checked status
	 */
	writeValue(checked: boolean): void {
		this.checked = checked;
	}

	onModelChange(e: boolean): void {
		// bind the changes to the local value
		this.checked = e;

		// handle what should happen on the outside, if something changes on the inside
		this.onChange(e);
	}

}
