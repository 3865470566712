import { OrderSealWrapperComponent } from './components/configurator/order-seal/order-seal-wrapper/order-seal-wrapper.component';
import { OrderSealSelectMeasuresAndMaterialComponent } from './components/configurator/order-seal/order-seal-select-measures-and-material/order-seal-select-measures-and-material.component';
import { OrderSealSelectScraperComponent } from './components/configurator/order-seal/order-seal-select-scraper/order-seal-select-scraper.component';
import { OrderSealComponent } from './components/configurator/order-seal/order-seal.component';
import { ProfileComponent } from './components/configurator/sealing-type/sealing-type.component';
import { ProfileMachineGroupComponent } from './components/configurator/profile-machine-group/profile-machine-group.component';
import { TextblocksComponent } from './components/textblocks/textblocks.component';
import { EditProfileComponent } from './shared/components/edit-profile/edit-profile.component';
import { FrameworkContractsComponent } from './components/framework-contracts/framework-contracts.component';
import { CreateUserComponent } from './components/admin/create-user/create-user.component';
import { ManageUsersComponent } from './components/admin/manage-users/manage-users.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { AccountManagerGuard } from './helpers/account-manager-guard';
import { OrdersComponent } from './components/orders/orders.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogsViewerComponent } from './components/admin/logs-viewer/logs-viewer.component';
import { ParametersConfigurationComponent } from './components/admin/parameters-configuration/parameters-configuration.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { CartComponent } from './components/cart/cart.component';
import { CreateOrderComponent } from './components/cart/create-order/create-order.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { OffersComponent } from './components/offers/offers.component';
import { AdminGuard } from './helpers/admin-guard';
import { AuthGuard } from './helpers/auth.guard';
import { OrderPageGuard } from './helpers/order-page.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { OrderSealSelectSealingTypeComponent } from './components/configurator/order-seal/order-seal-select-sealing-type/order-seal-select-sealing-type.component';
import { OuterDimensionTemplatesComponent } from './components/configurator/outer-dimension-templates/outer-dimension-templates.component';
import { MaterialProfile } from './shared/models/material-profile.model';
import { MaterialTableComponent } from './components/configurator/material-table/material-table.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'Haenssler', breadcrumb: 'Home' },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Enmelden', breadcrumb: 'Enmelden' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Vergessenes Passwort', breadcrumb: 'Vergessenes Passwort' }
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    data: { title: 'Meine Artikel', breadcrumb: 'Meine Artikel' },
    canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    component: CartComponent,
    data: { title: 'Warenkorb', breadcrumb: 'Warenkorb' },
    canActivate: [AuthGuard]
  },
  {
    path: 'offers',
    component: OffersComponent,
    data: { title: 'Angebote', breadcrumb: 'Angebote' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/parameters',
    component: ParametersConfigurationComponent,
    data: { title: 'Parameters configuration', breadcrumb: 'Parameters configuration' },
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/logs',
    component: LogsViewerComponent,
    data: { title: 'Sales Engine Logs', breadcrumb: 'Sales Engine Logs' },
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/manage-users',
    component: ManageUsersComponent,
    data: { title: 'Manage Users', breadcrumb: 'Manage Users' },
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/create-user',
    component: CreateUserComponent,
    data: { title: 'Create user', breadcrumb: 'Create user' },
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'manage-account',
    component: ManageAccountComponent,
    data: { title: 'Manage Account', breadcrumb: 'Manage Account' },
    canActivate: [AccountManagerGuard]
  },
  {
    path: 'update-password/:token',
    component: PasswordResetComponent,
    data: { title: 'Create password', breadcrumb: 'Create password' }
  },
  {
    path: 'order',
    component: CreateOrderComponent,
    data: { title: 'Adresse wählen', breadcrumb: 'Adresse wählen' },
    canActivate: [AuthGuard, OrderPageGuard],
  },
  {
    path: 'orders',
    component: OrdersComponent,
    data: { title: 'Orders', breadcrumb: 'Orders' },
    canActivate: [AuthGuard],
  },
  {
    path: 'framework-contracts',
    component: FrameworkContractsComponent,
    data: { title: 'Framework Contracts', breadcrumb: 'Framework Contracts' },
    canActivate: [AuthGuard],
  },
  {
    path: 'update-profile-image',
    component: EditProfileComponent,
    data: { title: 'Profile Image Update', breadcrumb: 'Profile Image Update' },
    canActivate: [AccountManagerGuard],
  },
  {
    path: 'admin/textblocks',
    component: TextblocksComponent,
    data: { title: 'Manage Order Comments', breadcrumb: 'Manage Order Comments' },
    canActivate: [AdminGuard],
  },
  {
    path: 'profile/machine-groups',
    component: ProfileMachineGroupComponent,
    data: { title: 'Manage Machine Groups', breadcrumb: 'Manage Machine Groups' },
    canActivate: [AdminGuard],
  },
  {
    path: 'profile/machine-groups/:idMaterialProfile',
    component: ProfileMachineGroupComponent,
    data: { title: 'Manage Machine Groups', breadcrumb: 'Manage Machine Groups' },
    canActivate: [AdminGuard],
  },
  {
    path: 'profile/category-and-profile',
    component: ProfileComponent,
    data: { title: 'Manage Category and Profile', breadcrumb: 'Manage Category and Profile' },
    canActivate: [AdminGuard],
  },  {
    path: 'profile/order-seal',
    component: OrderSealWrapperComponent,
    data: { title: 'Order Seal', breadcrumb: 'Order Seal' },
    canActivate: [AuthGuard],
  },

  {
    path: 'profile/order-seal/:step',
    component: OrderSealWrapperComponent,
    data: { title: 'Order Seal', breadcrumb: 'Order Seal' },
    canActivate: [AuthGuard],
  },

  {
    path: 'profile/template-outer-dimension',
    component: OuterDimensionTemplatesComponent,
    data: { title: 'Outer Dimension Templates', breadcrumb: 'Outer Dimension Templates' },
    canActivate: [AuthGuard],
  },

  {
    path: 'profile/materials-table',
    component: MaterialTableComponent,
    data: { title: 'Materials Table', breadcrumb: 'Materials Table' },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
