import { DateUtils } from './../../../../../shared/utils/date.utils';
import { Component, OnInit, Input } from '@angular/core';
import { ParametersConfigurationService } from 'src/app/shared/services/parameters-configuration.service';

@Component({
  selector: 'app-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.scss']
})
export class ContractInfoComponent implements OnInit {
  @Input() article;
  timeProgress = 0;
  amountProgress = 0;
  minOrangeProgress = 0;
  startDate: string;
  validUntilDate: string;
  constructor(private parametersConfigurationService: ParametersConfigurationService) { }

  ngOnInit(): void {
    // get min-orange-color parameter
    this.parametersConfigurationService.getMinFrameworkContractOrange().subscribe(data => this.minOrangeProgress = data);
    this.startDate = DateUtils.getFormattedDate(new Date(this.article.startDate), true);
    this.validUntilDate = DateUtils.getFormattedDate(new Date(this.article.validUntilDate), true);
    const start = new Date(this.article.startDate).getTime();
    const end = new Date(this.article.validUntilDate).getTime();
    const today = new Date().getTime();
    // Get the percentage
    this.timeProgress = Math.round(((today - start) / (end - start)) * 100);
    this.amountProgress = Math.round(this.article.orderedQuantity / this.article.totalQuantity * 100);
  }

}
