import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AddressPrefered } from './../models/address-preferred.model';
import { AddressType } from './../models/address-type.enum';
import { UserAddress } from './../models/user-address';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {
  private apiUrl = environment.apiUrl;
  public userId: string;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }


  /**
   * Gets user addresses
   * @returns get
   */
  get() {
    return this.http.get<UserAddress[]>(`${this.apiUrl}/user-address/${this.userId}`).pipe(
      map((data: UserAddress[]) => {
        const invoice = data.filter(address => address.addressType === AddressType.INVOICE_ADDRESS_TYPE);
        const delivery = data.filter(address => address.addressType === AddressType.DELIVERY_ADDRESS_TYPE);
        return { invoice, delivery };
      }),
      catchError(error => {
        console.error('There was an error in the receiving the user address, please contact Haenssler.');
        return of();
      }));
  }

  /**
   * Saves address preferred choice
   * @param address AddressPrefered
   */
  saveAddressPreferredChoice(address: AddressPrefered) {
    localStorage.setItem('preferred_address', JSON.stringify(address));
  }

  /**
   * Gets address preferred choice
   * @returns address preferred choice
   */
  getAddressPreferredChoice(): AddressPrefered {
    return JSON.parse(localStorage.getItem('preferred_address')) as AddressPrefered;
  }


}
