import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cart-progress',
  templateUrl: './cart-progress.component.html',
  styleUrls: ['./cart-progress.component.scss']
})
export class CartProgressComponent implements OnInit {
  @Input() step = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
