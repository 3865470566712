<div class="contract-toggle__header d-flex justify-content-between" (click)="collapseContract($event)">
    <div class="contract-toggle__name"><img src="/assets/img/icons/chevron-{{contractCollapse?'right':'down'}}.svg"
            alt="toggle"> VERTRAG NR {{contract.idFrameworkContractERP}} / {{contract.referenceNumber}} 
            <!-- <button class="download-pdf" (click)="downloadPdf(contract.idFrameworkContractERP)"> <img src="/assets/img/icons/file.svg" alt="pdf-download-icon" class="download-pdf-img"></button> -->
    </div>
    <div class="contract-toggle__date" i18n> GÜLTIG BIS {{DateUtils.getFormattedDate(contract.validUntilDate, true)}}</div>
</div>
<div class="contract-toggle__content" [class.opened]="!contractCollapse"
    [style.overflow]="overflow == true && contractCollapse == false ? 'visible' : 'hidden'">
    <section class="se-table-flex">
        <div class="se-table-header se-table-row">
            <div class="se-table-col sm position-col d-none d-md-block" i18n>Pos</div>
            <div class="se-table-col lg" i18n>{{'frameworkContractsList.article' | translate}} </div>
            <div class="se-table-col d-none d-md-flex md" i18n> {{'frameworkContractsList.frameworkContract' | translate}} </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="amount-col" i18n> {{'frameworkContractsList.amount' | translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="date-col" i18n> {{'frameworkContractsList.shippingDate' | translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="price-col" i18n> {{'frameworkContractsList.scaledPrices' | translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="price-col" i18n> {{'frameworkContractsList.totalPrice' | translate}} </div>
            </div>
            <div class="se-table-col md">
            </div>
        </div>
        <div *ngFor="let contractArticle of contract.frameworkContractArticles; index as i" [article]="contractArticle"
            @row app-articles-list-row class="contract-row">
        </div>
        <div *ngIf="contract.frameworkContractArticles != null && contract.frameworkContractArticles.length == 0"
            class="se-table-row">
            <div class="se-table-col lg text-center" i18n>Keine Artikel zu zeigen </div>
        </div>
    </section>
</div>
