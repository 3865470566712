<div class="container-fluid">
    <app-breadcrumb></app-breadcrumb>
    <form #loginForm="ngForm" class="parameters-table">
        <h2 class="se-title underlined" i18n>{{'admin.parametersConfiguration.title'|translate}}</h2>
        <table class="se-table table">
            <thead>
                <tr>
                    <th scope="col" i18n>{{'admin.parametersConfiguration.name'|translate}} </th>
                    <th scope="col" i18n>{{'admin.parametersConfiguration.desc'|translate}} </th>
                    <th scope="col" i18n>{{'admin.parametersConfiguration.type'|translate}} </th>
                    <th scope="col" i18n>{{'admin.parametersConfiguration.value'|translate}} </th>
                </tr>
            </thead>
            <tbody *ngIf="!parameters">
                <tr>
                    <td class="spinner-row" colspan="6" class="d-none d-sm-table-cell">
                        <app-loading-spinner></app-loading-spinner>
                    </td>
                    <td class="spinner-row" colspan="3" class="d-table-cell d-sm-none">
                        <app-loading-spinner></app-loading-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr *ngFor="let parameter of parameters;let i=index;">
                    <td> {{parameter.name}} </td>
                    <td> {{parameter.description}} </td>
                    <td> {{getType(parameter.type)}} </td>
                    <td>
                        <input [type]="parameter.type == 'STRING_TYPE' ? 'text' : 'number'"
                            [(ngModel)]="parameter.value" [pattern]="getValidationPattern(parameter.type)" required
                            *ngIf="parameter.type != 'BOOLEAN_TYPE'" [name]="parameter.name">
                        <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="parameter.value"
                            *ngIf="parameter.type == 'BOOLEAN_TYPE'" [name]="parameter.name">
                            <label ngbButtonLabel class="btn btn-true">
                                <input ngbButton type="radio" [value]="'true'"> {{'admin.parametersConfiguration.true'|translate}} </label>
                            <label ngbButtonLabel class="btn btn-false">
                                <input ngbButton type="radio" [value]="'false'"> {{'admin.parametersConfiguration.false'|translate}} </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <app-button class="d-block" [label]="'Save'" (onClick)="updateParameters()"
            [disabled]="loginForm.form.invalid || buttonDisabled">
        </app-button>
    </form>
</div>
