import { TranslateService } from "@ngx-translate/core";

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  public loginForm: UntypedFormGroup;
  public submitted = false;
  public BAD_CREDENTIALS_ERROR = 'Bad credentials';
  public BAD_CREDENTIALS_ERROR_KEY = 'codeTranslations.bad-credentials-error';
  // This url indicates the page the user was on before going to the login page
  public returnUrl: string;
  public error = '';

  /**
   * Gets loginForm fields
   */
  get fields() { return this.loginForm.controls; }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public translationsService: TranslateService
  ) {
    // when already logged redirect to dashboard
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  /**
   * Determines whether submit on
   * @returns void
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // if login form is correct try to login
    this.authenticationService.login(this.fields.username.value, this.fields.password.value)
      .pipe(first()).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => {
          // redirect to home or the last page the user was on before going to the login page
          this.router.navigate([this.returnUrl]);
        },
        error => {
          // get the error message
          this.error = error;
        });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
