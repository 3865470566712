import { ArticleCalculationProcess } from './../../../../../shared/models/article-calculation-proces-map.model';
import { DateUtils } from './../../../../../shared/utils/date.utils';
import { ArticlesListUtils } from './../../../../../shared/utils/articles-list.utils';
import { Component, Input, OnInit, ViewChild, } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { User } from './../../../../../shared/models/user.model';
import { ArticleCalculationProcessDate } from 'src/app/shared/models/article-calculation-proces-date.model';


@Component({
  selector: 'app-calculation-process',
  templateUrl: './calculation-process.component.html',
  styleUrls: ['./calculation-process.component.scss']
})
export class CalculationProcessComponent implements OnInit {
  ArticlesListUtils = ArticlesListUtils;
  DateUtils = DateUtils;
  @Input() capacityPriceCalcAnalyticsMap: ArticleCalculationProcess[];
  selectedOption: ArticleCalculationProcessDate[];
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  private user: User;
  tableCollapse = true;
  valid = true;
  selected = '0';

  constructor() { }
  selectOption(id: string) {
    this.selected = id;
  }

  ngOnInit(): void { 
    console.log("Data rows on article price information popup: " , this.capacityPriceCalcAnalyticsMap[this.selected].values)
  }

  formatNumber(myNumber: number) {
    return myNumber.toFixed(2); // Math.floor(myNumber);
  }


}
