import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService } from './../../shared/services/toast.service';
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/helpers/custom-validators';
import { UserService } from './../../shared/services/user.service';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  public token: string;
  public resetPasswordForm: UntypedFormGroup;
  private unsubscribe: Subject<void> = new Subject();
  submitted = false;
  tokenValidated = false;
  showOldPasswordField = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private translationsService: TranslateService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService
  ) { }

  /**
   * Gets resetPasswordForm fields
   */
  get fields() { return this.resetPasswordForm.controls; }

  get hasNumber() {
    return !this.fields.password.hasError('hasNumber') && this.fields.password.value !== '';
  }
  get hasUpperCase() {
    return !this.fields.password.hasError('hasUpperCase') && this.fields.password.value !== '';
  }
  get hasLowerCase() {
    return !this.fields.password.hasError('hasLowerCase') && this.fields.password.value !== '';
  }
  get hasMinLength() {
    return !this.fields.password.hasError('minlength') && this.fields.password.value !== '';
  }
  get notSame() {
    return !this.resetPasswordForm.hasError('notSame') && this.fields.confirmPassword.value !== '';
  }


  get oldPasswordRequired() {
    return !this.fields.oldPassword.hasError('required') && this.fields.oldPassword.value !== '';
  }



  ngOnInit(): void {
    if (this.authenticationService.currentUserValue) {
      this.showOldPasswordField = true;
      this.tokenValidated = true;
    } else {
      // get token from the route
      this.token = this.route.snapshot.paramMap.get('token');
      this.userService.validateToken(this.token).pipe(takeUntil(this.unsubscribe)).subscribe(data => this.tokenValidated = true, error => {
        this.toastService.show(this.translationsService.instant('codeTranslations.token-invalid'),
          { classname: 'bg-danger text-light', delay: 5000 });
        window.location.replace('/login');
      });
    }
    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ['', this.showOldPasswordField ? Validators.required : null],
      password: ['', Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/[*\d\._@!#%&()^~{}]+/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasUpperCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasLowerCase: true }),
        // 5. Has a minimum length of 8 characters
        Validators.minLength(8)])
      ],
      confirmPassword: ['', Validators.required]
    }, { validator: this.checkPasswords });

  }

  /**
   * Check that password and confirmPassword match
   * @param group FormGroup
   */
  checkPasswords(group: UntypedFormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  /**
   * On submit send password reset request
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }
    // not-logged user method
    if (!this.showOldPasswordField) {
      this.userService.resetPassword({ token: this.token, password: this.fields.password.value })
        .pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.toastService.show(this.translationsService.instant('codeTranslations.password-update-success'),
            { classname: 'bg-success text-light', delay: 5000 });
          this.router.navigate(['/login']);
        }, error => {
          this.toastService.show(this.translationsService.instant('codeTranslations.password-update-error') + error,
            { classname: 'bg-danger text-light', delay: 5000 });
        });
      // logged user method
    } else {
      this.userService.resetPasswordLoggedUser({
        oldPassword: this.fields.oldPassword.value,
        password: this.fields.password.value
      }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.toastService.show(this.translationsService.instant('codeTranslations.password-update-success'),
          { classname: 'bg-success text-light', delay: 5000 });
        this.router.navigate(['/dashboard']);
      }, error => {
        this.toastService.show(this.translationsService.instant('codeTranslations.password-update-error') + error,
          { classname: 'bg-danger text-light', delay: 5000 });
      });
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
