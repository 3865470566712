import { ParametersConfigurationService } from './../../services/parameters-configuration.service';
import { ParametersConfiguration } from './../../models/parameters-configuration.model';
import { ToastService } from './../../services/toast.service';
import { CapacityPriceList } from './../../models/capacity-price-list.model';
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from '@angular/common';
import { Article } from 'src/app/shared/models/article.model';
import { CurrencyType } from './../../models/currency-type.enum';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScaledPrice } from 'src/app/shared/models/scaled-price.model';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { PriceInformation } from '../../models/best-price.model';
import { CapacityPriceStatus } from '../../models/capacity-price-status.enum';
import { CapacityPrice } from '../../models/capacity-price.model';
import { CapacityPricesRequest } from '../../models/capacity-prices-request.model';
import { ArticlesListUtils } from '../../utils/articles-list.utils';
import { ScaledPricesList } from './../../models/scaled-price-list.model';
import { DateUtils } from './../../utils/date.utils';
import { ArticleType } from '../../models/article-type.enum';
import { CapacityPriceErrorType } from '../../models/capacity-price-error-type.enum';
import { StockInformation } from '../../models/stock-information.model';
export interface RequestQuery { idArticleERP: string; amount?: number; date?: Date; }


@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit, OnDestroy {
  ArticlesListUtils = ArticlesListUtils;
  scaledPrices: ScaledPrice[];
  maxBestPriceCalculations = 5;
  /*temp */ bestPriceCalculations = this.maxBestPriceCalculations;
  bestPriceLoading = false;
  validScaledPrices = true;
  private unsubscribe: Subject<void> = new Subject();
  @Input() article: Article;
  requestArticle: RequestQuery;
  @Output() dateChanged: EventEmitter<CapacityPriceList> = new EventEmitter<CapacityPriceList>();
  @Output() articleInStock: EventEmitter<StockInformation> = new EventEmitter<StockInformation>();
  @Output() currentScaledPrice: EventEmitter<ScaledPrice> = new EventEmitter<ScaledPrice>();
  @Input() requestQueryChanges: EventEmitter<RequestQuery> = new EventEmitter<RequestQuery>();

  // diagram options
  showXAxis = true;
  roundEdges = false;
  showGridLines = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = true;
  yAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 100];
  // diagram values
  values = [];
  colors = [];
  capacityDemandList: CapacityPriceList[] = [];
  // dates
  selectedDate = new Date();
  todayDate = new Date();

  showStartDate = new Date();
  showEndDate = new Date();
  // on first load don't trigger reload on changing date
  firstLoad = true;
  // used for diagram dragging
  draggable = false;
  lastMousePos = 0;
  public timeout: any = null;
  showingCapacityDemandList: CapacityPriceList[] = [];
  // best and current date boxes
  currentPrice: PriceInformation = new PriceInformation();
  bestPrice: PriceInformation = new PriceInformation();
  stock: StockInformation = { articlesInStock: 0, materialInStock: 0, showAvailableMaterial: false };
  loadingChanges = false;
  loading;
  loadingLeft = false;
  loadingRight = false;
  dateOffset = 0;
  constructor(
    private articleService: ArticlesService,
    private datePipe: DatePipe,
    private translationsService: TranslateService,
    private parametersConfiguration: ParametersConfigurationService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.parametersConfiguration.getDateOffsetParameter().subscribe((data: number) => this.dateOffset = data);
    this.todayDate.setHours(2, 0, 0, 0);
    this.loadingChanges = true;
    // this.initializeDiagram();
    // on article request changes in parent component reset all and load nes values for the requested date
    this.requestQueryChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data: RequestQuery) => {
      this.loadingChanges = true;
      this.loading = false;
      this.showingCapacityDemandList = [];
      this.capacityDemandList = [];
      this.firstLoad = true;
      data.date = new Date(this.convertDateToApiFormat(data.date));
      this.requestArticle = data;
      this.requestArticle.amount = data.amount;
      this.requestArticle.date = DateUtils.cloneDate(data.date);
      this.initializeDiagram();
    });
  }

  /**
   * Show more then max amount error message
   */
   showMoreThenMaxAmountRequested() {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.above-max-amount-error'),
      { classname: 'bg-danger text-light', delay: 10000 }
    );
  }

  /**
   * On selecting date in the chart moves diagram to the selected date
   * @param event event
   */
  onChartSelect(event) {
    const date = new Date(event.extra.date);
    this.moveToDate(date);
  }

  /**
   * Converts date to api format
   * @param date date
   * @returns date
   */
  convertDateToApiFormat(date) {
    if (date instanceof Date) {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    date = date.split('-').reverse().join('/');
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  /**
   * Initializes diagram
   */
  initializeDiagram() {
    this.showStartDate = DateUtils.cloneDate(this.requestArticle.date);
    this.showEndDate = DateUtils.cloneDate(this.requestArticle.date);
    this.selectedDate = DateUtils.cloneDate(this.requestArticle.date);
    const minDate = DateUtils.cloneDate(this.requestArticle.date);
    const maxDate = DateUtils.cloneDate(this.requestArticle.date);
    DateUtils.plusDays(minDate, -this.dateOffset);
    DateUtils.plusDays(maxDate, this.dateOffset);
    DateUtils.plusDays(this.showStartDate, -5);
    DateUtils.plusDays(this.showEndDate, 5);
    this.getInitialCapacityDemand(minDate, maxDate);
  }

  /**
   * Gets initial capacity demand
   * @param minDate starting date
   * @param maxDate ending date
   */
  getInitialCapacityDemand(minDate: Date, maxDate: Date) {
    if ((this.article.articleType === ArticleType.OFFER_ARTICLE && this.article.existingArticle === false)
      || this.article.articleType === ArticleType.FRAMEWORK_CONTRACT) {
      this.loading = false;
      this.scaledPrices = this.article.scaledPrices.scaledPriceList;
      this.getCapacityDemand(minDate, maxDate);
    } else {
      this.articleService.getArticleScaledPrices(this.requestArticle.idArticleERP)
        .pipe(takeUntil(this.unsubscribe)).subscribe((result: ScaledPricesList) => {
          if (result) {
            this.validScaledPrices = result.valid;
            this.loading = false;
            this.scaledPrices = result.scaledPriceList;
            this.getCapacityDemand(minDate, maxDate);
          } else {
            this.dateChanged.emit(null);
            this.loading = false;
          }
        });
    }
  }

  /**
   * Moves diagram to the left
   */
  goLeft() {
    if (!this.canGoLeft || this.selectedDate.getTime() < this.todayDate.getTime()) {
      return;
    }
    DateUtils.plusDays(this.showStartDate, -1);
    DateUtils.plusDays(this.showEndDate, -1);
    DateUtils.plusDays(this.selectedDate, -1);
    const lastDateToBeAbleToLoadLess = DateUtils.cloneDate(this.todayDate);
    DateUtils.plusDays(lastDateToBeAbleToLoadLess, +5);
    // load more values
    if (this.selectedDate.getTime() <= this.dateToLoadLess.getTime()
      && this.dateToLoadLess.getTime() > lastDateToBeAbleToLoadLess.getTime()) {
      this.loadingLeft = true;
      this.loadLess(true);
    } else {
      this.showCapacityDemand(this.showStartDate, this.showEndDate);
    }
  }

  /**
   * Moves diagram to the right
   */
  goRight() {
    if (!this.canGoRight) {
      return;
    }
    DateUtils.plusDays(this.showStartDate, +1);
    DateUtils.plusDays(this.showEndDate, +1);
    DateUtils.plusDays(this.selectedDate, +1);
    // load more values
    if (this.selectedDate.getTime() >= this.dateToLoadMore.getTime()) {
      this.loadingRight = true;
      this.loadMore(true);
    } else {
      this.showCapacityDemand(this.showStartDate, this.showEndDate);
    }

  }

  /**
   * Shows product added to cart message
   */
  showAmountBelowMinimumMessage() {
    let translation = this.translationsService.instant('codeTranslations.price-min-amount-order-more');
    translation = translation.replace('{minAmount}', this.scaledPrices[0].fromUnits);
    this.toastService.show(translation, { classname: 'bg-warning text-light', delay: 5000 });
  }


  /**
   * Gets date to load less
   */
  get dateToLoadLess() {
    // on selecting this date, the next capacity demand values will be requested
    const dateToLoadLess = DateUtils.cloneDate(this.capacityDemandList[0].date);
    DateUtils.plusDays(dateToLoadLess, + 10);
    return dateToLoadLess;
  }

  /**
   * Gets date to load more
   */
  get dateToLoadMore() {
    // on selecting this date, the next capacity demand values will be requested
    const dateToLoadMore = DateUtils.cloneDate(this.capacityDemandList[this.capacityDemandList.length - 1].date);
    DateUtils.plusDays(dateToLoadMore, -10);
    return dateToLoadMore;
  }

  /**
   * Loads more capacity prices
   * @param [show] load and show
   */
  loadLess(show = false) {
    this.loading = true;
    const maxDate = DateUtils.cloneDate(this.capacityDemandList[0].date);
    const minDate = DateUtils.cloneDate(maxDate);
    DateUtils.plusDays(maxDate, -1);
    DateUtils.plusDays(minDate, -20);
    this.getCapacityDemand(minDate, maxDate, show);
  }

  /**
   * Loads more capacity prices
   * @param [show] load and show
   */
  loadMore(show = false) {
    this.loading = true;
    const minDate = DateUtils.cloneDate(this.capacityDemandList[this.capacityDemandList.length - 1].date);
    const maxDate = DateUtils.cloneDate(minDate);
    DateUtils.plusDays(minDate, 1);
    DateUtils.plusDays(maxDate, 20);
    this.getCapacityDemand(minDate, maxDate, show);
  }

  get canGoRight() {
    if (this.loading) {
      return false;
    }
    const lastDate = DateUtils.cloneDate(this.capacityDemandList[this.capacityDemandList.length - 1].date);
    DateUtils.plusDays(lastDate, -6);
    if (this.selectedDate > lastDate) {
      return false;
    } else {
      return true;
    }
  }

  get canGoLeft() {
    if (this.loading) {
      return false;
    }
    const lastDate = DateUtils.cloneDate(this.capacityDemandList[0].date);
    DateUtils.plusDays(lastDate, 6);
    if (this.selectedDate.getTime() < lastDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Gets capacity demand
   * @param startDate Date
   * @param endDate Date
   */
  getCapacityDemand(startDate: Date, endDate: Date, show = true) {
    const capacityPricesRequest = new CapacityPricesRequest();
    capacityPricesRequest.amount = this.requestArticle.amount;
    capacityPricesRequest.idArticleERP = this.requestArticle.idArticleERP;
    capacityPricesRequest.articleType = this.article.articleType;
    if (this.article.articleType === ArticleType.OFFER_ARTICLE) {
      capacityPricesRequest.idOfferPosition = this.article.idPositionERP;
      if (this.article.existingArticle === false) {
        capacityPricesRequest.idOfferERP = this.article.idOfferERP;
        capacityPricesRequest.positionOffer = this.article.position.toString();
      }
    } else if (this.article.articleType === ArticleType.FRAMEWORK_CONTRACT) {
      capacityPricesRequest.idFrameworkContractERP = this.article.idFrameworkContractERP;
    }
    // We don't need this code anymore as we already control the dates in past in java
    // const firstDate = DateUtils.cloneDate(this.todayDate);
    // DateUtils.plusDays(firstDate, -5);
    // if (startDate < firstDate) {
    //   capacityPricesRequest.startDate = firstDate;
    // } else {
    //   capacityPricesRequest.startDate = startDate;
    // }
    capacityPricesRequest.startDate = startDate;
    capacityPricesRequest.endDate = endDate;

    this.articleService.getArticleCapacityPrices(capacityPricesRequest)
      .pipe(takeUntil(this.unsubscribe)).subscribe((data: CapacityPrice) => {
        if (data) {
          if (data.error === CapacityPriceErrorType.BELOW_MIN_AMOUNT_SCALED_PRICE && show) {
            this.showAmountBelowMinimumMessage();
          }
          if (data.error === CapacityPriceErrorType.ABOVE_MAX_AMOUNT && show) {
            this.showMoreThenMaxAmountRequested();
          }
          if (!this.bestPriceLoading && show && (data.articleInStock || data.materialInStock)) {
            // add information about stock
            this.stock.articlesInStock = data.articleInStock;
            this.stock.materialInStock = data.materialInStock;
          }
          // merge existing capacity demand array with received from api
          data.capacityPricesList.map((a) => {
            a.date = DateUtils.cloneDate(new Date(a.date)); a.date.setHours(2, 0, 0, 0);
            a.basePrice = data.basePrice;
          });

          this.capacityDemandList = [...this.capacityDemandList, ...data.capacityPricesList];

          // check dupplicates
          this.capacityDemandList.filter((elem, index, self) => index === self.indexOf(elem));
          this.capacityDemandList = _.uniqBy(this.capacityDemandList, 'date');
          // _.uniqBy(this.capacityDemandList, (e) => DateUtils.getFormattedDate(e.date, true));
          // sort cronologically
          this.capacityDemandList.sort((a, b) => a.date.getTime() - b.date.getTime());
          // check for missing days
          const missing = this.getMissingDatesIndexInCapDem();
          // if there is a missing day remove the rest from the list so the rest of the days will be loaded again
          if (missing) {
            this.capacityDemandList.splice(missing, this.capacityDemandList.length);
          }
          // get values between two dates
          if (show) {
            this.showCapacityDemand(this.showStartDate, this.showEndDate);
          }
          if (this.validScaledPrices) {
            this.getBestPrice();
          }
          this.loading = false;
          // window.setTimeout(() => {
          this.loadingRight = false;
          this.loadingLeft = false;
          // }, 30000);
        } else {
          this.dateChanged.emit(null);
        }
      });

  }

  /**
   * Iterates over capacity demand list and looks for missing dates.
   * if there is any missing date the method will return the index of this day
   * @returns number
   */
  getMissingDatesIndexInCapDem() {
    for (let i = 1; i < this.capacityDemandList.length; i++) {
      const daysDiff = ((this.capacityDemandList[i].date.getTime() - this.capacityDemandList[i - 1].date.getTime()) / 86400000/*24h*/) - 1;
      for (let j = 1; j <= daysDiff; j++) {
        return i - 1;
      }
    }
  }

  /**
   * Gets number of days between two dates
   * @param startDate start date
   * @param endDate end date
   * @returns number of days
   */
  getDaysBetween(startDate: Date, endDate: Date) {
    const differenceInTime = endDate.getTime() - startDate.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  getTooltip(capa: CapacityPriceList) {
    if (capa.priceStatus === CapacityPriceStatus.HIGH || capa.priceStatus === CapacityPriceStatus.LOW) {
      return Math.floor(capa.workloadBookedP * 100) + '%';
    } else {
      if (capa.priceStatus === CapacityPriceStatus.HOLIDAY) {
        return this.translationsService.instant('codeTranslations.price-holiday');
      } else if (capa.priceStatus === CapacityPriceStatus.NA) {
        return this.translationsService.instant('codeTranslations.price-not-available');
      } else if (capa.priceStatus === CapacityPriceStatus.NO_MATERIAL) {
        return this.translationsService.instant('codeTranslations.price-no-material');
      } else if (capa.priceStatus === CapacityPriceStatus.PAST) {
        return this.translationsService.instant('codeTranslations.price-past');
      } else if (capa.priceStatus === CapacityPriceStatus.UNVALIDATED) {
        return this.translationsService.instant('codeTranslations.price-unvalidated');
      } else if (capa.priceStatus === CapacityPriceStatus.BELOW_MIN_AMOUNT_SCALED_PRICE) {
        return this.translationsService.instant('codeTranslations.scaled-price-below-min-amount');
      }
    }
  }

  /**
   * Shows capacity demand on diagram
   * @param startDate from date
   * @param endDate till date
   */
  showCapacityDemand(startDate: Date, endDate: Date) {
    this.showingCapacityDemandList = this.capacityDemandList.filter(data => data.date >= startDate && data.date <= endDate);
    this.showingCapacityDemandList = _.uniqBy(this.showingCapacityDemandList, (e) => DateUtils.getFormattedDate(e.date, true));
    // map showing values on diagram
    this.values = this.showingCapacityDemandList.map(capa => {
      return {
        name: DateUtils.getFormattedDate(capa.date),
        value: Math.floor(capa.workloadBookedP * 100),
        extra: {
          date: capa.date,
          tooltip: this.getTooltip(capa)
        }

      };
    });
    // map colors for each capacity
    this.colors = this.showingCapacityDemandList.map(capa => {
      return {
        name: DateUtils.getFormattedDate(capa.date),
        value: ArticlesListUtils.getStatusColor(capa.priceStatus)
      };
    });
    // current and best price boxess
    this.getCurrentPrice();
    if (this.showingCapacityDemandList.length === 11) {
      this.loadingChanges = false;
    }
    this.loading = false;
  }

  /**
   * Gets random int
   */
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * Gets best price for the best price box
   */
  getBestPrice(disableReload = false) {
    this.bestPriceLoading = true;
    // loading prices from current selected price on the left and then on the right
    // if there is no best price on the left check if there is any best price on the right
    let beforeNowCapacityDemand = this.capacityDemandList.filter(data => data.date.getTime() <= this.selectedDate.getTime());
    beforeNowCapacityDemand.sort((a, b) => b.date.getTime() - a.date.getTime());
    let afterNowCapacityDemand = this.capacityDemandList.filter(data => data.date.getTime() >= this.selectedDate.getTime());
    beforeNowCapacityDemand = beforeNowCapacityDemand.filter(a => a.priceStatus === CapacityPriceStatus.LOW);
    afterNowCapacityDemand = afterNowCapacityDemand.filter(a => a.priceStatus === CapacityPriceStatus.LOW);
    if (beforeNowCapacityDemand.length > 0) {
      this.bestPrice = this.getPriceInformation(beforeNowCapacityDemand[0]);
      this.bestPriceCalculations = this.maxBestPriceCalculations; this.bestPriceLoading = false;
    } else if (afterNowCapacityDemand.length > 0) {
      this.bestPrice = this.getPriceInformation(afterNowCapacityDemand[0]);
      this.bestPriceCalculations = this.maxBestPriceCalculations; this.bestPriceLoading = false;
    } else {
      if (this.bestPriceCalculations > 0 && !disableReload) {
        this.loadMore(false);
        this.bestPriceCalculations = this.bestPriceCalculations - 1;
      } else {
        this.bestPrice = this.getPriceInformation(afterNowCapacityDemand[0]);
        this.bestPriceCalculations = this.maxBestPriceCalculations;
        this.bestPriceLoading = false;
      }
    }
  }

  /**
   * Gets current price for the current price box
   */
  getCurrentPrice() {
    const data = this.showingCapacityDemandList.filter(a => a.date.getTime() === this.selectedDate.getTime());
    const capPrice: CapacityPriceList = data[0];
    capPrice.idArticleERP = this.requestArticle.idArticleERP;
    // this.firstLoad ? this.firstLoad = false : this.dateChanged.emit(capPrice);
    // add showAvailableMaterial boolean to the stock info object
    if (capPrice) {
      this.stock.showAvailableMaterial = capPrice.showAvailableMaterial;
    }
    // emit article in stock change so articles-list-row can receive the info and show the tooltip
    this.articleInStock.emit(this.stock);
    this.dateChanged.emit(capPrice);
    this.currentPrice = this.getPriceInformation(capPrice);
  }

  /**
   * Gets price information from capacity price to show it in the box
   * @param capPrice CapacityPrice
   * @returns PriceInformation
   */
  getPriceInformation(capPrice: CapacityPriceList) {
    const priceInfo = new PriceInformation();
    priceInfo.scaledPrice = ArticlesListUtils.getScaledPriceByAmount(this.scaledPrices, this.requestArticle.amount);
    this.currentScaledPrice.emit(priceInfo.scaledPrice);
    priceInfo.amount = this.requestArticle.amount;
    if (capPrice == null) {
      priceInfo.priceStatus = CapacityPriceStatus.NA;
      priceInfo.currency = CurrencyType.EUR;
      return priceInfo;
    }
    priceInfo.currency = capPrice.currency;
    priceInfo.dateText = DateUtils.getFormattedDate(capPrice.date, true);
    priceInfo.date = DateUtils.cloneDate(capPrice.date);
    priceInfo.priceStatus = CapacityPriceStatus[capPrice.priceStatus];
    priceInfo.priceTotal = capPrice.price;
    return priceInfo;
  }

  /**
   * On click on best price box show it in the diagram
   * @param date Date
   */
  chooseBestPriceBox(date: Date) {
    if (this.bestPrice.priceStatus !== CapacityPriceStatus.LOW
      || this.bestPrice.dateText === this.currentPrice.dateText || this.bestPriceLoading) {
      return;
    }
    this.moveToDate(date);
  }

  /**
   * Moves to the date and shows it as selected
   * @param date Date
   */
  moveToDate(date: Date) {
    this.loadingChanges = true;
    this.loading = true;
    this.showStartDate = DateUtils.cloneDate(date);
    this.showEndDate = DateUtils.cloneDate(date);
    this.selectedDate = DateUtils.cloneDate(date);
    DateUtils.plusDays(this.showStartDate, -5);
    DateUtils.plusDays(this.showEndDate, 5);
    if (this.selectedDate <= this.dateToLoadLess) {
      this.loadLess(true);
    }
    else if (this.selectedDate >= this.dateToLoadMore) {
      this.loadMore(true);
    } else {
      this.showCapacityDemand(this.showStartDate, this.showEndDate);
    }
  }

  /**
   * on mouse start enable dragging and initialize mouse position values
   * @param event MouseEvent
   */
  onMouseStart(event: MouseEvent) {
    this.draggable = true;
    this.lastMousePos = event.clientX;
  }

  /**
   * Disable dragging
   * @param event MouseEvent
   */
  disableDragging(event: MouseEvent) {
    this.draggable = false;
  }

  /**
   * On mouse over move to the left or right in the diagram
   * @param event MouseEvent
   */
  onMouseOver(event: MouseEvent) {
    if (this.draggable && !this.loading) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (event.clientX < (this.lastMousePos - 40)) {
          this.lastMousePos = event.clientX;
          this.goRight();
        }
        if (event.clientX > (this.lastMousePos + 40)) {
          this.lastMousePos = event.clientX;
          this.goLeft();
        }
      }, 15);
    }
    event.preventDefault();
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
