import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UserRoleType } from 'src/app/shared/models/user-role-type.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PasswordResetRequest } from './../../../shared/models/password-reset-request.model';
import { UserInfo } from './../../../shared/models/user-info.model';
import { UserStatusType } from './../../../shared/models/user-status-type.model';
import { TranslateService } from "@ngx-translate/core";
import { ManageUsersService } from './../../../shared/services/manage-users.service';
import { UserService } from './../../../shared/services/user.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  users: UserInfo[];
  UserStatusType = UserStatusType;
  roles = [UserRoleType.ADMIN, UserRoleType.ACCOUNT_MANAGER];
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    private manageUsersService: ManageUsersService,
    private userService: UserService, private toastService: ToastService,
    private translationsService: TranslateService
  ) { }

  ngOnInit(): void {
    this.manageUsersService.getUsers().pipe(takeUntil(this.unsubscribe)).subscribe((data: UserInfo[]) => { this.users = data; });
  }

  /**
   * Gets a translated role name
   * @param role role
   * @returns role name
   */
  getRoleName(role: string) {
    role = role.replace(/_/g, '-');
    return this.translationsService.instant('codeTranslations.'+role.toLowerCase());
  }

  /**
   * Assigns role to a user
   * @param idUser idUser
   * @param role role
   * @param userIndex userIndex
   */
  assignRole(idUser, role, userIndex) {
    if (this.users[userIndex].status === UserStatusType.VIRTUAL) {
      return;
    }
    this.manageUsersService.assignRole({ idUser, role }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.users[userIndex].roles.push(role);
      this.toastService.show(this.translationsService.instant('codeTranslations.role-assigned'),
        { classname: 'bg-success text-light', delay: 2000 });
    }, error => {
      this.toastService.show(this.translationsService.instant('codeTranslations.manage-user-error'),
        { classname: 'bg-danger text-light', delay: 2000 });
    });
  }

  /**
   * Removes role of a user
   * @param idUser idUser
   * @param role role
   * @param userIndex userIndex
   */
  removeRole(idUser, role, userIndex) {
    if (this.users[userIndex].status === UserStatusType.VIRTUAL) {
      return;
    }
    this.manageUsersService.removeRole({ idUser, role }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      const index = this.users[userIndex].roles.indexOf(role);
      if (index > -1) {
        this.users[userIndex].roles.splice(index, 1);
        this.toastService.show(this.translationsService.instant('codeTranslations.role-removed'),
          { classname: 'bg-success text-light', delay: 2000 });
      }
    }, error => {
      this.toastService.show(this.translationsService.instant('codeTranslations.manage-user-error'),
        { classname: 'bg-danger text-light', delay: 2000 });
    });
  }

  /**
   * Sends password change request
   * @param email email
   */
  sendPasswordChangeRequest(user) {
    if (user.status === UserStatusType.VIRTUAL) {
      return;
    }
    this.userService.resetPasswordRequest({ email: user.username } as PasswordResetRequest)
      .pipe(takeUntil(this.unsubscribe)).subscribe(data =>
        this.toastService.show(this.translationsService.instant('codeTranslations.reset-password-email-sent-admin'), { classname: 'bg-success text-light', delay: 5000 }),
        error => this.toastService.show(this.translationsService.instant('codeTranslations.manage-user-error'),
          { classname: 'bg-danger text-light', delay: 2000 }));
  }

  blockUser(user: UserInfo) {
    if (user.status === UserStatusType.VIRTUAL) {
      return;
    }
    this.manageUsersService.blockUser(user.idErp).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.toastService.show(this.translationsService.instant('codeTranslations.user-blocked'),
        { classname: 'bg-success text-light', delay: 5000 });
      user.status = UserStatusType.BLOCKED;
    }, error => this.toastService.show(this.translationsService.instant('codeTranslations.user-blocked-error') + error,
      { classname: 'bg-danger text-light', delay: 2000 }));
  }

  unblockUser(user: UserInfo) {
    if (user.status === UserStatusType.VIRTUAL) {
      return;
    }
    this.manageUsersService.unblockUser(user.idErp).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.toastService.show(this.translationsService.instant('codeTranslations.user-unblocked'),
        { classname: 'bg-success text-light', delay: 5000 });
      user.status = UserStatusType.DISABLED;
    }, error => this.toastService.show(this.translationsService.instant('codeTranslations.user-unblocked-error') + error,
      { classname: 'bg-danger text-light', delay: 2000 }));
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
