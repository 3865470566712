import { ScaledPrice } from './../../../../shared/models/scaled-price.model';
import { FileUploadService } from './../../../../shared/services/file-upload.service';
import { FileUtils } from './../../../../shared/utils/file.utils';
import { PdfService } from './../../../../shared/services/pdf.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, of, timer } from 'rxjs';
import { takeUntil, delay, switchMap } from 'rxjs/operators';
import { CapacityPriceStatus } from 'src/app/shared/models/capacity-price-status.enum';
import { CapacityPricesRequest } from 'src/app/shared/models/capacity-prices-request.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Article } from './../../../../shared/models/article.model';
import { ArticlesListMode } from './../../../../shared/models/articles-list-mode.enum';
import { CapacityPrice } from './../../../../shared/models/capacity-price.model';
import { CartArticle } from './../../../../shared/models/cart-article.model';
import { DatepickerColors } from './../../../../shared/models/datepicker-colors.model';
import { ArticlesService } from './../../../../shared/services/articles.service';
import { TranslateService } from "@ngx-translate/core";
import { ShoppingCartService } from './../../../../shared/services/shopping-cart.service';
import { ToastService } from './../../../../shared/services/toast.service';
import { ArticlesListUtils } from './../../../../shared/utils/articles-list.utils';
import { DateUtils } from './../../../../shared/utils/date.utils';
import { ArticleType } from 'src/app/shared/models/article-type.enum';
import { CapacityPriceList } from 'src/app/shared/models/capacity-price-list.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

export interface RequestQuery { idArticleERP: string; amount?: number; date?: Date | string; }
@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-articles-list-row]',
  templateUrl: './articles-list-row.component.html',
  styleUrls: ['./articles-list-row.component.scss'],
  animations: [
    trigger('fade',
      [
        transition(
          ':enter', [
          style({ opacity: 0 }),
          animate('500ms', style({ opacity: 1 }))
        ]
        ),
        transition(
          ':leave', [
          style({ opacity: 1 }),
          animate('500ms', style({ opacity: 0 })),

        ])
      ]
    )
  ]
})

export class ArticlesListRowComponent implements OnInit, OnDestroy {
  @ViewChild('myTip') public tooltip: NgbTooltip;
  @ViewChild('myPriceTip') public priceTooltip: NgbTooltip;
  @Input() article: Article;
  // enables some functions for cart mode
  @Input() articlesListMode = ArticlesListMode.ARTICLES;
  ArticlesListMode = ArticlesListMode;
  ArticleType = ArticleType;
  CapacityPriceStatus = CapacityPriceStatus;
  currentScaledPrice: ScaledPrice;
  @Input() cartArticle: CartArticle = null;
  @Input() index: number;
  @Input() amount: number;
  // On delete notifies parent component
  @Output() delete = new EventEmitter();
  @Output() articlesChanged = new EventEmitter();
  @Output() articleAddedToCart = new EventEmitter();
  // On update cartArticle notifies parent component
  @Output() capacityPriceChanges = new EventEmitter();
  priceChanged = new EventEmitter();
  // logged user
  public user: User;

  private unsubscribe: Subject<void> = new Subject();

  // show or hide prices on mobile
  public mobileCollapse = true;
  // show or hide diagram
  public diagramCollapse = true;
  // form control for article quantity
  public articleQuantityFromControl;
  // request query for article with the amount and the date
  public requestQuery: RequestQuery = null;
  // article capacity price object
  public articleDatePrice: CapacityPriceList = null;
  // timeout used to request the capacity price after few seconds
  public articlesInStock: number;
  public materialInStock: number;
  public showAvailableMaterial: boolean;
  public tooltipMessage: string;
  public timeout: any = null;
  public dateReset = false;
  public firstLoad = false;
  setNewDate = new EventEmitter();
  requestArticleChange = new EventEmitter();
  updateDiagram = false;
  public processing = false;
  public cartArticleInCart = this.cartArticle == null ? false : true;
  public innerWidth: any;
  public datepickerColors: DatepickerColors[];
  public fileToUpload: File = null;
  public uploadProgress = 0;
  public uploadFailed = false;
  public uploadSucceed = false;

  public uploadedFiles: string[];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private articlesService: ArticlesService,
    private datePipe: DatePipe,
    private shoppingCartService: ShoppingCartService,
    public toastService: ToastService,
    private pdfService: PdfService,
    private authenticationService: AuthenticationService,
    public translationsService: TranslateService,
    private fileUploadService: FileUploadService,
  ) {
    this.user = this.authenticationService.currentUserValue;
  }

  /**
   * On resize window update inner width
   * @param event window:resize event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.waitForProcessing();

    this.innerWidth = window.innerWidth;
    this.cartArticleInCart = this.cartArticle == null ? false : true;
    if (this.cartArticle) {
      this.articleQuantityFromControl = this.cartArticle.amount;
      this.setRequestQueryAmount(this.cartArticle.idArticleERP, this.cartArticle.amount);

    } else if (this.amount) {
      this.articleQuantityFromControl = this.amount;
      this.setRequestQueryAmount(this.article.idArticleERP, this.amount);
    }
  }

  /**
   * Show loading capacity price error
   */
  showCapacityPriceLoadingError(idArticle: string) {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.capprice-loading-error') + idArticle,
      { classname: 'bg-danger text-light', delay: 3000 }
    );
  }

  /**
   * Shows product added to cart message
   */
  showProductAddedToCartMessage(article: CartArticle) {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.' + article.valid ? 'shoppingcart-item-added' : 'shoppingcart-item-added-unvalid'),
      { classname: article.valid ? 'bg-success' : 'bg-warning' + ' text-light', delay: 3000 }
    );
  }

  /**
   * Shows product added to cart message
   */
  showProductRemovedFromCartMessage() {
    this.toastService.show(this.translationsService.instant('codeTranslations.shoppingcart-item-deleted'),
      { classname: 'bg-success text-light', delay: 3000 });
  }

  /**
   * Shows product added to cart message
   */
  showProductUpdatedInCartMessage() {
    this.toastService.show(this.translationsService.instant('codeTranslations.shoppingcart-item-updated'),
      { classname: 'bg-success text-light', delay: 1000 });
  }

  /**
   * Sets request query date
   * @param idArticle id Article
   * @param index index in array
   * @param date date
   */
  setRequestQueryDate(idArticleERP, date: Date) {
    // cancel timeout if there is already another process
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    const requestQuery: RequestQuery = { idArticleERP, date };
    this.requestQuery = { ...this.requestQuery, ...requestQuery };
    this.showProcessing();
    this.timeout = window.setTimeout(() => {
      this.timeout = null;
      // request article if amount is set
      this.requestArticle();
    }, 2000);
  }

  /**
   * Sets request query amount
   * @param idArticleERP id Article
   * @param index index in array
   * @param amount amount
   */
  setRequestQueryAmount(idArticleERP, amount) {
    // cancel timeout if there is already another process
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    const requestQuery: RequestQuery = { idArticleERP, amount };
    this.requestQuery = { ...this.requestQuery, ...requestQuery };
    this.showProcessing();
    // request article after two seconds if date is set
    this.timeout = window.setTimeout(() => {
      this.timeout = null;
      this.requestArticle();
    }, 2000);
    // for mobile size get datepicker colors
    if (this.innerWidth < 599) {
      this.requestCurrentMonthDatepickerColors();
    }
  }

  /**
   * Shows processing spinner
   */
  showProcessing() {
    const idArticleERP = this.requestQuery.idArticleERP;
    const date = this.requestQuery.date;
    const amount = this.requestQuery.amount;
    if (idArticleERP && idArticleERP !== null && date && date !== null && amount && amount !== null) {
      this.processing = true;
    }
  }

  /**
   * Requests article when every field is set
   * @param index index
   */
  requestArticle() {
    const idArticleERP = this.requestQuery.idArticleERP;
    const date = this.requestQuery.date;
    const amount = this.requestQuery.amount;
    if (idArticleERP && idArticleERP !== null && date && date !== null && amount && amount !== null) {
      this.getArticlePriceAndAvailability(date, amount, idArticleERP);
    } else {
      this.processing = false;
      this.diagramCollapse = true;
      this.articleDatePrice = null;
      this.currentScaledPrice = null;
    }
  }

  /**
   * Gets article price and availability
   * @param date selected date
   * @param amount selected amount
   * @param idArticleERP article id
   * @param index article index
   */
  getArticlePriceAndAvailability(date, amount, idArticleERP) {
    this.requestArticleChange.emit(this.requestQuery);
  }

  /**
   * Converts date to api format
   * @param date date
   * @returns date
   */
  convertDateToApiFormat(date) {
    if (date instanceof Date) {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    date = date.split('-').reverse().join('/');
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  /**
   * Adds article to cart
   */
  addOrRemoveArticle() {
    if (this.isCartMode) {
      this.removeFromCart();
    } else {
      this.dateReset = false;
      this.addToCart();
    }
  }

  /**
   * Adds article to cart
   */
  addToCart() {
    const date: Date = new Date(this.convertDateToApiFormat(this.requestQuery.date));
    this.shoppingCartService.addArticleToCart({
      idArticleERP: this.article.idArticleERP,
      amount: this.requestQuery.amount,
      price: this.articleDatePrice.price,
      basePrice: this.articleDatePrice.basePrice,
      shippingDate: date,
      articleType: this.article.articleType,
      idOfferPosition: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.idPositionERP : null,
      offerPosition: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.position : null,
      idOfferERP: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.idOfferERP : null,
      idFrameworkContractPosition: this.article.articleType === ArticleType.FRAMEWORK_CONTRACT ? this.article.idPositionERP : null,
      idFrameworkContractERP: this.article.articleType === ArticleType.FRAMEWORK_CONTRACT ? this.article.idFrameworkContractERP : null
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CartArticle) => {
        this.articleAddedToCart.emit();
        this.diagramCollapse = true;
        this.resetAllInputs();
        this.showProductAddedToCartMessage(data);
      });
  }

  /**
   * Updates article in cart
   */
  updateToCart() {
    const date: Date = new Date(this.convertDateToApiFormat(this.requestQuery.date));
    this.shoppingCartService.updateArticleInCart({
      idArticleERP: this.article.idArticleERP,
      amount: this.requestQuery.amount,
      price: this.articleDatePrice.price,
      basePrice: this.articleDatePrice.basePrice,
      shippingDate: date,
      idCartArticle: this.cartArticle.idCartArticle,
      articleType: this.article.articleType,
      idOfferPosition: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.idPositionERP : null,
      offerPosition: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.position : null,
      idOfferERP: this.article.articleType === ArticleType.OFFER_ARTICLE ? this.article.idOfferERP : null
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.articlesChanged.emit();
        this.showProductUpdatedInCartMessage();
      });
  }

  /**
   * Confirms price
   */
  confirmPrice() {
    this.cartArticle.priceConfirmed = true;
    this.updateToCart();
  }

  /**
   * Removes article from cart
   */
  removeFromCart() {
    this.shoppingCartService.deleteFromCart(this.cartArticle.idCartArticle)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.article = null;
        this.cartArticle = null;
        this.cartArticleInCart = false;
        this.delete.emit();
        this.showProductRemovedFromCartMessage();
      });
  }

  /**
   * Resets all inputs
   */
  resetAllInputs() {
    this.articleQuantityFromControl = '';
    this.articleDatePrice = null;
    this.dateReset = true;
  }

  /**
   * Receive capacity price event from diagram component
   * @param capPrice capacity price
   */
  onCapacityPriceChanged(capPrice: CapacityPriceList) {
    if (!capPrice) {
      this.showCapacityPriceLoadingError(this.article.idArticleERP);
      this.processing = false;
      return;
    }
    // show amount suggestion tooltip when amount suggested is more than 0
    if (capPrice.amountSuggestion > 0) {
      let translation = this.translationsService.instant('codeTranslations.min-position-suggestion');
      translation = translation.replace('{minPositionValue}', capPrice.minPositionValue);
      this.tooltipMessage = translation;
    }
    // show tooltip when price is filled up
    this.priceTooltip.close();
    if (capPrice.priceFilledUp && !this.priceTooltip.isOpen()) {
      this.priceTooltip.open();
    }
    this.setNewDate.emit(capPrice.date);
    this.articleDatePrice = capPrice;
    this.processing = false;
    this.requestQuery.date = DateUtils.cloneDate(capPrice.date);
    this.capacityPriceChanges.emit(capPrice);
    // open diagram when the status is low in articles mode
    if ((capPrice.priceStatus === CapacityPriceStatus.NA || capPrice.priceStatus === CapacityPriceStatus.HIGH)
      && this.articlesListMode === ArticlesListMode.ARTICLES) {
      this.diagramCollapse = false;
    }
    // on first loading show checkbox if the price has changed since the last calculation
    if (this.isCartMode && Math.round(this.cartArticle.price) !== Math.round(capPrice.price) && !this.firstLoad) {
      this.cartArticle.priceConfirmed = false;
      // emits a price change so the price-label component receives the change and shows the checkbox
      this.priceChanged.emit(true);
    }
    // in cart mode update the cart article in db after date or amount changes
    if (this.isCartMode && this.firstLoad) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      // wait 2 seconds before update after every change
      this.timeout = window.setTimeout(() => {
        this.updateToCart();
        this.cartArticle.price = capPrice.price;
      }, 2000);
    }
    if (this.isCartMode) {
      this.firstLoad = true;
    }
    this.tooltip.close();
    if (this.tooltipMessage && this.showTooltip && !this.tooltip.isOpen()) {
      this.tooltip.open();
    }
  }

  /**
   * Months selection change
   * @param event monthChangeEvent
   */
  monthSelectionChange(event: any) {
    this.requestCustomMonthDatepickerColors(event.month, event.year);
  }

  /**
   * Articles in stock received
   */
  articleInStockReceived({ articlesInStock, materialInStock, showAvailableMaterial }) {
    let translation;
    this.materialInStock = materialInStock;
    this.articlesInStock = articlesInStock;
    this.showAvailableMaterial = showAvailableMaterial;

    if (showAvailableMaterial && materialInStock !== null && materialInStock !== 0 && articlesInStock !== null && articlesInStock !== 0) {
      // if both material and articles in stock are available sum articles in stock to material in stock
      this.materialInStock = materialInStock + articlesInStock;
      translation = this.translationsService.instant('codeTranslations.articles-and-material-in-stock');
      translation = translation.replace('{articlesInStock}', articlesInStock);
      translation = translation.replace('{materialInStock}', materialInStock);
    } else if ((articlesInStock === null || articlesInStock === 0)
      && (materialInStock !== null && materialInStock !== 0 && showAvailableMaterial)
      && this.requestQuery.amount !== materialInStock) {
      translation = this.translationsService.instant('codeTranslations.material-in-stock');
      translation = translation.replace('{materialInStock}', materialInStock);
    } else if ((materialInStock === null || materialInStock === 0 || !showAvailableMaterial)
      && (articlesInStock !== null && articlesInStock !== 0)
      && this.requestQuery.amount !== articlesInStock) {
      translation = this.translationsService.instant('codeTranslations.articles-in-stock');
      translation = translation.replace('{articlesInStock}', articlesInStock);
    } else {
      translation = null;
    }
    this.tooltipMessage = translation;
  }


  /**
   * Requests custom month datepicker colors
   * @param month number
   * @param year number
   */
  requestCustomMonthDatepickerColors(month: number, year: number) {
    const dateToday = new Date(year, month, 1);

    const lastDay = DateUtils.getLastDayOfMonth(year, month);
    const capacityRequest: CapacityPricesRequest = {
      idArticleERP: this.requestQuery.idArticleERP,
      amount: this.requestQuery.amount,
      startDate: new Date(this.convertDateToApiFormat(dateToday)),
      endDate: new Date(this.convertDateToApiFormat(lastDay)),
      articleType: this.article.articleType
    };
    if (this.article.articleType === ArticleType.OFFER_ARTICLE) {
      capacityRequest.idOfferPosition = this.article.idPositionERP;
      if (this.article.existingArticle === false) {
        capacityRequest.idOfferERP = this.article.idOfferERP;
        capacityRequest.positionOffer = this.article.position.toString();
      }
    }
    this.getDatepickerColors(capacityRequest);
  }

  /**
   * Requests current month datepicker colors
   */
  requestCurrentMonthDatepickerColors() {
    this.datepickerColors = [];
    let dateToday = new Date();
    let lastDay = new Date();
    if (this.articlesListMode === ArticlesListMode.CART) {
      const dateTemp = new Date(this.cartArticle.shippingDate);
      dateToday = new Date(dateTemp.getFullYear(), dateTemp.getUTCMonth(), 1);
    }
    lastDay = DateUtils.getLastDayOfMonth(lastDay.getFullYear(), lastDay.getMonth());
    const capacityRequest: CapacityPricesRequest = {
      idArticleERP: this.requestQuery.idArticleERP,
      amount: this.requestQuery.amount,
      startDate: new Date(this.convertDateToApiFormat(dateToday)),
      endDate: new Date(this.convertDateToApiFormat(lastDay)),
      articleType: this.article.articleType
    };
    if (this.article.articleType === ArticleType.OFFER_ARTICLE) {
      capacityRequest.idOfferPosition = this.article.idPositionERP;
      if (this.article.existingArticle === false) {
        capacityRequest.idOfferERP = this.article.idOfferERP;
        capacityRequest.positionOffer = this.article.position.toString();
      }
    }
    this.getDatepickerColors(capacityRequest);
  }

  /**
   * Gets datepicker colors
   * @param capacityRequest CapacityPricesRequest
   */
  getDatepickerColors(capacityRequest: CapacityPricesRequest) {
    this.articlesService.getArticleCapacityPrices(capacityRequest).pipe(takeUntil(this.unsubscribe))
      .subscribe((result: CapacityPrice) => {
        result.capacityPricesList.forEach(capacityPrice => {
          const datepickerColor = new DatepickerColors();
          datepickerColor.color = ArticlesListUtils.getStatusColor(capacityPrice.priceStatus);
          capacityPrice.date = new Date(capacityPrice.date);
          datepickerColor.day = capacityPrice.date.getDate();
          datepickerColor.month = capacityPrice.date.getMonth() + 1;
          datepickerColor.year = capacityPrice.date.getFullYear();
          this.datepickerColors.push(datepickerColor);
        });
      });
  }

  /**
   * Downloads pdf
   */
  downloadPdf(idArticleErp: string) {
    this.pdfService.getArticlePdf(idArticleErp).subscribe((data: any) => {
      if (data.size && data.size > 0) {
        const blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
        FileUtils.downloadFile(blob, this.article.idArticleERP);
      }
    });
  }

  /**
   * Assigns file to fileToUpload object
   */
  addFile(file) {
    this.uploadProgress = 0;
    this.uploadFailed = false;
    this.uploadSucceed = false;
    this.fileToUpload = file.item(0);
  }






  /**
   * Gets button disabled status
   */
  get buttonDisabled() {
    if (this.processing && !this.isCartMode) {
      return true;
    }
    if (this.cartArticleInCart) {
      return false;
    } else {
      if (this.timeout !== null) {
        return true;
      }
      if (!this.articleDatePrice || !ArticlesListUtils.checkStatusIsAvailable(this.articleDatePrice.priceStatus, true)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets show id
   */
  get showId() {
    if (this.article.articleType === ArticleType.OFFER_ARTICLE) {
      return this.article.existingArticle;
    } else {
      return this.article.idArticleERP !== null;
    }
  }

  /**
   * Gets default date
   */
  get defaultDate() {
    if (this.isCartMode) {
      if (this.cartArticle) {
        return this.cartArticle.shippingDate;
      }
    } else {
      return null;
    }
  }

  /**
   * Gets whether is cart mode
   */
  get isCartMode() {
    return ArticlesListUtils.isCartMode(this.articlesListMode);
  }

  /**
   * Gets whether is articles mode
   */
  get isArticlesMode() {
    return ArticlesListUtils.isArticlesMode(this.articlesListMode);
  }

  /**
   * Gets whether to show tooltip
   */
  get showTooltip() {
    return this.articleDatePrice && (this.articleDatePrice.priceStatus === CapacityPriceStatus.NA
      || this.articleDatePrice.priceStatus === CapacityPriceStatus.NO_MATERIAL
      || (this.articleDatePrice.priceStatus === CapacityPriceStatus.MIN_POSITION && this.articleDatePrice.amountSuggestion !== 0));
  }

  private waitForProcessing() {
    this.processing = false; // Set processing to false initially

    this.processingChanges().pipe(
      switchMap((processing) => {
        if (processing) {
          // If processing is true, start a timer that emits after 30 seconds
          return timer(30000).pipe(
            switchMap(() => of(processing)) // Convert boolean to an observable
          );
        } else {
          // If processing becomes false, reset the timer
          this.requestArticle();
          return of(false);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe((processed) => {
      // After 30 seconds, if processing is still true, set it to false
      if (processed) {
        this.toastService.show(this.translationsService.instant('global.timedOut'),{ classname: 'bg-danger text-light', delay: 3000 });
        this.processing = false;
      }
    });
  }

  private processingChanges() {
    return this.priceChanged.pipe(
      switchMap(() => of(this.processing)) // Convert boolean to an observable
    );
  }


  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
