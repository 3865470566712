<div class="se-table-row align-items-center se-table-body" [formGroup]="textblockForm" *ngIf="textblock || createMode">
    <div class="se-table-col pos-col">
        <span *ngIf="textblock?.idTextblock">{{textblock.idTextblock}}</span>
    </div>
    <div class="se-table-col lg">
        <input type="text" class="form-control se-input" placeholder="Beschreibung (DE)" *ngIf="editMode" formControlName="textDe">
        <span *ngIf="!editMode && textblock?.textDe">{{textblock.textDe}}</span>
    </div>
    <div class="se-table-col lg">
        <input type="text" class="form-control se-input" placeholder="Beschreibung (EN)" *ngIf="editMode" formControlName="textEn">
        <span *ngIf="!editMode && textblock?.textEn">{{textblock.textEn}}</span>
    </div>
    <div class="se-table-col sm d-flex">
        <ng-container *ngIf="!editMode">
            <app-button class="w-50 mr-1" type="button" [height]="'35px'" (click)="editMode = true"><i class="bi bi-pencil"></i></app-button>
            <app-button class="w-50 ml-1" type="button" [height]="'35px'" (click)="delete()"><i class="bi bi-trash3"></i></app-button>
        </ng-container>
        <ng-container *ngIf="editMode">
            <app-button class="w-50 mr-1" type="button" [height]="'35px'" (click)="save()"><i class="bi bi-check2"></i></app-button>
            <app-button class="w-50 ml-1" type="button" [height]="'35px'" (click)="cancelCreateMode(); editMode = false"><i class="bi bi-x"></i></app-button>
        </ng-container>
    </div>
</div>


