export class FileUtils {

    /**
     * Downloads file
     * @param file Blob
     * @param name string
     */
    public static downloadFile(file: Blob, name: string) {
        const fileToDownload = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.hidden = true;
        document.body.appendChild(a);
        a.href = fileToDownload;
        a.download = name;
        a.click();
        document.body.removeChild(a);
    }
}
