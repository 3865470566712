import { ProfileComponentService } from '../../../../../../shared/services/profile-component.service';
import { ProfileValidationService } from '../../../../../../shared/services/profile-validation.service';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';
import { ProfileComponentMaterialLine } from '../../../../../../shared/models/profile-component-material-line.model';
import { MaterialProfileMeasure } from '../../../../../../shared/models/material-profile-measure.model';
import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ProfileComponentMaterial } from 'src/app/shared/models/profile-component-material.model';
import { ConfiguratorTemplateComponent } from '../configurator-template/configurator-template.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from '@angular/core';
import { ProfileComponentMaterialLineComponent } from './profile-component-material-line/profile-component-material-line.component';

@Component({
  selector: 'app-profile-component-material',
  templateUrl: './profile-component-material.component.html',
  styleUrls: ['./profile-component-material.component.scss']
})

export class ProfileComponentMaterialComponent implements OnInit, OnChanges {
  @Input() materialProfileMeasure: MaterialProfileMeasure;
  @Input() profileComponent: ProfileComponent;
  @Input() profileComponentMaterial: ProfileComponentMaterial;
  @Output() profileComponentMaterialChange = new EventEmitter<ProfileComponentMaterial>();

  @ViewChildren(ProfileComponentMaterialLineComponent) childComponents: QueryList<ProfileComponentMaterialLineComponent>;

  invalidIds = [];
  profileComponentMaterialLineIds = [];
  profileComponentMaterialLines: ProfileComponentMaterialLine[] = [];
  materialProfileMeasureInvalid = false;
  validateForms = new EventEmitter<number>();
  timer: any;
  loadingTemplate: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private profileComponentService: ProfileComponentService, private profileValidationService: ProfileValidationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.profileComponentService.getProfileComponentMaterialLines(this.profileComponentMaterial.idProfileComponentMaterial, this.materialProfileMeasure.idMaterialProfileMeasure).subscribe(data => {
      this.profileComponentMaterialLines = data;


      if (!this.profileComponent.valid && this.profileComponentMaterialLines.length > 0) {
        this.invalidIds = ['measure-' + this.materialProfileMeasure.idMaterialProfileMeasure];
      }
      // check material profile measure for validity
      this.materialValidate();

      
    });
  }

  /**
   * Validates material lines 
   */
  materialValidate() {
    this.profileComponentService.getProfileComponentMaterialLines(this.profileComponentMaterial.idProfileComponentMaterial, this.materialProfileMeasure.idMaterialProfileMeasure).subscribe(data => {
      this.profileComponentMaterialLines.forEach(x => {
        // find in data
        const found = data.find(y => y.idProfileComponentMaterialLine == x.idProfileComponentMaterialLine);
        x.valid = found.valid;
      });
      // if all lines are valid, set material profile measure valid property to false
      this.materialProfileMeasureInvalid = this.profileComponentMaterialLines.filter(x => x.valid == false).length > 0;
    });
  }

  ngOnChanges(changes: SimpleChanges): void { }



  /**
   * Adds profile component material line
   */
  addProfileComponentMaterialLine() {
    let newLine = new ProfileComponentMaterialLine();
    newLine.idProfileComponentMaterialFK = this.profileComponentMaterial.idProfileComponentMaterial;
    newLine.materialProfileMeasure = this.materialProfileMeasure;
    this.profileComponentMaterialLines.push(newLine);
  }

  addProfileComponentMaterialLine2(): void {
    let newLine = new ProfileComponentMaterialLine();
    newLine.idProfileComponentMaterialFK = this.profileComponentMaterial.idProfileComponentMaterial;
    newLine.materialProfileMeasure = this.materialProfileMeasure;
  
    // Agregamos la nueva línea a la lista local.
    this.profileComponentMaterialLines.push(newLine);
  
    // Luego de agregar la línea, podemos esperar un ciclo de detección de cambios para asegurarnos de que el ngOnInit del componente hijo se haya ejecutado.
    setTimeout(() => {
      // Puedes realizar otras acciones aquí después de que se haya ejecutado el ngOnInit del componente hijo.
    },1000);
  }
  

  

   /**
   * Adds profile component material line
   */
   async addProfileComponentMaterialLineFromTemplate(newLine2 : ProfileComponentMaterialLine) {

    let newLine = new ProfileComponentMaterialLine();
    newLine.idProfileComponentMaterialFK = this.profileComponentMaterial.idProfileComponentMaterial;
    newLine.materialProfileMeasure = this.materialProfileMeasure;
    
    newLine.fromValue = newLine2.fromValue;
    newLine.toValue = newLine2.toValue;
    newLine.processingTime = newLine2.processingTime;
    newLine.setupUnit = newLine2.setupUnit;
    newLine.fromTemplate = true;
    this.profileComponentMaterialLines.push(newLine);

  }

  /**
   * Deletes profile component material line
   * @param index 
   */
  deleteProfileComponentMaterialLine(index: number) {
    this.profileComponentMaterialLines.splice(index, 1);
    this.profileValidationService.updateValidations();
    this.materialValidate();
    this.validateForms.emit(null);
  }

  /**
   * Profiles component material line change
   * @param order 
   * @param line 
   */
  profileComponentMaterialLineChange(order, line: ProfileComponentMaterialLine) {
    this.profileComponentMaterialLines[order] = line;
    this.materialValidate();
    this.validateForms.emit(line.idProfileComponentMaterialLine);
  }

  /**
   * Opens Pop-Up Component to apply template.
   */
  openTemplateModal() {
    const modalRef = this.modalService.open(ConfiguratorTemplateComponent)
    modalRef.componentInstance.materialProfileMeasure = this.materialProfileMeasure;
    modalRef.componentInstance.profileComponent = this.profileComponent;
    modalRef.componentInstance.profileComponentMaterial = this.profileComponentMaterial;
    modalRef.componentInstance.profileComponentMaterialLines = this.profileComponentMaterialLines;
    modalRef.componentInstance.templatesListMode;
    
    modalRef.componentInstance.onApplyTemplate.subscribe(async (result: ProfileComponentMaterialLine[]) => {

      await this.deleteElementsSequentially();
      this.profileComponentMaterialLines = [];
      let index = 0;
      for (const line of result) {
        try {
          await this.addProfileTemplateLineToDatabase(index,line);
          index++;
        } catch (error) {
        }
      }
    });  
  }

  /**
   * Adds a template line to the database of tempalte lines and pushes it onto the frontend.
   * @param order Index of the line to be added.
   * @param line  Line to be added.
   * @returns 
   */
  async addProfileTemplateLineToDatabase(order: number, line: ProfileComponentMaterialLine) {
    return new Promise<void>((resolve, reject) => {
      this.profileComponentService.createProfileComponentMaterialLine(
        line,
        this.materialProfileMeasure.idMaterialProfileMeasure,
        this.profileComponentMaterial.idProfileComponentMaterial
      ).subscribe(data => {
        this.modify(line, data);
        line.fromTemplate = true;
        this.profileComponentMaterialLines.push(line);
        this.profileComponentMaterialLineChange(order,line);
        this.profileValidationService.updateValidations();
        resolve();
      }, error => {
        reject(error);
      });
    });
  }


  modify(obj, newObj) {

    Object.keys(obj).forEach(function (key) {
      delete obj[key];
    });

    Object.keys(newObj).forEach(function (key) {
      obj[key] = newObj[key];
    });

  }

  /**
   * Deletes all the actual lines of the profile component sequentally.
   */
  async deleteElementsSequentially() {

    if(this.profileComponentMaterialLines.length > 0) {
      this.loadingTemplate = true;
      let copyMaterialLines = [...this.profileComponentMaterialLines];
      this.profileComponentMaterialLines = [];
      if(copyMaterialLines.length <= 0) return;
  
      for (let index = copyMaterialLines.length - 1; index >= 0; index--) {
        const lineBefore = copyMaterialLines[index];
        if (!lineBefore?.idProfileComponentMaterialLine) {
        } else {
          await this.profileComponentService.deleteProfileComponentMaterialLine(lineBefore.idProfileComponentMaterialLine).toPromise();
        }
      }
      this.profileValidationService.updateValidations();
      this.materialValidate();
      this.validateForms.emit(null);
      this.loadingTemplate = false;
    } 
  }
  


}
