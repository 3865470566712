import { Textblock } from './../models/textblock.model';
import { environment } from './../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextblockService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  /**
   * Adds textblock
   * @param textblock textblock
   * @returns textblock
   */
  addTextblock(textblock: Textblock) {
    return this.http.post(`${this.apiUrl}/textblocks`, textblock).pipe(
      catchError(error => {
        console.error('There was an error while creating a textblock, please contact Haenssler.');
        return of();
      })
    );
  }

  /**
   * Gets textblocks
   * @returns textblocks
   */
  getTextblocks() {
    return this.http.get(`${this.apiUrl}/textblocks`).pipe(
      catchError(error => {
        console.error('There was an error while creating a textblock, please contact Haenssler.');
        return of();
      })
    );
  }

  /**
   * Edits textblock
   * @param textblock textblock
   * @returns textblock
   */
  editTextblock(textblock: Textblock) {
    return this.http.put(`${this.apiUrl}/textblocks`, textblock).pipe(
      catchError(error => {
        console.error('There was an error while creating a textblock, please contact Haenssler.');
        return of();
      })
    );
  }

  /**
   * Deletes textblock
   * @param id textblock
   * @returns void
   */
  deleteTextblock(id: number) {
    return this.http.delete(`${this.apiUrl}/textblocks/${id}`).pipe(
      catchError(error => {
        console.error('There was an error while creating a textblock, please contact Haenssler.');
        return of();
      })
    );
  }

}
