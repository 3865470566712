<div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" [autoClose]="false">
    <button type="button" class="btn show-prices" (click)="loadScaledPrices()" id="dropdownScaledPrices"
        ngbDropdownToggle>
        <span class="d-flex align-items-center">
            <div class="mr-2 d-none d-md-block"> 
                <span *ngIf="currentScaledPrice; else scaledPriceDropdown" @fade>
                    {{(currentScaledPrice.price | currency: 'EUR' )+' / '+currentScaledPrice.quantityUnit || '-'}} 
                    <img class="icon-open" src="assets/img/icons/chevron-{{tableCollapse ? 'right' : 'down'}}.svg" alt="dropdown icon" i18n>
                </span>    
            <ng-template #scaledPriceDropdown >
                <span @fade>
                    {{'shared.scaledPrices.showPrices'|translate}}
                    <img class="icon-open" src="assets/img/icons/chevron-{{tableCollapse ? 'right' : 'down'}}.svg" alt="dropdown icon" i18n>
                </span> 
            </ng-template>
            </div>
        </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownScaledPrices">
        <table class="se-table table scaled-prices-table table-striped"
            *ngIf="!tableCollapse && article.scaledPrices && article.scaledPrices.scaledPriceList && article.scaledPrices.scaledPriceList.length > 0"
            @fade>
            <th i18n class="text-right" i18n *ngIf="article.articleType !== ArticleType.FRAMEWORK_CONTRACT">{{'shared.scaledPrices.from'|translate}}</th>
            <th i18n class="text-right" i18n *ngIf="article.articleType !== ArticleType.FRAMEWORK_CONTRACT">{{'shared.scaledPrices.to'|translate}}</th>
            <th i18n class="text-right" i18n *ngIf="article.articleType === ArticleType.FRAMEWORK_CONTRACT">{{'shared.scaledPrices.amount'|translate}}</th>
            <th i18n>{{'shared.scaledPrices.me'|translate}}</th>
            <th i18n class="text-center" i18n>{{'shared.scaledPrices.price'|translate}}</th>
            <tbody>
                <tr *ngFor="let scaledPrice of article.scaledPrices.scaledPriceList">
                    <td class="text-right">{{scaledPrice.fromUnits}}</td>
                    <td class="text-right" *ngIf="article.articleType !== ArticleType.FRAMEWORK_CONTRACT">{{scaledPrice.toUnits}}</td>
                    <td i18n>ST</td>
                    <td>{{scaledPrice.price}} {{scaledPrice.currency}} / {{scaledPrice.quantityUnit}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="!this.article.scaledPrices.valid" class="unvalidated-price">
                <tr>
                    <td colspan="4"><span *ngIf="!this.article.scaledPrices.valid" i18n>{{'shared.scaledPrices.notUpdatedPrices'|translate}}</span></td>
                </tr>
            </tbody>
        </table>
        <div class="not-available" *ngIf="errorOnLoading">
            <span i18n>{{'shared.scaledPrices.noPricesAvailableForArticle'|translate}}</span>
        </div>
        <app-loading-spinner
            *ngIf="(!article.scaledPrices || !article.scaledPrices.scaledPriceList || article.scaledPrices.scaledPriceList.length == 0) && errorOnLoading == false"
            [size]="15"></app-loading-spinner>
    </div>
</div>
