<div class="container-fluid">
    <div class="user-form">
        <div class="user-form__background"></div>
        <form class="user-form__form wsa-panel">
            <div class="user-form__header">
                <h1 class="user-form__title" i18n>{{'shared.editProfile.editProfilePicture'|translate}}</h1>
            </div>
            <div class="user-form__body">
                <div class="user-form__input-container">
                    <div class="user-form__avatar">
                        <img *ngIf="croppedImage==null && profileImage" [src]="profileImage+'?'+currTime">
                        <img *ngIf="croppedImage!=null && profileImage" [src]="croppedImage">
                        <div class="user-form__avatar-edit" (click)="open(content)">
                            <i class="bi bi-pencil"></i>
                        </div>
                    </div>
                </div>
                <div class="user-form__input-container">
                    <input type="text" name="email" disabled readonly [value]="user.username"
                        class="user-form__input no-icon-input se-input"
                        placeholder="{{'shared.editProfile.email'|translate}}">
                </div>
                <app-button label="{{'shared.editProfile.save'|translate}}" padding="10px" i18n
                    [disabled]="!saveActive" class="user-form__button" (onClick)="onSubmit()">
                </app-button>
                <a class="user-form__footer-link" [routerLink]="['/update-password/me']">>{{'shared.editProfile.changePassword'|translate}}</a>
            </div>
        </form>
    </div>
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click'); croppedImage=null;">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ngx-dropzone [disableClick]="base64Output !== null" (change)="onSelect($event)" (onRemove)="onRemove($event)" [maxFileSize]="5242880" [multiple]="false"
                accept="image/*">
                <ngx-dropzone-label>
                    <div class="text-center">
                        <div class="upload-icon">
                            <i class="bi bi-upload"></i>
                        </div>
                        <div class="upload-text" i18n>
                            <u>
                                {{'shared.editProfile.chooseAnImage'|translate}}
                            </u>
                            <br>{{'shared.editProfile.selectInThisField'|translate}}
                        </div>
                    </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngIf="base64Output" [removable]="true" (removed)="onRemove($event)">
                    <ngx-dropzone-label>
                        <image-cropper [imageBase64]="base64Output" [maintainAspectRatio]="true" [aspectRatio]="3 / 4"
                            [resizeToWidth]="128" format="png" (imageCropped)="imageCropped($event)">
                        </image-cropper>
                    </ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
            <div class="d-flex justify-content-end mt-2">
                <app-button (click)="d('Button click');croppedImage=null;" i18n>{{'shared.editProfile.abort'|translate}}</app-button>
                <app-button (click)="d('Button click')" class="ml-2" i18n>{{'shared.editProfile.done'|translate}}</app-button>
            </div>
        </div>
    </ng-template>
</div>
