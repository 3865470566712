import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from './../../../environments/environment';
import { User } from 'src/app/shared/models/user.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private apiUrl = environment.apiUrl;
  private user: User;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  /**
   * Gets logs files
   * @returns logs files
   */
  getLogsFiles(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/logs/${this.user.id}/`);
  }

  /**
   * Gets logs by logs file name
   * @param fileName file name
   * @param [numLines] number of lines
   * @returns logs
   */
  getLogs(fileName: string, numLines = -1): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/logs/${this.user.id}/${fileName}/${numLines}/`);
  }
}
