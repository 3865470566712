import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';
import { Profile } from 'src/app/shared/models/profile.model';
import { MaterialProfileService } from 'src/app/shared/services/material-profile.service';
import { MaterialTablePopupComponent } from '../../material-table-popup/material-table-popup.component';
import { ScaledPricesList } from './../../../../shared/models/scaled-price-list.model';
import { ImagesService } from './../../../../shared/services/images.service';
import { OrderSealService } from './../../../../shared/services/order-seal.service';
import { ToastService } from './../../../../shared/services/toast.service';
import { MeasureAbasType } from 'src/app/shared/models/measure-abas-type.enum';

@Component({
	selector: 'app-order-seal-select-measures-and-material',
	templateUrl: './order-seal-select-measures-and-material.component.html',
	styleUrls: ['./order-seal-select-measures-and-material.component.scss'],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(1000, style({ opacity: 1 }))
			]),
		]),
		trigger('fadeOut', [
			state('visible', style({ opacity: 1, display: 'flex' })), // Visible state
			state('hidden', style({ opacity: 0, display: 'none' })), // Hidden state
			transition('visible => hidden', animate('1s 1.5s')),
			transition('hidden => visible', animate('1s')),
		]),

	]
})

export class OrderSealSelectMeasuresAndMaterialComponent implements OnInit, OnDestroy {

	private unsubscribe: Subject<void> = new Subject();
	@ViewChild('svg') svgEl;
	materials: any[] = [{ name: 'test', id: 1 }, { name: 'test2', id: 2 }];
	selectedMaterials = [null, null];
	selectedProfile: Profile;
	components: ProfileComponent[];
	timer: any;
	scaledPricesForm: UntypedFormGroup;
	formDisabled = false;
	scaledPricesList: ScaledPricesList;
	loading = false;
	inputs: any[];
	formulas = [];
	matchTranslation = [];
	translation = "";
	afterFormula = "";

	selectedMaterialID: any;
	selectedMaterial: any;

	svgErrors: any[];
	measuresValid = false;			// If you set this value to true it will allow the button "Select Material". Just set it once.
	materialValid = false;			// If you set this value to true it will allow the button "Calculate Material Price". Just set it once.
	showChooseMaterial = false;
	showScaledPrices = false;
	quantityInputSvgBackendValidated = 0;

	constructor(
		private fb: UntypedFormBuilder,
		public orderSealService: OrderSealService,
		private imagesService: ImagesService,
		private httpClient: HttpClient,
		private renderer: Renderer2,
		private toastService: ToastService,
		public translationsService: TranslateService,
		private router: Router,
		private el: ElementRef,
		private modalService: NgbModal,
		private materialProfileService: MaterialProfileService,
	) {
		this.materialProfileService.applyMaterial.subscribe((i: number) => {
			this.applyMaterialBase(i);
		});
	}

	ngOnInit(): void {
		this.translationsService.use(localStorage.getItem('language'));

		this.selectedProfile = this.orderSealService.selectedProfileValue;

		this.components = this.selectedProfile.profileComponents.filter(component => component.profileComponentMaterials && component.profileComponentMaterials.length > 0);
		console.log(this.components)
		// get svg from api
		this.httpClient.get(this.imageDimensions, { responseType: 'text' }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
			// set svg to div element
			this.renderer.setProperty(this.svgEl.nativeElement, 'innerHTML', data);

			// get all input elements in svg
			this.inputs = this.svgEl.nativeElement.childNodes[2].querySelectorAll('input');



			const readOnlyInputs = this.svgEl.nativeElement.childNodes[2].querySelectorAll('foreignObject[readonly="true"] input');

			// loop through all input elements
			for (let i = 0; i < this.inputs.length; i++) {
				// get profile item if its already assigned to input
				const profileMeasureInputLine = this.orderSealService.selectedProfileValue.profileMeasureInputLines.find(x => x.idSvgInput === this.inputs[i].parentElement.id);
				// create labels for inputs inside svg
				if (profileMeasureInputLine) {
					const label = document.createElement('label');
					label.style.position = 'absolute'; label.style.bottom = '30px'; label.style.fontSize = '8px'; label.style.width = 'max-content'; label.style.opacity = "0";
					this.inputs[i].parentElement.style.overflow = 'visible'; label.innerHTML = `${profileMeasureInputLine.name} (${profileMeasureInputLine.fromValue}mm - ${profileMeasureInputLine.toValue}mm)`;

					// assign min and max values to input element
					this.inputs[i].min = profileMeasureInputLine.fromValue;
					this.inputs[i].max = profileMeasureInputLine.toValue;
					this.inputs[i].parentElement.prepend(label);

					this.inputs[i].addEventListener('mouseover', (event) => {
						label.style.opacity = "1";
					});

					this.inputs[i].addEventListener('mouseout', (event) => {
						label.style.opacity = "0";
					})

					// add event listener to validate inputs on change
					this.inputs[i].addEventListener('change', (e) => {
						this.validateSvgInputs();
						if (readOnlyInputs && readOnlyInputs.length > 0) {
							// calculate readonly width input value on change
							for (let j = 0; j < readOnlyInputs.length; j++) {
								this.recalculateReadOnly(readOnlyInputs[j]);
							}
						}
					});
				}
			}
		});

		this.createForm();

		// the next lines trigger validation on all quantity inputs when one input is changed otherwise only the changed input is validated
		const control = this.scaledPricesForm.get('scaledPricesLines') as UntypedFormArray;
		this.scaledPricesForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
			control.controls.forEach((c, index) => {
				c.get('quantity').updateValueAndValidity({ emitEvent: false });
			});
		});

		this.scaledPricesForm.controls.componentMaterials.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
			const readOnlyInput = this.svgEl.nativeElement.childNodes[2].querySelector('foreignObject[readonly="true"] input');
			this.validateSvgInputs();
			this.recalculateReadOnly(readOnlyInput);
		});


		this.initFormulas();
		this.initSvgValidation();
	}

	
	/**
	 * Gets measure abas value introduced by user.
	 * @param i : Index of the svgInput
	 * @returns 
	 */
	getInputValue(i: number) {
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		const inputById = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[i].idSvgInput} input`);
		return parseInt(inputById.value);
	}


	/**
	 * Checks if a formula is valid or not.
	 * @param formula Formula to be checked.
	 * @param indexSvgInput input which owns the formula
	 * @param indexFormula Index of the formular for that svgInput.
	 * @returns If the formula is valid or not.
	 */
	isFormulaValid(formula: string, indexSvgInput: number, indexFormula: number) {

		// Use a regular expression to match and extract parts of the formula
		const match = formula.match(/({[0-9]+})([><=]=?)(.*)/);

		// Helper function to extract and calculate values from operands
		const extractValue = (operand: string): number => {
			// Match the operand against a pattern that captures the value and operation
			const sumMinMatch = operand.match(/(\{[0-9]+\})([+-]=?)([0-9]+)/);
			let value: number;

			if (sumMinMatch) {
				// Extract the base value from the operand
				const baseValue = this.getInputValue(parseInt(sumMinMatch[1].replace(/[{}]/g, '')) - 1);

				// Extract the operation value from the operand
				const operationValue = parseInt(sumMinMatch[3]);

				// Calculate the value based on the operation (+ or -)
				value = sumMinMatch[2] === '+' ? baseValue + operationValue : baseValue - operationValue;
			} else {
				// If there is no operation in the operand, simply get the value
				value = this.getInputValue(parseInt(operand.replace(/[{}]/g, '')) - 1);
			}
			return value;
		};


		if (match) {


			const leftValue = extractValue(match[1].trim()); // Extract and calculate the left operand value
			const operator = match[2]; // Get the operator
			const rightValue = extractValue(match[3].trim()); // Extract and calculate the right operand value


			switch (operator) {
				case '<=':
					this.svgErrors[indexSvgInput].formulas[indexFormula] = leftValue <= rightValue;
					return leftValue <= rightValue;
				case '>=':
					this.svgErrors[indexSvgInput].formulas[indexFormula] = leftValue >= rightValue;
					return leftValue >= rightValue;
				case '<':
					this.svgErrors[indexSvgInput].formulas[indexFormula] = leftValue < rightValue;
					return leftValue < rightValue;
				case '>':
					this.svgErrors[indexSvgInput].formulas[indexFormula] = leftValue > rightValue;
					return leftValue > rightValue;
				case '=':
					this.svgErrors[indexSvgInput].formulas[indexFormula] = leftValue == rightValue;
					return leftValue == rightValue;
				default:
					this.svgErrors[indexSvgInput].formulas[indexFormula] = false;
					return false;
			}
		} else {
			this.svgErrors[indexSvgInput].formulas[indexFormula] = false;
			return false;
		}
	}

	getRestFormulaTranslation(formula: string) {

		// Use a regular expression to match and extract parts of the formula
		const match = formula.match(/({[0-9]+})([><=]=?)(.*)/);

		// Splited formula to translate
		this.matchTranslation = [...match];
		this.matchTranslation[2] = this.translationsService.instant('orderSeal.validation.' + this.matchTranslation[2]);

		// Second part of the formula, example: afterFormula {1}>{2}-3, it would save only the 2 
		let sideToTranslate = this.matchTranslation[3].split('}')[0].replace('{', '');
		this.translation = this.translationsService.instant('orderSeal.validation.' + this.orderSealService.selectedProfileValue.profileMeasureInputLines[sideToTranslate - 1].name);

		// Part after the formula, example: afterFormula {1}>{2}-3, it would save only the -3
		this.afterFormula = this.matchTranslation[3].replace(/{.*?}/, '');

		return this.matchTranslation[2] + " " + this.translation + " " + this.afterFormula + " " + this.translationsService.instant('orderSeal.validation.be');
	}

	/**
	 * Creates form for input validation
	 */
	createForm() {
		// create form 
		this.scaledPricesForm = this.fb.group({
			idArticleClient: [],
			componentMaterials: this.fb.array(this.components.map(component => {
				return this.fb.group({
					idProfileComponent: [component.idProfileComponent],
					material: [null, Validators.required]	// We initialize the material to null so that the user selects it.
				})
			})),

			measures: this.fb.array(this.selectedProfile.profileMeasureInputLines.map(measure => {
				return this.fb.group({
					idProfileMeasureInputLine: [measure.idProfileMeasureInputLine, Validators.required],
					idMeasureAbas: [measure.idMeasureAbas, Validators.required],
					name: [measure.name, Validators.required],
					value: [null, [Validators.required, Validators.min(measure.fromValue), Validators.max(measure.toValue)]],
				})
			})),

			scaledPricesLines: this.fb.array([
				this.fb.group({
					quantity: [1, [this.isSmallerThenLast(), Validators.required, Validators.pattern(/^(0|[1-9][0-9]*)$/)]],
					price: new UntypedFormControl({ value: null, disabled: true }),
					id: [Math.floor(Date.now() / 1000)]// timestamp 
				}),
			])
		});
	}

	/**
	 * Validates that the previous quantity is smaller then the current one
	 * if current quantity is bigger than previous fromValue is invalid
	 * @returns validation function
	 */
	isSmallerThenLast(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (control.parent) {
				const index = (control.parent.parent.controls as any[]).findIndex(x => x.controls.id.value == control.parent.value.id);
				if (index > 0) {
					if (control.value <= control.parent.parent.controls[index - 1].controls.quantity.value) {
						return {
							'smallerThenLast': true
						};
					}
				}
			}
			return null;
		}
	}

	/**
	 * Initializes formulas data for each svgInput
	 */
	initFormulas() {
		for (let i = 0; i < this.orderSealService.selectedProfileValue.profileMeasureInputLines.length; i++) {
			if (this.orderSealService.selectedProfileValue.profileMeasureInputLines[i].formula != null) {
				const formulaParts = this.orderSealService.selectedProfileValue.profileMeasureInputLines[i].formula.split('#').filter(Boolean);
				for (let j = 0; j < formulaParts.length; j++) {
					if (formulaParts[j].length <= 3) {
						formulaParts.splice(j, 1);
						j--;
					}
				}
				this.formulas.push(formulaParts);


			}
		}
	}

	/**
	 * Initializes data to mantain a control about the svgInput errors.
	 */
	initSvgValidation() {

		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		this.svgErrors = [];


		for (let i = 0; i < lines.length; i++) {

			let inputValidation = {
				"idSvgInput": lines[i].idSvgInput,
				"required": false,
				"fromTo": false,
				"formulas": new Array(this.formulas[i].length).fill(false)
			}
			this.svgErrors.push(inputValidation);

		}
	}

	/**
	 * 
	 * @param obj Checks if an object has all its keys values to true.
	 * @returns 
	 */
	areAllValuesTrue(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (Array.isArray(value)) {
					// Si es un array, verificamos que todos los elementos sean true
					if (!value.every(val => val === true)) {
						return false;
					}
				} else {
					// Si no es un array, verificamos el valor directamente
					if (value !== true) {
						return false;
					}
				}
			}
		}
		return true;
	}

	/**
	 * Validates svg inputs
	 */
	validateSvgInputs() {
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		let validInputCount = 0;

		for (let i = 0; i < lines.length; i++) {
			const inputById = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[i].idSvgInput} input`);
			
			(this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].get('value').patchValue(inputById.value, { emitEvent: false });
			this.scaledPricesForm.setErrors({ 'pending-validation': true });
			
			this.validateInput(lines[i].idSvgInput, (this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].get('value'), i, inputById.value);

			if ((this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].invalid) {
				if (inputById.value != "") {  //Only inputs that have something in it will get the class invalid
					inputById.classList.add('invalid');
				}
			}

			if ((this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].valid) {
				inputById.classList.remove('invalid');
				validInputCount++;
			}
		}

		if (this.areAllInputsValid()) {
			this.measuresValid = true;
			// If Measure Section enabled + Material Section enabled and the user has chosen a material.
			if (this.measuresValid && this.scaledPricesForm.value.componentMaterials[0].material != null) {
				this.materialValid = true;	// Enable button to calculate material.
			}
		} else {

			// If it's not valid and the user hasn't clicked to choose material.
			if (!this.showChooseMaterial) {
				this.measuresValid = false;
			}
		}
	}

	/**
	 * Recalculates width
	 * @param input 
	 */
	recalculateReadOnly(input) {
		const measuresQuery = this.scaledPricesForm.value.measures.map(measure => ({ idMeasureAbas: measure.idMeasureAbas, value: measure.value }));
		const idMaterialProfile = this.scaledPricesForm.value.componentMaterials[0].material; // TODO: what should we do when we have more than one component?
		this.calculateReadOnly(this.selectedProfile.idProfile, input.parentElement.id, idMaterialProfile, measuresQuery, input);
	}

	/**
	 * Removes item
	 * @param index 
	 * @returns  
	 */
	removeItem(index) {
		if (this.scaledPricesForm.disabled) { return; }
		this.scaledPricesLines.removeAt(index);
	}

	/**
	 * Adds scale item 
	 */
	addItem() {
		if (this.scaledPricesLines.length >= 11) { return; }
		this.scaledPricesLines.push(this.fb.group({
			quantity: [null, [this.isSmallerThenLast(), Validators.required, Validators.pattern(/^(0|[1-9][0-9]*)$/)]],
			price: new UntypedFormControl({ value: null, disabled: true }),
			id: [Math.floor(Date.now() / 1000)]
		}));
	}

	/**
	 * Gets scaled prices
	 */
	getScaledPrices() {
		if (this.scaledPricesForm.valid && this.loading === false) {
			this.loading = true;
			this.orderSealService.getScaledPrices(this.scaledPriceQuery).pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
				this.loading = false;
				if (res && res.scaledPriceList?.scaledPriceList && res.scaledPriceList?.scaledPriceList.length > 0) {
					this.scaledPricesList = res.scaledPriceList;
					(this.scaledPricesForm.controls.scaledPricesLines as UntypedFormArray).controls.forEach((c, index) => {
						c.get('price').patchValue(res.scaledPriceList.scaledPriceList[index].price, { emitEvent: false });
					});
					this.disableForm();
				} else {
					this.toastService.show(this.translationsService.instant('orderSeal.noPricesMessage'), { classname: 'bg-warning text-light', delay: 7000 });

				}
			}, (err) => {
				this.loading = false;
				this.toastService.show(this.translationsService.instant('orderSeal.noPricesMessage'), { classname: 'bg-warning text-light', delay: 7000 });
			});
		}
	}

	/**
	 * Disables the form and all inputs
	 * @param disable 
	 */
	disableForm(disable = true) {
		if (disable) { this.scaledPricesForm.disable(); }
		else {
			this.scaledPricesForm.enable();
			(this.scaledPricesForm.controls.scaledPricesLines as UntypedFormArray).controls.forEach((c, index) => { c.get('price').disable(); c.get('price').setValue(''); });
		}
		const inputs = this.svgEl.nativeElement.childNodes[2].querySelectorAll('input');
		inputs.forEach(input => { input.disabled = disable; });
	}

	/**
	 * Orders seal based on introduced values and scaled prices. It checks before if the inputsSvg values are valid before trying to do an order.
	 */
	orderSeal() {
		this.loading = true;

		// Validate the inputs on the backend.
		this.quantityInputSvgBackendValidated = 0;
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		const validationPromises = [];
		for (let i = 0; i < lines.length; i++) {
			const inputById = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[i].idSvgInput} input`);
			const measuresQuery = this.scaledPricesForm.value.measures.map(measure => ({ idMeasureAbas: measure.idMeasureAbas, value: measure.value }));

			(this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].get('value').patchValue(inputById.value, { emitEvent: false });

			const validationPromise = this.validateInputBackend(this.selectedProfile.idProfile, lines[i].idSvgInput, lines[i].fromValue, lines[i].toValue, this.scaledPriceQuery, inputById, (this.scaledPricesForm.get(`measures`) as UntypedFormArray).controls[i].get('value'))
				.then(() => {
					this.loading = false;
				})
				.catch(error => {
					// Handle validation error
					console.error("Validation error:", error);
				});

			validationPromises.push(validationPromise);
		}
		return Promise.all(validationPromises)

			.then(() => {

				// If all inputsSVG have been validated on the backend then we try to do an order.
				if (this.quantityInputSvgBackendValidated == lines.length) {
					this.orderSealService.getScaledPrices(this.createOrderQuery).pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
						this.loading = false;
						if (res && res.errorMessage && res.errorMessage.length > 0) {
							this.toastService.show(this.translationsService.instant(`orderSeal.${res.errorMessage}`), { classname: 'bg-warning text-light', delay: 7000 });
						}
						else if (res && res.scaledPriceList?.scaledPriceList && res.scaledPriceList?.scaledPriceList.length > 0 && res.idCreatedOfferERP) {

							this.router.navigate(['/offers', { showCreatedOffer: true, offerId: res.idCreatedOfferERP, complete: true }]);
							this.toastService.show(this.translationsService.instant('orderSeal.orderPlacedSuccessfully'), { classname: 'bg-success text-light', delay: 7000 });
						} else {
							this.toastService.show(this.translationsService.instant('orderSeal.orderFailed'), { classname: 'bg-warning text-light', delay: 7000 });
						}
					}, error => {
						this.loading = false;
						this.toastService.show(this.translationsService.instant('orderSeal.orderFailed'), { classname: 'bg-warning text-light', delay: 7000 });
					});
				}

			}).catch(error => {

			});


	}

	/**
	 * Calculates read only and updates the input value
	 */
	calculateReadOnly(idProfile: number, idSvgInput: string, idMaterialProfile: string, measuresQuery: any, input: HTMLInputElement) {
		if (idProfile && idSvgInput && idMaterialProfile && measuresQuery){	
			this.orderSealService.calculateReadOnly(idProfile, idSvgInput, idMaterialProfile, measuresQuery).pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
				if (res && res != -1) { input.value = res; }
			});
		}
	}

	/**
	 * Checks if all inputSvg validations are valid or not.
	 * @returns 
	 */
	areAllInputsValid() {

		let copySvgErrors = (JSON.parse(JSON.stringify(this.svgErrors)));
		let areAllValuesTrue = true;

		for (let i = 0; i < copySvgErrors.length; i++) {
			delete copySvgErrors[i].idSvgInput;
			if (!this.areAllValuesTrue(copySvgErrors[i])) {
				areAllValuesTrue = false;
			}
		}
		return areAllValuesTrue;
	}

	/**
	 * Checks if an inputSvg has passed all validations required for it.
	 * @param idSvgInput 
	 * @returns 
	 */
	isInputValid(idSvgInput: string) {

		let copySvgErrors = (JSON.parse(JSON.stringify(this.svgErrors)));
		let areAllValuesTrue = true;

		for (let i = 0; i < copySvgErrors.length; i++) {
			if (copySvgErrors[i].idSvgInput == idSvgInput) {

				delete copySvgErrors[i].idSvgInput;
				if (!this.areAllValuesTrue(copySvgErrors[i])) {
					areAllValuesTrue = false;
				}
				break;
			}
		}
		return areAllValuesTrue;
	}


	/**
	 * Validates an inputSVG through the typescript validatoin system.
	 * @param idSvgInput 
	 * @param formControl 
	 * @param svgIndex 
	 * @param svgValue 
	 */
	validateInput(idSvgInput: string, formControl: any, svgIndex: number, svgValue) {
		// We validate the input requirements.
		this.isRequiredValid(svgIndex, svgValue);
		this.isFromToValid(svgIndex, svgValue);

		for (let k = 0; k < this.formulas[svgIndex].length; k++) {
			this.isFormulaValid(this.formulas[svgIndex][k], svgIndex, k);
		}

		// We check if it has passed all checks for that input.
		this.scaledPricesForm.setErrors({});

		if (!this.isInputValid(idSvgInput)) {
			formControl.setErrors({ 'invalid': true });
		} else {
			this.scaledPricesForm.updateValueAndValidity();
		}
	}

	/**
	 * Validates input on change in java
	 */
	validateInputBackend(idProfile: number, idSvgInput: string, fromValue: number, toValue: number, scaledPriceQuery: any, input: HTMLInputElement, formControl: any) {
		return new Promise<void>((resolve, reject) => {
			this.orderSealService.validateInput(idProfile, idSvgInput, scaledPriceQuery)
				.pipe(takeUntil(this.unsubscribe))
				.subscribe((res: any) => {
					this.scaledPricesForm.setErrors({});
					if (!res.valid) {

						input.classList.add('invalid');
						formControl.setErrors({ 'invalid': true });

						if (res.message == "orderSeal.measureMatchFailedMessage") {
							this.toastService.show(`${this.translationsService.instant('orderSeal.invalidInputMessage')}: ${this.translationsService.instant(res.message)}`, { classname: 'bg-warning text-light', delay: 7000 });
						} else if (res.message == "orderSeal.invalidMeasureRangeMessage") {
							this.toastService.show(`${this.translationsService.instant('orderSeal.invalidInputMessage')}: ${this.translationsService.instant(res.message, { from: fromValue, to: toValue, code: this.translationsService.instant('orderSeal.' + idSvgInput) })}`, { classname: 'bg-warning text-light', delay: 7000 });
						} else {
							this.toastService.show(`${this.translationsService.instant('orderSeal.invalidInputMessage')}: [${this.translationsService.instant('orderSeal.' + idSvgInput)}] ${this.translationsService.instant(res.message)}`, { classname: 'bg-warning text-light', delay: 7000 });
						}
						reject(new Error("Validation failed")); // Reject the promise on validation failure
					} else {
						this.quantityInputSvgBackendValidated++;
						resolve(); // Resolve the promise on successful validation
					}
					this.scaledPricesForm.updateValueAndValidity();
				});
		});
	}


	/**
	 * Checks if the value has been filled and is not null.
	 * @param indexSvg Index of the input to be checked.
	 * @param value Actual value of the inputSvg.
	 * @returns If is required is valid or not.
	 */
	isRequiredValid(indexSvg: number, value: number) {
		this.svgErrors[indexSvg].required = value ? true : false;
		return this.svgErrors[indexSvg].required;
	}

	/**
	 * Checks if the from to value is correct.
	 * @param i Index of the input to be checked
	 * @param value Actual value of the inputSvg.
	 * @returns If the from to is valid or not.
	 */
	isFromToValid(i: number, value: number) {
		this.svgErrors[i].fromTo = this.selectedProfile.profileMeasureInputLines[i].fromValue <= value && value <= this.selectedProfile.profileMeasureInputLines[i].toValue;
		return this.svgErrors[i].fromTo;
	}

	/**
	 * Scrolls to an element on the component.
	 * @param id Id of the HTML element
	 */
	scroll(id) {
		const section = this.el.nativeElement.querySelector(id);

		if (section) {
			const rect = section.getBoundingClientRect();
			const offset = rect.top - (window.innerHeight - rect.height) / 2;
			window.scrollBy({ top: offset, behavior: 'smooth' });
		}
	}

	/**
	 * Shows the choose material section and scrolls to it.
	 */
	activeChooseMaterial() {
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		const inputById = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[1].idSvgInput} input`);

		this.showChooseMaterial = true;
		this.scroll('#choose-material-section');
	}



	/**
	 * Shows the scaled prices section and scrolls to it.
	 */
	activeScaledPrices() {
		this.showScaledPrices = true;
		this.scroll('#scaled-prices-section');
	}

	/**
	 * Gets image profile item left
	 */
	get imageDimensions() {
		return this.imagesService.getProfileImageUrl(this.orderSealService.selectedProfileValue.idProfile, 'dimensions') + '?' + Math.floor(Date.now() / 1000);
	}

	/**
	 * Gets scaled price query
	 */
	get scaledPriceQuery() {
		const getScaledPricesQuery = {
			idArticleClient: this.scaledPricesForm.value.idArticleClient,
			idProfile: this.selectedProfile.idProfile,
			materials: this.scaledPricesForm.value.componentMaterials?.map(material => ({ idMaterial: material.material, idProfileComponent: material.idProfileComponent })),
			measures: this.scaledPricesForm.value.measures.map(measure => ({ idMeasureAbas: measure.idMeasureAbas, value: measure.value })),
			scaledPriceQuantities: this.scaledPricesForm.value.scaledPricesLines.map(line => line.quantity),
			createOrder: false
		};
		return getScaledPricesQuery;
	}

	/**
	 * Gets create order query
	 */
	get createOrderQuery() {
		let orderSealQuery = this.scaledPriceQuery;
		orderSealQuery.createOrder = true;
		return orderSealQuery;
	}

	/**
	 * Gets scaled prices lines of the form
	 */
	get scaledPricesLines() {
		return this.scaledPricesForm.controls["scaledPricesLines"] as UntypedFormArray;
	}

	/**
	 * Gets component materials of the form
	 */
	get componentMaterials() {
		return this.scaledPricesForm.controls["componentMaterials"] as UntypedFormArray;
	}

	onMouseEnter(i: number) {
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		const input = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[i].idSvgInput} input`);
		input.classList.add("fade-in")
		input.style.backgroundColor = '#ffe8e7';
	}

	onMouseLeave(i: number) {
		let lines = this.orderSealService.selectedProfileValue.profileMeasureInputLines.filter(line => line.idSvgInput);
		const input = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${lines[i].idSvgInput} input`);
		input.classList.add("fade-out")
		input.style.backgroundColor = '';
	}


	openMaterialsModal() {
		const modalOptions: NgbModalOptions = { windowClass: 'custom-modal-materials' };
		const inputById = this.svgEl.nativeElement.childNodes[2].querySelector(`foreignObject#${MeasureAbasType.AD} input`);

		this.materialProfileService.outerDimensionAD4PopUp=inputById.value;
		this.modalService.open(MaterialTablePopupComponent, modalOptions);
	}

	applyMaterialBase(i: number) {
		this.selectedMaterialID = i
		this.selectedMaterial = this.selectedProfile.profileComponents[0].profileComponentMaterials.find(item => item.materialProfile.idMaterialProfile == i)?.materialProfile.displayName;

		this.scaledPricesForm.get('componentMaterials.0.material').setValue(i);
		this.scaledPricesForm.updateValueAndValidity()
	}


	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
