<div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" [autoClose]="true" #insideElement
    placement="bottom-right">

    <button type="button" class="se-button help-button d-none d-lg-block" id="dropdownCalculationProcess" ngbDropdownToggle> 
        ?
    </button>

    <div ngbDropdownMenu aria-labelledby="dropdownCalculationProcess" *ngIf="capacityPriceCalcAnalyticsMap">
        <div class="form-group">
            <select class="form-control id-select" id="selectIdArticle" [(ngModel)]="selected"
                (change)="selectOption($event.target.value)">
                <option *ngFor="let calcProcess of capacityPriceCalcAnalyticsMap; let i = index" [value]="i">
                    {{calcProcess.idArticleErp}} </option>
            </select>
        </div>
        <div class="calculation-process-container" *ngIf="selected && selected !== ''">
            <div class="dates-header d-flex justify-content-between">
                <div class="dates__col text-center text-uppercase bold" i18n> Date </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Holiday </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Workstep </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Group/Machine </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Grp number </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Module </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Total </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Used </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Demand </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Bookable </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Status </div>
                <div class="dates__col text-center text-uppercase bold" i18n> Booked </div>
            </div>
            <div class="dates d-flex justify-content-between" *ngFor="let data of capacityPriceCalcAnalyticsMap[selected].values">
                <div class="dates__col text-center"> {{DateUtils.getFormattedDate(data.planningDay, true)}} </div>
                <div class="dates__col text-center"> {{data.holiday}} </div>
                
                <div class="dates__col text-center">
                    <ng-container *ngIf="data.machineGroup == 'SHIPPING'">
                      ----
                    </ng-container>
                    <ng-container *ngIf="data.machineGroup != 'SHIPPING'">
                      {{ data.workStep }}
                    </ng-container>
                  </div>                  

                <div class="dates__col text-center">
                    <ng-container *ngIf="data.machine">
                        {{ data.machineGroup == 'SHIPPING' ? data.machineGroup : data.machineGroup + ' / ' + data.machine }}
                    </ng-container>
                    <ng-container *ngIf="!data.machine">
                        {{ data.machineGroup == 'SHIPPING' ? data.machineGroup : data.machineGroup + ' / * ' }}
                    </ng-container>
                </div>


                <div class="dates__col text-center"> {{data.machineGroupNumber}} </div>
                <div class="dates__col text-center"> {{data.module}} </div>
                <div class="dates__col text-center"> {{data.maxWorkloadMachineH | number:'1.1-3' }}h</div>
                <div class="dates__col text-center"> {{data.usedWorkloadMachineH | number:'1.1-3' }}h</div>
                <div class="dates__col text-center"> {{data.demandBookingH | number:'1.1-3' }}h</div>
                <div class="dates__col text-center"> {{data.bookableWorkloadH| number:'1.1-3' }}h</div>
                <div class="dates__col text-center" title="{{data.status}}"> {{(data.status | slice:0:10) +
                    (data.status.length > 10 ? '...' : '')}} </div>
                <div class="dates__col text-center"
                    style.background="{{ArticlesListUtils.getStatusColor(data.status)}}">
                    {{formatNumber(data.workloadBookedP*100)+ '%'}} - {{formatNumber(data.workloadBookedH) + 'h'}}
                </div>
            </div>
        </div>
    </div>
</div>