<div class="container-fluid">
    <div class="create-user">
        <div class="create-user__background"></div>
        <form class="create-user__form se-panel" [formGroup]="createUserForm">
            <div class="create-user__header">
                <h1 class="create-user__title" i18n> {{'admin.createUser.createUser' | translate}} </h1>
            </div>
            <div class="create-user__body">
                <div class="create-user__input-container">
                    <input type="text" name="idUserERP" formControlName="idUserERP" class="create-user__input se-input"
                        [placeholder]="'admin.createUser.erpId' | translate" i18n-placeholder>
                </div>
                <div class="create-user__input-container">
                    <input type="text" name="email" formControlName="email" class="create-user__input se-input"
                        [placeholder]="'admin.createUser.email' | translate" i18n-placeholder>
                </div>
                <div class="create-user__input-container">
                    <input type="text" name="name" formControlName="name" class="create-user__input se-input"
                        [placeholder]="'admin.createUser.name' | translate" i18n-placeholder>
                </div>
                <div class="create-user__input-container">
                    <input type="text" name="surname" formControlName="surname" class="create-user__input se-input"
                        [placeholder]="'admin.createUser.surname' | translate" i18n-placeholder>
                </div>
                <div class="create-user__input-container">
                    <input type="text" name="title" formControlName="title" class="create-user__input se-input"
                        [placeholder]="'admin.createUser.title' | translate" i18n-placeholder>
                </div>
                <div
                    class="create-user__input-container gender-input d-flex align-items-center justify-content-between">
                    {{"admin.createUser.gender" | translate}}: <app-radio-button value="male" [checked]="false" formControlName="gender"
                        ngDefaultControl name="gender"><span>{{"admin.createUser.male" | translate}}</span> </app-radio-button>
                    <app-radio-button value="female" [checked]="false" formControlName="gender" ngDefaultControl
                        name="gender"><span>{{"admin.createUser.female" | translate}}</span> </app-radio-button>
                    <app-radio-button value="other" [checked]="false" formControlName="gender" ngDefaultControl
                        name="gender"><span>{{"admin.createUser.other" | translate}}</span> </app-radio-button>
                </div>
                <app-button [label]=" 'admin.createUser.createUser' | translate" padding="10px" (onClick)="onSubmit()"
                    [disabled]="!createUserForm.valid" class="create-user__button">
                </app-button>
                <div class="create-user__validation text-left">
                    <div i18n>
                        <span class="create-user__check">
                            <svg-icon src="/assets/img/icons/{{idRequired ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': idRequired ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'admin.createUser.erpIdRequired' | translate}}</div>
                    <div i18n> <span class="create-user__check">
                            <svg-icon src="/assets/img/icons/{{emailRequired ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': emailRequired ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'admin.createUser.emailRequired' | translate}}</div>
                    <div i18n>
                        <span class="create-user__check">
                            <svg-icon src="/assets/img/icons/{{emailValid ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': emailValid ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'admin.createUser.emailValid' | translate}}</div>
                    <div i18n>
                        <span class="create-user__check">
                            <svg-icon src="/assets/img/icons/{{genderRequired ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': genderRequired ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'admin.createUser.genderRequired' | translate}}</div>
                </div>
            </div>
        </form>
    </div>
</div>
