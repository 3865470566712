import { FileUtils } from './../../../shared/utils/file.utils';
import { PdfService } from './../../../shared/services/pdf.service';
import { DateUtils } from './../../../shared/utils/date.utils';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Offer } from 'src/app/shared/models/offer.model';
import { Output } from '@angular/core';

@Component({
  selector: 'app-offer-row',
  templateUrl: './offer-row.component.html',
  styleUrls: ['./offer-row.component.scss']
})
export class OfferRowComponent implements OnInit {
  DateUtils = DateUtils;
  @Input() offer: Offer;
  @Output() offerOpened = new EventEmitter();
  offerCollapse = true;
  overflow = false;
  constructor(private pdfService: PdfService) { }

  /**
   * Downloads pdf
   */
  downloadPdf(idOfferERP: string) {
    this.pdfService.getOfferPdf(idOfferERP).subscribe((data: any) => {
      if (data.size && data.size > 0) {
        const blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
        FileUtils.downloadFile(blob, this.offer.idOfferERP);
      }
    });
  }

  /**
   * Collapses offer
   * @param event event
   */
  collapseOffer(event) {
    const className = event.target.className;
    // avoid opening or closing on clicking pdf download button
    if (className !== 'download-pdf' && className !== 'download-pdf-img') {
      this.offerCollapse = !this.offerCollapse;
      this.offerOpened.emit(!this.offerCollapse);
      // make overflow visible when the offer is opened
      if (!this.offerCollapse) {
        // wait until animation is finished
        setTimeout(() => {
          this.overflow = true;
        }, 500);
      } else {
        this.overflow = false;
      }
    }
  }


  ngOnInit(): void {
  }

}
