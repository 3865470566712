


export class DateUtils {
    /**
     * Gets last day of month
     * @param y number
     * @param m number
     * @returns Date
     */
    public static getLastDayOfMonth(y: number, m: number) {
        return new Date(y, m + 1, 0);
    }

    /**
     * Clones date object
     * @param dateObject date object
     * @returns deep copy of Date
     */
    public static cloneDate(dateObject: Date) {
        const date = new Date(dateObject.valueOf());
        date.setHours(2, 0, 0, 0);
        return date;
    }

    /**
     * Gets formatted date to show on diagram
     * @param date Date
     * @returns string
     */
    public static getFormattedDate(date: Date, showYear = false) {
        if (date instanceof Date === false) {
            date = this.cloneDate(date);
        }
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const monthString = month < 10 ? '0' + month : '' + month;
        const dayString = day < 10 ? '0' + day : '' + day;
        const yearString = showYear ? '.' + date.getFullYear() : '';
        return dayString + '.' + monthString + yearString;
    }

    /**
     * Adds days to the date object
     * @param dateObject date object
     * @param days number of days
     */
    public static plusDays(dateObject: Date, days: number) {
        dateObject.setDate(dateObject.getDate() + days);
        dateObject.setHours(2, 0, 0, 0);
    }

}
