import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-remove-confirmation-modal',
  templateUrl: './remove-confirmation-modal.component.html'
})
export class RemoveConfirmationModalComponent {
  showQuestion = true;
  x = this.getRandom();
  y = this.getRandom();
  result = this.x + this.y;
  userResult: number;
  constructor(public modal: NgbActiveModal, public translationsService: TranslateService) { }

  /**
   * Gets a random number from 0 to 10
   * @returns random number
   */
  getRandom(): number {
    return Math.floor(Math.random() * 10) + 1;
  }
}

