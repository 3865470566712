import { takeUntil } from 'rxjs/operators';
import { ProfileValidationService } from '../../../shared/services/profile-validation.service';
import { MaterialProfile } from '../../../shared/models/material-profile.model';
import { MaterialProfileService } from '../../../shared/services/material-profile.service';
import { SealingType } from '../../../shared/models/sealing-type.model';
import { SealingTypesService } from '../../../shared/services/sealing-types.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from 'src/app/shared/models/profile.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sealing-type',
  templateUrl: './sealing-type.component.html',
  styleUrls: ['./sealing-type.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  sealingTypes: SealingType[] = [];
  materialProfiles: MaterialProfile[];
  constructor(private sealingTypesService: SealingTypesService, private materialProfileService: MaterialProfileService, private profileValidationService: ProfileValidationService) { }

  ngOnInit(): void {
    this.getSealingTypes();
    this.materialProfileService.getMaterialProfilesActive().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.materialProfiles = data;
    });
  }

  /**
   * Gets category sealingTypes
   */
  getSealingTypes() {
    // this.profileValidationService.loadSealingTypes();
    this.profileValidationService.sealingTypesUpdated.subscribe(data => {
      // this.sealingTypes = data;
      // iterate over sealingTypes and subarray profiles and update valid property
      this.profileValidationService.updateValidationsValues(this.sealingTypes)
    });


    this.sealingTypesService.getSealingTypes().subscribe(data => {
      this.sealingTypes = data;
      this.profileValidationService.setSealingTypes(this.sealingTypes)
    });
  }

  /**
   * Adds new sealing-type
   */
  addNew() {
    this.sealingTypes.unshift(new SealingType());
  }

  /**
   * Removes null values
   */
  removeNullValues() {
    if (this.sealingTypes != null) {
      this.sealingTypes = this.sealingTypes.filter(category => category != null);
    }
  }

  /**
   * Removes null values
   */
  removeNullValuesCategory(index: number) {
    if (this.sealingTypes[index] && this.sealingTypes[index].profiles != null) {
      this.sealingTypes[index].profiles = this.sealingTypes[index].profiles.filter(item => item != null);
    }
  }


  panelChange(event) {
    // get index of selected panel
    const index = event.panelId.split('-')[2];
    // get category
    const category = this.sealingTypes[index];
    // if no items add a new one
    if (category.profiles == null || category.profiles.length === 0) {
      category.profiles = [];
      category.profiles.push(new Profile());
    }
  }

  /**
   * Adds new item
   */
  addNewItem(index, nav: NgbNav) {
    if (this.sealingTypes[index].profiles == null) { this.sealingTypes[index].profiles = []; }
    this.sealingTypes[index].profiles.push(new Profile());
    nav.select(this.sealingTypes[index].profiles.length - 1);
  }

  /**
   * Check if add new item option is available
   * @param index 
   * @returns  
   */
  addNewAvailable(index: number) {
    if (this.sealingTypes[index].profiles.length > 0 && this.sealingTypes[index].profiles[this.sealingTypes[index].profiles.length - 1] && this.sealingTypes[index].profiles[this.sealingTypes[index].profiles.length - 1].idProfile == null) {
      return false;
    } else {
      return true;
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}