import { ProfileMeasureOutputLine } from './profile-measure-output-line.model';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';
import { ProfileMeasureInputLine } from './profile-measure-input-line.model';
export class Profile {
    idProfile: number;
    name: string;
    profileMeasureInputLines: ProfileMeasureInputLine[];
    profileMeasureOutputOtherLines: ProfileMeasureOutputLine[];
    valid: boolean;
    profileComponents: ProfileComponent[];
    image?: string;
    checked?: boolean;
}
