<app-section-header section="manage-account"></app-section-header>
<div class="manage-account">
    <div class="container-fluid">
        <div class="d-flex">
            <div class="form-group">
                <select class="form-control id-select" id="selectIdArticle" [(ngModel)]="accountManagerId"
                    (change)="getCustomers()">
                    <option *ngFor="let accountManager of accountManagers; let i = index"
                        [value]="accountManager.idErp"> {{accountManager.name}} {{accountManager.surname}} -
                        {{accountManager.idErp}} </option>
                </select>
            </div>
            <form class="ml-auto">
                <div class="form-group form-inline "> <input class="form-control se-input" type="text"
                        [formControl]="filter" placeholder="Kunden suchen" />
                    <img src="/assets/img/icons/search.svg" alt="search-icon" class="search-icon">
                </div>
            </form>
        </div>
        <div class="articles-table">
            <section class="se-table-flex">
                <div class="se-table-header se-table-row">
                    <div class="se-table-col md" i18n>{{'manageAccount.userIdentification'|translate}}</div>
                    <div class="se-table-col md" i18n>{{'manageAccount.searchExtensionCustomer'|translate}}</div>
                    <div class="se-table-col md" i18n>{{'manageAccount.customerName'|translate}}</div>
                    <div class="se-table-col sm" i18n></div>
                </div>
                <div class="se-table-row align-items-center" *ngFor="let customer of customers$ | async;">
                    <div class="se-table-col md">
                        <ngb-highlight [result]="customer.idCustomerErp" [term]="filter.value"></ngb-highlight>
                    </div>
                    <div class="se-table-col md">
                        <ngb-highlight [result]="customer.customerAbbreviation" [term]="filter.value"></ngb-highlight>
                    </div>
                    <div class="se-table-col md">
                        <ngb-highlight [result]="customer.customerName" [term]="filter.value"></ngb-highlight>
                    </div>
                    <div class="se-table-col sm">
                        <app-button class="d-block w-100" label="{{'manageAccount.loginAsThisUser'|translate}}"
                            (onClick)="loginAs(customer)" i18n>
                        </app-button>
                    </div>
                </div>
                <div *ngIf="customers && customers.length == 0 && this.showNoCustomersError == false" class="se-table-row">
                    <div class="se-table-col lg" i18n>
                        <app-loading-spinner></app-loading-spinner>
                    </div>
                </div>
                <div *ngIf="this.showNoCustomersError == true" class="se-table-row">
                    <div class="se-table-col lg text-center" i18n>
                        {{'manageAccount.noCustomersFoundAccManager'|translate}}
                    </div>
                </div>
          
            </section>
            <app-button class="load-more m-auto" (click)="loadMore()" [disabled]="!loadMoreAvailable"
                [ngClass]="{'inactive': loadMoreAvailable === false}" label="{{'manageAccount.loadMore'|translate}}" i18n>
            </app-button>
        </div>
    </div>
</div>
