import { Profile } from '../models/profile.model';
import { map } from 'rxjs/operators';
import { MachineProfile } from '../models/machine-profile.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SealingType } from '../models/sealing-type.model';


@Injectable({
  providedIn: 'root'
})
export class SealingTypesService {

  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(private http: HttpClient) { }

  /**
   * Get category sealingTypes
   * @returns category sealingTypes
   */
  public getSealingTypes(): Observable<SealingType[]> {
    return this.http.get(this.apiUrl + '/sealing-type/all') as Observable<SealingType[]>;
  }

  /**
   * Createssealing type
   * @param sealingType 
   * @returns sealing typeype 
   */
  public createSealingType(sealingType: SealingType): Observable<SealingType> {
    return this.http.post(this.apiUrl + '/sealing-type', sealingType) as Observable<SealingType>;
  }

  /**
   * Creates sealing type
   * @param profile 
   * @returns  
   */
  public createProfile(profile: Profile, idSealingType: number): Observable<Profile> {
    return this.http.post(this.apiUrl + '/sealing-type/item/' + idSealingType, profile) as Observable<Profile>;
  }

  /**
   * Saves sealing type
   * @param profile 
   * @returns sealing type
   */
  public saveSealingType(sealingType: SealingType): Observable<SealingType> {
    return this.http.put(this.apiUrl + '/sealing-type', sealingType) as Observable<SealingType>;
  }

  /**
   * Saves sealing type
   * @param profile 
   * @returns sealing type 
   */
  saveProfile(profile: Profile, formulaUpdated = false, inputOrderUpdated = false): Observable<Profile> {
    return this.http.put(`${this.apiUrl}/sealing-type/item/${formulaUpdated}/${inputOrderUpdated}`, profile).pipe(map((data: Profile) => { data.profileMeasureInputLines.sort((a, b) => a.showOrder - b.showOrder); return data; }
    )) as Observable<Profile>;
  }

  /**
   * Get calculated output value
   * @param profileMeasureOutputCalculator 
   * @returns 
   */
  testCalculateOutput(profileMeasureOutputCalculator: any): Observable<number> {
    return this.http.post(`${this.apiUrl}/sealing-type/test-calculate-output`, profileMeasureOutputCalculator) as Observable<number>;
  }

  /**
  * Deletes sealing type
  * @param idSealingType 
  * @returns  
  */
  deleteSealingType(idSealingType: number) {
    return this.http.delete(this.apiUrl + '/sealing-type/' + idSealingType);
  }

  /**
   * Deletes profile
   * @param idProfile 
   * @returns  
   */
  deleteProfile(idProfile: number) {
    return this.http.delete(this.apiUrl + '/sealing-type/item/' + idProfile);
  }

  /**
   * Deletes profile measure line
   * @param idProfileMeasureInputLine 
   */
  deleteProfileMeasureInputLine(idProfileMeasureInputLine: number) {
    return this.http.delete(this.apiUrl + '/sealing-type/input-line/' + idProfileMeasureInputLine);
  }

  /**
   * Deletes profile measure line
   * @param idProfileMeasureInputLine 
   */
  deleteProfileMeasureOutputLine(idProfileMeasureOutputLine: number) {
    return this.http.delete(this.apiUrl + '/sealing-type/output-line/' + idProfileMeasureOutputLine);
  }
}
