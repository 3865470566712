import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
  @Input() bootstrapIcon;
  @Input() section: string;
  constructor(private codeTranslationsService: TranslateService) { }
  get sectionName() {
    return this.codeTranslationsService.instant('codeTranslations.' + this.section + '-section');
  }
  ngOnInit(): void {
  }

}
