<div class="se-table-row align-items-center" *ngIf="!reorderArticleClicked" @fade>
    <div class="se-table-col sm position-col d-none d-md-block">{{ orderArticle.position }}</div>
    <div class="se-table-col lg">
        <div class="id-col">
            <div class="article-id">
                <span class="bold">{{orderArticle.idArticleERP}}</span>
                <span *ngIf="orderArticle.pdf && orderArticle.pdf !== ''" class="download-article-pdf">
                    <img src="assets/img/icons/download.svg" alt="download" (click)="downloadPdf(orderArticle.idArticleERP)">
                </span>
            </div>
            <p class="article-description"> {{orderArticle.descriptionERP}}</p>
        </div>
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row">
        <div class="amount-col"> {{orderArticle.amount}} </div>
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row">
        <div class="date-col"> {{DateUtils.getFormattedDate(orderArticle.shippingDate, true)}} </div>
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row">
        <div class="scaled-price-col"> {{scaledPrice| currency: 'EUR' }} / 100 </div>
    </div>
    <div class="se-table-col d-none d-sm-flex md se-row">
        <div class="price-col"> {{ orderArticle.price | currency: CurrencyType.EUR}} </div>
    </div>
    <div class="se-table-col md">
        <app-button label="{{'orders.orderAgain'|translate}}" (click)="reorderArticle()" class="repeat-order-button"></app-button>
    </div>
</div>
<div class="articles-list-row" [article]="article" [index]="index" [amount]="orderArticle.amount"
    (articleAddedToCart)="reorderArticleClicked=false" app-articles-list-row *ngIf="reorderArticleClicked && article"
    @fade>
</div>
