import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClick = new EventEmitter();
  @Input() label: string;
  @Input() width: string;
  @Input() height: string;
  @Input() backgroundImage: string;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() padding: string;
  @Input() loading: boolean;
  @Input() loadingText: string;

  /**
   * emit event on click on button
   * @param event click events
   */
  onClickButton(event) {
    this.onClick.emit(event);
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
