import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileMeasureInputLine } from '../../../../../../shared/models/profile-measure-input-line.model';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile-link-values-dialog',
  templateUrl: './profile-link-values-dialog.component.html',
  styleUrls: ['./profile-link-values-dialog.component.scss']
})
export class ProfileLinkValuesDialogComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() profileMeasureInputLines: ProfileMeasureInputLine[];
  @Input() itemValueIndex: number;
  @Output() itemUpdated = new EventEmitter();

  formulaForm: FormArray;
  individualFormula: [] = [];

  // allows  =  <  >  <=  >=  +  - between {} blocks and +-number at the end
  regexFormulaValidation = /^{(\d+)}(=|<|>|>=|<=|\+|-){(\d+)}(\+|-)?(\d+)?/


  regexInitialValueValidation = /\{\d+\}.*?/

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    // Initialize formulaForms as a FormArray
    this.formulaForm = this.fb.array([
      this.fb.control(this.formulaForForm, [Validators.required, Validators.pattern(this.regexFormulaValidation)])
    ]);

    // Stores de formulas on an array
    let individualFormula = this.profileMeasureInputLines[this.itemValueIndex].formula.split('#').filter(Boolean);

    individualFormula.forEach(substring => {
      this.addFormulaForm(substring)
    });

    // Removes the original string of the formula (which like #{1}>{2}#{1}>{3})
    this.removeFormulaForm(0) 


    // Subscribe to value changes for all formula form controls in the FormArray
    this.formulaForm.controls.forEach((control, index) => {

      control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (!this.regexInitialValueValidation.test(value)) {
          control.patchValue('{' + (this.profileMeasureInputLines[this.itemValueIndex].showOrder + 1) + '}', { emitEvent: false });
          console.log(`Invalid input at position ${index}: ${value}`); // Displays regex errors
        } else {
          control.patchValue(value.replace(' ', ''), { emitEvent: false }); // Remove spaces
        }
      });
    });

  }

  /**
   * Gets formula for form or default value
   */
  get formulaForForm() {
    if (this.profileMeasureInputLines[this.itemValueIndex].formula) {
      return this.profileMeasureInputLines[this.itemValueIndex].formula;
    } else {
      return '{' + (this.profileMeasureInputLines[this.itemValueIndex].showOrder + 1) + '}';
    }
  }


  // Adds a new formula input to the formulas array
  addFormulaForm(formula = '') {

    // In case a formula its created without anything (like clicking the button) it adds its index at the begining
    if (formula == '') {
      formula = '{' + (this.profileMeasureInputLines[this.itemValueIndex].showOrder + 1) + '}';
    }

    this.formulaForm.push(
      this.fb.control(formula, [Validators.required, Validators.pattern(this.regexFormulaValidation)])
    );
  }


  // Removes a formula input
  removeFormulaForm(index: number) {
    this.formulaForm.removeAt(index);
  }

  /**
   * Save connection category profile link values
   */
  connect() {
    if (this.formulaForm.valid) {
      //Saves each formula line onto one single string on the database, separated with a #
      let concatenatedFormulas: string = "";
      for (let index = 0; index < this.formulaForm.value.length; index++) {
        concatenatedFormulas += "#" + this.formulaForm.value[index]
      }
      this.profileMeasureInputLines[this.itemValueIndex].formula = concatenatedFormulas;

      this.itemUpdated.emit(this.profileMeasureInputLines[this.itemValueIndex]);
    }
  }

  /**
   * Removes connection category profile link values
   * @returns  
   */
  remove() {
    if (!this.profileMeasureInputLines[this.itemValueIndex].formula) { return; }
    this.profileMeasureInputLines[this.itemValueIndex].formula = null;
    this.itemUpdated.emit(this.profileMeasureInputLines[this.itemValueIndex]);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
