import { FileUtils } from './../../../../shared/utils/file.utils';
import { PdfService } from './../../../../shared/services/pdf.service';
import { animate, query, stagger, style, transition, trigger, keyframes } from '@angular/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ArticleType } from 'src/app/shared/models/article-type.enum';
import { Article } from 'src/app/shared/models/article.model';
import { CurrencyType } from './../../../../shared/models/currency-type.enum';
import { OrderArticle } from './../../../../shared/models/order-article.model';
import { DateUtils } from './../../../../shared/utils/date.utils';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-order-article-row]',
  templateUrl: './order-article-row.component.html',
  styleUrls: ['./order-article-row.component.scss'],
  providers: [DecimalPipe, DatePipe],
  animations: [
    trigger('fade',
      [
        transition(
          ':enter', [
          style({
            opacity: 0,
            height: 0,
            borderWidth: 0,
            padding: 0,
            overflow: 'hidden'
          }),
          animate(500, keyframes([
            style({ opacity: 0, height: 0, padding: 0, borderWidth: 0, offset: 0 }),
            style({ opacity: 0, height: 0, padding: 0, borderWidth: 0, offset: 0.4 }),
            style({ opacity: 0, height: '*', padding: '*', borderWidth: '*', offset: 0.6 }),
            style({ opacity: 1, height: '*', padding: '*', borderWidth: '*', offset: 1 }),
          ])
          )
        ]
        ),
        transition(
          ':leave', [
          animate(500, keyframes([
            style({ opacity: 1, height: '*', padding: '*', borderWidth: '*', offset: 0 }),
            style({ opacity: 0, height: '*', padding: '*', borderWidth: '*', offset: 0.4 }),
            style({ opacity: 0, height: 0, padding: 0, borderWidth: 0, offset: 0.6 }),
            style({ opacity: 0, height: 0, padding: 0, borderWidth: 0, offset: 1 }),
          ])
          )
        ])
      ]
    )
  ]
})
export class OrderArticleRowComponent implements OnInit {
  CurrencyType = CurrencyType;
  DateUtils = DateUtils;
  @Input() orderArticle: OrderArticle;
  @Input() index: number;
  article: Article;
  constructor(private pdfService: PdfService) { }
  reorderArticleClicked = false;
  ngOnInit(): void { }
  reorderArticle() {
    this.reorderArticleClicked = true;
    const anyArticle = this.orderArticle as any;
    this.article = anyArticle as Article;
    this.article.articleType = ArticleType.ARTICLE;
  }

  /**
   * Gets scaled price label
   */
  get scaledPrice() {
    return this.orderArticle.price / this.orderArticle.amount * 100;
  }

  /**
   * Downloads pdf
   */
  downloadPdf(idArticleErp: string) {
    this.pdfService.getArticlePdf(idArticleErp).subscribe((data: any) => {
      if (data.size && data.size > 0) {
        const blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
        FileUtils.downloadFile(blob, this.orderArticle.idArticleERP);
      }
    });
  }
}
