<div class="create-order">
    <div class="address" *ngIf="!createdOrder">
        <app-cart-progress step=1></app-cart-progress>
        <div class="container-fluid">
            <app-create-order-form (orderCreated)="createdOrder = $event"></app-create-order-form>
        </div>
        <app-cart-navigation step=2></app-cart-navigation>
    </div>
    <div class="container-fluid" *ngIf="createdOrder">
        <div class="order-success">
            <div class="order-success__background"></div>
            <app-breadcrumb></app-breadcrumb>
            <div class="order-success__body">
                <h3 i18n>{{'cart.orderSuccessfullyCompleted'|translate}}</h3> {{'cart.orderNumber'|translate}}
                {{createdOrder.idOrderERP}} <div class="order-success__link">
                    <a routerLink="/dashboard" i18n> {{'cart.backToHomePage'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
