<app-section-header section="orders"></app-section-header>
<div class="orders">
    <div class="container-fluid">
        <app-articles-filter (formGroupChange)="onSearch($event)" [searchFormControl]="searchFormControl"
            placeholder="{{'orders.filter'|translate}}"></app-articles-filter>
        <div class="orders-content" *ngIf="orders.length > 0">
            <div class="order-toggle" *ngFor="let order of orders">
                <app-order-row [order]="order" (orderOpened)="orderOpened($event)" *ngIf="orders.length == 1">
                </app-order-row>
                <app-order-row [order]="order" *ngIf="orders.length > 1"></app-order-row>
                <div *ngIf="showLoadFullOrder && orders[0].orderArticles.length <= 1 && !showLoadFullOrderClicked"
                    class="se-table-row load-full-order-row" [ngClass]="{'opened': singleArticleOrderOpened}">
                    <div class="se-table-col lg text-center">
                        <div class="load-full-order-content d-flex align-items-center">
                            <img src="/assets/img/icons/dots.svg" alt="dots">
                            <button class="load-full-order" i18n (click)="getFullOrder()">{{'orders.loadCompleteOrder'|translate}}
                                <img src="/assets/img/icons/reload.svg" alt="load-full"> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="loading">
            <app-loading-spinner></app-loading-spinner>
        </div>
        <div *ngIf="orders.length == 0 && !loading" class="se-table-row">
            <div class="se-table-col lg text-center" i18n>{{'orders.noItemsToShow'|translate}}</div>
        </div>
    </div>
    <app-button class="load-more m-auto" *ngIf="!showSearch" (click)="loadMore()"
        [disabled]="loadMoreAvailable === false || loading == true"
        [ngClass]="{'inactive': loadMoreAvailable === false || loading == true}" label="{{'orders.loadMore'|translate}}" i18n></app-button>
    <app-cart-navigation [step]="0"></app-cart-navigation>
</div>
