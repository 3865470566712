import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Conformities } from "../models/conformities";
import { Colors } from "../models/colors.model";
import { TokenValue } from "../models/token-value.model";

@Injectable({ providedIn: 'root' })
export class MaterialAppService {
  private apiUrl = environment.apiUrl;
  private materialsUrl = environment.apiUrlMaterial;

  constructor(private http: HttpClient) { }

  public getToken(): Observable<TokenValue> {
    return this.http.get(this.apiUrl + '/material-app/get-token-for-rest-api') as Observable<TokenValue>;
  }


  // Helper method to create headers with an empty Authorization value
  private getEmptyAuthorizationHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', '');
  }

  public getColors(token: TokenValue): Observable<Colors[]> {
    const body = { token };

    // Set the options with an empty Authorization header
    const options = {
      headers: this.getEmptyAuthorizationHeaders(),
    };

    return this.http.post<Colors[]>(this.materialsUrl + '/master-data/colors/get-list-4-se', body, options);
  }

  public getConformities(token: TokenValue): Observable<Conformities[]> {
    const body = { token };

    // Set the options with an empty Authorization header
    const options = {
      headers: this.getEmptyAuthorizationHeaders(),
    };

    return this.http.post<Conformities[]>(this.materialsUrl + '/master-data/conformities/get-list-4-se', body, options);
  }


}
