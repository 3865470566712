import { UserService } from './../../services/user.service';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { PersonalContact } from 'src/app/shared/models/personal-contact.model';
import { PersonalContactService } from 'src/app/shared/services/personal-contact.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Subject } from 'rxjs';
import { ImagesService } from '../../services/images.service';

@Component({
  selector: 'app-personal-contact',
  templateUrl: './personal-contact.component.html',
  styleUrls: ['./personal-contact.component.scss']
})
export class PersonalContactComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  currentUser: User;
  personalContactInfo: PersonalContact;
  constructor(
    private authenticationService: AuthenticationService,
    private personalContactService: PersonalContactService,
    private userService: UserService,
    private imageService: ImagesService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  ngOnInit(): void {
    // subscribing on personal contact get
    if (this.currentUser) {
      this.personalContactService.getPersonalContact().pipe(takeUntil(this.unsubscribe))
        .subscribe((data: PersonalContact) => { this.personalContactInfo = data; this.initProfileImage(); });
    }
  }

  // gets the potrait photo of the employee or a dummy if it does not exist
  private initProfileImage(): void {
    this.personalContactInfo.profileImage = this.imageService.getPersonalContactImageUrl(this.personalContactInfo.idERP);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
