<app-section-header section="manage-users"></app-section-header>
<div class="manage-users">
    <div class="container-fluid">
        <div class="users-table">
            <section class="se-table-flex">
                <div class="se-table-header se-table-row">
                    <div class="se-table-col md" i18n>{{'admin.manageUsers.user' | translate}}</div>
                    <div class="se-table-col sm" i18n>{{'admin.manageUsers.gender' | translate}}</div>
                    <div class="se-table-col sm" i18n>{{'admin.manageUsers.title' | translate}}</div>
                    <div class="se-table-col sm" i18n>{{'admin.manageUsers.status' | translate}}</div>
                    <div class="se-table-col sm" i18n>{{'admin.manageUsers.role' | translate}}</div>
                    <div class="se-table-col sm" i18n></div>
                    <div class="se-table-col sm" i18n></div>
                    <div class="se-table-col sm" i18n></div>
                </div>
                <div class="se-table-row align-items-center se-table-body"
                    *ngFor="let user of users; let userIndex = index">
                    <div class="se-table-col md">
                        <div class="id-label">{{user.idErp}}</div>
                        <div class="info-label"> {{user.name}} - {{user.surname}} </div>
                        <div class="info-label"> {{user.username}} </div>
                    </div>
                    <div class="se-table-col sm"> {{user.gender}} </div>
                    <div class="se-table-col sm"> {{user.title}} </div>
                    <div class="se-table-col sm"> {{user.status}} </div>
                    <div class="se-table-col sm">
                        <span class="roles" *ngFor="let role of user.roles; let i = index"> {{getRoleName(role)}} <span
                                *ngIf="i !== user.roles.length-1">|</span></span>
                    </div>
                    <div class="se-table-col sm">
                        <div ngbDropdown #myDrop="ngbDropdown">
                            <app-button type="button" id="dropdownManageRoles" ngbDropdownToggle
                                [disabled]="user.status === UserStatusType.VIRTUAL" label="{{'admin.manageUsers.manageRoles'|translate}}">
                            </app-button>
                            <div ngbDropdownMenu aria-labelledby="dropdownManageRoles" class="manage-roles"
                                *ngIf="user.status !== UserStatusType.VIRTUAL">
                                <div class="role-action" *ngFor="let role of roles"> <b>{{getRoleName(role)}} </b>
                                    <button *ngIf="user.roles.indexOf(role) === -1"
                                        (click)="assignRole(user.idUser, role, userIndex)"> {{'admin.manageUsers.add' | translate}} </button>
                                    <button *ngIf="user.roles.indexOf(role) !== -1"
                                        (click)="removeRole(user.idUser, role, userIndex)"> {{'admin.manageUsers.remove' | translate}} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="se-table-col sm">
                        <app-button [label]="'admin.manageUsers.block' | translate" (click)="blockUser(user)"
                            [disabled]="user.status === UserStatusType.VIRTUAL"
                            *ngIf="user.status !== UserStatusType.BLOCKED">
                        </app-button>
                        <app-button [label]="'admin.manageUsers.unblock' | translate" (click)="unblockUser(user)"
                            [disabled]="user.status === UserStatusType.VIRTUAL"
                            *ngIf="user.status === UserStatusType.BLOCKED">
                        </app-button>
                    </div>
                    <div class="se-table-col sm">
                        <app-button [label]="'admin.manageUsers.passReset' | translate" (click)="sendPasswordChangeRequest(user)"
                            [disabled]="user.status == UserStatusType.BLOCKED || user.status === UserStatusType.VIRTUAL">
                        </app-button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
