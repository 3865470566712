import { CapacityPriceList } from './../../../../../shared/models/capacity-price-list.model';
import { ArticlesListUtils } from './../../../../../shared/utils/articles-list.utils';
import { TranslateService } from "@ngx-translate/core";
import { CurrencyType } from './../../../../../shared/models/currency-type.enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CapacityPrice } from 'src/app/shared/models/capacity-price.model';
import { ArticlesListMode } from './../../../../../shared/models/articles-list-mode.enum';
import { CapacityPriceStatus } from './../../../../../shared/models/capacity-price-status.enum';

@Component({
  selector: 'app-price-label',
  templateUrl: './price-label.component.html',
  styleUrls: ['./price-label.component.scss'],
  animations: [
    trigger('fade',
      [
        transition(
          ':enter', [
          style({ opacity: 0 }),
          animate('500ms', style({ opacity: 1 }))
        ]
        ),
        transition(
          ':leave', [
          style({ opacity: 1 }),
          animate('500ms', style({ opacity: 0 })),

        ])
      ]
    )
  ]
})
export class PriceLabelComponent implements OnInit {
  ArticlesListUtils = ArticlesListUtils;
  CurrencyType = CurrencyType;
  CapacityPriceStatus = CapacityPriceStatus;
  ArticlesListMode = ArticlesListMode;
  @Input() articlesListMode = ArticlesListMode.ARTICLES;
  @Input() processing = false;
  @Input() articleDatePrice: CapacityPriceList;
  @Input() priceChanged = new EventEmitter();
  @Output() priceAccept = new EventEmitter();
  priceAccepted = false;
  showPriceChange = false;

  constructor(public translationsService: TranslateService) { }

  ngOnInit(): void {
    this.priceChanged.subscribe(data => {
      this.showPriceChange = data;
    });
  }

  /**
   * Accepts price confirmation and emit change for parent
   */
  acceptPrice() {
    if (!this.priceAccepted && this.showPriceChange) {
      this.priceAccepted = true;
      this.priceAccept.emit(true);
      window.setTimeout(() => {
        this.showPriceChange = false;
      }, 2000);
    }
  }

}
