import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { startWith, takeUntil, map, filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Customer } from './../../shared/models/customer.model';
import { CustomerService } from './../../shared/services/customer.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
  providers: [DecimalPipe],
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  loggedUser;
  customers: Customer[] = [];
  accountManagerId: string;
  customersFiltered: Customer[];
  showingCustomersNumber: number;
  showingCustomers: Customer[];
  customers$: Observable<Customer[]>;
  filter = new UntypedFormControl('');
  loadMoreItemsNumber = 50;
  accountManagers = [];
  showNoCustomersError = false;
  public timeout: any = null;
  private unsubscribe: Subject<void> = new Subject();
  constructor(private authenticationService: AuthenticationService, private customersService: CustomerService) { }

  ngOnInit(): void {
    this.authenticationService.logoutAsCustomer();
    this.getAccountManagers();

    this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      if (user) {
        this.loggedUser = user.idErp;
        if (user.loginAsManager) {
          this.accountManagerId = user.loginAsManager;
        } else {
          this.accountManagerId = user.idErp;

        }
      }
    });
    this.customersService.getCustomers(this.accountManagerId).pipe(takeUntil(this.unsubscribe)).subscribe(customers => {
      if (!customers || customers.length === 0) { this.showNoCustomersError = true; }
      this.customersFiltered = customers;
      this.showingCustomersNumber = this.loadMoreItemsNumber;
      if (this.loadMoreItemsNumber && this.customersFiltered) {
        this.showingCustomers = this.customersFiltered.slice(0, this.loadMoreItemsNumber); }
      this.customers$ = of(this.showingCustomers);
      this.customers = customers; this.toggleSearch();
    });
  }

  /**
   * Toggles search
   */
  toggleSearch() {
    this.filter.valueChanges.pipe(
      startWith(''),
    ).pipe(takeUntil(this.unsubscribe)).subscribe(text => {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.timeout = null;
        this.customersFiltered = this.search(text.toLowerCase());
        this.showingCustomersNumber = this.loadMoreItemsNumber;
        this.showingCustomers = this.customersFiltered ? this.customersFiltered.slice(0, this.loadMoreItemsNumber) : [];
        this.customers$ = of(this.showingCustomers);

      }, 1000);
    });
  }

  /**
   * Search text in the customers table
   * @param text text to search
   * @returns customers
   */
  search(text: string) {
    if (!this.customers){ return null;}
    return this.customers.filter(obj => {
      return obj.customerName.toLowerCase().indexOf(text) !== -1 ||
        obj.customerAbbreviation.toLowerCase().indexOf(text) !== -1 ||
        obj.idCustomerErp.toString().indexOf(text) !== -1;
    });
  }

  /**
   * Gets customers
   */
  getCustomers() {
    this.showNoCustomersError = false;
    this.authenticationService.loginAsAccountManager(this.accountManagerId);
    this.customers = [];
    this.customers$ = of([]);
    this.customersService.getCustomers(this.accountManagerId).pipe(takeUntil(this.unsubscribe)).subscribe(customers => {
      // if (!customers) { this.showNoCustomersError = true; }
      this.customers = customers;
      if (this.filter.value !== '') {
        this.customersFiltered = this.search(this.filter.value.toLowerCase());
        this.showingCustomersNumber = this.loadMoreItemsNumber;
        this.showingCustomers = this.customersFiltered ? this.customersFiltered.slice(0, this.loadMoreItemsNumber) : [];
        this.customers$ = of(this.showingCustomers);
      } else {
        this.customersFiltered = customers;
        this.showingCustomersNumber = this.loadMoreItemsNumber;
        this.showingCustomers = this.customersFiltered ? this.customersFiltered.slice(0, this.loadMoreItemsNumber) : [];
        this.customers$ = of(this.showingCustomers);
      }
    });
  }

  getAccountManagers() {
    this.customersService.getAccountManagersList().pipe(takeUntil(this.unsubscribe),
      map((data: any) => data.filter((customer: any) => {
        return (customer.roles.indexOf('ROLE_ADMIN') === -1 ||
          customer.roles.indexOf('ROLE_ADMIN') !== -1 && customer.idErp === this.loggedUser);
      }))
    ).subscribe(accountManagers => {
      this.accountManagers = accountManagers;
    });
  }

  /**
   * Logins as selected customer
   * @param customer customer
   */
  loginAs(customer: Customer) {
    this.authenticationService.getOrCreateUser(customer).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.authenticationService.loginAsCusomer(data);
    });
  }

  get loadMoreAvailable() {
    return this.customersFiltered && this.customers && this.showingCustomers &&
      this.customersFiltered.length > 0 && this.customers.length > 0 &&
      this.showingCustomers.length < this.customersFiltered.length;
  }

  /**
   * Loads more
   */
  loadMore() {
    const customers = this.customersFiltered.slice(this.showingCustomersNumber, this.showingCustomersNumber + this.loadMoreItemsNumber);
    this.showingCustomers = [...this.showingCustomers, ...customers];
    this.customers$ = of(this.showingCustomers);
    this.showingCustomersNumber = this.showingCustomersNumber + this.loadMoreItemsNumber;
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
