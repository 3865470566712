<div class="container-fluid">
    <div class="forgot-password">
        <div class="forgot-password__background"></div>
        <form class="forgot-password__form se-panel" [formGroup]="forgotPasswordForm">
            <div class="forgot-password__header">
                <h1 class="forgot-password__title" i18n> Sales Engine </h1>
            </div>
            <div class="forgot-password__body">
                <div class="forgot-password__input-container">
                    <div class='forgot-password__icon forgot-password__icon_email'></div>
                    <input type="text" name="username" formControlName="username"
                        class="forgot-password__input forgot-password__input_email se-input" placeholder="Email"
                        [ngClass]="{ 'is-invalid': submitted && fields.username.errors }" i18n-placeholder>
                </div>
                <app-button label="{{'forgotPassword.sendRecoveryLink'|translate}}" padding="10px" (onClick)="onSubmit()"
                    class="forgot-password__button" i18n>
                </app-button>
                <div class="reset-password__validation text-left">
                    <div i18n> <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{emailRequired ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': emailRequired ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'forgotPassword.emailRequired'|translate}}</div>
                    <div i18n>
                        <span class="reset-password__check">
                            <svg-icon src="/assets/img/icons/{{emailValid ? 'check':'dot'}}.svg"
                                [svgStyle]="{ 'fill': emailValid ?'#4AB371':'#000', 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </span> {{'forgotPassword.emailShouldBeValid'|translate}}</div>
                </div>
                <a class="forgot-password__login" [routerLink]="['/login']" i18n> {{'forgotPassword.backToLogin'|translate}} </a>
            </div>
        </form>
    </div>
</div>
