<div class="container pt-3 pb-3">
    <div class="row" [formGroup]="scaledPricesForm">
        <div class="col-md-12 mb-3">

            <!-- Step 1️⃣ -->
            <div class="card">
                <div class="card-body pb-2">
                    <h5 class="se-title pb-2" i18n>{{'orderSeal.determineDimensionsProfile'|translate}}
                        {{selectedProfile.name}}</h5>

                    <div class="svg-container">

                        <div class="loading-price" *ngIf="loading">
                            <app-loading-spinner [size]="40"></app-loading-spinner>
                        </div>

                        <div #svg class="pt-md-0 p-5 svgImage"></div>

                        <div formArrayName="measures" class="measures mb-5">
                            <div *ngFor="let control of scaledPricesForm.get('measures')['controls']; let i = index"
                                (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)">

                                <!-- Dissapears with required ✅ fromTo ✅ formula ✅ -->
                                <h6
                                    *ngIf="!(svgErrors[i].required && svgErrors[i].fromTo && areAllValuesTrue(svgErrors[i].formulas))">
                                    {{this.translationsService.instant('orderSeal.'+control.get('idMeasureAbas').value)}}
                                </h6>

                                <!-- Apply conditional class based on control validity -->
                                <!-- Validación Required -->

                                <div class="error-line" [@fadeOut]="!svgErrors[i].required ? 'visible' : 'hidden'"
                                    [@fadeIn]>
                                    <div>
                                        <img src="/assets/img/icons/tick{{(!svgErrors[i].required) ? '-no' : ''}}.svg">
                                    </div>
                                    <div>
                                        {{ 'orderSeal.validation.required' | translate }}
                                    </div>
                                </div>


                                <div class="error-line" [@fadeOut]="!svgErrors[i].fromTo ? 'visible' : 'hidden'"
                                    [@fadeIn]>
                                    <div>
                                        <img src="/assets/img/icons/tick{{!svgErrors[i].fromTo ? '-no' : ''}}.svg">
                                    </div>
                                    <div>
                                        {{ 'orderSeal.validation.valueBetween' | translate:{ fromValue:
                                        this.selectedProfile.profileMeasureInputLines[i].fromValue, toValue:
                                        this.selectedProfile.profileMeasureInputLines[i].toValue } }}
                                    </div>
                                </div>

                                <div *ngFor="let formula of this.formulas[i]; let j = index">
                                    <div class="error-line"
                                        [@fadeOut]="!svgErrors[i].formulas[j] ? 'visible' : 'hidden'" [@fadeIn]>
                                        <div>
                                            <img
                                                src="/assets/img/icons/tick{{!svgErrors[i].formulas[j] ? '-no' : ''}}.svg">
                                        </div>
                                        {{'orderSeal.validation.valueBe' | translate}}
                                        {{getRestFormulaTranslation(formula)}}
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>

                        <div [class.show]="!showChooseMaterial" class="step d-flex flex-row button-bottom-right ">
                            <button [disabled]="!measuresValid" type="button" class="transparent btn next-button"
                                (click)="activeChooseMaterial()">
                                {{'orderSeal.measuresAndMaterial.chooseMaterial'|translate}}
                            </button>
                            <img src="assets/img/icons/chevron-right{{!measuresValid ? '-grey' : ''}}.svg">
                        </div>


                        <div class="form-group article-number-form-group">
                            <label for="article-number"
                                i18n>{{'orderSeal.measuresAndMaterial.articleNumberOptional'|translate}}</label>
                            <input type="text" name="article-number" class="form-control se-input"
                                formControlName="idArticleClient"
                                placeholder="{{'orderSeal.measuresAndMaterial.articleNumber'|translate}}">
                        </div>

                    </div>


                </div>
            </div>
            <!-- End Step 1️⃣ -->
            <br>

            <!-- Step 2️⃣ -->
            <div id="choose-material-section">
                <div [@fadeIn] *ngIf="showChooseMaterial" class="card">
                    <div class="card-body pb-2">
                        <h5 class="se-title" i18n>{{'orderSeal.chooseMaterial'|translate}}</h5>

                        <ng-container formArrayName="componentMaterials">
                            <div class="form-group" *ngFor="let form of componentMaterials.controls; let i = index"
                                [formGroup]="form">
                                <div class="d-flex align-items-center material-select">

                                    <div class="row w-100 align-items-center">
                                        <div class="col-4">
                                            <label for="material-1"
                                                i18n>{{'orderSeal.measuresAndMaterial.materialFor'|translate}}
                                                {{selectedProfile.profileComponents[i].name}}
                                                {{'orderSeal.measuresAndMaterial.choose'|translate}}:</label>
                                        </div>

                                        <div class="col-4">
                                            <span *ngIf="selectedMaterialID">
                                                <b>ID: </b> {{selectedMaterialID}}
                                            </span>
                                            <span *ngIf="selectedMaterial">
                                                <b>Name: </b> {{selectedMaterial}}
                                            </span>
                                        </div>

                                        <div class="col-4">
                                            <app-button class="cursor-pointer" (click)="openMaterialsModal()">
                                                {{'orderSeal.chooseMaterial'|translate}}
                                            </app-button>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </ng-container>

                        <div class="d-flex justify-content-end mt-3">
                            <div [class.show]="!showScaledPrices" class="step d-flex flex-row">
                                <button [disabled]="!materialValid" type="button" class="transparent btn next-button"
                                    (click)="activeScaledPrices()">
                                    {{'orderSeal.measuresAndMaterial.calculateMaterialPrice'|translate}}
                                </button>
                                <img src="assets/img/icons/chevron-right{{!materialValid ? '-grey' : ''}}.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Step 2️⃣ -->

            <br>

            <!-- Step 3️⃣ -->
            <div id="scaled-prices-section">

                <div [@fadeIn] *ngIf="showScaledPrices" class="card">
                    <div class="card-body pb-2">
                        <h5 class="se-title" i18n>{{'orderSeal.scaledPrices'|translate}}</h5>
                        <div class="card-items">
                            <ng-container formArrayName="scaledPricesLines">
                                <div class="card-item d-flex flex-column align-items-center"
                                    *ngFor="let form of scaledPricesLines.controls; let i = index" [formGroup]="form">
                                    <div
                                        class="scaled-wrapper col-8 col-md-8 col-lg-8 d-flex flex-row scaled-price-line">
                                        <div class="card-item-input d-flex align-items-center">
                                            <div class="card-item-label" i18n>
                                                {{'orderSeal.measuresAndMaterial.fromQuantity'|translate}}
                                            </div>
                                            <div class="card-item-value ml-2">
                                                <input type="number" class="form-control w-100"
                                                    placeholder="{{'orderSeal.measuresAndMaterial.from'|translate}}"
                                                    min="0" formControlName="quantity" pattern="^(0|[1-9][0-9]*)$">
                                            </div>
                                        </div>
                                        <div class="card-item-input d-flex align-items-center">
                                            <div class="card-item-label" i18n>
                                                {{'orderSeal.measuresAndMaterial.price'|translate}} / {{scaledPricesList
                                                ? scaledPricesList.scaledPriceList[i]?.quantityUnit : '100'}}
                                                {{'orderSeal.measuresAndMaterial.piece'|translate}}
                                            </div>
                                            <div class="card-item-value ml-2">
                                                <input type="number" class="form-control" formControlName="price">
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end line-control-buttons">
                                            <button
                                                [ngStyle]="{ 'visibility': scaledPricesLines.controls.length === 1 ? 'hidden' : 'visible' }"
                                                class="remove-button"><i class="bi bi-trash3"
                                                    (click)="removeItem(i)"></i></button>
                                        </div>

                                    </div>
                                    <div *ngIf="i === scaledPricesLines.controls.length -1"
                                        class="col-12 col-md-8 col-lg-8 d-flex align-items-center "
                                        style="padding: 10px 0 0 0;">
                                        <app-add-new-button padding="10px 0 0 0"
                                            text="{{'orderSeal.measuresAndMaterial.addMoreMaterial'|translate}}"
                                            [disabled]="scaledPricesForm.disabled || (this.scaledPricesLines.length >= 11)"
                                            (onAddClick)="addItem()" i18n>
                                        </app-add-new-button>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                        <div class="mb-2 col-md-12 mt-2 d-flex bottom-buttons justify-content-end">
                            <app-button width="300px" (onClick)="disableForm(false)"
                                label="{{'orderSeal.measuresAndMaterial.toEdit'|translate}}" i18n
                                *ngIf="scaledPricesForm.disabled">
                            </app-button>
                            <app-button width="300px" [disabled]="scaledPricesForm.invalid  || loading"
                                (onClick)="orderSeal()"
                                label="{{'orderSeal.measuresAndMaterial.requestOffer'|translate}}" i18n
                                *ngIf="scaledPricesForm.disabled">
                            </app-button>
                            <app-button width="300px" [disabled]="scaledPricesForm.invalid || loading"
                                (onClick)="getScaledPrices()" *ngIf="!scaledPricesForm.disabled"
                                [ngClass]="{'inactive': scaledPricesForm.invalid || loading}"
                                label="{{'orderSeal.measuresAndMaterial.calculateMaterialPrice'|translate}}" i18n>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Step 3️⃣ -->
        </div>
    </div>