<div class="diagram-overlay">
    <div class="container-diagram d-flex justify-content-between">
        <div class="diagram d-flex" *ngIf="loading !== undefined">
            <button class="diagram__button diagram__button-left btn d-block" (click)="goLeft()">
                <svg-icon src="/assets/img/icons/chevron-left.svg" *ngIf="!loadingLeft">
                </svg-icon>
                <app-loading-spinner *ngIf="loadingLeft" [size]="20"></app-loading-spinner>
            </button>
            <div class="diagram-wrapper">
                <ngx-charts-bar-vertical [view]="" [results]="values" [gradient]="gradient" [yAxisTicks]="yAxisTicks"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                    [customColors]="colors" [showYAxisLabel]="showYAxisLabel" [roundEdges]="roundEdges"
                    [showGridLines]="showGridLines" (mousemove)="onMouseOver($event)" (mousedown)="onMouseStart($event)"
                    (mouseup)="disableDragging($event)" (mouseout)="disableDragging($event)"
                    (select)="onChartSelect($event)">
                    <ng-template #tooltipTemplate let-model="model"> {{ model.extra.tooltip }} </ng-template>
                </ngx-charts-bar-vertical>
                <div class="current-day">
                    <span i18n>{{'shared.diagram.shippingDate'|translate}}</span>
                    <div class="line"></div>
                </div>
            </div>
            <button class="diagram__button diagram__button-right btn d-block" (click)="goRight()">
                <svg-icon src="/assets/img/icons/chevron-right.svg" *ngIf="!loadingRight">
                </svg-icon>
                <app-loading-spinner *ngIf="loadingRight"  [size]="20"></app-loading-spinner>
            </button>
        </div>
        <div class="best-price" *ngIf="loading !== undefined">
            <div class="best-price__container d-flex flex-wrap">
                <div class="best-price__box">
                    <div class="best-price__header d-flex align-items-center">
                        <p class="best-price__title bold" i18n>{{'shared.diagram.desiredShippingDate'|translate}}: {{currentPrice.dateText || ''}} </p>
                        <div class="best-price__icon ml-auto"
                            [ngStyle]="{'border-color': ArticlesListUtils.getStatusColor(currentPrice.priceStatus)}">
                            <svg-icon src="/assets/img/icons/check.svg"
                                [svgStyle]="{ 'fill':ArticlesListUtils.getStatusColor(currentPrice.priceStatus), 'width.px':25, 'height.px':25 }">
                            </svg-icon>
                        </div>
                    </div>
                    <div class="best-price__underline"></div>
                    <div class="best-price__properties">
                        <div class="best-price__properties_item d-flex">
                            <p class="price__properties_title" i18n>{{'shared.diagram.amount'|translate}}:</p>
                            <p class="price__properties_value">{{currentPrice.amount}} Stk.</p>
                        </div>
                        <div class="best-price__properties_item d-flex">
                            <p class="price__properties_title" i18n>{{'shared.diagram.price'|translate}}:</p>
                            <p class="price__properties_value" *ngIf="currentPrice.scaledPrice">
                                {{(currentPrice.scaledPrice.price | currency:  currentPrice.currency )+' / '+currentPrice.scaledPrice.quantityUnit || '-'}}
                            </p>
                        </div>
                        <div class="best-price__properties_item best-price__properties_item-total d-flex bold mt-3">
                            <p class="price__properties_title" i18n>{{'shared.diagram.inTotal'|translate}}:</p>
                            <p class="price__properties_value"
                                *ngIf="currentPrice.scaledPrice && currentPrice.scaledPrice">
                                {{(currentPrice.priceTotal) || '0' | currency:  currentPrice.currency }} </p>
                        </div>
                    </div>
                </div>
                <div class="best-price__box clickable" (click)="chooseBestPriceBox(bestPrice.date)">
                    <app-loading-spinner *ngIf="this.bestPriceLoading" class="best-price__spinner">
                    </app-loading-spinner>
                    <div class="best-price__content" *ngIf="!this.bestPriceLoading">
                        <div class="best-price__header best-price__header-high d-flex align-items-center">
                            <p class="best-price__title bold" i18n>{{'shared.diagram.bestPriceDate'|translate}}: {{bestPrice.dateText || '-'}} </p>
                            <div class="best-price__icon ml-auto"
                                [ngStyle]="{'border-color': ArticlesListUtils.getStatusColor(bestPrice.priceStatus)}">
                                <svg-icon src="/assets/img/icons/check.svg"
                                    *ngIf="bestPrice.dateText === currentPrice.dateText"
                                    [svgStyle]="{ 'fill': ArticlesListUtils.getStatusColor(bestPrice.priceStatus), 'width.px':25, 'height.px':25 }">
                                </svg-icon>
                            </div>
                        </div>
                        <div class="best-price__underline"></div>
                        <div class="best-price__properties">
                            <div class="best-price__properties_item d-flex">
                                <p class="price__properties_title" i18n>{{'shared.diagram.amount'|translate}}:</p>
                                <p class="price__properties_value">{{bestPrice.amount}} Stk.</p>
                            </div>
                            <div class="best-price__properties_item d-flex">
                                <p class="price__properties_title" i18n>{{'shared.diagram.price'|translate}}:</p>
                                <p class="price__properties_value"
                                    *ngIf="bestPrice.priceTotal && bestPrice.scaledPrice">
                                    {{(bestPrice.scaledPrice.price  | currency:  bestPrice.currency )+' / '+ bestPrice.scaledPrice.quantityUnit || '-'}}
                                </p>
                            </div>
                            <div class="best-price__properties_item best-price__properties_item-total d-flex bold mt-3">
                                <p class="price__properties_title" i18n>{{'shared.diagram.inTotal'|translate}}:</p>
                                <p class="price__properties_value" *ngIf="bestPrice.scaledPrice">
                                    {{(bestPrice.priceTotal) || '0' | currency:  bestPrice.currency }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="best-price__info" i18n>
                <p>{{'shared.diagram.productionInfo'|translate}}</p>
                <p> {{'shared.diagram.bestPriceInfo'|translate}}</p>
            </div>
        </div>
    </div>
    <div class="loading-overlay" [ngClass]="{'loading' : loadingChanges}"></div>
    <app-loading-spinner *ngIf="loadingChanges" class="loading-spinner"></app-loading-spinner>
</div>
