<footer>
    <app-personal-contact *ngIf="showPersonalContact"></app-personal-contact>
    <div class="contact">
        <div class="container">
            <h2 class="contact__title" i18n>{{'shared.footer.contact'|translate}}</h2>
            <p class="contact__label" i18n>Hänssler Kunststoff - und Dichtungstechnik GmbH</p>
            <p class="contact__label" i18n>Edwin-Reis-Str. 5</p>
            <p class="contact__label" i18n>68229 Mannheim</p>
            <a class="contact__label contact__label-email" href="mailto:haenssler@dicht.de">haenssler@dicht.de</a>
            <a class="contact__label" href="tel:+490621484800" i18n>Telefon: +49 (0) 621 48480-0</a>
            <a class="contact__label" href="fax:+496214848033" i18n>Fax: +49 (0) 621 48480-33</a>
        </div>
    </div>
    <div class="social">
        <div class="container" fxLayoutAlign="center center">
            <div fxLayout.md="row">
                <a href="https://www.instagram.com/haenssler.dicht.de/" target="_blank">
                    <img class="social__image" src="/assets/img/icons/social/instagram.png" alt="instagram">
                </a>
                <a href="https://www.facebook.com/HaensslerKunststoffDichtungstechnik" target="_blank">
                    <img class="social__image" src="/assets/img/icons/social/facebook.png" alt="facebook">
                </a>
                <a href="https://www.youtube.com/channel/UCUX2OWRGuTLzzF7XGU1pNKw" target="_blank">
                    <img class="social__image" src="/assets/img/icons/social/youtube.png" alt="youtube">
                </a>
                <a href="https://www.xing.com/companies/H%25c3%2584NSSLERHYDRAULIKGMBH?keyword=H%E4nssler%20Hydraulik%20GmbH;current="
                    target="_blank">
                    <img class="social__image" src="/assets/img/icons/social/xing.png" alt="xing">
                </a>
                <a href="https://de.linkedin.com/company/haenssler-mannheim" target="_blank">
                    <img class="social__image" src="/assets/img/icons/social/linkedin.png" alt="linkedin">
                </a>
            </div>
        </div>
    </div>
    <div class="copyright">
        <div class="container">
            <div class="copyright__label" i18n>
                <a href="https://www.dicht.de/de/impressum" target="_blank" rel="noopener noreferrer">{{'shared.footer.imprint'|translate}}</a> | <a
                    href="{{privacyPolicyUrl}}" target="_blank" rel="noopener noreferrer"
                    *ngIf="privacyPolicyUrl">{{'shared.footer.dataProtection'|translate}}</a> | <a
                    href=" https://www.dicht.de/images/downloads/Haenssler_AGB.pdf" target="_blank"
                    rel="noopener noreferrer">{{'shared.footer.termsAndConditions'|translate}}</a> </div>
            <div class="copyright__label" i18n>© 2020 Hänssler Kunststoff - und Dichtungstechnik GmbH</div>
        </div>
    </div>
</footer>
