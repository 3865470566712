import { catchError, takeUntil } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from './../../../shared/services/toast.service';
import { UserService } from './../../../shared/services/user.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public createUserForm: UntypedFormGroup;
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private toastService: ToastService,
    private translationsService: TranslateService) { }

  ngOnInit(): void {
    this.createUserForm = this.formBuilder.group({
      idUserERP: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      name: [''],
      surname: [''],
      title: [''],
      gender: ['', Validators.required]
    });
  }

  /**
   * On submit form creates a new user and shows a success or error message
   */
  onSubmit() {
    this.userService.createUser(this.createUserForm.value).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.showUserCreatedMessage();
      this.createUserForm.reset();
    }, error => this.showUserCreatedError(error));
  }

  /**
   * Gets createUserForm fields
   */
  get fields() { return this.createUserForm.controls; }

  /**
   * Validation getters
   */
  get emailValid() {
    return !this.fields.email.hasError('email') && this.fields.email.value !== '';
  }
  get emailRequired() {
    return !this.fields.email.hasError('required') && this.fields.email.value !== '';
  }
  get idRequired() {
    return !this.fields.idUserERP.hasError('required') && this.fields.idUserERP.value !== '';
  }
  get genderRequired() {
    return !this.fields.gender.hasError('required') && this.fields.gender.value !== '';
  }

  /**
   * Shows user created success message
   */
  showUserCreatedMessage() {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.user-created'),
      { classname: 'bg-success text-light', delay: 3000 }
    );
  }

  /**
   * Shows user created error message
   */
  showUserCreatedError(error: string) {
    this.toastService.show(
      this.translationsService.instant('codeTranslations.user-created-error') + error,
      { classname: 'bg-danger text-light', delay: 3000 }
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
