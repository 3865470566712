import { ProfileMeasureOutputLine } from './../../../../shared/models/profile-measure-output-line.model';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';

import {
  RemoveConfirmationModalComponent,
} from '../../../../shared/components/delete-confirmation/remove-confirmation-modal.component';
import { MaterialProfile } from '../../../../shared/models/material-profile.model';
import { Profile } from '../../../../shared/models/profile.model';
import { TranslateService } from "@ngx-translate/core";
import { ImagesService } from '../../../../shared/services/images.service';
import { ProfileComponentService } from '../../../../shared/services/profile-component.service';
import { SealingTypesService } from '../../../../shared/services/sealing-types.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProfileValidationService } from './../../../../shared/services/profile-validation.service';

@Component({
  selector: 'app-sealing-type-body',
  templateUrl: './sealing-type-body.component.html',
  styleUrls: ['./sealing-type-body.component.scss']
})
export class ProfileBodyComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() profile: Profile;
  @Output() profileChange = new EventEmitter<Profile>();
  @Input() idSealingType: number;
  @Input() materialProfiles: MaterialProfile[];
  editNameMode = false;
  editName = '';
  timer: any;
  showUploadForm = [true, true];
  imageInvalid = [true, true];
  profileForm: UntypedFormGroup;
  profileComponents: ProfileComponent[] = [];
  lineIndex = false;
  otherWrapper = { name: 'Other' };
  showInputsOrder = new EventEmitter<boolean>();
  constructor(
    private sealingTypesService: SealingTypesService, private fb: UntypedFormBuilder, private modalService: NgbModal, private toastService: ToastService,
    private translationsService: TranslateService, private imagesService: ImagesService, private profileComponentService: ProfileComponentService, private profileValidationService: ProfileValidationService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.editName = this.profile.name;
    // create form
    this.createForm();
    this.checkImage(0, this.imageProfileLeft);
    this.checkImage(1, this.imageProfileRight);
    if (this.profile.profileComponents) {
      this.profileComponents = this.profile.profileComponents;
    }
    this.profileValidationService.sealingTypesRevalidated.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.createForm();
    });
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  /**
   * Checks image
   * @param index 
   * @param image 
   */
  checkImage(index, image) {
    this.showUploadForm[index] = false;
    this.imageInvalid[index] = false;
    const img = new Image();
    img.onload = function () {
      this.showUploadForm[index] = false;
      this.imageInvalid[index] = false;
    }.bind(this);

    img.onerror = function () {
      console.log('error', image);
      // Set a placeholder image 
      this.showUploadForm[index] = true;
      this.imageInvalid[index] = true;
    }.bind(this);
    img.src = image;
  }

  /**
   * Updates item
   */
  updateProfile() {
    let profile = { ...this.profile };
    if (this.editName == '' || this.editName == null) { return; }
    profile.name = this.editName;
    if (this.profile.idProfile == null) {
      this.createProfile(profile);
      return;
    }
    this.sealingTypesService.saveProfile(profile).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.profile = { ...data };
      this.profileChange.emit(this.profile);
    });
    this.editNameMode = false;
  }

  /**
   * Cancels edit
   */
  cancelEdit() {
    this.editNameMode = false;
    if (this.profile.idProfile == null) {
      this.profileChange.emit(null);
    }

  }

  /**
   * Creates category item
   * @param profile 
   */
  createProfile(profile) {
    this.sealingTypesService.createProfile(profile, this.idSealingType).pipe(takeUntil(this.unsubscribe)).subscribe((data: Profile) => {
      this.profile = { ...data };
      this.editNameMode = false;
      this.profileChange.emit(this.profile);
      this.createForm();
      // show toast message success
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });

    }, error => {
      // show toast message error
      this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
    }
    );
  }

  /**
   * Creates form
   */
  createForm() {
    if (this.profile.idProfile == null) {
      this.editNameMode = true;
    }
    if (this.profile.profileComponents) {
      this.profileComponents = this.profile.profileComponents;
    }
    //this.profile.profileComponents = this.profile.profileComponents.filter(component=>component!=null);
    this.profileForm = this.fb.group({
      idProfile: [this.profile.idProfile, Validators.required],
      name: [this.profile.name, Validators.required],
      profileMeasureInputLines: this.fb.array(
        this.profile?.profileMeasureInputLines ? this.profile.profileMeasureInputLines.sort((a, b) => a.showOrder - b.showOrder).map(m => {

          return this.fb.group({
            idProfileMeasureInputLine: [m.idProfileMeasureInputLine, Validators.required],
            name: [m.name, Validators.required],
            fromValue: [m.fromValue, Validators.required],
            toValue: [m.toValue, Validators.required],
            // factor: [m.factor, [Validators.required, Validators.pattern(/^([-+*] ?\d+(\.\d+)? ?)*$/), this.validateFactor]],
            idSvgInput: [m.idSvgInput || null],
            showOrder: [m.showOrder || 0],
            compareRatio: [m.compareRatio || 1],
            formula: [m.formula || null],
            readonly: [m.readonly || false],
          })
        }) : []
      ),
      profileComponents: this.fb.array(
        this.profile.profileComponents ? this.profile.profileComponents.map(m => {
          return this.fb.group({
            idProfileComponent: [m?.idProfileComponent, Validators.required],
            name: [m.name, Validators.required],
            profileMeasureOutputLines: this.fb.array(
              m?.profileMeasureOutputLines ? m.profileMeasureOutputLines.sort((a, b) => a.showOrder - b.showOrder).map(m =>
                this.mapProfileOutputMeasureLineFormGroup(m)
              ) : [])

          });
        }) : []
      ),
      profileMeasureOutputOtherLines: this.fb.array(
        this.profile?.profileMeasureOutputOtherLines ? this.profile.profileMeasureOutputOtherLines.sort((a, b) => a.showOrder - b.showOrder).map(m => this.mapProfileOutputMeasureLineFormGroup(m)) : []
      ),
    });
  }

  /**
   * Maps profile output measure line form group
   * @param profileMeasureOutputLine 
   * @returns  
   */
  mapProfileOutputMeasureLineFormGroup(profileMeasureOutputLine: ProfileMeasureOutputLine) {
    return this.fb.group({
      idProfileMeasureOutputLine: [profileMeasureOutputLine.idProfileMeasureOutputLine, Validators.required],
      name: [profileMeasureOutputLine.name, Validators.required],
      idSvgOutput: [profileMeasureOutputLine.idSvgOutput || null],
	  displayTabs: [profileMeasureOutputLine.displayTabs || []],
	  internTabs: [profileMeasureOutputLine.internTabs || []],
      showOrder: [profileMeasureOutputLine.showOrder || 0],
      internValid: [profileMeasureOutputLine.internValid || false],
      displayValid: [profileMeasureOutputLine.displayValid || false],
    });
  }

  /**
   * Gets profile output values wrappers (components and other lines used to show output dropdown titles)
   */
  get profileOutputValuesWrappers() {
    return [...this.profileComponents.filter(data => data && data?.idProfileComponent), this.otherWrapper];
  }

  /**
   * Deletes category item
   */
  deleteProfile() {
    this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
      if (result.remove === true) {
        this.sealingTypesService.deleteProfile(this.profile.idProfile).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.profile = null;
          this.profileChange.emit(this.profile);
          // show toast message success
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
        }, error => {
          // show toast message error
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
        }
        );
      }
      return result
    }).catch((res) => { });
  }

  /**
   * On file change submit file
   * @param event 
   * @param imageType 
   */
  fileChange(event, imageType) {
    this.onSubmit(event, imageType);
  }

  /**
   * Gets image profile item left
   */
  get imageProfileLeft() {
    return this.imagesService.getProfileImageUrl(this.profile.idProfile, 'dimensions') + '?' + Math.floor(Date.now() / 1000);
  }

  /**
   * Gets image profile item right
   */
  get imageProfileRight() {
    return this.imagesService.getProfileImageUrl(this.profile.idProfile, 'profile') + '?' + Math.floor(Date.now() / 1000)
  }

  /**
   * On submit click
   * @param base64 
   * @param imageType 
   */
  onSubmit(base64, imageType): void {
    const formData = new FormData();
    formData.append('file', new File([base64], 'profile.svg', { type: 'image/svg' }));
    this.imagesService.uploadProfileImage(formData, this.profile.idProfile, imageType).pipe(takeUntil(this.unsubscribe)).subscribe(arg => {
      if (imageType === 'dimensions') {
        this.checkImage(0, this.imageProfileLeft);
        this.profileValidationService.updateValidations();
      } else {
        this.checkImage(1, this.imageProfileRight);
      }
    });
  }

  /**
   * Creates a new profile component form
   */
  createNewComponent() {
    this.profileComponents.push({
      idProfileComponent: null,
      name: 'Neue Komponente',
      profileComponentMaterials: [],
      profileMeasureOutputLines: [],
      articleGroup: null,
      valid: null
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

/** TODO: MOVE TO OUTPUT
 * Validates factor
 * @param control
 * @returns factor
 */
  // validateFactor(control: AbstractControl): { [key: string]: any } | null {
  //   if (control.value) {
  //     try {
  //       if (eval('1' + control.value) === undefined) {
  //         return { 'invalidFactor': true };
  //       }
  //       else if (!(eval('1' + control.value) > 1)) {
  //         return { 'invalidFactorResult': true };
  //       }
  //     } catch (error) {
  //       return { 'invalidFactor': true };
  //     }
  //   }
  //   if (control.value === null) {
  //     return { 'invalidFactor': true };
  //   }
  //   return null;
  // }