<div class="shadow">
    <div class="modal-header" style="margin-top:20px">
        <h4 class="modal-title" id="modal-title">{{'confirmDialog.title' | translate}}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss({remove: false})">
            <i class="bi bi-x"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            {{'confirmDialog.override' | translate}}
        </div>
    </div>
    <div class="modal-footer">
        <app-button (click)="modal.dismiss({remove: false})"> {{'confirmationModal.cancel' | translate}} </app-button>
        <app-button (click)="modal.close({remove: true})"> {{'confirmationModal.confirm' | translate}}</app-button>
    </div>
</div>