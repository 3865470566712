import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MaterialProfile } from './../../../../../../shared/models/material-profile.model';
import { ProfileMeasureOutputTab } from './../../../../../../shared/models/profile-measure-output-tab.model';
import { ProfileMeasureOutputLine } from './../../../../../../shared/models/profile-measure-output-line.model';
import { SealingTypesService } from './../../../../../../shared/services/sealing-types.service';
import { ProfileMeasureInputLine } from './../../../../../../shared/models/profile-measure-input-line.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-profile-outputs-formula-dialog',
	templateUrl: './profile-outputs-formula-dialog.component.html',
	styleUrls: ['./profile-outputs-formula-dialog.component.scss']
})
export class ProfileOutputsFormulaDialogComponent implements OnInit {
	private unsubscribe: Subject<void> = new Subject();

	@Input() profileMeasureOutputLine: ProfileMeasureOutputLine;
	@Input() idProfile: number;
	@Input() profileMeasureInputLines: ProfileMeasureInputLine[];
	@Output() profileMeasureOutputLineChange = new EventEmitter();
	@Input() displayType = true;
	@Input() materials: MaterialProfile[];
	@Input() profileOutputOther = false;
	@ViewChild('nav') ngbNav: NgbNav;

	copyFromTypeString = this.translateService.instant(this.displayType ? 'sealingType.profile.workpiece' : 'sealingType.profile.view');
	calculationResult = null;

	formulaForm: UntypedFormGroup;
	tabForm: UntypedFormGroup;
	calculatorForm: UntypedFormGroup;
	tabs: ProfileMeasureOutputTab[] = []

	regexConditionValidation = /^(?:(?:(?:(?<!^)(?:<=|>=|==|!=|>|<))? ?(?:(?:\|\||\&\&)(?<!$))?(?:(?:\()? ?(?:\{[\d{1,5}]\} ?| ?\d{1,5} ?) ?) ?(?: ?[\*\+-\/] ?(?:\()? ?(?:\{[\d{1,5}]\} ?|\d{1,5} ?)(?:[\)\(])?){0,5}(?:[\)\(])?){0,7})$/;
	regexThenValidation = /^ ?(?:\(?(?:\d*\.?\d*|\{\d\})) ?(?:[\+\-\*\/]{1} ?\(?(?:\d*\.?\d*|\{\d\})+)?(?:\)? ?(?:[\+\-\*\/]{1} ?\(? ?(?:\d*\.?\d*|\{\d\})+ ?)?)*$/;
	regexFactorValidation = /^([-+*] ?\d+(\.\d+)? ?)*$/;

	regexElseFormulaValidation = this.regexThenValidation;
	activeTab = 0;
	lastFormValue = null;


	constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private sealingTypesService: SealingTypesService, private translateService: TranslateService) { }

	ngOnInit(): void {
		this.createTestCalculatorForm();
		this.profileMeasureOutputLine.displayTabs?.sort((a, b) => a.idProfileMeasureOutputTab - b.idProfileMeasureOutputTab);
		this.profileMeasureOutputLine.internTabs?.sort((a, b) => a.idProfileMeasureOutputTab - b.idProfileMeasureOutputTab);
		this.tabs = this.displayType ? this.profileMeasureOutputLine.displayTabs : this.profileMeasureOutputLine.internTabs;
		if (this.tabs.length === 0) {
			this.tabs.push({ idProfileMeasureOutputTab: null, formula: [], factor: '', elseFormula: '', materials: [] });
		}
		const elseFormula = this.tabs[0]?.elseFormula || '';
		const formulaLines = this.tabs[0]?.formula || '';
		const formulaExists = formulaLines?.length > 0 || elseFormula?.length > 0;
		this.tabForm = this.fb.group({
			tabs: this.fb.array(this.tabs.map(tab => this.getNewTabForm(tab, !formulaExists, true)))
		});
		this.lastFormValue = JSON.stringify(this.tabForm.value);
		this.tabForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.validateElse();
		})
		this.validateElse();
	}

	/**
	 * Validates else formula
	 */
	validateElse() {
		if (this.tabs.length > 0) {
			for (let i = 0; i < this.tabs.length; i++) {
				if (this.formulaHasLines(i)) {
					// remove required validator from else formula
					(this.tabForm?.get('tabs') as FormArray).controls[i].get('elseFormula').setValidators(null);
					this.tabForm?.get('tabs')?.get(i + '')?.get('elseFormula').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				} else {
					(this.tabForm?.get('tabs') as FormArray).controls[i].get('elseFormula').setValidators([Validators.required, Validators.pattern(this.regexElseFormulaValidation)]);
					this.tabForm?.get('tabs')?.get(i + '')?.get('elseFormula').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				}
			}
		}
	}

	/**
	 * Creates test calculator form
	 */
	createTestCalculatorForm() {
		this.calculatorForm = this.fb.group({
			calculatorValues: this.fb.array(
				this.profileMeasureInputLines.map(inputLine => this.fb.group({
					idMeasureAbas: [inputLine.idMeasureAbas],
					value: [0]
				}))
			)
		});
	}

	/**
	 * On tab change reset calculation result
	 * @param event 
	 */
	onTabChange(event) {
		this.calculationResult = null;
		this.validateElse();
	}

	/**
	 * Runs test calculation of an output
	 */
	testCalculateOutput() {
		this.calculationResult = null;
		const profileMeasureOutputLineCopy = { ...this.profileMeasureOutputLine };

		if (this.displayType) {
			profileMeasureOutputLineCopy.displayTabs = this.tabForm.value.tabs;
		} else {
			profileMeasureOutputLineCopy.internTabs = this.tabForm.value.tabs;
		}

		this.sealingTypesService.testCalculateOutput({ idProfile: this.idProfile, profileMeasureOutputLine: profileMeasureOutputLineCopy, measures: this.calculatorForm.value.calculatorValues, internFormulaMode: !this.displayType, tabIndex: this.activeTab, otherMode: this.profileOutputOther }).pipe(takeUntil(this.unsubscribe)).subscribe(
			data => {
				console.log("data: " , data)
				this.calculationResult = data;
			},
			(error) => {
			console.error('Error in API call:', error);
			});
	}

	/**
	 * Save connection category profile link values
	 */
	connect() {
		if (this.tabForm.valid) {
			this.lastFormValue = JSON.stringify(this.tabForm.value);
			if (this.displayType) {
				this.profileMeasureOutputLine.displayTabs = this.tabForm.value.tabs;
			} else {
				this.profileMeasureOutputLine.internTabs = this.tabForm.value.tabs;
			}
			this.profileMeasureOutputLineChange.emit(this.profileMeasureOutputLine);
		}
	}

	/**
	 * Gets new tab form filled with tab object data
	 * @param tab 
	 * @param [usePlaceholderFactor] use true for new tabs
	 * @param [useEmptyId] use true for new tabs
	 * @returns  
	 */
	getNewTabForm(tab, usePlaceholderFactor = false, useEmptyId = false) {
		return this.fb.group({
			idProfileMeasureOutputTab: [useEmptyId ? null : tab.idProfileMeasureOutputTab],
			materials: this.fb.array(tab.materials?.map(material => this.fb.group({
				idMaterialProfile: [material.idMaterialProfile],
			}))),
			factor: [usePlaceholderFactor ? (this.profileOutputOther ? '' : '* 1.1 + 1.6') : tab.factor, [Validators.pattern(this.regexFactorValidation)]],
			elseFormula: [tab.elseFormula, this.formulaHasLines() ? [] : [Validators.required, Validators.pattern(this.regexElseFormulaValidation)]],
			formula: this.fb.array(tab.formula.map(formulaLine => this.fb.group({
				idProfileMeasureOutputFormulaLine: [useEmptyId ? null : formulaLine.idProfileMeasureOutputFormulaLine],
				condition: [formulaLine.condition, [Validators.required, Validators.pattern(this.regexConditionValidation)]],
				then: [formulaLine.then, [Validators.required, Validators.pattern(this.regexThenValidation)]],
			})))
		});
	}

	/**
	 * Copies from display formula if in intern mode or reverse
	 */
	copyFromDisplayFormula() {
		this.activeTab = 0;
		const formulaLinesInverse = !this.displayType ? this.profileMeasureOutputLine.displayTabs : this.profileMeasureOutputLine.internTabs;
		this.tabs = formulaLinesInverse;
		this.tabForm = this.fb.group({
			tabs: this.fb.array(formulaLinesInverse.map(tab => this.getNewTabForm(tab, false, true)))
		});
	}

	/**
	 * Adds a new tab
	 */
	addTab() {
		if (!this.profileOutputOther) {
			const newTab = { idProfileMeasureOutputTab: null, formula: [], factor: '', elseFormula: '', materials: [] };
			this.tabs.push(newTab);
			const tabs = (this.tabForm?.get('tabs') as FormArray);
			tabs.push(this.getNewTabForm(newTab, true, true));
			this.ngbNav.select(this.tabs.length - 1);
		}
	}

	/**
	 * Closes tab
	 * @param event 
	 * @param index 
	 */
	closeTab(event, index) {
		if (!this.profileOutputOther) {
			if (this.activeTab === index) {
				this.activeTab = index - 1;
				this.ngbNav.select(index - 1);
			} else if (this.activeTab > index) {
				this.activeTab--;
				this.ngbNav.select(this.activeTab);
			}
			this.tabs.splice(index, 1);
			const tabs = (this.tabForm?.get('tabs') as FormArray);
			tabs.removeAt(index);
			event.preventDefault();
			event.stopImmediatePropagation();
		}
	}

	/**
	 * Adds a formula line
	 */
	addFormulaLine() {
		const formula = ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('formula') as FormArray)
		formula.push(this.fb.group({
			idProfileMeasureOutputFormulaLine: [''],
			condition: ['', [Validators.required, Validators.pattern(this.regexConditionValidation)]],
			then: ['', [Validators.required, Validators.pattern(this.regexThenValidation)]]
		}));
	}

	/**
	 * Removes a formula line
	 * @param index 
	 */
	removeFormulaLine(index: number) {
		const formula = ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('formula') as FormArray)
		formula.removeAt(index);
	}

	/**
	 * Checks/unchecks material
	 * @param material 
	 * @param event 
	 */
	checkMaterial(material, event) {
		const items = ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('materials') as FormArray);
		if (event.target.checked) {
			items.push(this.fb.control({ idMaterialProfile: material.idMaterialProfile }));
		} else {
			const index = items.controls.findIndex(x => x.value.idMaterialProfile === material.idMaterialProfile);
			items.removeAt(index);
		}
	}
	/**
	 * Selects all materials
	 */
	selectAllMaterials() {
		const items = ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('materials') as FormArray);
		items.clear();
		this.editableMaterials.forEach(idMaterialProfile => {
			items.push(this.fb.control({ idMaterialProfile: idMaterialProfile }));
		});
	}

	/**
	 * Unselects all materials
	 */
	unselectAllMaterials() {
		const items = ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('materials') as FormArray);
		items.clear();
	}
	/**
	 * Gets formula controls
	 */
	getFormulaControls(tabIndex) {
		// Check if this.tabForm is defined before accessing its properties
		if (this.tabForm) {
			const tabsArray = this.tabForm.get('tabs') as FormArray;

			if (tabsArray && tabsArray.controls[tabIndex]) {
				const formulaArray = tabsArray.controls[tabIndex].get('formula') as FormArray;

				if (formulaArray) {
					return formulaArray.controls;
				}
			}
		}

		return null;
	}


	/**
	 * Check if material is checked
	 * @param idMaterialProfile 
	 * @returns 
	 */
	materialIsChecked(idMaterialProfile) {
		return this.checkedMaterials.includes(idMaterialProfile);
	}

	/**
	 * Check if material can be checked/unchecked
	 * @param idMaterialProfile 
	 * @returns 
	 */
	materialIsEditable(idMaterialProfile) {
		return this.editableMaterials.includes(idMaterialProfile);
	}

	/**
	 * Gets checked materials for current tab
	 */
	get checkedMaterials() {
		return ((this.tabForm?.get('tabs') as FormArray).controls[this.activeTab].get('materials') as FormArray).value.map(material => material.idMaterialProfile);
	}

	/**
	 * Gets all checked materials (all tabs)
	 */
	get allCheckedMaterials() {
		return (this.tabForm?.get('tabs') as FormArray).value.map(({ materials }) => materials.map(({ idMaterialProfile }) => idMaterialProfile)).flat();
	}

	/**
	 * Gets editable (available to check/uncheck) materials for current tab
	 */
	get editableMaterials() {
		// current tab checked materials + not checked materials
		return this.materials.filter(material => this.checkedMaterials.includes(material.idMaterialProfile) || !this.allCheckedMaterials.includes(material.idMaterialProfile)).map(({ idMaterialProfile }) => idMaterialProfile)
	}

	get unselectAllAvailable() {

		return this.checkedMaterials.length > 0;
	}

	get selectAllAvailable() {
		return this.checkedMaterials.length !== this.editableMaterials.length;
	}

	get formulaUnsaved() {
		return this.lastFormValue !== JSON.stringify(this.tabForm?.value);
	}

	formulaHasLines(tabIndex = this.activeTab) {
		return this.getFormulaControls(tabIndex)?.length > 0;
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
