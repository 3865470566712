import { ImagesService } from './../../services/images.service';
import { UserService } from './../../services/user.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [
    NgbModalConfig, NgbModal, NgbActiveModal
  ]
})
export class EditProfileComponent implements OnInit {
  public editUserForm: UntypedFormGroup;
  public user = JSON.parse(localStorage.getItem('currentUser'));
  public files = [];
  profileImage;
  imageChangedEvent: any = '';
  croppedImage: any = null;
  base64Output: any = null;
  currTime = new Date().valueOf();

  constructor(
    private config: NgbModalConfig,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    private imageService: ImagesService,
    private formBuilder: UntypedFormBuilder
  ) { config.backdrop = 'static'; config.keyboard = false; }

  ngOnInit(): void {
    this.createGroup();
    this.loadImage();
  }

  /**
   * Creates form group
   */
  createGroup(): void {
    this.editUserForm = this.formBuilder.group({
      name: [this.user.name, Validators.required],
      surname: [this.user.surname, Validators.required],
      email: [this.user.username, Validators.required]
    });
  }

  /**
   * Loads profile image
   */
  loadImage(): void {
    this.croppedImage = null; this.base64Output = null;
    this.profileImage = this.imageService.getPersonalContactImageUrl(this.user.idErp);
    this.currTime = new Date().valueOf();
  }

  /**
   * On select file perform an upload
   * @param event event
   */
  onSelect(event): void {
    if (event.rejectedFiles.length > 0) { return; }
    this.convertFile(event.addedFiles[0]).subscribe(base64 => {
      this.base64Output = 'data:image/gif;base64,' + base64;
    });
  }

  /**
   * On click save submit changes
   */
  onSubmit(): void {
    if (this.croppedImage) {
      const formData = new FormData();
      fetch(this.croppedImage)
        .then(res => res.blob())
        .then(blob => {
          formData.append('file', new File([blob], 'profile.png', { type: 'image/png' }));
          this.imageService.uploadPersonalContactImage(formData).subscribe(arg => { this.loadImage(); });
        });
    }
  }

  /**
   * Convert from File into base64
   * @param file File
   * @returns base64
   */
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  /**
   * Opens modal wiht file upload component
   * @param content content
   * @param selectedPdf selected pdf to update
   */
  open(content): void {
    this.modalService.open(content);
  }

  /**
   * On remove event
   * @param event remove event
   */
  onRemove(event): void {
    this.croppedImage = null;
    this.base64Output = null;
  }

  /**
   * Files change event
   * @param event Files change event
   */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  /**
   * On images cropped event
   * @param event images cropped event
   */
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  /**
   * Gets save active
   */
  get saveActive(): boolean {
    return this.croppedImage != null || (this.editUserForm.valid && this.editUserForm.touched);
  }

}
