import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Article } from 'src/app/shared/models/article.model';
import { Offer } from '../models/offer.model';
import { environment } from './../../../environments/environment';
import { ArticleType } from './../models/article-type.enum';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  private apiUrl = environment.apiUrl;
  userId: string;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.authenticationService.currentUserId.subscribe(userId => {
      this.userId = userId;
    });
  }

  /**
   * Gets offers
   * @param startPosition start
   * @param endPosition end
   * @returns Observable
   */
  getOffers(startPosition: number, endPosition: number, searchText = null, showSalesEngineOnly: boolean = false) {
    const search = searchText == null ? '' : searchText + '/';
    return this.http.get<Offer[]>(`${this.apiUrl}/offers/${this.userId}/${startPosition}/${endPosition}/${showSalesEngineOnly}/${search}`)
      .pipe(map(
        offers => offers.map(offer => {
          offer.offerArticles.map(art => { art.articleType = ArticleType.OFFER_ARTICLE; return art; }); return offer;
        })
      ), catchError(error => {
        // console.error(`There was an error while getting your offers, please contact Haenssler.`);
        return of([]);
      }));
  }

  /**
   * Searchs offers by idOfferERP
   * @param idOfferERP idOfferERP
   * @returns Observable
   */
  searchOffersById(idOfferERP, showSalesEngineOnly= false) {
    return this.http.get<Offer[]>(`${this.apiUrl}/offers/${this.userId}/offer-search/${idOfferERP}/${showSalesEngineOnly}`)
      .pipe(map(
        offers => offers.map(offer => {
          offer.offerArticles.map(art => { art.articleType = ArticleType.OFFER_ARTICLE; return art; }); return offer;
        })
      ), catchError(error => {
        // console.error(`There was an error while getting your offers, please contact Haenssler.`);
        return of([]);
      }));
  }

  /**
   * Gets offer article
   * @param idOfferPosition idOfferPosition
   * @returns offer article
   */
  getOfferArticle(idOfferPosition) {
    return this.http.get<Article>(`${this.apiUrl}/offers/${this.userId}/${idOfferPosition}`)
      .pipe(map(offer => { offer.articleType = ArticleType.OFFER_ARTICLE; return offer; }), catchError(error => {
        console.error(`There was an error while loading the offer article, please contact Haenssler.`);
        return of(null);
      }));
  }
}
