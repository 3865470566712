<div class="container-fluid">
    <div class="row">
        <div class="col-lg-4 pl-0 mt-2">
            <h4>{{'profileMachineGroup.title' | translate}}</h4>
        </div>
    </div>

    <!-- BASIC INPUT SEARCH 🔍-->
    <div class="flex-row row justify-content-center remove-border filter-boxes">

        <div class="d-flex search-box col-1 remove-border"></div>

        <div class="d-flex search-box col-2" [ngClass]="{'disabled-box': showAdvancedSearch}">
            <input class="form-control se-input remove-border" type="text" [(ngModel)]="searchDisplayName"
                placeholder="{{'global.search' | translate}}" (input)="generalFilter()" [disabled]="showAdvancedSearch">

            <img src="/assets/img/icons/search.svg" class="search-icon">

            <div class="filter-icon d-flex align-items-center cursor-pointer eye-button"
                [ngClass]="showAdvancedSearch ? 'bi bi-eye-slash lborder' : ''" (click)="showAdvancedSearch = false">
            </div>
        </div>

        <div class="d-flex search-box col-2 remove-border ">
            <span class="d-flex align-items-center cursor-pointer" *ngIf="!showAdvancedSearch"
                (click)="showAdvancedSearch = true">Advanced search</span>
        </div>

    </div>
    <!-- END BASIC INPUT SEARCH 🔍-->

    <div class="table-container" role="table">

        <!-- HEADERS -->
        <div class="flex-table header" role="rowgroup">
            <ng-container *ngFor="let header of headers; let last = last; let i = index">
                <div [ngClass]="header.colsize + ' material-header'" (click)="sortMaterialProfiles(header.property)">
                    <div class="d-flex justify-content-between adv-search-box">
                        <div>
                            <b *ngIf="!last">{{ getTranslation(header.property) }}</b>
                        </div>
                        <div>
                            <i *ngIf="!last"
                                [ngClass]="i >= headers.length - 3 ?
                            (sortingDirections[header.property] === 'asc' ? 'bi-sort-numeric-down' : 'bi-sort-numeric-up-alt') :
                            (sortingDirections[header.property] === 'asc' ? 'bi-sort-alpha-down' : 'bi-sort-alpha-up-alt')"
                                class="sort-icon">
                            </i>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ADVANCED FILTERS ⚙️ -->
            <ng-container *ngIf="showAdvancedSearch" class="row adv-search-box">

                <div class="d-flex material-col col-2">
                    <input class="form-control se-input" type="text" [(ngModel)]="searchDisplayName"
                        placeholder="{{'global.search' | translate}}" (input)="filterByDisplayName()">
                    <img src="/assets/img/icons/search.svg" class="adv-search-icon">
                </div>

                <div class="d-flex material-col col-2">
                    <input class="form-control se-input" type="text" [(ngModel)]="searchDescription"
                        placeholder="{{'global.search' | translate}}" (input)="filterByDescription()">
                    <img src="/assets/img/icons/search.svg" class="adv-search-icon">
                </div>

                <div class="d-flex material-col col-1">
                    <ng-select [(ngModel)]="selectedColor" (change)="filterMaterialColor()" [multiple]="true"
                        [items]="usedColors" bindLabel="name" bindValue="id">
                    </ng-select>
                </div>

                <div class="d-flex material-col col-1">
                    <input class="form-control se-input" type="text" [(ngModel)]="searchHardness"
                        placeholder="{{'global.search' | translate}}" (input)="filterByHardness()">
                    <img src="/assets/img/icons/search.svg" class="adv-search-icon">
                </div>

                <div class="d-flex material-col col-2">
                    <ng-select [(ngModel)]="selectedConformities" (change)="filterByConformities()" bindLabel="name"
                        bindValue="idConformity" [items]="usedConformities" [multiple]="true"></ng-select>
                </div>

                <div class="d-flex material-col col-2">
                    <input class="form-control se-input" type="number" [(ngModel)]="searchTemp"
                        placeholder="{{'materialTable.operatingTemperature' | translate}}" (ngModelChange)="filterByTempRange()">
                </div>

                <div class="d-flex material-col col-2">

                    <div class="row">
                        <div class="col-6 d-flex align-items-center cursor-pointer" (click)="resetFilter()"
                            title="{{'profileMachineGroup.resetFilters' | translate}}">
                            <app-button>
                                <i class="bi bi-arrow-clockwise"></i>
                            </app-button>
                        </div>

                        <div class="col-6 d-flex align-items-center cursor-pointer" (click)="resetFilter()"
                            title="{{'profileMachineGroup.hideAdvFilters' | translate}}"
                            (click)="showAdvancedSearch = !showAdvancedSearch">
                            <app-button>
                                <span class="bi-x-lg"></span>
                            </app-button>
                        </div>
                    </div>

                </div>

            </ng-container>
            <!-- END ADVANCED FILTERS ⚙️ -->

        </div>
        <!-- END HEADERS -->


        <!-- ROWS DATA 📝-->
        <div class="flex-row row">

            <ng-container class="row" *ngFor="let material of displayMaterials; let i = index">
                <div class="col-2 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{material.displayName}}
                </div>
                <div class="col-2 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{material.description}}
                </div>
                <div class="col-1 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    <div class="color-circle" *ngIf="getColorHexById(material.colorId) != null" [ngbTooltip]="'global.color' | translate" [style.background-color]="getColorHexById(material.colorId)"></div>
                </div>
                <div class="col-1 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{material.hardness}}
                </div>
                <div class="col-2 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{ getConformityNamesByIds(material.conformitiesId).join(', ') }}
                </div>

                <div class="col-1 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{material.minTemp}}ºC 
                </div>

                <div class="col-1 material-col" [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    {{material.maxTemp}}ºC 
                </div>

                <div class="col-2 material-col d-flex align-items-center justify-content-center"
                    [ngClass]="i % 2 === 0 ? ' even-row' : ' odd-row'">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <div class="d-flex flex-row align-items-center justify-content-center">
                            <app-button style="align-self: center;" (click)="applyMaterial(material.idMaterialProfile)"
                                [disabled]="!disableOnIncorrectMeasures(material)">
                                {{ 'global.choose' | translate }}
                            </app-button>
                            <i *ngIf="!disableOnIncorrectMeasures(material)"
                                class="error-circle bi bi-exclamation-octagon ml-1" [ngbTooltip]="tooltipContent"></i>
                            <span class="ml-1 placeholder" *ngIf="disableOnIncorrectMeasures(material)"></span>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
        <!-- END DATA ROWS 📝-->
    </div>
</div>
<br>