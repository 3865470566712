<ng-container *ngIf="show">
    <ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="toast.autohide"
        [delay]="toast.delay || 5000" (hide)="toastService.remove(toast)">
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>
         <div class="body-wrapper">
            <i class="bi bi-x" (click)="toastService.remove(toast)"></i>
           
            {{ toast.textOrTpl }}
         </div>
        </ng-template>
    </ngb-toast>
</ng-container>