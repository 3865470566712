import { FileUtils } from './../../../shared/utils/file.utils';
import { PdfService } from './../../../shared/services/pdf.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { OrderHistory } from './../../../shared/models/order-history.model';
import { DateUtils } from './../../../shared/utils/date.utils';

@Component({
  selector: 'app-order-row',
  templateUrl: './order-row.component.html',
  styleUrls: ['./order-row.component.scss']
})
export class OrderRowComponent implements OnInit {
  @Input() order: OrderHistory;
  @Output() orderOpened = new EventEmitter();

  DateUtils = DateUtils;
  orderCollapse = true;
  overflow = false;
  selectedFile: string;
  fileToDownload = null;
  constructor(private pdfService: PdfService) { }

  ngOnInit(): void {
  }

  /**
   * Downloads pdf
   */
  downloadPdf(idOrderERP: string) {
    this.pdfService.getOrderPdf(idOrderERP).subscribe((data: any) => {
      if (data.size && data.size > 0) {
        const blob: any = new Blob([data], { type: 'application/pdf; charset=utf-8' });
        FileUtils.downloadFile(blob, this.order.idOrderERP);
      }
    });
  }
  /**
   * Collapses order
   * @param event event
   */
  collapseOrder(event) {
    const className = event.target.className;
    // avoid opening or closing on clicking pdf download button
    if (className !== 'download-pdf' && className !== 'download-pdf-img') {
      this.orderCollapse = !this.orderCollapse;
      this.orderOpened.emit(!this.orderCollapse);
      // make overflow visible when the order is opened
      if (!this.orderCollapse) {
        // wait until animation is finished
        setTimeout(() => {
          this.overflow = true;
        }, 500);
      } else {
        this.overflow = false;
      }
    }
  }

}
