import { UntypedFormControl } from '@angular/forms';
import { FrameworkContract } from './../../shared/models/framework-contract.model';
import { ParametersConfigurationService } from './../../shared/services/parameters-configuration.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { FrameworkContractService } from './../../shared/services/framework-contracts.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-framework-contracts',
  templateUrl: './framework-contracts.component.html',
  styleUrls: ['./framework-contracts.component.scss'],
  providers: [DecimalPipe, DatePipe],
})
export class FrameworkContractsComponent implements OnInit {
  contracts = [];
  fullContracts = [];
  loading = true;
  public searchFormControl = new UntypedFormControl('');
  public showLoadFullContract = false;
  public showLoadFullContractClicked = false;
  public singleArticleContractOpened = false;
  constructor(private frameworkContractService: FrameworkContractService) { }

  ngOnInit(): void {
    this.frameworkContractService.getFrameworkContracts().subscribe(data => {
      data = data.filter((obj: FrameworkContract) => (obj.validUntilDate !== null && obj.startDate !== null));
      this.fullContracts = data;
      this.contracts = data;
      this.loading = false;
    });
  }

  /**
   * Contracts opened
   */
  contractOpened(opened) {
    this.singleArticleContractOpened = opened;
  }

  /**
   * Gets full contract
   */
  getFullContract() {
    this.showLoadFullContractClicked = true;
  }

  /**
   * Search framework contracts by idFrameworkContractERP or idArticleERP
   */
  onSearch(searchText) {
    this.contracts = this.fullContracts.filter((contract: FrameworkContract) => {
      const term = searchText.toLowerCase();
      return contract.frameworkContractArticles.filter((contractArticle: any) => {
        return contractArticle.idFrameworkContractERP.toLowerCase().includes(term) ||
          contractArticle.idArticleERP.toLowerCase().includes(term) ||
          contractArticle.referenceNumber.toLowerCase().includes(term);
      }).length > 0;
    });

  }

}
