<form class="form-inline">
    <div class="form-group">
        <div class="input-group" [ngClass]="{'invalid': invalid}">
            <input class="form-control se-input" [placeholder]="placeholder || 'dd.mm.yyyy'" name="dp"
                [(ngModel)]="model" (ngModelChange)="emitDate()" (navigate)="monthChange($event)" ngbDatepicker
                #d="ngbDatepicker" [showWeekNumbers]="true" [dayTemplate]="customColors ? t : false" [markDisabled]="isDisabled">
            <div class="input-group-append" *ngIf="d">
                <button class="btn calendar se-border" (click)="d.toggle()" type="button">
                    <img src="assets/img/icons/calendar.svg" alt="pick date">
                </button>
            </div>
        </div>
    </div>
</form>
<ng-template #t let-date>
    <div class="text-center day-container"
        [ngStyle]="{'background-color': getDayColor(date.day, date.month, date.year)}"> {{ date.day }} </div>
</ng-template>
