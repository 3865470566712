import { Textblock } from './../../shared/models/textblock.model';
import { Component, OnInit } from '@angular/core';
import { TextblockService } from 'src/app/shared/services/textblock.service';

@Component({
  selector: 'app-textblocks',
  templateUrl: './textblocks.component.html',
  styleUrls: ['./textblocks.component.scss']
})
export class TextblocksComponent implements OnInit {
  addNew = false;
  loading = true;
  textblocks;
  constructor(private textblockService: TextblockService) { }

  ngOnInit(): void {
    this.loadTextblocks();
  }

  /**
   * Load textblocks
   */
  loadTextblocks() {
    this.loading = true;
    this.textblocks = null;
    this.textblockService.getTextblocks().subscribe((data: Textblock[]) => { this.textblocks = data; this.loading = false; });
  }

  /**
   * Add new textblock mode activate
   */
  addNewActivate(): void {
    this.addNew = true;
  }
}
