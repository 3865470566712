import { ScaledPrice } from './scaled-price.model';
import { CapacityPriceStatus } from './capacity-price-status.enum';
export class PriceInformation {
    priceStatus: CapacityPriceStatus;
    priceTotal: number;
    scaledPrice: ScaledPrice;
    amount: number;
    currency: string;
    dateText: string;
    date: Date;
}
