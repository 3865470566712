<div class="contact-us" *ngIf="currentUser">
    <div class="container">
        <div *ngIf="personalContactInfo && personalContactInfo.name" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between center">
            <div class="contact-person" fxLayout="row" fxLayout.lt-md="column">                
                <img src="{{personalContactInfo.profileImage}}" alt="contact-person" 
                    onerror="this.onerror=null;this.src='https://shop.dicht.de/static/personal-contact/dummy.jpg';"
                    class="contact-person__image">
                <div class="contact-person__info">
                    <h3 class="contact-person__title" i18n>{{'shared.personalContact.yourContactPerson'|translate}}</h3>
                    <p class="contact-person__label" *ngIf="personalContactInfo.name">{{personalContactInfo.name}}</p>
                    <p class="contact-person__label" *ngIf="personalContactInfo.position">
                        {{personalContactInfo.position}}</p>
                    <a class="contact-person__label" href="tel:{{personalContactInfo.telephone}}"
                        *ngIf="personalContactInfo.telephone">{{'shared.personalContact.phone'|translate}} {{personalContactInfo.telephone}}</a>
                    <a class="contact-person__label" href="mailto:{{personalContactInfo.email}}"
                        *ngIf="personalContactInfo.email">{{'shared.personalContact.email'|translate}} {{personalContactInfo.email}}</a>
                </div>
            </div>
            <div class="contact-action">
                <a class="contact-action__button button13" href="mailto:{{personalContactInfo.email}}">
                    <span i18n>{{'shared.personalContact.contactUs'|translate}}</span>
                </a>
            </div>
        </div>
        <div class="error" *ngIf="personalContactInfo && personalContactInfo.errorMessage">
            {{personalContactInfo.errorMessage|translate}}</div>
    </div>
</div>
