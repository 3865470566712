import { Profile } from './../../../../../shared/models/profile.model';
import { ProfileValidationService } from './../../../../../shared/services/profile-validation.service';
import { takeUntil } from 'rxjs/operators';
import { RemoveConfirmationModalComponent } from '../../../../../shared/components/delete-confirmation/remove-confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialProfile } from '../../../../../shared/models/material-profile.model';
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProfileComponentService } from '../../../../../shared/services/profile-component.service';
import { Component, OnInit, Input, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ProfileComponent } from 'src/app/shared/models/profile-component.model';
import { Subject } from 'rxjs';
import { ModalPopupComponent } from 'src/app/shared/components/modal-popup/modal-popup.component';
import { ConfiguratorTemplateComponent } from './configurator-template/configurator-template.component';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile-component.component.html',
  styleUrls: ['./profile-component.component.scss'],
})
export class ProfileComponentComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() profileComponent: ProfileComponent;
  @Input() profile: Profile;
  @Output() profileChange = new EventEmitter<Profile>();
  @Output() profileComponentChange = new EventEmitter<ProfileComponent>();
  @Input() idProfile: number;
  @Input() materialProfiles: MaterialProfile[];
  materialProfilesExcludeUsed: MaterialProfile[];
  createMode = false;
  editMode = false;
  addMaterialMode = false;
  selectedMaterial: number;
  invalidIds = [];
  articleGroups = [];
  articleGroupsById = [];
  selectedArticleGroup: string;
  editArticleGroup = false;
  constructor(private profileComponentService: ProfileComponentService, private profileValidationService: ProfileValidationService, private toastService: ToastService, private translationsService: TranslateService, private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.profileComponent == null) {
      return;
    }
    if (!this.profileComponent?.idProfileComponent) {
      this.createMode = true;
      this.editMode = true;
    }
    this.materialProfilesExcludeUsed = this.materialProfiles?.filter(x => !this.profileComponent?.profileComponentMaterials.some(y => y.materialProfile.idMaterialProfile === x.idMaterialProfile));
    this.invalidIds = this.profileComponent.profileComponentMaterials.filter(x => !x.valid).map(x => "material-profile-" + x.idProfileComponentMaterial);
    this.profileComponentService.getArticleGroups().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      // map by short name
      this.articleGroups = data;
      for (var i = 0; i < data.length; i++) {
        this.articleGroupsById[data[i].shortName] = data[i];
      }
    });
    this.profileValidationService.sealingTypesRevalidated.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.profileComponent.valid = this.profileValidationService.getProfileComponentValid(this.profileComponent.idProfileComponent);
      this.profileComponent.profileComponentMaterials.forEach(x => {
        x.valid = this.profileValidationService.getProfileComponentMaterialValid(x.idProfileComponentMaterial);
      });
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  /**
   * Updates profile component
   */
  updateProfileComponent() {
    if (!this.profileComponent.articleGroup) { return; }
    if (this.createMode) {
      this.profileComponentService.createProfileComponent(this.profileComponent, this.idProfile).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.profileComponent = data;
        this.profileComponentChange.emit(this.profileComponent);
        this.editMode = false;
        this.createMode = false;
        this.profileValidationService.updateValidations();
        // show toast message success
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      }, error => {
        // show toast message error
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
      });
    } else if (this.editMode) {
      this.profileComponentService.updateProfileComponent(this.profileComponent).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.profileComponent = data;
        this.profileComponentChange.emit(this.profileComponent);
        this.editMode = false;
        this.createMode = false;
        this.profileValidationService.updateValidations();
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      }, error => {
        // show toast message error
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
      });
    }
    this.editMode = false;
  }


  /**
   * Adds profile component material
   */
  addProfileComponentMaterial() {
    if (this.selectedMaterial) {
      this.profileComponentService.createProfileComponentMaterial(this.selectedMaterial, this.profileComponent.idProfileComponent).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.profileComponent = data;
        this.profileChange.emit(this.profile);
        this.profileValidationService.updateValidations();
        // remove selectedMaterial
        this.materialProfilesExcludeUsed = this.materialProfiles.filter(x => x.idMaterialProfile !== this.selectedMaterial);
        this.selectedMaterial = null;
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      }, error => {
        // show toast message error
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
      });
      // call rest api and add material to profile component
    }
  }

  /**
   * Deletes component
   */
  deleteComponent() {
    if (this.profileComponent.idProfileComponent) {
      this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
        if (result.remove === true) {

          this.profileComponentService.deleteProfileComponent(this.profileComponent.idProfileComponent).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.profile.profileComponents = this.profile.profileComponents.filter(x => x.idProfileComponent !== this.profileComponent.idProfileComponent);
            this.profileChange.emit(this.profile);
            this.profileComponent = null;
            this.profileComponentChange.emit(this.profileComponent);
            this.profileValidationService.updateValidations();
            this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
          }, error => {
            // show toast message error
            this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
          });
        }
      });
    } else { this.profileComponent = null; this.profileComponentChange.emit(this.profileComponent); }
  }

  /**
   * Deletes component material
   * @param idMaterialProfile 
   * @param idProfileComponent 
   */
  deleteComponentMaterial(idProfileComponentMaterial: number) {
    this.modalService.open(RemoveConfirmationModalComponent).result.then((result: any) => {
      if (result.remove === true) {
        this.profileComponentService.deleteProfileComponentMaterial(idProfileComponentMaterial).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          // delete by id
          this.profileComponent.profileComponentMaterials.splice(this.profileComponent.profileComponentMaterials.findIndex(x => x.idProfileComponentMaterial === idProfileComponentMaterial), 1);
          if (this.profileComponent.profileComponentMaterials.length === 0) {
            this.profileComponent.valid = false;
          }
          this.materialProfilesExcludeUsed = this.materialProfiles.filter(x => !this.profileComponent.profileComponentMaterials.some(y => y.materialProfile.idMaterialProfile === x.idMaterialProfile));
          this.profileValidationService.updateValidations();

          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
        }, error => {
          // show toast message error
          this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved-error'), { classname: 'bg-danger text-light', delay: 3000 });
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  


  
}
