import { TranslateService } from "@ngx-translate/core";
import { ToastService } from './../../shared/services/toast.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { SearchResult } from './../../shared/models/search-result-enum.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Offer } from './../../shared/models/offer.model';
import { OffersService } from './../../shared/services/offers.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import _ from 'lodash';
@Component({
	selector: 'app-offers',
	templateUrl: './offers.component.html',
	styleUrls: ['./offers.component.scss'],
	providers: [DecimalPipe, DatePipe],
})
export class OffersComponent implements OnInit, OnDestroy {
	offers: Offer[] = [];
	public offersFull: Offer[] = [];
	loading = true;
	// start loading articles from 0
	private startPos = 0;
	private fullListStartPos = 0;
	// any time we click load more 50 new articles will be loaded
	private numberOfArticlesToLoad = 50;
	// load articles until position
	private limit = this.numberOfArticlesToLoad;
	private loadedArticlesCount = 0;
	public searchFormControl = new UntypedFormControl('');
	public showSearch = false;
	private searchText = null;
	private unsubscribe: Subject<void> = new Subject();
	public showLoadFullOffer = false;
	public showLoadFullOfferClicked = false;
	public singleArticleOfferOpened = false;
	public showSalesEngineOnly = false;
	constructor(
		private offersService: OffersService,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private translationsService: TranslateService,
		private router: Router
	) { }

	ngOnInit(): void {
		// if route contains showSearch param load offers from local storage

		if (this.route.snapshot.paramMap.get('showSearch') || this.route.snapshot.paramMap.get('showCreatedOffer')) {
			this.showSearch = Boolean(this.route.snapshot.paramMap.get('showSearch'));
			// set search form control without triggering the search function
			this.searchFormControl.setValue(this.route.snapshot.paramMap.get('offerId'), { emitEvent: false });
			this.searchText = this.route.snapshot.paramMap.get('offerId');
			// check if offer is loaded completely
			this.showLoadFullOffer = !JSON.parse(this.route.snapshot.paramMap.get('complete'));
			if (this.route.snapshot.paramMap.get('showCreatedOffer')) {
				this.showSalesEngineOnly = true;
				this.searchOffersByIdOffer();
			}
		}
		this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
			if (this.route.snapshot.paramMap.get('showSearch')) {
				this.showLoadFullOfferClicked = false;
				this.showSearch = true;
				this.loadOffers();
				this.showLoadFullOffer = !JSON.parse(this.route.snapshot.paramMap.get('complete'));
				// set search form control on route change without triggering the search function
				this.searchFormControl.setValue(this.route.snapshot.paramMap.get('offerId'), { emitEvent: false });
			}
		});
		this.loadOffers();
	}
	onSwitchShowSalesEngineOnlyChanged(event) {
		this.startPos = 0;
		this.fullListStartPos = 0;
		this.numberOfArticlesToLoad = 50;
		this.limit = this.numberOfArticlesToLoad;
		this.loadedArticlesCount = 0;
		this.offers = []; this.loading = true;
		this.showSalesEngineOnly = event;
		this.loadOffers();
		if(this.route.snapshot.paramMap.get('offerId')) {
			this.searchOffersByIdOffer();
		}
	}
	/**
	 * Searches offers by text when user types into the search input
	 * @param data string
	 */
	onSearch(data: string) {
		data = data.trim();
		this.startPos = 0;
		this.loading = true;
		this.offers = [];
		if (data === '') {
			// if the search field is empty use the last original list values and show the list we had before search
			this.searchText = null;
			this.showSearch = false;
			this.offers = this.offersFull;
			this.startPos = this.fullListStartPos;
			if (this.offersFull.length === 0) {
				this.loadOffers();
			} else {
				this.loading = false;
			}
			return;
		}
		this.searchText = data;
		this.loadOffers();
		this.searchOffersByIdOffer();
		this.showSearch = false;
	}

	/**
	 * Loads more offers on click on load more
	 * @returns void
	 */
	loadMore() {
		if (!this.loadMoreAvailable) { return; }
		this.loading = true;
		if (this.offers !== null) {
			this.startPos += this.numberOfArticlesToLoad;
			this.limit = this.numberOfArticlesToLoad;
		}
		this.loadOffers();
	}

	/**
	 * Loads offers
	 */
	loadOffers() {
		if (this.showSearch) {
			// get offers from local storage
			this.offers = [JSON.parse(localStorage.getItem(SearchResult.SearchResultOffers))];
			this.loading = false;
		}
		else {

			this.offersService.getOffers(this.startPos, this.limit, this.searchText, this.showSalesEngineOnly)
				.pipe(takeUntil(this.unsubscribe)).subscribe((offers: Offer[]) => {
					// merge existing array with the received one
					if (offers) {
						this.offers = [...this.offers, ...offers];
						this.offers = _.uniqBy(this.offers, 'idOfferERP');
						this.loading = false;
						// number of articles received
						if (offers.length > 0) {
							this.loadedArticlesCount = offers[offers.length - 1].numberOfArticles;
						} else {
							this.loadedArticlesCount = 0;
						}

						if (this.searchText == null) {
							this.fullListStartPos = this.startPos;
							this.offersFull = this.offers;
						}
					} else {
						this.loading = false;
					}
				});
		}
	}

	/**
	 * Searchs offers by id offer and merges with matchcode search results
	 */
	searchOffersByIdOffer(loadFullListAction = false) {
		this.loading = true;
		this.offersService.searchOffersById(this.searchText, this.showSalesEngineOnly).pipe(takeUntil(this.unsubscribe)).subscribe((offers: Offer[]) => {
			// merge existing array with the received one
			if (offers && !loadFullListAction) {
				this.offers = [...this.offers, ...offers];
				this.offers = _.uniqBy(this.offers, 'idOfferERP');
			} else if (offers) {
				if (this.offers[0].offerArticles.length !== offers[0].offerArticles.length) {
					// on calling this function in offer to load the full offer articles list substitute offerArticles
					this.offers[0].offerArticles = offers[0].offerArticles;
				} else {
					// if no changes since reloading the offer show warning message no-more-full-offer-items
					this.showGetFullOfferWarning();
				}
			}
			this.loading = false;
		});
	}

	/**
	 * Loads the full offer with all the articles
	 */
	getFullOffer() {
		this.searchText = this.route.snapshot.paramMap.get('offerId');
		this.searchOffersByIdOffer(true);
		this.showLoadFullOfferClicked = true;
	}

	/**
	 * Used to show the load full offer button
	 * @param opened Boolean
	 */
	offerOpened(opened) {
		this.singleArticleOfferOpened = opened;
	}

	/**
	 * Shows a warning toaster message when the full offer contains only one article
	 */
	showGetFullOfferWarning() {
		this.toastService.show(
			this.translationsService.instant('codeTranslations.no-more-full-offer-items'),
			{ classname: 'bg-warning text-light', delay: 3000 }
		);
	}

	/**
	 * Gets load more available
	 */
	get loadMoreAvailable() {
		return (this.offers !== undefined
			&& this.loadedArticlesCount > 0);
	}

	/**
	 * on destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
