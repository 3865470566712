import { BreadcrumbService } from './../../services/breadcrumb.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  label: string;
  url: string;
  breadcrumbs: any[] = [];
  showBreadcrumb = false;
  constructor(private breadcrumbService: BreadcrumbService) { }

  public ngOnInit(): void {
    this.breadcrumbs = this.breadcrumbService.breadcrumbs;
    this.showBreadcrumb = this.breadcrumbService.showBreadcrumb;
    this.url = this.breadcrumbService.url;
    this.label = this.breadcrumbService.label;
  }

}
