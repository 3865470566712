import { takeUntil } from 'rxjs/operators';
import { PasswordResetRequest } from './../../shared/models/password-reset-request.model';
import { UserService } from './../../shared/services/user.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public forgotPasswordForm: UntypedFormGroup;
  public submitted = false;
  private unsubscribe: Subject<void> = new Subject();
  /**
   * Gets loginForm fields
   */
  get fields() { return this.forgotPasswordForm.controls; }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastService: ToastService,
    public codeTranslationsService: TranslateService) {
    // when already logged redirect to dashboard
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
    });
  }

  /**
   * Submit password request
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.userService.resetPasswordRequest({ email: this.fields.username.value } as PasswordResetRequest)
      .pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.toastService.show(this.codeTranslationsService.instant('codeTranslations.reset-password-email-sent'), { classname: 'bg-success text-light', delay: 2000 });
        window.setTimeout(() => {
          window.location.replace('/login');
        }, 2000);
      }
      );
  }

  get emailValid() {
    return !this.fields.username.hasError('email') && this.fields.username.value !== '';
  }

  get emailRequired() {
    return !this.fields.username.hasError('required') && this.fields.username.value !== '';
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
