<div class="order-toggle__header d-flex justify-content-between" (click)="collapseOrder($event)">
    <div class="order-toggle__name"><img src="/assets/img/icons/chevron-{{orderCollapse?'right':'down'}}.svg"
            alt="toggle"> BESTELLUNG NR {{order.idOrderERP}} <button class="download-pdf"
            (click)="downloadPdf(order.idOrderERP)"> <img src="/assets/img/icons/file.svg" alt="pdf-download-icon"
                class="download-pdf-img" [ngbTooltip]="'Download PDF'"></button>
    </div>
    <div class="order-toggle__date"> {{DateUtils.getFormattedDate(order.shippingDate, true)}}</div>
</div>
<div class="order-toggle__content" [class.opened]="!orderCollapse"
    [style.overflow]="overflow == true && orderCollapse == false ? 'visible' : 'hidden'">
    <section class="se-table-flex">
        <div class="se-table-header se-table-row">
            <div class="se-table-col sm position-col d-none d-md-block" i18n>Pos</div>
            <div class="se-table-col lg" i18n>{{'orders.article'|translate}} </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="amount-col" i18n> {{'orders.numOfPieces'|translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="date-col" i18n> {{'orders.shippingDate'|translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="price-col" i18n> {{'orders.basisPrice'|translate}} </div>
            </div>
            <div class="se-table-col d-none d-sm-flex md">
                <div class="price-col" i18n> {{'orders.totalPrice'|translate}} </div>
            </div>
            <div class="se-table-col md">
            </div>
        </div>
        <div *ngFor="let orderArticle of order.orderArticles; index as i" [orderArticle]="orderArticle" [index]="i"
            app-order-article-row class="order-row">
        </div>
        <div *ngIf="order.orderArticles != null && order.orderArticles.length == 0" class="se-table-row">
            <div class="se-table-col lg text-center" i18n>Keine Artikel zu zeigen </div>
        </div>
    </section>
</div>
