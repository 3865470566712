import { element } from 'protractor';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileMeasureInputLine } from '../../../../../../shared/models/profile-measure-input-line.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-profile-link-image-dialog',
  templateUrl: './profile-link-image-dialog.component.html',
  styleUrls: ['./profile-link-image-dialog.component.scss']
})
export class ProfileLinkImageDialogComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Input() image;
  @Input() profileMeasureInputLines: ProfileMeasureInputLine[];
  @Input() itemValueIndex: number;
  @Output() itemUpdated = new EventEmitter();
  @ViewChild('svg') svgEl;

  constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient, private renderer: Renderer2) { }

  ngOnInit(): void {
    // get svg from api
    this.httpClient.get(this.image, { responseType: 'text' }).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      // set svg to div element
      this.renderer.setProperty(this.svgEl.nativeElement, 'innerHTML', data);
      this.handleSvgInputs();
    });
  }

  /**
   * Iterates over all svg inputs and creates labels for them, adds event listeners and sets correct styles
   */
  handleSvgInputs() {
    // remove all previous event listeners from inputs
    this.svgEl.nativeElement.childNodes[2].replaceWith(this.svgEl.nativeElement.childNodes[2].cloneNode(true));
    // get all input elements in svg
    const inputs = this.svgEl.nativeElement.childNodes[2].querySelectorAll('input');
    // loop through all input elements
    for (let i = 0; i < inputs.length; i++) {
      // get profile item if its already assigned to input
      const profileMeasureInputLine = this.profileMeasureInputLines.find(x => x.idSvgInput === inputs[i].parentElement.id);
      // set default properties to input and assign the dimension name if assigned
      inputs[i].value = "";
      inputs[i].readOnly = true;
      inputs[i].disabled = true;
      // if dimension is not assigned add event listener to input to assign it on click
      if (!profileMeasureInputLine) {
        if (!inputs[i].parentElement.getAttribute('readonly')) {
          inputs[i].disabled = false;
          inputs[i].style.borderColor = "#000";
          inputs[i].addEventListener('click', (e) => {
            // assign input to dimension
            this.profileMeasureInputLines[this.itemValueIndex].idSvgInput = e.target.parentElement.id;
            this.itemUpdated.emit(this.profileMeasureInputLines[this.itemValueIndex]);
            this.activeModal.close();
          });
        } else {
          inputs[i].disabled = true;
          inputs[i].style.cursor = 'not-allowed';
          inputs[i].style.borderColor = "#999";
          inputs[i].style.backgroundColor = "#bfbfbf";
        }
      }
      else {
        const label = document.createElement('label');
        label.style.position = 'absolute'; label.style.bottom = '-15px'; label.style.fontSize = '10px'; label.style.width = 'max-content';
        inputs[i].parentElement.style.overflow = 'visible'; label.innerHTML = `${profileMeasureInputLine.name}`;
        inputs[i].parentElement.prepend(label);
      }
      if (profileMeasureInputLine && profileMeasureInputLine.idProfileMeasureInputLine === this.profileMeasureInputLines[this.itemValueIndex].idProfileMeasureInputLine) {
        // add cross icon
        const el = this.renderer.createElement('i');
        el.classList.add('bi', 'bi-x-lg');
        inputs[i].style.borderColor = "#00d47c";
        inputs[i].parentElement.appendChild(el);
        // add event listener on click on remove cross icon
        el.addEventListener('click', (e) => {
          this.profileMeasureInputLines[this.itemValueIndex].idSvgInput = null;
          this.itemUpdated.emit(this.profileMeasureInputLines[this.itemValueIndex]);
          inputs[i].parentElement.removeChild(el);
          this.handleSvgInputs();
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
