import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserRoleType } from './../../../models/user-role-type.enum';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }
  sidebarOpened = false;
  me: User;
  customerCare: User;
  private unsubscribe: Subject<void> = new Subject();
  ngOnInit(): void {
    this.authenticationService.currentUser.pipe(
      takeUntil(this.unsubscribe)).subscribe(currentUser => {
        if (currentUser !== null && currentUser !== undefined) {
          if (this.customerCare === undefined) {
            // get user information for normal user
            this.authenticationService.currentUserId.pipe(takeUntil(this.unsubscribe)).subscribe(userId => {
              this.authenticationService.getLoggedUser().pipe(takeUntil(this.unsubscribe))
                .subscribe((user: any) => { if (user.roles.indexOf(UserRoleType.ACCOUNT_MANAGER) === -1) { this.me = user; } });
            });
          }
          if (this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER)) {
            // get information for the default registred customer care user
            if (currentUser.loginAsManager === undefined) {
              this.authenticationService.getAccountManager(currentUser.idErp).pipe(takeUntil(this.unsubscribe))
                .subscribe(customerCare => { this.customerCare = customerCare; });
            } else {
              // substituted customer care user
              this.authenticationService.getAccountManager(currentUser.loginAsManager).pipe(takeUntil(this.unsubscribe))
                .subscribe(customerCare => { this.customerCare = customerCare; });
            }
            if (!currentUser.loginAs) {
              this.me = null;
            }
          }
        }
      });
  }
  openSidebar() {
    this.sidebarOpened = true;
  }
  logout() {
    this.authenticationService.logout();
  }

  get showShoppingCart() {
    return this.router.url !== '/manage-account';
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
