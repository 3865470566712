import { UserRoleType } from './../shared/models/user-role-type.enum';
import { ShoppingCartService } from './../shared/services/shopping-cart.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AdminGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isAuthorized(UserRoleType.ADMIN)) {
            // if user has admin role
            return true;
        }

        // otherwise redirect to dashboard
        this.router.navigate(['/dashboard']);
        return false;
    }

}
