<ng-container [formGroup]="profileForm">
    <div class="w-100">
        <h5 class="se-title">
            {{'sealingType.profile.inputValues'|translate}}
        </h5>
    </div>
    <div class="card-items" formArrayName="profileMeasureInputLines">
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <!-- HSEP-1580 Dragable rows "cdkDrag"-->
            <div class="card-item d-flex align-items-center justify-content-start flex-sm-row flex-column"
                cdkDragLockAxis="y" cdkDrag *ngFor="let itemValue of profile.profileMeasureInputLines; let j = index"
                [formGroupName]="j">
                <div class="custom-placeholder" *cdkDragPlaceholder></div>
                <ng-container *ngIf="{editMode: itemValue.idProfileMeasureInputLine === null} as edit">
                    <div class="card-item-input card-item-input-name d-flex align-items-center">
                        <div *ngIf="!lineIndex; else elseblock" class="col-lg-1 drag-and-drop" cdkDragHandle>
                            <i class="bi bi-grip-vertical"></i>
                        </div>
                        <ng-template #elseblock>
                            <div *ngIf="itemValue.idProfileMeasureInputLine != null"
                                class="col-lg-1 drag-and-drop d-flex">{{'{'+(j+1)+'}'}}</div>
                        </ng-template>

                        <div class="ml-2 max-flex-width-100">
                            <div class="card-item-label card-item-label-title d-flex align-items-center">
                                <input type="text" class="form-control" [readonly]="!edit.editMode" placeholder="{{'sealingType.profile.name'|translate}}" formControlName="name" (change)="updateProfileValuesWithDelay()" *ngIf="edit.editMode">
                                <span *ngIf="!edit.editMode">{{itemValue.name}}</span>
                                <!-- <i class="bi bi-check2 ml-3"
                                    *ngIf="edit.editMode && itemValue.idProfileMeasureInputLine != null"
                                    (click)="updateProfileValues();edit.editMode = false"></i>
                                <i class="bi bi-x" (click)="edit.editMode = !edit.editMode;"
                                    *ngIf="edit.editMode && itemValue.idProfileMeasureInputLine != null"></i> -->
                                <i class="bi bi-pencil ml-2"
                                    (click)="itemValue.readonly ? null : edit.editMode = !edit.editMode"
                                    [ngClass]="{'disabled':itemValue.readonly}"
                                    *ngIf="!itemValue.readonly && !edit.editMode">
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex center-values ml-3">
                        <div class="card-item-input d-flex align-items-center">
                            <div class="card-item-label">
                                {{'sealingType.profile.from'|translate}}
                            </div>
                            <div class="card-item-value ml-2 ">
                                <input type="number" class="form-control w-100" placeholder="{{'sealingType.profile.from'|translate}}" min="0"
                                    (change)="updateProfileValuesWithDelay()" formControlName="fromValue">
                            </div>
                        </div>
                        <div class="card-item-input d-flex align-items-center">
                            <div class="card-item-label">
                                {{'sealingType.profile.to'|translate}}
                            </div>
                            <div class="card-item-value ml-2 "> <!-- HSEP-1585 -->
                                <input type="number" class="form-control" placeholder="{{'sealingType.profile.to'|translate}}" min="0"
                                    (change)="updateProfileValuesWithDelay()" formControlName="toValue">
                            </div>
                        </div>
                        <!-- <div class="card-item-input d-flex align-items-center factor-item-input">
                        <div class="card-item-label">
                            Aufmaß
                        </div>
                        <div class="card-item-value ml-2 ">
                            <input type="text" class="form-control" placeholder="Faktor" min="0" pattern="^([-+*] ?\d+(\.\d+)? ?)*$" step="any" (change)="updateProfileValuesWithDelay()" formControlName="factor">
                        </div>
                    </div> -->
                    </div>
                    <div
                        class="flex-wrap flex-lg-nowrap d-flex align-items-center line-control-buttons ml-auto">
                        <i class="bi bi-calculator" [ngbTooltip]="'Formulas'" 
                            [class.active]="canOpenLinkValueModal(itemValue)" 
                            [class.inactive]="!canOpenLinkValueModal(itemValue)" 
                            (click)="openLinkValueModal(j)"></i>
                        <i class="bi bi-tag" (click)="openLinkImageModal(j)"
                            [ngClass]="{'active': itemValue.idSvgInput, 'disabled': openLinkImageDisabled, 'not-assigned': (!itemValue.idSvgInput && itemValue.readonly)}"></i>
                        <i class="bi bi-trash3" [ngbTooltip]="'Delete'" (click)="itemValue.readonly ? null : deleteProfileMeasureInputLine(j)"
                            [ngClass]="{'disabled':itemValue.readonly}" *ngIf="!itemValue.readonly"></i>
                    </div>
                </ng-container>
            </div>
        </div>
        <app-add-new-button padding="10px 0 0 0" text="{{'sealingType.profile.addNewMeasure'|translate}}" (onAddClick)="addItem()">
        </app-add-new-button>
    </div>
</ng-container>