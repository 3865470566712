<form>
    <div class="form-group form-inline">
        <div class="ml-auto d-flex">
            <!--<app-datepicker class="datepicker-filter-input" [placeholder]="'Von'" [minDate]="minDate"></app-datepicker>
            <app-datepicker class="datepicker-filter-input ml-2" [placeholder]="'Bis'" [minDate]="minDate"></app-datepicker>-->
            <input class="form-control ml-2 se-input search-article-input" type="text" [formControl]="searchFormControl"
                (keyup.enter)="searchFormControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });"
                [placeholder]="placeholder" />
            <button type="submit" class="btn se-border search-article-button">
                <img src="/assets/img/icons/search.svg" alt="search-icon">
            </button>
        </div>
    </div>
</form>
