import { UserRoleType } from './../../../../shared/models/user-role-type.enum';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShoppingCartService } from 'src/app/shared/services/shopping-cart.service';
import { AddressPrefered } from '../../../../shared/models/address-preferred.model';
import { CreateOrderResponse } from '../../../../shared/models/create-order-response.model';
import { OrderRequest } from '../../../../shared/models/order-request.model';
import { UserAddress } from '../../../../shared/models/user-address';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { TranslateService } from "@ngx-translate/core";
import { OrderService } from '../../../../shared/services/order.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserAddressService } from '../../../../shared/services/user-address.service';

@Component({
  selector: 'app-create-order-form',
  templateUrl: './create-order-form.component.html',
  styleUrls: ['./create-order-form.component.scss']
})
export class CreateOrderFormComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  @Output() orderCreated = new EventEmitter();
  orderAddressForm: UntypedFormGroup;
  invoiceAddresses: UserAddress[];
  deliveryAddresses: UserAddress[];
  selectedAddress: AddressPrefered = new AddressPrefered();
  API_ERROR_STATUS = 'API_ERROR';
  ABAS_SENT_OK_STATUS = 'SENT_ERP_OK';
  EMAIL_SEND_ERROR = 'EMAIL_SEND_ERROR';
  ABAS_SENT_ERROR_STATUS = 'SENT_ERP_ERROR';
  ABAS_MISSING_ORDER_ID = 'MISSING_ORDER_ID';
  VALIDATION_ERROR_STATUS = 'VALIDATION_ERROR';
  VALIDATION_STATUS_INVALID_DATE = 'INVALID_DATE';
  VALIDATION_STATUS_INVALID_PRICE = 'INVALID_PRICE';
  VALIDATION_STATUS_UNKNOWN_ERROR = 'UNKNOWN_ERROR';
  processingOrder = false;
  idAccountManager: string;
  constructor(
    private userAddressService: UserAddressService,
    private orderService: OrderService,
    private cartService: ShoppingCartService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private translationsService: TranslateService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.isUserAccountManager) {
      this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe)).subscribe(data => this.idAccountManager = data.idErp);
    }

    // gets last saved address choice in local storage
    this.selectedAddress = this.userAddressService.getAddressPreferredChoice();
    // form group for order form
    this.orderAddressForm = new UntypedFormGroup({
      deliveryAddressId: new UntypedFormControl(this.selectedAddress ? this.selectedAddress.deliveryAddressId : null),
      invoiceAddressId: new UntypedFormControl(this.selectedAddress ? this.selectedAddress.invoiceAddressId : null),
      idClientOrder: new UntypedFormControl(this.selectedAddress ? this.selectedAddress.idClientOrder : null),
      idAccountManager: new UntypedFormControl(this.isUserAccountManager ? this.idAccountManager : null),
      shippingNote: new UntypedFormControl(this.selectedAddress ? this.selectedAddress.shippingNote : null),
      customDeliveryAddress: new UntypedFormControl()
    });
    // getting delivery and invoice addresses
    this.userAddressService.get().pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.invoiceAddresses = data.invoice;
      this.deliveryAddresses = data.delivery;
      if (this.fields.deliveryAddressId.value == null) {
        this.orderAddressForm.controls.deliveryAddressId.setValue(this.deliveryAddresses[0].idUserAddressERP);
      }
      if (this.fields.invoiceAddressId.value == null) {
        this.orderAddressForm.controls.invoiceAddressId.setValue(this.invoiceAddresses[0].idUserAddressERP);
      }
    });

    // on changing form values updates address in local storage
    this.orderAddressForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data: AddressPrefered) => {
      this.userAddressService.saveAddressPreferredChoice(data);
    });
  }
  /**
   * On submit form
   */
  onFormSubmit() {
    if (this.processingOrder === true) {
      return;
    }
    this.processingOrder = true;
    // creating a new order request
    const orderRequest: OrderRequest = {
      deliveryAddressERP: this.orderAddressForm.value.deliveryAddressId === '-1' ? null : this.orderAddressForm.value.deliveryAddressId,
      invoiceAddressERP: this.orderAddressForm.value.invoiceAddressId,
      message: this.orderAddressForm.value.shippingNote,
      idClientOrder: this.orderAddressForm.value.idClientOrder,
      idAccountManager: this.orderAddressForm.value.idAccountManager,
      languageCode: this.translationsService.getDefaultLang(),
      customDeliveryAddress: this.orderAddressForm.value.deliveryAddressId === '-1' ?
        this.orderAddressForm.value.customDeliveryAddress : null
    };

    // placing an order
    this.orderService.createOrder(orderRequest).pipe(takeUntil(this.unsubscribe))
      .pipe(takeUntil(this.unsubscribe)).subscribe((order: CreateOrderResponse) => {
        if (order.status === this.ABAS_SENT_OK_STATUS || order.status === this.EMAIL_SEND_ERROR) {
          if (order.status === this.EMAIL_SEND_ERROR) {
            this.showOrderCreatedOErrorMessage(this.translationsService.instant('codeTranslations.order-email-error'));
          } else {
            // show success message
            this.showOrderCreatedMessage();
          }
          // reset articles number in cart
          this.cartService.loadNumberOfArticles();
          // reset form
          this.orderAddressForm.reset();
          // navigate to the dashboard
          this.orderCreated.emit(order);
          // this.router.navigate(['/dashboard']);
        }
        else if (order.status === this.ABAS_SENT_ERROR_STATUS) {
          this.showOrderCreatedOErrorMessage(this.translationsService.instant('codeTranslations.order-erp-error') + order.message);
        }
        else if (order.status === this.API_ERROR_STATUS) {
          this.showOrderCreatedOErrorMessage(this.translationsService.instant('codeTranslations.order-api-error') + order.message);
        }
        else if (order.status === this.ABAS_MISSING_ORDER_ID) {
          this.showOrderCreatedOErrorMessage(this.translationsService.instant('codeTranslations.order-erp-error-id'));
        }
        else if (order.status === this.VALIDATION_ERROR_STATUS) {
          const translationKey = this.getErrorTranslationKey(order.validationStatus);
          this.showOrderCreatedOErrorMessage(this.translationsService.instant('codeTranslations.' + translationKey));
          this.router.navigate(['/cart']);
        }
        this.processingOrder = false;
      });
  }

  /**
   * Gets translation key for validationStatus
   * @param validationStatus string
   * @returns string
   */
  getErrorTranslationKey(validationStatus: string) {
    if (validationStatus === this.VALIDATION_STATUS_INVALID_DATE) { return 'order-validation-date-invalid'; }
    if (validationStatus === this.VALIDATION_STATUS_INVALID_PRICE) { return 'order-validation-price-invalid'; }
    if (validationStatus === this.VALIDATION_STATUS_UNKNOWN_ERROR) { return 'order-validation-unknown-error'; }
  }

  /**
   * Shows order created message
   */
  showOrderCreatedMessage() {
    this.toastService.show(this.translationsService.instant('codeTranslations.order-form-order-created'),
      { classname: 'bg-success text-light', delay: 5000 });
  }

  /**
   * Shows order error at creation created message
   */
  showOrderCreatedOErrorMessage(errorText: string) {
    this.toastService.show(this.translationsService.instant('codeTranslations.'+errorText),
      { classname: 'bg-danger text-light', delay: 5000 });
  }

  /**
   * Gets fields from form control
   */
  get fields() { return this.orderAddressForm.controls; }

  /**
   * Determines whether button is disabled or not
   */
  get ifButtonDisabled() {
    if (this.processingOrder === true ||
      (this.fields.deliveryAddressId.value == null || this.fields.invoiceAddressId.value == null) ||
      (this.fields.deliveryAddressId.value === '-1' && (this.fields.customDeliveryAddress.value === null ||
        this.fields.customDeliveryAddress.value.length === 0))) {
      return true;
    }
    return false;
  }

  get isUserAccountManager() {
    return this.authenticationService.isAuthorized(UserRoleType.ACCOUNT_MANAGER);
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
