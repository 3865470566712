import { ManageRole } from './../models/manage-role.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {
  private apiUrl = environment.apiUrl;
  private user: User;
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {
    this.authenticationService.currentUser.subscribe(user => {
      this.user = user;
    });
  }
  /**
   * Gets a list of users
   * @returns Observable
   */
  getUsers() {
    return this.http.get(`${this.apiUrl}/users/list/`);
  }

  /**
   * Assigns role to user
   * @param manageRole ManageRole
   * @returns Observable
   */
  assignRole(manageRole: ManageRole) {
    return this.http.post(`${this.apiUrl}/users/assign-role`, manageRole);
  }
  /**
   * Removes role of user
   * @param manageRole ManageRole
   * @returns Observable
   */
  removeRole(manageRole: ManageRole) {
    return this.http.post(`${this.apiUrl}/users/remove-role`, manageRole);
  }

  /**
   * Blocks user
   * @param idUserERP idUserERP
   * @returns Observable
   */
  blockUser(idUserERP: string) {
    return this.http.post(`${this.apiUrl}/users/block-user`, { idUserERP });
  }

  /**
   * Unblocks user
   * @param idUserERP idUserERP
   * @returns Observable
   */
  unblockUser(idUserERP: string) {
    return this.http.post(`${this.apiUrl}/users/unblock-user`, { idUserERP });
  }
}
