import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfileComponentMaterialTemplateLine } from 'src/app/shared/models/profile-component-material-template-line.model';
import { ProfileComponentMaterialTemplate } from 'src/app/shared/models/profile-component-material-template.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslateService } from "@ngx-translate/core";
import { ProfileComponentTemplateService } from 'src/app/shared/services/profile-component-template.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil, map, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-outer-dimension-templates-list-line',
  templateUrl: './outer-dimension-templates-list-line.component.html',
  styleUrls: ['./outer-dimension-templates-list-line.component.scss']
})

// Lines used for the parent element OuterDimensionTemplatesListComponent
export class OuterDimensionTemplatesListLineComponent implements OnInit {

  @Input() templateLine: ProfileComponentMaterialTemplateLine;
  @Input() template: ProfileComponentMaterialTemplate;
  @Input() templateLines: ProfileComponentMaterialTemplateLine[];
  @Input() validateForm = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter();
  @Output() templateLineChange = new EventEmitter<ProfileComponentMaterialTemplateLine>();
  private unsubscribe: Subject<void> = new Subject();
  
  templateLineForm: UntypedFormGroup;
  timer: any;

  constructor(private fb: UntypedFormBuilder, private toastService: ToastService, private templateService: ProfileComponentTemplateService, private translationsService: TranslateService) { }

  ngOnInit(): void {
    this.createForm();

    this.templateLineForm.valueChanges.subscribe(data => {
      this.updateItemValuesWithDelay()
    });
    this.validateForm.subscribe(data => {
      if (data != this.templateLine?.idTemplateLine) {
        this.templateLineForm.updateValueAndValidity({ emitEvent: false });
        this.templateLineForm.controls.toValue.updateValueAndValidity({ emitEvent: false });
        this.templateLineForm.controls.fromValue.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.templateLineForm) {
      this.templateLineForm.updateValueAndValidity();
    }
  }

  // Creates an empty form to then fill up
  createForm() {
    this.templateLineForm = this.fb.group({
      idTemplateLine: [this.templateLine?.idTemplateLine || 0],
      fromValue: [this.templateLine?.fromValue || 0],
      toValue: [this.templateLine?.toValue || null],
      setupUnit: [this.templateLine?.setupUnit || null],
      processingTime: [this.templateLine?.processingTime || null],
      idTemplateFK: [this.templateLine?.idTemplateFK || null],
    });
  }

  // Adds a delay to modify values after one second of being modified, instead of every time you type each number/letter
  updateItemValuesWithDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.updateProfileComponentMaterialLine();
    }, 1000);
  }

  addLine() {
    this.templateService.createProfileComponentTemplateLine(
      this.templateLineForm.value).subscribe(data => {
        this.modify(this.templateLine, data);
        this.templateLineChange.emit(this.templateLine);
        this.templateLineForm.patchValue(this.templateLine, { emitEvent: false });
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      });

  }

  updateProfileComponentMaterialLine() {

    // If it's a new Line we create it.
    if (!this.templateLine?.idTemplateLine) {

        this.templateService.createProfileComponentTemplateLine(
          this.templateLineForm.value).subscribe(data => {
            this.modify(this.templateLine, data);
            this.templateLineChange.emit(this.templateLine);
            this.templateLineForm.patchValue(this.templateLine, { emitEvent: false });
            this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
          });

    // If it's an existing line, we update it.
    } else {
      this.templateService.updateProfileComponentTemplateLine(this.templateLineForm.value).subscribe(data => {
        this.modify(this.templateLine, data);
        this.templateLineChange.emit(this.templateLine);
        this.templateLineForm.patchValue(this.templateLine, { emitEvent: false });
        this.toastService.show(this.translationsService.instant('codeTranslations.configuration-changes-saved'), { classname: 'bg-success text-light', delay: 3000 });
      });
    }
  }

  // Overrides the old object with a modified new one
  modify(obj, newObj) {
    Object.keys(obj).forEach(function (key) {
      delete obj[key];
    });

    Object.keys(newObj).forEach(function (key) {
      obj[key] = newObj[key];
    });
  }

  /**
   * Deletes profile component material line
   */
  deleteProfileComponentMaterialTemplateLine() {
    if (!this.templateLine.idTemplateLine) {
      this.onDelete.emit();
    } else {
      this.onDelete.emit();
      // Delete it visually and from the database.
      this.templateService.deleteProfileComponentTemplateLine(this.templateLine.idTemplateLine)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
    }
  }

  /**
   * on destroy
   */
    ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  
}
